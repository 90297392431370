import { Injectable } from '@angular/core';
import { AngularFireAuth, } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { User } from '../models/users.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private afauth: AngularFireAuth) {

        //al Iniciar este servicio, se llama desde el construtor la funcion para obetener el Id del Usuario Logeado
        this.getUid();
    }

    async register(datos: User) {
        return await this.afauth.createUserWithEmailAndPassword(datos.email, datos.password);
    }

    async login(email: string, password: string) {
        return await this.afauth.signInWithEmailAndPassword(email, password);
    }


    async loginWithGoogle(email: string, password: string) {
        return await this.afauth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    async recuperAcceso(email: string) {
        return await this.afauth.sendPasswordResetEmail(email);
    }

    //creo una funcion tipo OBSERVABLE para conocer el estado de authenticacion del usuario
    getUserLogged() {
        try {
            return this.afauth.authState;
        } catch (err) {
            return null;
        }
    }

    //desloguea el usuario actual
    async logout() {
        return await this.afauth.signOut();
    }

    //obtiene el usuario actual
    async getUserCurrent() {
        return await this.afauth.currentUser;
    }

    //Creo Funcion async para obtener solo el ID del usuario Logeado
    async getUid() {
        const user = await this.afauth.currentUser;
        if (user === null) {
            return null;
        } else {
            return user.uid;
        }
    }

    //funcion para eliminar usuario 28/03/24 se creo para las proliticas de privacidad datos goog
    async deleteUser() {
        try {
            return (await this.afauth.currentUser).delete();
        } catch {
            return null;
        }
    }

    //(en proceso)funcion para verificar si el usuario inicio sesion recientemente  05/04/24 se creo para las proliticas de privacidad datos goog
    async checkTimeAuth() {
        try {
            return (await this.afauth.currentUser).metadata
        } catch {
            return null;
        }
    }

}
