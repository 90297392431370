import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';

// Importaciones relacionadas a publicidad Admob
 /* se inhabilita publicidad al 03-05-24
import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition
} from '@capacitor-community/admob';
*/

@Component({
  selector: 'app-afiliacion',
  templateUrl: './afiliacion.component.html',
  styleUrls: ['./afiliacion.component.scss'],
})
export class AfiliacionComponent implements OnInit {

  constructor(private router: Router,
    private toastController: ToastController,
    private platform: Platform) { }

  async ngOnInit() {
   // await this.initializeAdmob();
  }


  //inicializo la publicidad Admob
   /* se inhabilita publicidad al 03-05-24
  async initializeAdmob() {

    //await this.platformh.ready();
    if (this.platform.is('cordova')) {
      const options: BannerAdOptions = {
        adId: 'ca-app-pub-4505046024910255/8420417432',
        adSize: BannerAdSize.BANNER,
        position: BannerAdPosition.BOTTOM_CENTER,
        margin: 0,
        //isTesting: false,
        isTesting: true,
        npa: false
      };

      await AdMob.initialize({
        requestTrackingAuthorization: false,
        //initializeForTesting: false,
        initializeForTesting: true,
      }).catch(err => console.error('Could not init Admob', err));

      await AdMob.showBanner(options).catch(err => console.error('Could not show Admob banner', err));;

    }

  }

  ionViewDidLeave() {
    if (AdMob && this.platform.is('cordova')) {
      AdMob.removeBanner();
    }
  }
  */

  //funcion prueba para abrir menú modelo
  async abremenu() {
    try {
      return await this.router.navigate(['/menumovil/+584243065560']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast1(mensaje: string) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle',
    });
    await toast1.present();
  }

  //inicar conversación por whatssap
  startWhass() {
    let link = 'https://wa.me/+584243065560';
    window.open(link, '_blank');
  }

  //inicar conversación por whatssap
  abregplay() {
    let link = 'https://play.google.com/store/apps/details?id=com.entregamovil.entregamovil';
    window.open(link, '_blank');
  }

  //funcion para copiar Pedido
  async copiaTexto() {
    let inputFalso = document.createElement('input');
    inputFalso.setAttribute('value', 'soporte.entregamovil@gmail.com');
    document.body.appendChild(inputFalso);
    inputFalso.select();
    document.execCommand("copy");
    document.body.removeChild(inputFalso);
    return await this.presentToast1('Email de contacto copiado ' + inputFalso.value)
  }

}
