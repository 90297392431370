import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn, QueryGroupFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore) { }

  //Servicio para crear un nuevo Usuario 
  async createUser(data: any, path: string, id: string) {
    const collection = this.firestore.collection(path);
    return await collection.doc(id).set(data);
  }

  //Servicio para crear un nuevo Documento 
  async createDoc<tipo>(data: any, path: string) {
    const collection = this.firestore.collection<tipo>(path);
    return await collection.add(data);
  }

  //creando un Servicio para crear un nuevo Documento con ID (le agregué el <tipo> al 03-05-22 verificar si trae errores)
  async createDoc2<tipo>(data: any, path: string, id: string) {
    const collection = this.firestore.collection<tipo>(path);
    return await collection.doc(id).set(data);
  }

  //reando servicio para actualizar documento
  async updateDoc<tipo>(data: any, path: string, id: string) {
    const collection = this.firestore.collection<tipo>(path);
    return await collection.doc(id).update(data);
  }

  getId(): string {
    return this.firestore.createId();
  }

  getCollection<tipo>(path: string): Observable<any> {
    const collection = this.firestore.collection<tipo>(path);
    return collection.valueChanges()
  }

  /*esta funcion la cree para que sea usada por la funcion getcomercio de componente menumovil, existe un error 
  con el tipo de observable si lo dejo sin []*/
  getCollection2<tipo>(path: string): Observable<tipo[]> {
    const collection = this.firestore.collection<tipo>(path);
    return collection.valueChanges()
  }

  /*Funcion tipo observable para traer parte de una coleccion segun una 
  condicion en pruebas*/
  getCollection3<tipo>(path: string, ref: QueryFn): Observable<tipo[]> {
    const collection = this.firestore.collection<tipo>(path, ref);
    return collection.valueChanges()
  }

  //funcion para recuperar un documento en especifico sin obervable
  getCollection4<tipo>(path: string): Promise<any> {
    const collection = this.firestore.doc<tipo>(path);
    return collection.get().toPromise();
  }

  /*Funcion para traer (sin observable) parte de una coleccion segun una condicion
   en pruebas*/
  getCollection5<tipo>(path: string, ref: QueryFn): Promise<any> {
    const collection = this.firestore.collection<tipo>(path, ref);
    return collection.get().toPromise();
  }

  //llamo el registro de ubicacion del repartidor 
  getlocateRepartidores<tipo>(path: string): Observable<any> {
    return this.firestore.collection<tipo>(path, ref => ref.orderBy('fecha', 'desc')).stateChanges();
  }

  //llamo el registro de estados de repartidores 
  getestadoRepartidores<tipo>(path: string, ref: QueryFn): Observable<any> {
    return this.firestore.collection<tipo>(path, ref).valueChanges();
  }

  //Verifico si el repartidor esta ocupado y en linea 
  getOcupadorepartidor<tipo>(path: string, id: string): Observable<any> {
    const collection = this.firestore.collection(path).doc<tipo>(id);
    return collection.valueChanges();
  }

  getDoc<tipo>(path: string, id: string): Observable<any> {
    const collection2 = this.firestore.collection(path).doc<tipo>(id);
    return collection2.valueChanges()
  }

  getDoc2<tipo>(path: string, id: string): Promise<any> {
    const collection = this.firestore.collection(path).doc<tipo>(id);
    return collection.get().toPromise();
  }

  //servicio para eliminar el Producto
  async deletePro(path: string, id: string) {
    const collection = this.firestore.collection(path);
    return await collection.doc(id).delete();
  }

  //servicio para eliminar carrito cuando ya ha sido enviado
  async deleteCarrito(path: string, id: string) {
    const collection = this.firestore.collection(path);
    return await collection.doc(id).delete();
  }

  //servicio para eliminar coleccion completa 28/03/24
  async deleteDoc(path: string, id: string) {
    const collection = this.firestore.collection(path);
    try {
      return await collection.doc(id).delete();
    } catch {
      return null;
    }

  }


  /*
  getGroup(path:string,ref:QueryGroupFn): Promise<any>{
    const collection=this.firestore.collectionGroup(path,ref);
    return collection.get().toPromise();
  }
  */

}
