import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Pedido, PedidoAsignado, RepartidorAfiliado, User } from 'src/app/models/users.model';
import { CarritoService } from 'src/app/services/carrito.service';
import { EstadorepartidorService } from 'src/app/services/estadorepartidor.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-popuprepartidor',
  templateUrl: './popuprepartidor.component.html',
  styleUrls: ['./popuprepartidor.component.scss'],
})
export class PopuprepartidorComponent implements OnInit, OnDestroy {

  getRepartidores: RepartidorAfiliado[] = [];
  getUbicaciones: any[] = [];
  getUbicaciones2: any[] = [];
  //en este array lo lleno solo para ser utilizado en el filtro de repartidores
  getUbicaciones3: any[] = [];
  getPedido: Pedido;
  //coordenadas del Comercio para calcular distancia con el repartidor
  latComer1: number = 0;
  lngComer1: number = 0;

  //declaracion de Observables para aplicar la desuscripcion
  private getdataSuscriber1: Subscription = undefined;

  //defino valor de temporizador para recibir informacion con Eventemmiter cuando el observavble deje de transmitir
  private contador1: any = undefined;

  //Creo la suscripcion al observable para capturar el estado del Repartidor en el eventemmiter
  private _getRepartidores$ = new BehaviorSubject<any>(null);
  private getRepartidoressuscriber: Subscription = undefined;

  //variable que guarda correo del comercio
  private emailComercio1: string;

  constructor(private carritoService: CarritoService,
    private estadorepartidor: EstadorepartidorService,
    private alertController: AlertController,
    private firestore: FirestoreService,
    private toastController: ToastController,
    private modalControler: ModalController,
    private traeUid: UserlogedService,
    private loadingController22: LoadingController) {
    //se recibe en el constructor la data del comercio para capturar las coordenadas de ubicacion
    this.getdataSuscriber1 = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        //guargo los valores de ubicacion del comercio
        this.latComer1 = res.ubicacionpri.lat;
        this.lngComer1 = res.ubicacionpri.lng;
        //guardo el email del comercio
        this.emailComercio1 = res.email;
      }
    })
  }

  ngOnInit() {
    this.traePedidotemp();
  }

  async ngOnDestroy() {
    //me desuscribo del evento pasadata
    this.estadorepartidor.desuscribeObservables();
    if (this.getdataSuscriber1 != undefined) {
      this.getdataSuscriber1.unsubscribe();
    }
    //me desuscribo del observable de repartidores cargados en el popup
    this.getRepartidoressuscriber.unsubscribe();
  }

  //Este metodo se activa cuando se cierra el compoenente 
  //ionViewDidLeave(){}


  //funcion que filtra repartidores despues que estan cargados
  searchRepartidor(event) {
    this.getUbicaciones = this.getUbicaciones3.filter((repartidorr: RepartidorAfiliado) => {
      return ((repartidorr.nombre.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) ||
        (repartidorr.email.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    });
  }

  //fucion que calcula distancia del repartidor al comercio
  async getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371;
    // calculo lat de radio
    let dLat = this.deg2rad(lat2 - lat1);
    // calculo lon de radio
    let dLon = this.deg2rad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    // distancia en Km
    return d;
  }
  //funcion asociada al calculo de distancia
  deg2rad(deg: number) {
    return deg * (Math.PI / 180)
  }


  async traePedidotemp() {
    //inicio mensaje de localizacion de repartidores
    await this.presentLoading2();
    //Recibo el pedido seleccionado
    this.carritoService.pasaDatapedidofinal.subscribe(res1 => {
      if (res1.data1) {
        this.getPedido = res1.data1;
      }
    })

    //Recibo la lista de repartidores desde el event emmiter y se la mando al observable
    this.carritoService.pasaData2.subscribe(async res => {
      if (await res.data) {
        return this._getRepartidores$.next(res.data);
      }
    })

    //me suscribo al observavble del 
    this.getRepartidoressuscriber = this._getRepartidores$.asObservable().subscribe(async res2 => {
      if (res2) {
        //Si se deja de mandar informacion en 1,5 segundos, es cuando emito los valores (ahorro de recursos de lectura con firebase)
        if (this.contador1 != undefined) {
          clearTimeout(this.contador1);
        }
        this.contador1 = undefined;
        this.contador1 = setTimeout(async () => {
          let bbb = []
          bbb = res2;
          let getRepartidores1 = []
          getRepartidores1 = Array.from(bbb.reduce((map, obj) => map.set(obj.uid, obj), new Map()).values());
          this.getRepartidores = [];
          for await (let add of getRepartidores1) {
            this.getRepartidores.push(add)
          }
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading() (en pruebas)
          const popover = await this.loadingController22.getTop();
          if (popover) {
            await popover.dismiss(null);
          }
          return await this.traeUbicaciones(this.getRepartidores);
        }, 1500);
      }
    })
    //Si no hay repartidores se envia un mensaje despues de 2 segundos
    setTimeout(async () => {
      if (!this.getRepartidores.length) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover = await this.loadingController22.getTop();
        if (popover) {
          await popover.dismiss(null);
        }
        this.presentToast2('No se localizaron repartidores, intente luego...')
      }
    }, 7000);
  }

  //trae ubicaciones de los repartidores disponibles y calcula la distancia
  async traeUbicaciones(repartidores: RepartidorAfiliado[]) {
    repartidores = Array.from(repartidores.reduce((map, obj) => map.set(obj.uid, obj), new Map()).values());
    this.getUbicaciones = [];
    for await (let repartidor1 of repartidores) {
      let path = 'usuarios/' + repartidor1.uid + '/registraubicacion/';
      await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('fecha', 'asc').limitToLast(1)).then(async ubicacion => {
        await ubicacion.forEach(async (doc: any) => {
          let valor: any[] = [];
          valor[0] = doc.data();
          if (valor.length) {
            //Se calcula la distancia del comercio a la ultima del repartidor
            let distkm = await this.getDistanceFromLatLonInKm(this.latComer1, this.lngComer1, valor[0].lat, valor[0].lng);
            //se convierte la fecha a formato legible por html
            valor[0].fecha = valor[0].fecha.toDate();
            //funcion que calcula el tiempo transcurrido
            let tiempopas = this.calculaTiempo(valor[0].fecha);
            //se concatena las ubicaciones al repartidor
            let aaa: any[] = [];
            aaa = (valor.map(obj => (Object.assign(obj, { nombre: repartidor1.nombre, email: repartidor1.email, uidRepartidor: repartidor1.uid, tiempo: tiempopas, distancia: parseFloat(distkm.toFixed(2)) }))));
            // se sgrega el registro al array de ubicaciones
            this.getUbicaciones.push(aaa[0]);
            //le aplico reduccion de duplicados
            this.getUbicaciones = Array.from(this.getUbicaciones.reduce((map, obj) => map.set(obj.uidRepartidor, obj), new Map()).values());
          }
        })
      })
    }
    //ordeno array por fecha de actualizacion de posicion mas ceracana a la actual
    this.getUbicaciones.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
    //lleno el array que sera utilizado en el filtro de ubicaciones por reartidor
    this.getUbicaciones3 = this.getUbicaciones;
  }


  //Funcion para asignar el pedido
  async asignaPedido(repartidor: any) {
    const alert1 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: '¿Confirma que desea <strong>ASIGNAR</strong> el pedido del cliente <b>' + this.getPedido.cliente.nombre.trim() + '</b>'
        + ' al repartidor <b>' + repartidor.nombre.trim() + '</b>?',
      buttons: [
        {
          text: 'NO ',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            await this.presentLoading();
            //reverifico si el pedido ya fue asignado
            const pathh = 'usuarios/' + this.getPedido.uidComercio + '/pedido/' + this.getPedido.id;
            await this.firestore.getCollection4<Pedido>(pathh).then(async pedidoAsing11 => {
              let valor33: any;
              valor33 = await pedidoAsing11.data();
              //si el estado del pedido e igual al RECHAZADAREPARTIDORRECHAZADAREPARTIDOR,PAGADO,CANCELADOREPARTIDOR o CAMBIOREPARTIDOR diferente de EnviadoRepartidor se procede
              if (await valor33.estado == 'PAGADO' || await valor33.estado == 'RECHAZADAREPARTIDOR' || await valor33.estado == 'DEVUELTO' || await valor33.estado == 'CANCELADOREPARTIDOR') {
                const path3 = 'usuarios/' + repartidor.uidRepartidor + '/pedidoasignado/';
                const path4 = 'usuarios/' + repartidor.uidRepartidor;
                let pedidoAsignado: PedidoAsignado = {
                  estatus: 'ENVIADOREPARTIDOR',
                  fecha: new Date(),
                  fechaEntrega: new Date(),
                  idComercio: this.getPedido.uidComercio,
                  idPedido: this.getPedido.id,
                  nombreComercio: this.getPedido.nombreComercio,
                  precio: this.getPedido.zonadelivery.precio,
                  pagadocliente: false,
                  pagadocomercio: false,
                  conciliado: false,
                  fechapagocomer: null,
                  referenciapagocomer: '',
                  emailrepar: repartidor.email,
                  emailcomer: this.emailComercio1
                }
                //Verifico si el repartidor no se ha ocupado
                await this.firestore.getCollection4<User>(path4).then(async ocupadoRep => {
                  let valor1: any = null;
                  valor1 = ocupadoRep.data();
                  if (await valor1.estado == true && await valor1.ocupado == false && await valor1.estatus == 'ACTIVO') {
                    //Creo la Solicitud al Repartidor (guarda relacion con Funtions: newAsignacion para atualizado de pedido)
                    await this.firestore.createDoc2<PedidoAsignado>(pedidoAsignado, path3, pedidoAsignado.idPedido).then(async () => {
                      setTimeout(async () => {
                        //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
                        const popover = await this.loadingController22.getTop();
                        if (popover) {
                          await popover.dismiss(null);
                        }
                        //muestro mensaje de Estado en Linea al Repartidor
                        await this.presentToast('Solicitud enviada al repartidor exitosamente')
                      }, 3000);
                      //se cierra el popuprepartidor
                      await this.modalControler.dismiss();
                    }).catch(async () => {
                      setTimeout(async () => {
                        //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
                        const popover1 = await this.loadingController22.getTop();
                        if (popover1) {
                          await popover1.dismiss(null);
                        }
                        //muestro mensaje de problemas en el guardado
                        await this.presentToast1('Ocurrio un problema al enviar la solicitud al repartidor, intente nuevamente')
                      }, 3000);
                    });
                  } else {
                    //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
                    const popover2 = await this.loadingController22.getTop();
                    if (popover2) {
                      await popover2.dismiss(null);
                    }
                    return await this.presentToast2('El repartidor se acaba de ocupar, intente con otro repartidor...')
                  }
                })
              } else {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover4 = await this.loadingController22.getTop();
                if (popover4) {
                  await popover4.dismiss(null);
                }
                await this.presentToast2('El pedido ya fue asignado, por favor verifique estado del pedido...')
                // return this.getRepartidoresActivosSuscriber2.unsubscribe();    
              }
            })
          }
        }
      ]
    });
    await alert1.present();
  }

  //configuracion de la funcion que muestra mensaje de envio exitoso al repartidor
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 2500,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra mensaje de envio NO exitoso al repartidor
  async presentToast1(mensaje: string) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'danger',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast1.present();
  }

  //configuracion de la funcion que muestra mensaje cuando no hay repartidores disponmibles
  async presentToast2(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 2000,
      position: 'middle'
    });
    toast2.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController22.create({
      cssClass: 'normal',
      message: 'Asignando Pedido...',
      duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading2() {
    //crea mensaje
    const loading2 = await this.loadingController22.create({
      cssClass: 'normal',
      message: 'Localizando Repartidores...',
      duration: 8500
    });
    //presenta mensaje
    await loading2.present();
  }


  //funcion para caluclar tiempo basado en contador de tiempo pasado      
  calculaTiempo(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds 
    let seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = Math.round(timeDiff % 60);
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = Math.round(timeDiff % 24);
    // remove hours from the date
    timeDiff = Math.floor(timeDiff / 24);
    // the rest of timeDiff is number of days
    let days = timeDiff;

    //Establezco mensaje para contador de segundos
    if (seconds < 60 && hours <= 0 && minutes <= 0 && days <= 0) {
      if (seconds < 1) {
        let res: string;
        return res = "Justo ahora";
      }
      if (seconds > 1 && seconds < 60) {
        let res: string;
        return res = "Hace " + seconds + " segundos";
      }
      if (seconds == 1) {
        let res: string;
        return res = "Hace " + seconds + " segundo";
      }
    }
    //Establezco mensaje para contador de minutos
    if (minutes > 0 && hours == 0 && days == 0) {
      if (minutes == 1) {
        let res: string;
        return res = "Hace " + minutes + " minuto";
      }
      if (minutes > 1) {
        let res: string;
        return res = "Hace " + minutes + " minutos";
      }
    }

    //Establezco mensaje para contador de horas
    if (hours > 0 && minutes >= 0 && days == 0) {
      if (hours == 1 && minutes == 0) {
        let res: string;
        return res = "Hace " + hours + " hora";
      }
      if (hours == 1 && minutes == 1) {
        let res: string;
        return res = "Hace " + hours + " hora y " + minutes + " minuto";
      }
      if (hours == 1 && minutes > 1) {
        let res: string;
        return res = "Hace " + hours + " hora y " + minutes + " minutos";
      }
      if (hours > 1 && minutes == 1) {
        let res: string;
        return res = "Hace " + hours + " horas y " + minutes + " minuto";
      }
      if (hours > 1 && minutes == 0) {
        let res: string;
        return res = "Hace " + hours + " horas";
      }
      if (hours > 1 && minutes > 1) {
        let res: string;
        return res = "Hace " + hours + " horas y " + minutes + " minutos";
      }
    }
    //Establezco mensaje para contador de dias
    if (days > 0) {
      if (days == 1) {
        let res: string;
        return res = "Hace " + days + " dia";
      }
      if (days > 1) {
        let res: string;
        return res = "Hace " + days + " dias";
      }
    }

  }


}
