import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';

//importaciones de los models
import { CategoriaMenu, Productos, User } from 'src/app/models/users.model';

//importaciones relacionadas a mensajes de esperas en pantalla
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { PopupcategoriaComponent } from '../popupcategoria/popupcategoria.component';
import { BuscarproductoService } from 'src/app/services/buscarproducto.service';
import { UserlogedService } from 'src/app/services/userloged.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';



@Component({
  selector: 'app-mimenu',
  templateUrl: './mimenu.component.html',
  styleUrls: ['./mimenu.component.scss'],
})
export class MimenuComponent implements OnInit, OnDestroy {

  //Se declara el array Categorias para llamar desde el input menuCategroias
  categorias: CategoriaMenu[] = [];

  //Se declara el array productosGet para llamar desde el ngOnInit
  productosGet: Productos[] = [];

  //creo un array para guardar las fotos de los productos
  fotoproductosGet = [];

  //Se declara un Array para ordenarlo 
  productosGetOrder: Productos[] = [];
  productosGetOrder2: Productos[] = [];

  //Inicializo el Objeto de la interfaz de la categoria de Menus
  categoriaMenu: CategoriaMenu = {
    idCategoriaMenu: "",
    nombreCategoria: ""
  }

  //Inicializo el Objeto de la Interfaz Productos
  productoAgregado: Productos = {
    idProducto: "",
    nombreProducto: "",
    descripcionProducto: "",
    precio: 0,
    categoria: "",
    disponible: true,
    urlfoto: "",
    fechaCreacion: new Date,
    fechaActualizacion: new Date
  }

  //declaro variable newProducto para ser usada en la interfaz, habilita el registro de producto
  newProducto = false;
  //Declaro variable relacionada a la imagen del producto que se va a mostrar cuando se selecciona
  newImage = "";
  //Declaro una nueva varibale de la imagen que se va a subir cuando se selecciona
  newFile: any = '';
  //creo una variable que me va a almacenar el uid del usuario logueado
  uidUserLog: any;
  //creo una variable que me va a almacenar lel tipo de local de  usuario logueado
  datUser: User;
  //Declaro una variable global para el mensaje de espera en pantalla, relacionada a la funcion presentLoading()
  loading2: any;
  //esta variable controla la visa de foto de producto
  verFotoproducto = false;
  //variable que calcula tipo de usuario
  private planUser: string;


  //declaracion de Observables para aplicar la desuscripcion
  private getProductoSuscriber: Subscription = undefined;
  private dataSuscriber: Subscription = undefined;
  private getcategoriasSuscriber: Subscription = undefined;

  constructor(private firestore: FirestoreService,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private alertController: AlertController,
    private modalControler: ModalController,
    private buscarproducto: BuscarproductoService,
    private traeUid: UserlogedService,
    private router: Router
  ) { }



  ngOnInit() {
    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.dataSuscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.uidUserLog = res.uid;
        this.datUser = res;
        //cargo el plan del proveedor
        this.planUser = res.plan;
        //cargo datos de categorias y productos asociados al usuario
        this.getProductos();
        this.getCategorias2();
      } else {
        this.uidUserLog = 0;
      }
    })
  }

  ngOnDestroy() {
    if (this.dataSuscriber != undefined) {
      this.dataSuscriber.unsubscribe();
    }
    if (this.getcategoriasSuscriber != undefined) {
      this.getcategoriasSuscriber.unsubscribe();
    }
  }

  //filtro de productos
  searchProducto(event) {
    this.productosGetOrder = this.productosGetOrder2.filter((producto: Productos) => {
      return ((producto.nombreProducto.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    });
  }

  //Función para Activar o desactivar producto
  async activaProducto(producto: Productos) {
    //si el producto no esta disponible  
    if (producto.disponible == false) {
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>HABILITAR</strong> produto: <b>' + producto.nombreProducto + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.uidUserLog + '/productos/';
              //Actualizo habilitar a true en Productos
              await this.firestore.updateDoc<Productos>({ disponible: true }, path2, producto.idProducto).then(async () => {
                //muestro mensaje de habilitacion con exito
                return await this.presentToast('<b>Habilitación</b> de Producto Exitosa...')
              }).catch(async () => {
                producto.disponible = false;
                return await this.presentToast1('Ocurrio un problema de conexion al Habilitar el producto: ', producto.nombreProducto);
              });
            }
          }
        ]
      });
      await alert1.present();
    } else if (producto.disponible == true) {
      const alert2 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>DESHABILITAR</strong> el producto: <b>' + producto.nombreProducto + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.uidUserLog + '/productos/';
              //Actualizo habilitar a true la disponibilidad del producto
              await this.firestore.updateDoc<Productos>({ disponible: false }, path2, producto.idProducto).then(async () => {
                //muestro mensaje de guardado con exito
                return await this.presentToast('<b>Deshabilitación</b> de producto de forma Exitosa...')
              }).catch(async () => {
                return await this.presentToast1('Ocurrio un problema de conexion al Deshabilitar el producto: ', producto.nombreProducto);
              });
            }
          }
        ]
      });
      await alert2.present();
    }
  }


  //esta funcion se utiliza para limpiar las variables al terminar el proceso de moficacion     
  cierraModificacion() {
    this.newProducto = false;
    this.verFotoproducto = false;
    this.newFile = '';
  }


  //valida tipo de usuario y cantidad de productos
  async validaPlan(productoAg: Productos) {
    if (productoAg.idProducto === "") {
      //validacion para usuarios plan AA y AF
      if (this.productosGet.length + 1 <= 50 && (this.planUser == 'AA' || this.planUser == 'AF')) {
        this.registrarProducto();
      } else if (this.productosGet.length + 1 <= 100 && (this.planUser == 'BB' || this.planUser == 'BF')) {
        this.registrarProducto();
      } else if (this.productosGet.length + 1 <= 150 && (this.planUser == 'CC' || this.planUser == 'CF')) {
        this.registrarProducto();
      } else if (this.productosGet.length + 1 <= 200 && (this.planUser == 'DD' || this.planUser == 'DF')) {
        this.registrarProducto();
      } else if (this.productosGet.length + 1 <= 250 && (this.planUser == 'EE' || this.planUser == 'EF')) {
        this.registrarProducto();
      } else if (this.planUser == 'GG' || this.planUser == 'GF') {
        this.registrarProducto();
      } else {
        await this.presentToast('Su plan actual no permite registrar mas de ' + (this.productosGet.length) + ' productos, le invitamos a contratar un plan mayor')
      }
    } else {
      this.registrarProducto();
    }
  }



  //Agrega el Producto a la Base de Datos del Menu
  async registrarProducto() {
    //Valido si el usuario esta logeado
    if (this.uidUserLog !== 0) {
      //creo una constante que guardara productos
      const data = this.productoAgregado;
      //limpio el valor de urlfoto de productos
      data.urlfoto = '';
      //creo una constante para guardar de fotos de productos
      const datafotoproducto = {
        idproducto: this.productoAgregado.idProducto,
        datafoto: ''
      }
      //con este id asigno un id para un nuevo producto y actualizo fecha de modificacion
      if (data.idProducto === "") {
        data.idProducto = this.firestore.getId();
        datafotoproducto.idproducto = data.idProducto
      } else {
        data.fechaActualizacion = new Date
      }
      //creo la ruta para guardar los productos al usuario logueado
      const path = 'usuarios/' + this.uidUserLog + '/productos/';
      //creo una ruta para guardar datos de fotos de productos
      const pathfoto = 'usuarios/' + this.uidUserLog + '/fotoproductos/';
      /*se llama el servicio para guardar la imagen solo cuando this.NewFile sea diferente de vacio, solo en este caso 
      tendrá valor, porque lo toma desde el metodo newImageUpload()*/
      if (this.newFile != "") {
        datafotoproducto.datafoto = this.newFile;
      }
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading();
      //creo o actualizo el productos en base de datos
      await this.firestore.createDoc2(data, path, data.idProducto).then(async () => {
        //guardo la foto solo si existe actualizacion de foto
        if (this.newFile != "") {
          await this.firestore.createDoc2(datafotoproducto, pathfoto, datafotoproducto.idproducto).then(async () => {
            //muestro mensaje de guardado con exito
            await this.presentToast('Foto de Producto guardada con exito')
          }).catch(async () => {
            //muestro mensaje de problemas en el guardado
            await this.presentToast('No se pudo Guardar la Foto, Intente nuevamente ')
          })
        }
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover = await this.loadingController.getTop();
        if (popover) {
          await popover.dismiss(null);
        }
        //muestro mensaje de guardado con exito
        await this.presentToast('Producto guardado con exito')
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
        //muestro mensaje de problemas en el guardado
        await this.presentToast('No se pudo Guardar, Intente nuevamente ')
      })

      //llamo el metodo para refrescar productos
      ///await this.getProductos();
    }
    //Igual a valores por defecto
    this.productoAgregado = {
      idProducto: "",
      nombreProducto: "",
      descripcionProducto: "",
      precio: 0,
      categoria: "",
      disponible: false,
      urlfoto: "",
      fechaCreacion: new Date,
      fechaActualizacion: new Date
    }
    //se declara la variable newProducto nuevamente a false para que se limpie la interfaz
    this.newProducto = false;
    //Se limpia el valor de la varibale this.newfile para que cuando se modifique un producto no tome la ultima foto cargada del evento
    this.newFile = "";
  }


  //haciendo pruebas registrando la categoria tomando en el campo idCategoriaMenu el ID automatico asignado en el proceso de registro
  async registrarCategoria2() {
    if (this.uidUserLog !== 0) {
      const data = this.categoriaMenu;
      data.idCategoriaMenu = this.firestore.getId();
      const path = 'usuarios/' + this.uidUserLog + '/categoriaMenu/';
      await this.firestore.createDoc2(data, path, data.idCategoriaMenu).then(async () => {
        //muestro mensaje de guardado con exito
        await this.presentToast('Categoria Creada Exitosamente')
      }).catch(async () => {
        //muestro mensaje de problemas en el guardado
        await this.presentToast1('No se pudo guardar la categoria, intente nuevamente', '')
      })
    }
  }

  //metodo para obtener categorias
  async getCategorias2() {
    if (this.uidUserLog !== 0) {
      const path = 'usuarios/' + this.uidUserLog + '/categoriaMenu/';
      this.getcategoriasSuscriber = this.firestore.getCollection<CategoriaMenu>(path).subscribe(res => {
        if (res) {
          this.categorias = res
          //Utilizo funcion predefinida de (a,b) para Ordenar el array de categorias
          this.categorias.sort(function (a, b) {
            if (a.nombreCategoria < b.nombreCategoria) {
              return -1;
            } else if (a.nombreCategoria > b.nombreCategoria)
              return 1;
            else
              return 0;
          });
        }
      });
    }
  }

  //metodo para Obtener Productos
  async getProductos() {
    if (this.uidUserLog != 0) {
      //creo la ruta para obtener los productos del usuario logeado
      const path = 'usuarios/' + this.uidUserLog + '/productos/';
      /*llamo la funcion para recargar la vista a la base de datos, solo this.productosGet esta vacio,
      así me aseguro que solo se hara la consulta cuando yo decida limpiarlo, como por ejemplo al incluir
      un nuevo producto o actualizarlo */
      if (!this.productosGet.length) {
        this.buscarproducto.productosCap(path);
      }
      //busco los productos de este usuario en el objeto Subject declarado en el servicio
      this.getProductoSuscriber = this.buscarproducto.getProductos().subscribe(async res => {
        if (res) {
          this.productosGet = res;
        }

        /*le asigno al campo urlfoto la foto capturada en this.fotoprductosGet (desactivado el 30-10 para ahorrar recursos firebase)
        for await (let producto of this.productosGet){
          for await (let fotoproducto of this.fotoproductosGet){
            if(producto.idProducto===fotoproducto.idproducto){
              producto.urlfoto=fotoproducto.datafoto;
            }
          }
        }
        */

        //Cargo en el Array que utilizare para ordenar el Resultado Obtenido
        this.productosGetOrder = this.productosGet,


          //Utilizo funcion predefinida de (a,b) para iniciar el Orden del Array por nombreproducto
          this.productosGetOrder.sort(function (a, b) {
            if (a.nombreProducto < b.nombreProducto) {
              return -1;
            } else if (a.nombreProducto > b.nombreProducto)
              return 1;
            else
              return 0;
          });

        //Utilizo funcion predefinida de (a,b) para continuar el Orden por Categorias
        this.productosGetOrder.sort(function (a, b) {
          if (a.categoria < b.categoria) {
            return -1;
          } else if (a.categoria > b.categoria)
            return 1;
          else
            return 0;
        });


        /*
        //Utilizo funcion predefinida de (a,b) para Ordenar el array de productos por disponibilidad
        this.productosGetOrder.sort(function (a,b){
          if(b.disponible<a.disponible){
            return -1;
          }else if(b.disponible>a.disponible)
            return 1;
          else
            return 0;
        }); 
        */

        //array que guarda los mismos resultados para ser utilizado en filtro
        this.productosGetOrder2 = this.productosGetOrder;
      });
    }
  }


  //Funcion para llamar el Popup de Categorias
  async popupCategor() {
    const modal = await this.modalControler.create({
      component: PopupcategoriaComponent,
      cssClass: 'ion-modal'
    });
    await modal.present();
  }

  /*Se deshabilito la opcion de eliminar productos por seguridad
  //Funcion para Eliminar Producto con toast ionic (se desahbilito al 01-08-22, por seguridad, la data de productos solo permitira modificaciones)
  async deleteProducto(producto:Productos){
    //const res = await this.auth.getUserCurrent();
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: '¿Confirma que desea <strong>Eliminar</strong> el producto:'+' '+producto.nombreProducto+'?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel:'+blah);
          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: () => {
            console.log('Confirm Okay');
              //llamo la funcion de mensaje en pantalla
            this.presentLoading();
            if(this.uidUserLog!==0){
              const path='usuarios/'+ this.uidUserLog+'/productos/';
              this.firestore.deletePro(path,producto.idProducto).then(res=>{
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                this.loading2.dismiss();
                //muestro mensaje de guardado con exito
                this.presentToast('Producto eliminado con exito')
                }).catch(error=>{
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                this.loading2.dismiss();
                //muestro mensaje de problemas en el guardado
                this.presentToast('No se pudo Eliminar el producto, Intente nuevamente ')
                })
              }
              //elimino foto solo si tiene url asociado
              if(producto.urlfoto!="" && this.uidUserLog!==0){
              const path2='fotosusuarios/'+this.uidUserLog+'/';
              this.firestorageService.deleteFotoPro(path2,producto.nombreProducto);
              }
          }
        }
      ]
    });
    await alert.present();
  }
 */

  //Funcion para cambiar el estado de la variable newProducto
  newPro() {
    this.newProducto = true;
    //Reseteamos el Objeto Producto Agregado
    this.productoAgregado = {
      idProducto: "",
      nombreProducto: "",
      descripcionProducto: "",
      precio: 0,
      categoria: "",
      disponible: false,
      urlfoto: "",
      fechaCreacion: new Date,
      fechaActualizacion: new Date
    }
  }


  //Funcion para seleccionar imagen y mostrarla en pantalla
  async newImageUpload(event: any) {
    if (event.target.files && event.target.files[0]) {
      //Iniciando reduccion de imagenes antes de enviarlas a firebase, 31-05-22
      //declaro constante de 300px para reduccion
      //Reiniciando reduccion de imagenes antes de enviarlas a firebase, 17-11-22
      //declaro constante de 500px para mejora de reduccion ya que la imagen estaba deficiente de resolucion
      const WIDTH = 500
      let image_file = event.target.files[0]
      let reader = new FileReader
      reader.readAsDataURL(image_file)
      reader.onload = (event) => {
        let image_url = event.target.result as string;
        //creo lienzo
        let image = document.createElement("img")
        //le paso al lienzo url de la iagen recibida por el evento
        image.src = image_url
        //adentro del lienzo creo un canvas para aplicar reduccion en base al tamaño de la imagen original
        image.onload = () => {
          let canvas = document.createElement("canvas")
          let radio = WIDTH / image.width
          canvas.width = WIDTH
          canvas.height = image.height * radio
          const context = canvas.getContext("2d")
          context.drawImage(image, 0, 0, canvas.width, canvas.height)
          let new_image_url = context.canvas.toDataURL("image/jpeg", 100)
          let new_image = document.createElement("img")
          new_image.src = new_image_url
          //document.getElementById("imagenreduc").appendChild(new_image)
          this.newFile = new_image.src
        }
      }
    }
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    this.loading2 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      duration: 8000
    });
    //presenta mensaje
    await this.loading2.present();
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 1500,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra errores en pantalla
  async presentToast1(mensaje: string, nombreproducto: string) {
    const toast2 = await this.toastController.create({
      message: mensaje + ' ' + nombreproducto,
      color: 'danger',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    toast2.present();
  }


  /*funcion que habilita la vista de foto de producto (esta funcion se dehabilito al 30-10 para solo traer registor por producto solciitado= con verFotoproduc1(producto:Productos))
  verFotoproduc(producto:Productos){
    this.verFotoproducto=true;
   //creo la ruta para obtener las fotos de productos del usuario logeado
    const pathfotos='usuarios/'+this.uidUserLog+'/fotoproductos/';
    if(!this.fotoproductosGet.length){
      this.buscarproducto.fotoProductos(pathfotos)
    }
     /*busco la foto de los productos de este usuario en el objeto Subject declarado en el servicio,
     //fue guardado en un obserbable tio subject para reducir consultas a firebase
     this.buscarproducto.getFotoProductos().subscribe(async (res: any[])=>{
        if(res){
          this.fotoproductosGet=res;
          //le asigno al campo urlfoto la foto capturada en this.fotoprductosGet
          for await (let producto of this.productosGet){
            for await (let fotoproducto of this.fotoproductosGet){
              if(producto.idProducto===fotoproducto.idproducto){
                producto.urlfoto=fotoproducto.datafoto;
              }
            }
          }
        }
     })
  }
  */

  //funcion que habilita la vista de foto de producto
  async verFotoproduc1(producto: Productos) {
    this.verFotoproducto = true;
    //solo consulto la foto si no ha sido previamente cargadas
    if (producto.urlfoto == '') {
      //creo la ruta para obtener las fotos de productos del usuario logeado
      const pathfoto = 'usuarios/' + this.uidUserLog + '/fotoproductos/';
      //consulto la foto
      await this.buscarproducto.getFoto(pathfoto, producto.idProducto).then(async fotoproduct => {
        let valor: any;
        valor = fotoproduct.data();
        //le asigno al campo urlfoto la foto encontrada
        for (let producto2 of this.productosGet) {
          if (producto2.idProducto === valor.idproducto && producto2.urlfoto == '') {
            producto2.urlfoto = valor.datafoto;
          }
        }
      })
    }
  }

  async verCata() {
    //funcion prueba para abrir menú modelo
    try {
      return await this.router.navigate(['/productomovil/' + this.datUser.telefono]);
    } catch (err) {
      return await this.router.navigate(['/mimenu']);
    }
  }

  async verMenu() {
    //funcion prueba para abrir menú modelo
    try {
      return await this.router.navigate(['/menumovil/' + this.datUser.telefono]);
    } catch (err) {
      return await this.router.navigate(['/mimenu']);
    }
  }



}
