import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';
//import { GooglePlus} from '@awesome-cordova-plugins/google-plus';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Importaciones relacionadas a publicidad Admob
 /* se inhabilita publicidad al 03-05-24
import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition
} from '@capacitor-community/admob';
*/

@Component({
  selector: 'app-ingresar',
  templateUrl: './ingresar.component.html',
  styleUrls: ['./ingresar.component.scss'],
})
export class IngresarComponent implements OnInit, OnDestroy {


  loginUsuario: FormGroup;


  //se agrega la interfaz tipo User para ser utilizada automaticamente cuando el usuario inicie sesion con google
  datos: User = {
    nombre: "",
    apellido: "",
    tipolocal: "",
    token: "",
    nombrelocal: "",
    uid: "",
    email: "",
    password: "",
    telefono: "",
    direccionlocal: "",
    plan: "",
    ubicacionpri: { lat: 5.90, lng: -65.28 },
    ubicacionsec: { lat: 0, lng: 0 },
    perfil: "",
    urlfoto: "",
    fechaRegistro: new Date,
    fechaActRegistro: new Date,
    estado: false,
    estatus: "ACTIVO",
    ocupado: false,
    ubicacion: "",
    municipio: "",
    valoracion: 100,
    deliveryOn: false,
  }

  //Creo una variable para guardar el usuario logeado por google
  userGoogle: any = '';

  //creo una variable que me va a almacenar el uid del usuario logeado desde el padre
  uidUserLog = '';

  //declaracion de Observables para aplicar la desuscripcion
  private ingresarSuscriber: Subscription;
  private dataSuscriber: Subscription;

  //variable relacionada al captcha
  captcha1 = '';


  constructor(private authService: AuthService,
    private router: Router,
    private firestore: FirestoreService,
    private traeUid: UserlogedService,
    public platform: Platform,
    private afAuth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private _renderer: Renderer2,
    private googleLog: GooglePlus
  ) {
    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.ingresarSuscriber = this.traeUid.getUserlog().subscribe(res => {
      if (res) {
        this.uidUserLog = res.uid;
      }
    })
    //cargo las validaciones de entrada
    this.buildForm();
  }

  async ngOnInit() {
   // await this.initializeAdmob();
  }

  //inicializo la publicidad Admob
   /* se inhabilita publicidad al 03-05-24
  async initializeAdmob() {

    //await this.platformh.ready();
    if (this.platform.is('cordova')) {
      const options: BannerAdOptions = {
        adId: 'ca-app-pub-4505046024910255/8420417432',
        adSize: BannerAdSize.BANNER,
        position: BannerAdPosition.BOTTOM_CENTER,
        margin: 0,
        //isTesting: false,
        isTesting: true,
        npa: false
      };

      await AdMob.initialize({
        requestTrackingAuthorization: false,
        //initializeForTesting: false,
        initializeForTesting: true,
      }).catch(err => console.error('Could not init Admob', err));

      await AdMob.showBanner(options).catch(err => console.error('Could not show Admob banner', err));;

    }

  }

  ionViewDidLeave() {
    if (AdMob && this.platform.is('cordova')) {
      AdMob.removeBanner();
    }
  }
  */

  ngOnDestroy() {
    if (this.ingresarSuscriber != undefined) {
      this.ingresarSuscriber.unsubscribe()
    }
    if (this.dataSuscriber != undefined) {
      this.dataSuscriber.unsubscribe()
    }
  }

  //funcion relacionada al captcha
  async resolved(captchaResponse: string) {
    this.captcha1 = captchaResponse;

    //envio la respuesta a google (en proceso de evaluacion)
    let script = this._renderer.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js?captchaResponse";
    this._renderer.appendChild(document.body, script);
  }


  async Ingresar() {
    const email = this.loginUsuario.value.emailCon;
    const password = this.loginUsuario.value.passwordCon;
    //activa la identificacion solo si los campos email y login tienen informacion
    if (email != "" && password != "") {
      //lanzo el loading de espera de registro de usuario
      await this.presentLoading();
      await this.authService.login(email, password).then(async user => {
        if (user) {

          /* inhabilitacion temporal mientras se crea eliminacion de cuenta de usuarios 27/03/24
          if (user.user?.emailVerified) {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover = await this.loadingController.getTop();
            if (popover) {
              await popover.dismiss(null);
            }
            return await this.presentToast('Inicio de sesión exitoso');
          } else if (!user.user?.emailVerified) {
            return await this.verificaCorreo();
          }

          */
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 27/03/24
            const popover = await this.loadingController.getTop();
            if (popover) {
              await popover.dismiss(null);
            }

        }
      }).catch(async (err) => {
        let codeRes = err.code;
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
        //capturo el mensaje de erro segun el caso
        switch (codeRes) {
          case 'auth/missing-email':
            return await this.presentToast1('campo email vacio o invalido');
          case 'auth/invalid-email':
            return await this.presentToast1('Ha introducido un email invalido');
          case 'auth/user-not-found':
            return await this.presentToast1('No existe ningún registro de usuario que corresponda al email proporcionado');
          case 'auth/wrong-password':
            return await this.presentToast1('Contraseña incorrecta, intente nuevamente o haga click en olvido contraseña');
          case 'auth/user-disabled':
            return await this.presentToast1('Su cuenta se encuentra inhabilitada, debe contactar a soporte App');
          case 'auth/too-many-requests':
            return await this.presentToast1('El acceso a esta cuenta se ha inhabilitado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede volver a intentarlo más tarde');
          default:
            return await this.presentToast1('Error de registro desconocido');
        }
      })
    }
  }

  //metodo relacionado al FormGroup, para salvar los datos del formulario (en pruebas)
  validar(event: Event) {
    event.preventDefault(); //este metodo cancela el comportamiento por defecto de las pag html y captura lo que tiene antes de recargar
    //reverifico que realmente el formulario es valido (paso todas las validaciones) y así salvo los cambios
    if (this.loginUsuario.valid) {
      //si el formato es valido procedo a activar el proceso de ingreso
      this.Ingresar();
    } else {
      this.loginUsuario.markAllAsTouched();
      return;
    }
  }

  //metodo relacionado al FormGroup 
  private buildForm() {
    this.loginUsuario = this.formBuilder.group({
      emailCon: ['', [Validators.required, Validators.email]],
      passwordCon: ['', [Validators.required]]
    })
  }


  googleLogin() {
    if (this.platform.is('cordova')) {
      this.nativeGoogleLogin();
    } else {
      this.IngresarConGoogle();
    }
  }

  async nativeGoogleLogin() {
    try {
      await this.googleLog.login({
        'webClientId': '533034778667-c2hnr38dr92spigg32vm21pqe7r35n6n.apps.googleusercontent.com',
        'offline': true,
        'scopes': 'profile email'
      }).then(async res => {
        return await this.afAuth.signInWithCredential(res.idToken)
      })
    } catch (err) {
      //console.log('este es el error si lo hay',err)
    }
  }

  async IngresarConGoogle() {
    const email = this.loginUsuario.value.emailCon;
    const password = this.loginUsuario.value.passwordCon;
    await this.authService.loginWithGoogle(email, password).then(async res => {
      //lanzo el loading de espera de registro de usuario
      await this.presentLoading();
      //se guarda en esta variable el usuario logeado
      this.userGoogle = res;
      //Registro usuario con datos del Login Google
      if (this.userGoogle != '' || this.userGoogle.user.uid != '' || this.userGoogle != undefined || this.userGoogle != null) {
        const path = 'usuarios/';
        const id = this.userGoogle.user.uid;
        //actualizo perfil y uid como cliente para usuarios logueados con google
        this.datos.perfil = 'CLIENTE'
        this.datos.uid = id;
        //obtengo el usuario logeado, y si existe como usuario registrado, no se crea nuevamente
        await this.firestore.getDoc2<User>(path, id).then(async userReg => {
          if (await userReg.data() != undefined || await userReg.data() != null) {
            let timerOut: NodeJS.Timeout;
            clearTimeout(timerOut);
            timerOut = setTimeout(async () => {
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover3 = await this.loadingController.getTop();
              if (popover3) {
                await popover3.dismiss(null);
              }
              return await this.presentToast('Inicio de sesión exitoso');
            }, 1500);
          } else if (await userReg.data() == undefined || await userReg.data() == null) {
            //procedo a hacer el registro de usuario
            if (this.userGoogle.additionalUserInfo.profile.given_name != null) {
              this.datos.nombre = this.userGoogle.additionalUserInfo.profile.given_name;
            }
            if (this.userGoogle.additionalUserInfo.profile.family_name != null) {
              this.datos.apellido = this.userGoogle.additionalUserInfo.profile.family_name;
            }
            if (this.userGoogle.additionalUserInfo.profile.email != null) {
              this.datos.email = this.userGoogle.additionalUserInfo.profile.email;
            }
            if (this.userGoogle.user.photoURL != null) {
              this.datos.urlfoto = this.userGoogle.user.photoURL;
            }
            await this.firestore.createUser(this.datos, path, id).then(async () => {
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover = await this.loadingController.getTop();
              if (popover) {
                await popover.dismiss(null);
              }
              await this.presentToast('Creación de usuario exitosa, cuando guste, complete sus datos de registro y ubicación');
              //Despues de este Proceso Manda el Usuario al perfil de usuario
              return await this.router.navigate(['/perfilusuario']);
            }).catch(async () => {
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover2 = await this.loadingController.getTop();
              if (popover2) {
                await popover2.dismiss(null);
              }
              //capturo el mensaje de erro segun el caso
              return await this.presentToast1('Ocurrió un problema en el registro de datos de usuario, proceda a iniciar sesión para completar el registro... si tiene problemas contacte a soporte App');
            });
          }
        }).catch(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover5 = await this.loadingController.getTop();
          if (popover5) {
            await popover5.dismiss(null);
          }
          //capturo el mensaje de erro segun el caso
          return await this.presentToast1('Ocurrió un problema en la verificación de datos de Usuario, intente nuevamente o contacte a soporte App');
        });
      }
    }).catch(async (err) => {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover4 = await this.loadingController.getTop();
      if (popover4) {
        await popover4.dismiss(null);
      }
      const codeRes1 = await err.code
      //capturo el mensaje de erro segun el caso
      switch (codeRes1) {
        case 'auth/cancelled-popup-request':
          return await this.presentToast1('Se canceló la solicitud anterior de inicio de sesión mediante credenciales Google');
        case 'auth/popup-closed-by-user':
          return await this.presentToast1('Usted cerró la solicitud de inicio de sesión mediante credenciales Google ');
        case 'auth/user-disabled':
          return await this.presentToast1('Su cuenta se encuentra inhabilitada, debe contactar a soporte App');
        default:
          return await this.presentToast1('Ocurrió un problema de validación desconocido, si el problema continua, contacte a soporte App');
      }
    });
  }

  async olvidoContra() {
    //si la respuesta al realizar el pedido viene con error, es decir si res=null
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Recuperar Acceso',
      backdropDismiss: false,
      message: "Escriba su correo electronico de registro, cuando lo escriba correctamente presione <b>ENVIAR</b>",
      inputs: [
        {
          name: 'email',
          type: 'email',
          id: 'email-id',
          placeholder: 'escriba su correo aqui'
        },
      ],
      buttons: [
        {
          text: 'CANCELAR',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => { }
        }, {
          text: 'ENVIAR',
          id: 'confirm-button',
          handler: async (data) => {
            if (data.email != '') {
              await this.presentLoading1()
              await this.authService.recuperAcceso(data.email).then(() => {
                let timerOutt: NodeJS.Timeout;
                clearTimeout(timerOutt);
                timerOutt = setTimeout(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover5 = await this.loadingController.getTop();
                  if (popover5) {
                    await popover5.dismiss(null);
                  }
                  return await this.presentToast('Se envió a su correo, el proceso de recuperación de acceso');
                }, 1000);
              }).catch(async (err1) => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover6 = await this.loadingController.getTop();
                if (popover6) {
                  await popover6.dismiss(null);
                }
                const codeRes2 = await err1.code
                //capturo el mensaje de erro segun el caso
                switch (codeRes2) {
                  case 'auth/invalid-email':
                    return await this.presentToast1('formato de correo incorrecto, por favor escriba bien el correo');
                  case 'auth/user-not-found':
                    return await this.presentToast1('el correo no se encuentra registrado con nosotros, verifique e intente nuevamente');
                  default:
                    return await this.presentToast1('Ocurrió un problema validando su información suministrada, si el problema continúa, contacte a soporte App');
                }
              });
            } else if (data.email == '' || data.email == null) {
              await this.presentToast1('no se proporcionó correo para recuperación, no puede estar vacio');
              return;
            }
          }
        }
      ]
    });
    await alert.present();
  }

  //se agrego una opcion directa a usuario para eliminacion de su cuenta por politicas seguridad google 27-03-24
  async eliminarCuenta() {
    //si la respuesta al realizar el pedido viene con error, es decir si res=null
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Eliminar cuenta y datos asociados',
      backdropDismiss: false,
      message: "¡Lamentamos que te vayas!, para eliminar definitivamente tu cuenta y datos asociados, por favor inicia sesión por última vez, luego navega a tu perfil de usuario y presiona el enlace con el texto <strong>Eliminar mi cuenta y datos asociados</strong>",
      buttons: [
        {
          text: 'CANCELAR',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => { }
        },
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => { }
        },
      ]
    });
    await alert.present();
  }

  async verificaCorreo() {
    await this.authService.getUserCurrent().then(async user => {
      await user?.sendEmailVerification().then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover44 = await this.loadingController.getTop();
        if (popover44) {
          await popover44.dismiss(null);
        }
        await this.authService.logout();
        return await this.presentToast('Su Registro no ha sido validado, se reenvió a su correo electrónico, un link para validar su registro').then(() => {
          // Despues de este Proceso Manda el Usuario al perfil de Usuario
          setTimeout(async () => {
            return await this.router.navigate(['/ingresar']).then(() => {
              //una vez que se cierra sesion mando a recargar pagina
              window.location.reload();
            })
          }, 2000)
        });
      });
    })
  }


  //configuracion de la funcion que muestra un mensaje exitoso en pantalla
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 7000,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra mensaje de advertencia
  async presentToast1(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 7000,
      position: 'middle'
    });
    toast2.present();
  }
  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Iniciando Sesión...',
      //duration: 4000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading1() {
    //crea mensaje
    const loading1 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando recuperación...',
      //duration: 4000
    });
    //presenta mensaje
    await loading1.present();
  }
}
