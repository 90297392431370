import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RepartidorAfiliado, User } from '../models/users.model';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EstadorepartidorService {

  //Creo la suscripcion al observable para capturar el estado del Repartidor
  public getEstado$ = new BehaviorSubject<any>(null);
  public getEstado2$ = new BehaviorSubject<any>(null);

  //array para hacer una primera captura de los repartidores
  public repartidoresCap:RepartidorAfiliado[]=[];
  public repartidoresCap2:RepartidorAfiliado[]=[];

  //creo objeto que almacenará los repartidores afiliados
  public repartidores:RepartidorAfiliado[]=[];
  public repartidores2:RepartidorAfiliado[]=[];
  public repartidores3:RepartidorAfiliado[]=[];
  public repartidores4:RepartidorAfiliado[]=[];

  //declaracion de Observables para aplicar la desuscripcion
  private getEstadoRepartidoresSuscriber:Subscription=undefined;
  private getOcupaRepartidoresSuscriber:Subscription=undefined;
  
  constructor(private firestore:FirestoreService) { }

    //convertimos esta funcion en una promesa para que reaccione solo cuando el repartidor este disponible
    public async estadoRepartidor(uidComercio:string){
       this.desuscribeObservables();
       const path='usuarios/'+uidComercio+'/repartidorafiliado/';
       this.getEstadoRepartidoresSuscriber=this.firestore.getestadoRepartidores<RepartidorAfiliado>(path,ref=>(ref.where('habilitarcomercio','==',true).where('habilitarrepartidor','==',true))).subscribe(async res => {
        if(await res){
        //limpio el array para cada cambio
        this.repartidoresCap=[];
        //agrego repartidores al array que cumplan las condiciones
        for await (let add of res){
            this.repartidoresCap.push(add)
        } 
        //limpio el array para cada cambio
          this.repartidores2=[];
          for await (let add2 of this.repartidoresCap){
            const pathrepartidor='usuarios/';
            this.getOcupaRepartidoresSuscriber=this.firestore.getOcupadorepartidor<User>(pathrepartidor,add2.uid).subscribe(async res2 => {
              if(res2){
                  if (await res2.ocupado==false && await res2.estatus=='ACTIVO' && await res2.estado==true){
                   this.repartidores2.push(add2);
                   this.repartidores=Array.from(this.repartidores2.reduce((map,obj)=>map.set(obj.uid,obj), new Map()).values()); 
                   this.getEstado$.next(this.repartidores)
                  }
                }
            })
            //se limpia el array para reinicar cada ciclo con valores nuevos
            //this.repartidores2=[];
          }
        }       
      }) 
    }

    //convertimos esta funcion en una promesa para la consulta automatica e repartidores
    public async estadoRepartidor2(uidComercio:string){
      const path='usuarios/'+uidComercio+'/repartidorafiliado/';
      await this.firestore.getCollection5<RepartidorAfiliado>(path,ref=>(ref.where('habilitarcomercio','==',true).where('habilitarrepartidor','==',true))).then(async ress => {
        //limpio el array para ser llenado 
        this.repartidoresCap2=[];
        if(await ress){
          await ress.forEach(async (doc:any) => {
              this.repartidoresCap2.push(doc.data());
          })
        }
      })  
        //limpio el array de repartidores disponibles
      this.repartidores3=[];
      for await (let add3 of this.repartidoresCap2){
        const pathrepartidor='usuarios/'+add3.uid;
        await this.firestore.getCollection4<User>(pathrepartidor).then(async res2 => {
          if(await res2){
          let valor5:any=null;
          valor5 = res2.data();
              if (await valor5.ocupado==false && await valor5.estatus=='ACTIVO' && await valor5.estado==true){
                this.repartidores3.push(add3);
                this.repartidores4=Array.from(this.repartidores3.reduce((map,obj)=>map.set(obj.uid,obj), new Map()).values());  
              }
          }
        })
      }
      this.getEstado2$.next(this.repartidores4)
    }

    //Observable de los Repartidores Asociados al Comercio y en Linea coon el Comercio
    getEstadorepartidor(): Observable<any>{
      return this.getEstado$.asObservable();
     }

    //Observable de los Repartidores Asociados al Comercio y en Linea coon el Comercio para la seleccion automatica
    getEstadorepartidor2(): Observable<any>{
      return this.getEstado2$.asObservable();
     }

    //llamo a la desuscripcion de observables
    desuscribeObservables(){
      if(this.getEstadoRepartidoresSuscriber!=undefined){
        this.getEstadoRepartidoresSuscriber.unsubscribe()
      }
      if(this.getOcupaRepartidoresSuscriber!=undefined){
        this.getOcupaRepartidoresSuscriber.unsubscribe()
      }
    }

    



  }