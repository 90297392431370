import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Pedido, PedidoAsignado, preComercio, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';
import { Geolocation } from '@capacitor/geolocation';
import { GetpositionService } from 'src/app/services/getposition.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-repartidor',
  templateUrl: './repartidor.component.html',
  styleUrls: ['./repartidor.component.scss'],
})
export class RepartidorComponent implements OnInit, OnDestroy {

  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLog: any;
  private dataUserlog: User;

  //declaracion de Observables para aplicar la desuscripcion
  private getUbicacionSuscriber: Subscription = undefined;
  private dataSuscriber: Subscription = undefined;
  private dataSuscriber2: Subscription = undefined;
  private getPedidosSuscriber: Subscription = undefined;
  private getPedidoSuscriber: Subscription = undefined;
  private verPedidoSuscriber: Subscription = undefined;
  private getdataubicaSuscriber: Subscription = undefined;
  private getHistorialrepar: Subscription = undefined;

  private getResume: Subscription = undefined;

  public getPedidoAsignado: PedidoAsignado[] = [];
  public historialRepar2: PedidoAsignado[] = [];
  public historialRepar3: PedidoAsignado[] = [];
  public getPedido: Pedido;
  public getPedidoasign: Pedido = undefined;

  public verPed = false;
  public verEntrega = false;
  public verIniciaentrega = false;

  //variables que guardan latitud y longitud de la ubicacion actual
  public latRepartidor: number = 0;
  public lngRepartidor: number = 0;

  myUrl: any;
  myTrustedURL: any = undefined;

  //estas 2 variables cotrolaran el tiempo restante de la vista previa de la orden
  timerReset: any;
  timerOrden: string;

  //esta variable activa y desactiva los botones de toma de pedido
  activaToma: boolean = true;

  //este contador guarda lo que lleva el repartidor diario facturado
  totalDiario = 0;

  //variable que guarda direccion del comercio
  direcComer = '';

  //estas variables llaman mis claves api de mapas
  private apikey1 = environment.mapsConfig.apikey1
  private apikey2 = environment.mapsConfig.apikey2

  //lleva el tiempo de observable de datos de usuario logueado
  private contad: any

  //esta variable guarda el valor de la ultima fecha de guardado
  private fechaUbica: any = undefined;
  private tiempoUbica: string;

  constructor(private traeUid: UserlogedService,
    private firestore: FirestoreService,
    private sanitizer: DomSanitizer,
    private toastController2: ToastController,
    private loadingController2: LoadingController,
    private alertController: AlertController,
    private getposition2: GetpositionService,
    private platform: Platform) {
    //se recibe en el constructor el uid capturado  medio de Subject
    this.dataSuscriber = this.traeUid.getDatauser().subscribe(async res => {
      if (res) {
        this.uidUserLog = res.uid;
        this.dataUserlog = res;
        //verficamos is el repartidor tiene algun reparto en curso
        await this.repartoCurso(this.uidUserLog);
        //cargo el historial de repartos del dia
        await this.getHistorialdiario(this.uidUserLog);
        /*me suscribo al evento de volver a pantalla cada ves que app viene de reposo para llamar al guardado de ubicacion
        this.getResume = this.platform.resume.subscribe(async ()=>{
          if (res.estado && !res.ocupado && res.perfil=='REPARTIDOR') {
            await this.guardaUbicacion();
          }
        })
        */
      } else {
        this.uidUserLog = 0;
      }
    })

  }


  ngOnDestroy(): void {
    if (this.getUbicacionSuscriber != undefined) {
      this.getUbicacionSuscriber.unsubscribe();
    }
    if (this.dataSuscriber != undefined) {
      this.dataSuscriber.unsubscribe();
    }
    if (this.getPedidosSuscriber != undefined) {
      this.getPedidosSuscriber.unsubscribe();
    }
    if (this.verPedidoSuscriber != undefined) {
      this.verPedidoSuscriber.unsubscribe();
    }
    if (this.dataSuscriber2 != undefined) {
      this.dataSuscriber2.unsubscribe();
    }
    if (this.getResume != undefined) {
      this.getResume.unsubscribe();
    }
  }

  ngOnInit() {

    //llamo al evento addEventListener en el ngOninit para refrescar estado de ubicación cada vez que se vuelve a primer plano
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        //si la ultima ubicaion registrada es mayor a 5 min se guarda la ubicacion
        if (this.dataUserlog.estado && !this.dataUserlog.ocupado &&
          this.dataUserlog.perfil == 'REPARTIDOR' &&
          (!this.platform.is('capacitor') || !this.platform.is('cordova'))) {
          await this.guardaUbicacion();
        }
      }
    })


  }



  //cada vez que cargo el componente consulto ultima ubicacion del repartidor
  async ionViewWillEnter() {
    //si la ultima ubicaion registrada es mayor a 5 min se guarda la ubicacion
    if (this.dataUserlog.estado && !this.dataUserlog.ocupado &&
      this.dataUserlog.perfil == 'REPARTIDOR' &&
      (!this.platform.is('capacitor') || !this.platform.is('cordova'))) {
      await this.guardaUbicacion();
    }
  }

  // funcion que guarda la ubicacion de repartidor si la ultima ubicacion guardada tiene mas de 10minutos
  async guardaUbicacion() {
    //solo se entra a guardar información si desde la ultima vez que se guardo ubicación con esta funcion al momento
    //de vuelta a la pantalla principaln han pasado mas de 5 minutos
    if (this.fechaUbica != undefined) {
      //console.log('valor de this.fechaUbica cuando es undefine',this.fechaUbica)
      this.tiempoUbica = await this.calculaTiempopedido3(this.fechaUbica);
    }
    if (this.fechaUbica == undefined || this.tiempoUbica == 'caducado1') {
      //creo la ruta para consultar la ultima ubicacion del repartidor
      const pathu = 'usuarios/' + this.uidUserLog + '/registraubicacion/';
      await this.firestore.getCollection5<any>(pathu, ref => (ref).orderBy('fecha', 'asc').limitToLast(1)).then(async ubicacion => {
        await ubicacion.forEach(async (doc: any) => {
          let valor: any[] = [];
          valor[0] = doc.data();
          let timeUbica = await this.calculaTiempopedido3(valor[0].fecha.toDate())
          //le asigno el valor conseguido this.fechaUbica la primera vez que es undefine
          if (this.fechaUbica == undefined) {
            this.fechaUbica = valor[0].fecha.toDate();
          }
          //console.log('ENTRÓ A CONSULTAR BASE DE DATOS',timeUbica)
          //me desuscribo del observable de la data del usuario
          //this.dataSuscriber.unsubscribe()
          if (timeUbica == 'caducado1') {
            //Activo el servicio para registrar la ubicacion en la entrega
            await this.getposition2.getRepartidorlocation().then(async () => {
              //Solicito Ubicación Actual del repartidor
              let traeUbicacion = await this.getposition2.getLocaterepartidor2();
              if (traeUbicacion != null) {
                const idUbica = this.firestore.getId();
                await this.firestore.createDoc2(traeUbicacion, pathu, idUbica).then(async () => {
                  //guardo en esta variable la ultima fecha de ubbicación guardada
                  this.fechaUbica = await traeUbicacion.fecha;
                  return await this.presentToast('Ubicación actualizada')
                  //console.log('valor de this.fechaUbica al guardar ubicacion',this.fechaUbica)
                }).catch(async () => {
                });
              } else if (traeUbicacion == null) {
                //creo la ruta para consultar la ultima ubicacion del repartidor
                //const pathu2='usuarios/';
                //await this.firestore.updateDoc<User>({estado:false},pathu2,this.uidUserLog).then(async ()=>{        
                return await this.presentToast2('¡Repartidor, no se detecta acceso a su ubicación!')
                //})
              }
            })
          } else {
            return await this.presentToast('Ubicación actualizada hace  ' + timeUbica)
          }
        })
      })
    } else {
      return await this.presentToast('Ubicación fue actualizada hace ' + this.tiempoUbica)
    }
  }


  //esta funcion se utliza para contar el tiempo de las ordenes segun status
  async calculaTiempopedido2(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);

    if (minutes >= 0) {
      if (minutes == 0 && hours == 0) {
        let res: string;
        res = seconds + ' seg.';
        return res;
      }
      if (minutes < 10 && hours == 0) {
        let res: string;
        res = minutes + ' min y ' + seconds + ' seg.';
        return res;
      }
      if (minutes >= 10 || hours > 0) {
        let res: string;
        res = 'caducado';
        return res;
      }
    }
  }

  //esta funcion se utliza para contar el tiempo desde la ultima ubicacion guardada al momento que la aplicacion entra en primer plano
  async calculaTiempopedido3(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);

    if (minutes >= 0) {
      if (minutes == 0 && hours == 0) {
        let res: string;
        res = seconds + ' seg.';
        return res;
      }
      if (minutes < 5 && hours == 0) {
        let res: string;
        res = minutes + ' min y ' + seconds + ' seg.';
        return res;
      }
      if (minutes >= 5 || hours > 0) {
        let res: string;
        res = 'caducado1';
        return res;
      }
    }
  }


  //funcion para autorizar URL de carga de mapa sin costo
  procesaUrl() {
    this.myTrustedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.myUrl);
  }

  /* en esta funcion cargo solo los repartos asignados pendientes por aceptar y los que fueron aceptados pero no ejecutaron actualizacion en
  sucoleccion pedidos por algun problema de conexion*/
  getRepartos(uidRepartidor: string) {
    //creo la ruta para obtener los datos de los pedido asignados
    const path = 'usuarios/' + uidRepartidor + '/pedidoasignado/';
    //obtengo todos los pedidos asignados en status ENVIADOAREPARTIDOR
    this.getPedidosSuscriber = this.firestore.getCollection3<PedidoAsignado>(path, ref => (ref.where('estatus', 'in', ['ENVIADOREPARTIDOR', 'ACEPTADOREPARTIDOR']))).subscribe(async pedidos => {
      if (pedidos) {
        if (this.getPedidoAsignado.length) {
          this.verPed = false;
        }
        this.verEntrega = true;
        //limpio el array
        this.getPedidoAsignado = [];
        //elimino elementos duplicados
        let aaa = []
        aaa = pedidos;
        let a = []
        a = Array.from(aaa.reduce((map, obj) => map.set(obj.idPedido, obj), new Map()).values());
        //convertimos la fecha para que sea legible en la impresion html
        a.forEach((element: any) => {
          element.fecha = element.fecha.toDate(),
            this.getPedidoAsignado.push(element)
        })
        //ordenamos el array por fecha pedido
        this.getPedidoAsignado.sort(function compare(a, b) {
          return new Date(b.fecha).getTime() - new Date(a.fecha).getTime()
        });
      }
    })
  }


  //en esta función se van guardando el historial de repartos del dia en curso
  async getHistorialdiario(uidRepar: string) {
    //creo la ruta para obtener los datos de los pedido asignados
    const path3 = 'usuarios/' + uidRepar + '/pedidoasignado/';
    let fechaInicial = new Date();
    let fechaFinal = new Date();
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.historialRepar2 = [];
    this.historialRepar3 = []
    this.getHistorialrepar = this.firestore.getCollection3<PedidoAsignado>(path3, ref => (ref.where('fecha', '>=', fechaInicial).where('fecha', '<=', fechaFinal).where('estatus', 'in', ['CANCELADOREPARTIDOR', 'RECHAZADAREPARTIDOR', 'ENTREGADO', 'DEVUELTO']))).subscribe(async historialrepar => {
      if (historialrepar) {
        this.historialRepar2 = historialrepar;
        if (this.historialRepar2.length) {
          //procedo a reducir duplicados(si los hay) por el id de pedido 
          this.historialRepar2 = Array.from(this.historialRepar2.reduce((map, obj) => map.set(obj.idPedido, obj), new Map()).values());
          for await (let totales of this.historialRepar2) {
            //convertimos la fecha para que sea legible en la impresion html
            let fechaleg: any;
            fechaleg = totales.fechaEntrega;
            totales.fechaEntrega = fechaleg.toDate();
            this.historialRepar3.push(totales)
          }
          //procedo a reducir duplicados(si los hay) por el id de pedido 
          this.historialRepar3 = Array.from(this.historialRepar3.reduce((map, obj) => map.set(obj.idPedido, obj), new Map()).values());
          //ordenamos el array por fecha de entrega
          this.historialRepar3.sort(function compare(a, b) {
            return new Date(b.fechaEntrega).getTime() - new Date(a.fechaEntrega).getTime()
          });
          //en este for totalizo los pedidos entregados por el repartidor
          this.totalDiario = 0;
          for await (let totales2 of this.historialRepar3) {
            if (totales2.estatus == 'ENTREGADO') {
              this.totalDiario = this.totalDiario + totales2.precio;
            }
          }
        }
      }
    })
  }


  //funcion para calcular tiempo transcurrido que lleva la orden asignada
  async calculaTiempopedido1(startTime: Date) {
    //creo un contador que muestra informacion cada segundo
    clearInterval(this.timerReset);
    this.timerReset = setInterval(async () => {
      this.timerOrden = '';
      this.timerOrden = await this.calculaTiempopedido2(startTime);
      //Desactivo el contador cuando el tiempo es mayor o igual a 10min
      if (this.timerOrden == 'caducado') {
        clearInterval(this.timerReset);
      }
    }, 1000);
  }



  //Imprimo vista previa del pedido
  async verPedido(pedidoAsignado: PedidoAsignado) {
    //creo la ruta para obtener los datos de los pedido asignados
    const pathh = 'usuarios/' + pedidoAsignado.idComercio + '/pedido/';
    //obtengo el pedido segun el ID
    this.verPedidoSuscriber = this.firestore.getDoc<Pedido>(pathh, pedidoAsignado.idPedido).subscribe(async pedid => {
      if (pedid) {
        this.getPedido = await pedid;
        //convertimos la fecha para que sea legible en la impresion html
        this.getPedido.fechaPedido = await pedid.fechaPedido.toDate()
        this.verPed = true;
        this.verEntrega = true;
        //mando a calcular vencimiento de toma de orden
        await this.calculaTiempopedido1(pedidoAsignado.fecha);
        //proceso la url por api segun si esd dispositivo android o web
        if (this.platform.is('cordova')) {
          //proceso la direccion de entrega para cargar vista previa del mapa
          this.myUrl = '';
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + this.getPedido.ubicacionDespacho.lat + ',' + this.getPedido.ubicacionDespacho.lng + "&zoom=18"
        } else {
          //proceso la direccion de entrega para cargar vista previa del mapa
          this.myUrl = '';
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + this.getPedido.ubicacionDespacho.lat + ',' + this.getPedido.ubicacionDespacho.lng + "&zoom=18"
        }
        //proceso la url de forma segura para mostrar el mapa
        this.procesaUrl();
        //me desuscribo del observable para que no se recargue en pantalla el pedido
        this.verPedidoSuscriber.unsubscribe();
      }
    })
  }

  async cerrarVista() {
    this.verPed = false;
    this.verEntrega = false;
    this.getPedido = undefined;
    //reseteo el contador de toma de pedido
    clearInterval(this.timerReset);
    //reseteo valor del contador
    this.timerOrden = '';
  }

  async cerrarVista2() {
    this.verPed = true;
    this.verEntrega = false;
    this.getPedido = undefined;
  }

  //aceptacion del pedido
  async tomarPedido(pedidoAsignado: PedidoAsignado) {
    //Activo el servicio para tomar el pedido
    await this.getposition2.getRepartidorlocation().then(async () => {
      //Desativo el boton para activar la toma
      this.activaToma = false;
      //Solicito Ubicación Actual del repartidor
      let traeUbicacion1 = await this.getposition2.getLocaterepartidor2();
      if (await traeUbicacion1 != null) {
        //reseteo el contador de toma de pedido
        clearInterval(this.timerReset);
        //reseteo valor del contador
        this.timerOrden = '';
        await this.cerrarVista2();
        await this.presentLoading();
        //reverifico si el pedido ya fue asignado
        const path2 = 'usuarios/' + pedidoAsignado.idComercio + '/pedido/' + pedidoAsignado.idPedido;
        await this.firestore.getCollection4<Pedido>(path2).then(async pedidoAsing12 => {
          let valor12: any = null;
          valor12 = await pedidoAsing12.data();
          //si el estado del pedido e igual al RECHAZADAREPARTIDOR o PAGADO diferente de EnviadoRepartidor se procede
          if (await valor12 != null && await valor12.estado == 'ENVIADOREPARTIDOR' && pedidoAsignado != undefined && await valor12.uidRepartidor == this.uidUserLog) {
            //creo la ruta para obtener para actualizar el status del pedido aceptado
            const path1 = 'usuarios/' + pedidoAsignado.idComercio + '/pedido/';
            /*Actualizo estatus en el pedido asignado (guarda Relacion con Funtions newReparto, donde se actualiza el capo ocupado del repartidor
            a true y el pedidoasignado a ACEPTADOREPARTIDOR)*/
            await this.firestore.updateDoc<Pedido>({ estado: "ACEPTADOREPARTIDOR", fechaEntrega: new Date }, path1, pedidoAsignado.idPedido).then(async () => {
              //impio la variable que guarda informacion del mapa vista previa
              this.myTrustedURL = undefined;
              await this.cerrarVista2();
              //llamo al reparto en curso
              await this.repartoCurso(this.uidUserLog);
              //muestro mensaje Exitoso para el Repartidor
              await this.presentToast('Captura exitosa... dirijase al comercio');
              setTimeout(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover5 = await this.loadingController2.getTop();
                if (popover5) {
                  await popover5.dismiss(null);
                }
              }, 2000);
            }).catch(async () => {
              this.verPed = true;
              this.verEntrega = true;
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover6 = await this.loadingController2.getTop();
              if (popover6) {
                await popover6.dismiss(null);
              }
              //cargo las solicitudes de repartos nuevamente
              this.getRepartos(this.uidUserLog);
              //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
              return await this.presentToast1('Ocurrio un problema de conexión, intente nuevamente')
            });
          } else if (await valor12.estado == 'ACEPTADOREPARTIDOR' && await valor12.uidRepartidor == this.uidUserLog) {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover7 = await this.loadingController2.getTop();
            if (popover7) {
              await popover7.dismiss(null);
            }
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            return await this.presentToast1('¡El pedido ya fue tomado por usted!')
          } else if (await valor12.estado == 'RECHAZADAREPARTIDOR') {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover7 = await this.loadingController2.getTop();
            if (popover7) {
              await popover7.dismiss(null);
            }
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            return await this.presentToast1('¡El reparto ya no se encuentra disponible para usted!')
          }
        })
      } else {
        //Desativo el boton para activar la toma
        this.activaToma = true;
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover8 = await this.loadingController2.getTop();
        if (popover8) {
          await popover8.dismiss(null);
        }
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        return await this.presentToast1('No Hay acceso a su ubicación, reactive la ubicacion para tomar el pedido...')
      }

    })
  }


  /*si el repartidor toma el pedido y por alguna razon ajena no puede
  continuar con la busqueda al comercio*/
  async cancelarCaptura(pedidoCapturado: Pedido) {
    const alert6 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: "¿Confirma que desea <b>CANCELAR</b> el reparto capturado del comercio <b>" + pedidoCapturado.nombreComercio + "</b>?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            await this.cerrarVista2();
            await this.presentLoading4();
            //reverifico si el pedido se encuentra en el estatus correspondiente
            const path2 = 'usuarios/' + pedidoCapturado.uidComercio + '/pedido/' + pedidoCapturado.id;
            await this.firestore.getCollection4<Pedido>(path2).then(async pedidoAsing13 => {
              let valor13: any = null;
              valor13 = await pedidoAsing13.data();
              //si el estado del pedido es igual al ACEPTADOREPARTIDOR se procede a cancelar la captura
              if (await valor13 != null && await valor13.estado == 'ACEPTADOREPARTIDOR' && pedidoCapturado != undefined && await valor13.uidRepartidor == this.uidUserLog && this.getPedidoasign.estado == 'ACEPTADOREPARTIDOR') {
                //creo la ruta para obtener para actualizar el status del pedido aceptado
                const path1 = 'usuarios/' + pedidoCapturado.uidComercio + '/pedido/';
                /*Actualizo estatus en el pedido asignado (guarda Relacion con Funtions newReparto, donde se actualiza el capo ocupado del repartidor
                a false y el pedidoasignado a CANCELADOREPARTIDOR)*/
                await this.firestore.updateDoc<Pedido>({ estado: "CANCELADOREPARTIDOR", fechaEntrega: new Date }, path1, pedidoCapturado.id).then(async () => {
                  this.verPedidoSuscriber.unsubscribe();
                  this.getPedidoSuscriber.unsubscribe();
                  if (this.getPedidosSuscriber != undefined) {
                    this.getPedidosSuscriber.unsubscribe();
                  }
                  this.verIniciaentrega = false;
                  this.getPedidoasign = undefined;
                  this.getPedidoAsignado = [];
                  //muestro mensaje Exitoso para el Repartidor
                  await this.presentToast('¡El reparto fue cancelado por usted!');
                  setTimeout(async () => {
                    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                    const popover5 = await this.loadingController2.getTop();
                    if (popover5) {
                      await popover5.dismiss(null);
                      this.activaToma = true;
                      //una vez que se cierra el popup mando a recargar la app para reiniciar el settimer del navbar para guardar de ubicacion
                      window.location.reload();
                    } else {
                      this.activaToma = true;
                      //una vez que se cierra el popup mando a recargar la app para reiniciar el settimer del navbar para guardar de ubicacion
                      window.location.reload();
                    }
                  }, 2000);
                }).catch(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover6 = await this.loadingController2.getTop();
                  if (popover6) {
                    await popover6.dismiss(null);
                  }
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  return await this.presentToast1('No fue posible cancelar su reparto, intente nuevamente ó comuniquese con soporte de App')
                });
              } else if (await valor13.estado != 'ACEPTADOREPARTIDOR' && await valor13.estado != 'CANCELADOREPARTIDOR' && await valor13.estado != 'RECHAZADAREPARTIDOR') {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover7 = await this.loadingController2.getTop();
                if (popover7) {
                  await popover7.dismiss(null);
                }
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                return await this.presentToast1('No fue posible cancelar su reparto, intente nuevamente ó comuniquese con soporte de App')
              } else if (await valor13.estado == 'CANCELADOREPARTIDOR') {
                if (this.verPedidoSuscriber != undefined) {
                  this.verPedidoSuscriber.unsubscribe();
                }
                if (this.getPedidoSuscriber != undefined) {
                  this.getPedidoSuscriber.unsubscribe();
                }
                if (this.getPedidosSuscriber != undefined) {
                  this.getPedidosSuscriber.unsubscribe();
                }
                this.verIniciaentrega = false;
                this.getPedidoasign = undefined;

                setTimeout(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover7 = await this.loadingController2.getTop();
                  if (popover7) {
                    await popover7.dismiss(null);
                    this.activaToma = true;
                    //recargo componente
                    window.location.reload();
                  } else {
                    this.activaToma = true;
                    //recargo componente
                    window.location.reload();
                  }
                }, 2000);
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                return await this.presentToast1('¡El reparto ya fue cancelado por usted!')
              } else if (await valor13.estado == 'RECHAZADAREPARTIDOR') {
                /*
                if(this.verPedidoSuscriber!=undefined){
                  this.verPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidoSuscriber!=undefined){
                  this.getPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidosSuscriber!=undefined){
                  this.getPedidosSuscriber.unsubscribe();
                }
                this.verIniciaentrega=false;
                this.getPedidoasign=undefined;
    
                setTimeout(async()=>{     
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover7 = await this.loadingController2.getTop();
                  if (popover7){
                    await popover7.dismiss(null); 
                    //reseteo los detalles del pedido asignado
                    this.getPedidoasign=undefined;
                    //recargo componente
                    window.location.reload();
                  }else{
                    //reseteo los detalles del pedido asignado
                    this.getPedidoasign=undefined;
                    //recargo componente
                    window.location.reload();
                  }
                }, 2000);
                */
                //recargo componente
                window.location.reload();
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                return await this.presentToast1('¡El comercio previamente canceló el reparto con usted!')
              }
            })
          }
        }
      ]
    });
    await alert6.present();
  }


  /*si el repartidor desea cancelar el pedido antes de tomarlo, se crea esta opcion*/
  async cancelarEnvio(pedidoEnviado: Pedido) {
    const alert67 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: "¿Confirma que desea <b>CANCELAR</b> el reparto que le fue asignado por el comercio <b>" + pedidoEnviado.nombreComercio + "</b>?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            await this.cerrarVista2();
            await this.presentLoading4();
            //reverifico si el pedido se encuentra en el estatus correspondiente
            const path22 = 'usuarios/' + pedidoEnviado.uidComercio + '/pedido/' + pedidoEnviado.id;
            await this.firestore.getCollection4<Pedido>(path22).then(async pedidoAsing43 => {
              let valor43: Pedido = null;
              valor43 = await pedidoAsing43.data();
              //si el estado del pedido es igual al ENVIADOREPARTIDOR se procede a cancelar el envio
              if (valor43 != null && valor43.estado == 'ENVIADOREPARTIDOR' && pedidoEnviado != undefined && valor43.uidRepartidor == this.uidUserLog && pedidoEnviado.estado == 'ENVIADOREPARTIDOR') {
                //creo la ruta para obtener para actualizar el status del pedido enviado
                const path11 = 'usuarios/' + pedidoEnviado.uidComercio + '/pedido/';
                /*Actualizo estatus en el pedido asignado (guarda Relacion con Funtions newReparto, donde se actualiza el capo ocupado del repartidor
                a false y el pedidoasignado a CANCELADOREPARTIDOR)*/
                await this.firestore.updateDoc<Pedido>({ estado: "CANCELADOREPARTIDOR", fechaEntrega: new Date }, path11, pedidoEnviado.id).then(async () => {
                  this.verPedidoSuscriber.unsubscribe();
                  this.getPedidoSuscriber.unsubscribe();
                  if (this.getPedidosSuscriber != undefined) {
                    this.getPedidosSuscriber.unsubscribe();
                  }
                  this.verIniciaentrega = false;
                  this.getPedidoasign = undefined;
                  this.getPedidoAsignado = [];
                  //muestro mensaje Exitoso para el Repartidor
                  await this.presentToast('¡La asignación del reparto fue cancelado por usted!');
                  setTimeout(async () => {
                    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                    const popover55 = await this.loadingController2.getTop();
                    if (popover55) {
                      await popover55.dismiss(null);
                      this.activaToma = true;
                      //recargo componente
                      window.location.reload();
                    } else {
                      this.activaToma = true;
                      //recargo componente
                      window.location.reload();
                    }
                  }, 2000);
                }).catch(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover56 = await this.loadingController2.getTop();
                  if (popover56) {
                    await popover56.dismiss(null);
                  }
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  return await this.presentToast1('No fue posible cancelar la asignación, intente nuevamente ó comuniquese con soporte de App')
                });
              } else if (await valor43.estado != 'ENVIADOREPARTIDOR' && await valor43.estado != 'ACEPTADOREPARTIDOR' && await valor43.estado != 'RECHAZADAREPARTIDOR') {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover75 = await this.loadingController2.getTop();
                if (popover75) {
                  await popover75.dismiss(null);
                }
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                return await this.presentToast1('No fue posible cancelar la signación, intente nuevamente ó comuniquese con soporte de App')
              } else if (await valor43.estado == 'ACEPTADOREPARTIDOR') {
                if (this.verPedidoSuscriber != undefined) {
                  this.verPedidoSuscriber.unsubscribe();
                }
                if (this.getPedidoSuscriber != undefined) {
                  this.getPedidoSuscriber.unsubscribe();
                }

                if (this.getPedidosSuscriber != undefined) {
                  this.getPedidosSuscriber.unsubscribe();
                }
                this.verIniciaentrega = false;
                this.getPedidoasign = undefined;

                setTimeout(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover76 = await this.loadingController2.getTop();
                  if (popover76) {
                    await popover76.dismiss(null);
                    this.activaToma = true;
                    //recargo componente
                    window.location.reload();
                  } else {
                    this.activaToma = true;
                    //recargo componente
                    window.location.reload();
                  }
                }, 2000);
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                return await this.presentToast1('¡El reparto ya fue aceptado por usted!, si acepto por error, puede cancelarlo')
              } else if (await valor43.estado == 'RECHAZADAREPARTIDOR') {
                /*
                if(this.verPedidoSuscriber!=undefined){
                  this.verPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidoSuscriber!=undefined){
                  this.getPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidosSuscriber!=undefined){
                  this.getPedidosSuscriber.unsubscribe();
                }
                this.verIniciaentrega=false;
                this.getPedidoasign=undefined;
             
                 setTimeout(async()=>{
                 //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                 const popover77 = await this.loadingController2.getTop();
                 if (popover77){
                    await popover77.dismiss(null); 
                     this.activaToma=true;
                    //reseteo los detalles del pedido asignado
                    this.getPedidoasign=undefined;
                     //recargo componente
                     window.location.reload();
                   }else{
                     this.activaToma=true;
                    //reseteo los detalles del pedido asignado
                    this.getPedidoasign=undefined;
                     //recargo componente
                     window.location.reload();
                   }
               }, 2000);
               */
                //recargo componente
                window.location.reload();
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                return await this.presentToast1('¡El comercio previamente canceló el reparto con usted!!')
              }
            })
          }
        }
      ]
    });
    await alert67.present();
  }



  //Funcion que verifica si el repartidor tiene algun reparto en curso
  async repartoCurso(uidRepartidor1: string) {
    //creo la ruta para verificar si existe pedido asignado
    const path = 'usuarios/' + uidRepartidor1 + '/pedidoasignado/';
    //obtengo todos los pedidos asignados en status ENVIADOAREPARTIDOR
    this.getPedidoSuscriber = this.firestore.getCollection3<PedidoAsignado>(path, ref => (ref.where('estatus', '==', 'ACEPTADOREPARTIDOR')).limit(1)).subscribe(pedido1 => {
      if (pedido1.length) {
        //creo la ruta para traerme el pedido
        const path1 = 'usuarios/' + pedido1[0].idComercio + '/pedido/';
        //obtengo el pedido segun el ID
        this.verPedidoSuscriber = this.firestore.getDoc<Pedido>(path1, pedido1[0].idPedido).subscribe(async pedido2 => {
          if (await pedido2 && (await pedido2.estado == 'ACEPTADOREPARTIDOR' || await pedido2.estado == 'ENCAMINO') && await pedido2.uidRepartidor == this.uidUserLog) {
            this.getPedidoasign = await pedido2;
            //convertimos la fecha para que sea legible en la impresion html
            this.getPedidoasign.fechaPedido = await pedido2.fechaPedido.toDate();
          } else {
            this.verPed = true;
            this.verEntrega = true;
            //cargo las solicitudes de repartos pendientes
            this.getRepartos(this.uidUserLog);
          }
        })
      } else {
        //cargo las solicitudes de repartos pendientes
        this.getRepartos(this.uidUserLog);
      }
    })
  }

  //Funcion que activa el Inicio del Reparto
  async iniciaReparto(pedidoEnviado: Pedido) {

    const alert5 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: "¿Confirma que ya recibió del comercio <b>" + pedidoEnviado.nombreComercio + "</b> el pedido del cliente <b>" + pedidoEnviado.cliente.nombre + "</b>" +
        " y está listo para dirigirse hacia : <b>" + pedidoEnviado.zonadelivery.zona + "</b> con el pedido?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //Solicito ubicacion de dispositivo
            await Geolocation.getCurrentPosition().then(async ress => {
              if (ress) {
                this.latRepartidor = ress.coords.latitude;
                this.lngRepartidor = ress.coords.longitude;
              } else {
                this.latRepartidor = 0;
                this.lngRepartidor = 0;
                //muestro mensaje de problemas en el guardado
                return await this.presentToast1('¡Debe activar la ubicación!')
              }
            }).catch(async () => {
              await Geolocation.requestPermissions();
              //muestro mensaje de problemas en el guardado
              return await this.presentToast1('¡Debe activar la ubicación!')
            })

            //reverifico si el pedido esta en el estado correspondiente para este paso ACEPTADOREPARTIDOR
            const path22 = 'usuarios/' + pedidoEnviado.uidComercio + '/pedido/' + pedidoEnviado.id;
            await this.firestore.getCollection4<Pedido>(path22).then(async pedidoAsing32 => {
              let valor32: any = null;
              valor32 = await pedidoAsing32.data();
              //si el estado del pedido e igual al ACEPTADOREPARTIDOR se procede a iniciar el pedido
              if (await valor32 != null && await valor32.estado == 'ACEPTADOREPARTIDOR' && await valor32.uidRepartidor == this.uidUserLog && this.getPedidoasign.estado == 'ACEPTADOREPARTIDOR') {
                //inicio arrancada de pedido
                await this.presentLoading3();
                //creo la ruta para obtener para actualizar el status del pedido aceptado
                const path4 = 'usuarios/' + pedidoEnviado.uidComercio + '/pedido/';
                //Actualizo estado en el pedido del comercio
                await this.firestore.updateDoc<Pedido>({ estado: "ENCAMINO", fechaEntrega: new Date }, path4, pedidoEnviado.id).then(async () => {
                  this.verIniciaentrega = true;
                  //recargo el reparto en curso
                  await this.repartoCurso(this.uidUserLog);
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover53 = await this.loadingController2.getTop();
                  if (popover53) {
                    await popover53.dismiss(null);
                  }
                  //Inicio el mapa desde ubicacion actual o desde la ubicacion del comercio
                  if (this.latRepartidor == 0 && this.lngRepartidor == 0) {
                    await this.funcionMapa(pedidoEnviado);
                  } else {
                    await this.funcionMapa1(pedidoEnviado, this.latRepartidor, this.lngRepartidor);
                  }
                }).catch(async () => {
                  //recargo el reparto en curso
                  await this.repartoCurso(this.uidUserLog);
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover3 = await this.loadingController2.getTop();
                  if (popover3) {
                    await popover3.dismiss(null);
                  }
                  //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                  return await this.presentToast1('Ocurrio un problema al iniciar la entrega, intente nuevamente')
                });
              } else if (await valor32.estado == 'ENCAMINO' && await valor32.uidRepartidor == this.uidUserLog) {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover4 = await this.loadingController2.getTop();
                if (popover4) {
                  await popover4.dismiss(null);
                }
                //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                return await this.presentToast1('La entrega ya fue iniciada, proceda con el despacho...')
              } else if (await valor32.estado == 'RECHAZADAREPARTIDOR' || await valor32.uidRepartidor != this.uidUserLog) {
                /*
                if(this.verPedidoSuscriber!=undefined){
                  this.verPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidoSuscriber!=undefined){
                  this.getPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidosSuscriber!=undefined){
                  this.getPedidosSuscriber.unsubscribe();
                }              
                setTimeout(async()=>{
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover4 = await this.loadingController2.getTop();
                  if (popover4){
                    await popover4.dismiss(null); 
                      //recargo componente
                      window.location.reload();
                    }else{
                      //recargo componente
                      window.location.reload();
                    }
                }, 2000);
                this.verIniciaentrega=false;
                this.getPedidoasign=undefined;
                */
                //recargo componente
                window.location.reload();
                //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                return await this.presentToast1('El comercio canceló la entrega actual, contacte con comercio...')
              }
            });
          }
        }
      ]
    });
    await alert5.present();

  }

  // en esta función se maneja para procesar pedidos devueltos a comercio
  async pedidoDevuelto(pedidodev: Pedido) {

    const alert61 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: "¿Confirma que desea marcar y notificar como <b>DEVUELTO</b> el reparto en proceso del comercio: <b>" + pedidodev.nombreComercio + "</b>, cliente: <b>" + pedidodev.cliente.nombre + " " + pedidodev.cliente.apellido + "</b>?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //reverifico si el pedido esta en el estado correspondiente para este paso ENCAMINO
            const path23 = 'usuarios/' + pedidodev.uidComercio + '/pedido/' + pedidodev.id;
            await this.firestore.getCollection4<Pedido>(path23).then(async pedidoAsing33 => {
              let valor33: any = null;
              valor33 = await pedidoAsing33.data();
              //si el estado del pedido e igual a ENCAMINO se procede a iniciar el pedido
              if (await valor33 != null && await valor33.estado == 'ENCAMINO' && this.getPedidoasign.estado == 'ENCAMINO' && await valor33.uidRepartidor == this.uidUserLog) {
                //inicio arrancada de pedido
                await this.presentLoading3();
                //creo la ruta para obtener para actualizar el status del pedido aceptado
                const path4 = 'usuarios/' + pedidodev.uidComercio + '/pedido/';
                //Actualizo estado en el pedido del comercio
                await this.firestore.updateDoc<Pedido>({ estado: "DEVUELTO", fechaEntrega: new Date }, path4, pedidodev.id).then(async () => {
                  if (this.verPedidoSuscriber != undefined) {
                    this.verPedidoSuscriber.unsubscribe();
                  }
                  if (this.getPedidoSuscriber != undefined) {
                    this.getPedidoSuscriber.unsubscribe();
                  }
                  if (this.getPedidosSuscriber != undefined) {
                    this.getPedidosSuscriber.unsubscribe();
                  }
                  this.verIniciaentrega = false;
                  this.getPedidoasign = undefined;
                  setTimeout(async () => {
                    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                    const popover8 = await this.loadingController2.getTop();
                    if (popover8) {
                      await popover8.dismiss(null);
                      //recargo componente
                      window.location.reload();
                    } else {
                      //recargo componente
                      window.location.reload();
                    }
                  }, 2000);
                  //muestro mensaje Exitoso para el Repartidor
                  await this.presentToast('¡El reparto actual ha sido marcado y notificado como <b>DEVUELTO</b> exitosamente!');
                }).catch(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover9 = await this.loadingController2.getTop();
                  if (popover9) {
                    await popover9.dismiss(null);
                  }
                  //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                  return await this.presentToast1('Ocurrio un problema al marcar el reparto como <b>DEVUELTO</b>, intente nuevamente')
                });
              } else if (await valor33.estado == 'ENTREGADO' && await valor33.uidRepartidor == this.uidUserLog) {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover10 = await this.loadingController2.getTop();
                if (popover10) {
                  await popover10.dismiss(null);
                }
                //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                return await this.presentToast1('Previamente la entrega fue notificada y marcada como exitosa por usted')
              } else if (await valor33.estado == 'RECHAZADAREPARTIDOR' || await valor33.uidRepartidor != this.uidUserLog) {
                /*
                 if(this.verPedidoSuscriber!=undefined){
                  this.verPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidoSuscriber!=undefined){
                  this.getPedidoSuscriber.unsubscribe();
                }
                if(this.getPedidosSuscriber!=undefined){
                    this.getPedidosSuscriber.unsubscribe();
                }
    
                setTimeout(async()=>{
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover11 = await this.loadingController2.getTop();
                  if (popover11){
                    await popover11.dismiss(null); 
                      //recargo componente
                      window.location.reload();
                    }else{
                      //recargo componente
                      window.location.reload();
                    }
                }, 2000);
                this.verIniciaentrega=false;
                this.getPedidoasign=undefined;
                */
                //recargo componente
                window.location.reload();
                //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                return await this.presentToast1('El comercio canceló la entrega en proceso, contacte con comercio...')
              }
            });

          }
        }
      ]
    });
    await alert61.present();

  }


  //confirmacion de pago de delivery recibido por repartidor
  async costodelivRepartidor(pedido2: Pedido) {
    //si el comercio no ha confirmado la recepción del pago del delivery, procedo a preguntar
    if (!pedido2.cobrodelivComercio) {
      //envio un mensaje diferente cuando se trata de una zona a cotizar
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        backdropDismiss: false,
        message: "¿El pago del servicio de reparto por <b>" + pedido2.zonadelivery.precio.toFixed(2) + "USD</b> o su equivalente en moneda base, fue recibido por usted?",
        buttons: [
          {
            text: 'NO',
            id: 'cancel-button',
            handler: async () => {
              return await this.presentToast2('Sí recibe el pago durante el proceso de entrega, notifiquelo en el boton: <b>Notificar pago recibido por repartidor</b>, en caso contrario comuniquese con el comercio')
            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              //reverifico si el pedido esta en el estado correspondiente para este paso ENCAMINO
              const path25 = 'usuarios/' + pedido2.uidComercio + '/pedido/' + pedido2.id;
              await this.firestore.getCollection4<Pedido>(path25).then(async pedidoAsing53 => {
                let valor53: any = null;
                valor53 = await pedidoAsing53.data();
                //si el estado del pedido e igual a ENCAMINO se procede a iniciar el pedido
                if (await valor53 != null && await valor53.estado == 'ENCAMINO' && this.getPedidoasign.estado == 'ENCAMINO' && await valor53.uidRepartidor == this.uidUserLog) {
                  //creo la ruta para actualizar el pago recibido por el comercio a true
                  const path1 = 'usuarios/' + pedido2.uidComercio + '/pedido/';
                  //Actualizo la opcion del delivery a false 
                  await this.firestore.updateDoc<Pedido>({ cobrodelivRepartidor: true }, path1, pedido2.id).then(async () => {
                    //muestro mensaje de actuslización con exito
                    return await this.presentToast('¡Pago de reparto recibido por repartidor, comercio será notificado!')
                  }).catch(async () => {
                    //si hay un error al actualizar la recepcion de pago por el comercio
                    return await this.presentToast1('No fue posible confirmar la recepción de pago del reparto por el usted, intente nuevamente ó comuniquese con soporte de App')
                  });
                } else if (await valor53.estado == 'RECHAZADAREPARTIDOR' || await valor53.uidRepartidor != this.uidUserLog) {
                  /*
                  if(this.verPedidoSuscriber!=undefined){
                   this.verPedidoSuscriber.unsubscribe();
                 }
                 if(this.getPedidoSuscriber!=undefined){
                  this.getPedidoSuscriber.unsubscribe();
                 }
                 if(this.getPedidosSuscriber!=undefined){
                   this.getPedidosSuscriber.unsubscribe();
                 }
                 this.verIniciaentrega=false;
                 this.getPedidoasign=undefined;
                  setTimeout(async()=>{
                    //recargo componente
                    window.location.reload();
                  }, 2000);
                  */
                  //recargo componente
                  window.location.reload();
                  //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
                  return await this.presentToast1('El comercio cancelo la entrega en proceso, contacte con comercio...')
                }
              })
            }
          }
        ]
      });
      await alert1.present();
    }

  }

  //inicar llamada telefonica
  startCallphone(phone: string) {
    window.open("tel:" + phone);
  }

  //inicar conversación por whatssap
  startWhass(phone2: string, cliente2: string, idPe: string) {
    let link1 = 'https://wa.me/' + phone2 + '?text=Ref:%20' + idPe + ', Hola%20le%20escribe%20el%20repartidor%20sobre%20el%20Pedido%20de: ' + cliente2
    window.open(link1, '_blank');
  }


  //configuracion de la funcion que muestra mensaje de envio exitoso al repartidor
  async presentToast(mensaje: string) {
    const toast11 = await this.toastController2.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 3000,
      color: 'success',
      position: 'middle'
    });
    await toast11.present();
  }

  //configuracion de la funcion que muestra mensaje de envio NO exitoso al repartidor
  async presentToast1(mensaje: string) {
    const toast2 = await this.toastController2.create({
      message: mensaje,
      color: 'danger',
      cssClass: 'normal',
      duration: 7000,
      position: 'middle'
    });
    await toast2.present();
  }

  //configuracion de la funcion que muestra mensaje de advertencia
  async presentToast2(mensaje: string) {
    const toast3 = await this.toastController2.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 5000,
      position: 'middle'
    });
    await toast3.present();
  }

  //configuracion de la funcion que muestra mensaje de espera mientras se captura el reparto
  async presentLoading() {
    //crea mensaje
    let loading1 = await this.loadingController2.create({
      cssClass: 'normal',
      message: 'Capturando Reparto...',
      duration: 8000
    });
    //presenta mensaje
    await loading1.present();
  }

  //configuracion de la funcion que muestra mensaje de espera mientras se Inica la entrega
  async presentLoading3() {
    //crea mensaje
    let loading2 = await this.loadingController2.create({
      cssClass: 'normal',
      message: 'Iniciando Reparto...',
      duration: 8000
    });
    //presenta mensaje
    await loading2.present();
  }

  //configuracion de la funcion que muestra mensaje de espera mientras se Inica la entrega
  async presentLoading4() {
    //crea mensaje
    let loading3 = await this.loadingController2.create({
      cssClass: 'normal',
      message: 'Finalizando Reparto...',
      duration: 8000
    });
    //presenta mensaje
    await loading3.present();
  }

  //abre el mapa con el inicio dede el comercio
  async funcionMapa(pedidoget: Pedido) {
    const path4 = 'precomercio/' + pedidoget.uidComercio;
    await this.firestore.getCollection4<preComercio>(path4).then(async pedi => {
      let valor1: any;
      valor1 = await pedi.data();
      var link = "" + "http://maps.google.com/maps?saddr=" + valor1.ubicacionpri.lat + "," + valor1.ubicacionpri.lng + "&daddr=" + pedidoget.ubicacionDespacho.lat + "," + pedidoget.ubicacionDespacho.lng;
      window.open(link, '_blank');
    })
  }

  //abre el mapa desde que se toma el pedido
  async funcionMapa1(pedidoget: Pedido, lat: number, lng: number) {
    var link = "" + "http://maps.google.com/maps?saddr=" + lat + "," + lng + "&daddr=" + pedidoget.ubicacionDespacho.lat + "," + pedidoget.ubicacionDespacho.lng;
    window.open(link, '_blank');
  }

  //abre el mapa desde el punto que se encuentre el repartidor
  async funcionMapa2(pedidoget: Pedido) {
    //Solicito ubicacion de dispositivo
    await Geolocation.getCurrentPosition().then(async ress2 => {
      var link = "" + "http://maps.google.com/maps?saddr=" + ress2.coords.latitude + "," + ress2.coords.longitude + "&daddr=" + pedidoget.ubicacionDespacho.lat + "," + pedidoget.ubicacionDespacho.lng;
      window.open(link, '_blank');
    }).catch(async () => {
      //muestro mensaje de problemas en el guardado
      await this.presentToast2('¡Debe activar la ubicación!')
      await Geolocation.requestPermissions();
    })
  }

  //abre el mapa desde el punto que se encuentre el repartidor hasta el comercio
  async funcionMapa3(pedidoget: Pedido) {
    //Solicito ubicacion de dispositivo
    await Geolocation.getCurrentPosition().then(async ress3 => {
      const path5 = 'precomercio/' + pedidoget.uidComercio;
      await this.firestore.getCollection4<preComercio>(path5).then(async pedi => {
        let valor: any;
        valor = await pedi.data();
        var link = "" + "http://maps.google.com/maps?saddr=" + ress3.coords.latitude + "," + ress3.coords.longitude + "&daddr=" + valor.ubicacionpri.lat + "," + valor.ubicacionpri.lng;
        window.open(link, '_blank');
        //se capturan datos de dirección
        this.direcComer = valor.direccionlocal + ', ' + valor.municipio + ', ' + valor.ubicacion;
      });
    }).catch(async () => {
      //muestro mensaje de problemas en el guardado
      await this.presentToast2('¡Debe activar la ubicación!')
      await Geolocation.requestPermissions();
    })
  }

  //muestra area de entrega en la app
  mostrarArea(pedidoGet: Pedido) {
    //proceso la url por api segun si esd dispositivo android o web
    if (this.platform.is('cordova')) {
      //proceso la direccion de entrega para cargar vista previa del mapa
      this.myUrl = '';
      this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + pedidoGet.ubicacionDespacho.lat + ',' + pedidoGet.ubicacionDespacho.lng + "&zoom=18";
    } else {
      //proceso la direccion de entrega para cargar vista previa del mapa
      this.myUrl = '';
      this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + pedidoGet.ubicacionDespacho.lat + ',' + pedidoGet.ubicacionDespacho.lng + "&zoom=18";
    }
    //proceso la url de forma segura para mostrar el mapa
    this.procesaUrl();
  }

  //notifica la entrega al cliente
  async pedidoEntregado(pedidoGet2: Pedido) {
    if (this.getdataubicaSuscriber != undefined) {
      this.getdataubicaSuscriber.unsubscribe();
    }
    //Activo el servicio para registrar la ubicacion en la entrega
    await this.getposition2.getRepartidorlocation().then(async () => {
      //Solicito Ubicación Actual del repartidor
      let traeUbicacion = await this.getposition2.getLocaterepartidor2();
      if (await traeUbicacion != null) {
        await this.cerrarVista2();
        await this.presentLoading4();
        //reverifico si el pedido ya fue marcado como entregado
        const path32 = 'usuarios/' + pedidoGet2.uidComercio + '/pedido/' + pedidoGet2.id;
        await this.firestore.getCollection4<Pedido>(path32).then(async pedidoAsing33 => {
          let valor33: any = null;
          valor33 = await pedidoAsing33.data();
          //si el estado del pedido e igual a ENCAMINO se procede a TERMINAR EL PEDIDO
          if (await valor33 != null && await valor33.estado == 'ENCAMINO' && pedidoGet2 != undefined && this.getPedidoasign.estado == 'ENCAMINO' && await valor33.uidRepartidor == this.uidUserLog) {
            //creo la ruta para obtener para actualizar el status del pedido aceptado
            const path6 = 'usuarios/' + pedidoGet2.uidComercio + '/pedido/';
            //Actualizo estado en el pedido del comercio (guarda relacion con Function newReparto, la cual actualizada 
            //el campo ocupado del repartidor a false y el estatus del pedido asignado en el repartidor)
            await this.firestore.updateDoc<Pedido>({ estado: "ENTREGADO", fechaEntrega: new Date }, path6, pedidoGet2.id).then(async () => {
              this.verPedidoSuscriber.unsubscribe();
              this.getPedidoSuscriber.unsubscribe();
              if (this.getPedidosSuscriber != undefined) {
                this.getPedidosSuscriber.unsubscribe();
              }
              this.verIniciaentrega = false;
              this.getPedidoasign = undefined;
              this.getPedidoAsignado = [];
              //se registra la ubicacion en el despacho
              const path2 = 'usuarios/' + pedidoGet2.uidRepartidor + '/registraubicacion/';
              //se utiliza el id de pedido como ide de registro de ubicacion para tener ubicacion de entrega e cada pedido
              const idUbica = pedidoGet2.id
              await this.firestore.createDoc2(traeUbicacion, path2, idUbica);
              //muestro mensaje Exitoso para el Repartidor
              await this.presentToast('Entrega finalizada...');
              setTimeout(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover4 = await this.loadingController2.getTop();
                if (popover4) {
                  this.activaToma = true;
                  await popover4.dismiss(null).then(() => {
                    //recargo el compoenente
                    window.location.reload();
                  });
                } else {
                  this.activaToma = true;
                  //recargo el componente
                  window.location.reload();
                }
              }, 2000);
            }).catch(async () => {
              this.verPed = true;
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover55 = await this.loadingController2.getTop();
              if (popover55) {
                await popover55.dismiss(null);
              }
              //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
              return await this.presentToast1('Ocurrio un problema al notificar la entrega del pedido, intente nuevamente')
            });
          } else if (await valor33.estado == 'ENTREGADO' && await valor33.uidRepartidor == this.uidUserLog) {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover5 = await this.loadingController2.getTop();
            if (popover5) {
              await popover5.dismiss(null).then(() => {
                //recargo el componente
                window.location.reload();
              });
            } else {
              //recargo el componente
              window.location.reload();
            }
            //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
            return await this.presentToast1('La entrega ya fue notificada y finalizada...')
          } else if (await valor33.estado == 'RECHAZADAREPARTIDOR' || await valor33.uidRepartidor == '') {
            if (this.verPedidoSuscriber != undefined) {
              this.verPedidoSuscriber.unsubscribe();
            }
            if (this.getPedidoSuscriber != undefined) {
              this.getPedidoSuscriber.unsubscribe();
            }
            if (this.getPedidosSuscriber != undefined) {
              this.getPedidosSuscriber.unsubscribe();
            }
            this.verIniciaentrega = false;
            this.getPedidoasign = undefined;
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover45 = await this.loadingController2.getTop();
            if (popover45) {
              await popover45.dismiss(null).then(() => {
                //recargo el componente
                window.location.reload();
              });
            } else {
              //recargo el componente
              window.location.reload();
            }
            //muestro mensaje de problemas durante la actualizacion del status en el pedido asignado
            return await this.presentToast1('El comercio cancelo la entrega en proceso, contacte con comercio...')
          }
        })
      } else {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        return await this.presentToast1('No hay acceso a su ubicación, reactive la ubicacion para finalizar el pedido...')
      }
    });
  }



}
