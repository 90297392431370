import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Pedido, preComercio, preRepartidor, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-orden',
  templateUrl: './orden.component.html',
  styleUrls: ['./orden.component.scss'],
})
export class OrdenComponent implements OnInit, OnDestroy {

  //Variable que guarda el numero de Orden Recibido por URL
  ordenId = "";
  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLogg: any = 0;
  //esta variable cambia a false si existe un cliente logueado
  buscarComercio = false;
  //array para guardar los comercios
  comercios: User[] = [];
  //variable que almacena dinamicamente el comercio que se va buscando
  searchedComer: User[] = [];
  //variable que guarda dinamicamente el texto que se introduce en la busqueda del comercio
  textComer: any;
  //en este objeto guardo el pedido asociado al numero de orden
  cargaPedido: Pedido[] = [];
  //declaracion de Observables para aplicar la desuscripcion
  private getPedidoSuscriber: Subscription = undefined;
  private getdataUser: Subscription = undefined;

  //coordenadas del Comercio para pintarla en el mapa
  latComer = 0;
  lngComer = 0;

  //aqui se guardan los datos el repartidor
  getRepartidorasignado = '';
  uidReparcontrol = '';
  telefonoRepar = '';
  urlFotorepar = '';
  urlFotorepar2 = '';

  //creo una variable para un contador
  timerPedido1: string;
  timerReset1: any;

  //guarda usuario logueado
  dataUser: User = undefined;

  //estas variables llaman mis claves api de mapas
  private apikey1 = environment.mapsConfig.apikey1
  private apikey2 = environment.mapsConfig.apikey2
  //esta variable carga la apihey definitiva para mostrar en la orden
  apik: string;

  constructor(private activateOrdenroute: ActivatedRoute,
    private firestore: FirestoreService,
    private router: Router,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private alertController: AlertController,
    private traeUid: UserlogedService,
    private platform: Platform) {

    //aca obtengo el uid del comercio desde la ruta URL
    this.activateOrdenroute.params.subscribe(async params => {
      if (params !== null) {
        this.ordenId = params['id'];
        // se llama la orden segun el uid
        await this.getOrden(this.ordenId);
      } else {
        return;
      }
    });

    //se recibe en el constructor la data del usuario log por medio de Subject
    this.getdataUser = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.dataUser = res;
      } else if (!res) {
        this.dataUser = undefined;

      }
    })

    //proceso la url por api segun si es dispositivo android o web
    if (this.platform.is('cordova')) {
      this.apik = this.apikey2
    } else {
      this.apik = this.apikey1
    }

  }

  ngOnInit() { }

  async ngOnDestroy() {
    //aplico desuscripcion de observables de este componenente al finalizarlo
    if (this.getPedidoSuscriber != undefined) {
      this.getPedidoSuscriber.unsubscribe();
    }
    if (this.getdataUser != undefined) {
      this.getdataUser.unsubscribe();
    }

    //luego de realizar las desuscripciones mando al home, solo si se cambia por inactividad
    // await this.router.navigate(['/home']);
  }

  async getOrden(idPedido: string) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    //trae el pedido del usuario seleccionado
    const path = 'orden/';
    await this.firestore.getDoc2<Pedido>(path, idPedido).then(async dataPedido => {
      let valor3: Pedido = null;
      valor3 = await dataPedido.data();
      //la fecha maxima de vista del pedido es maximo 1 dia atras a partir e las 12pm
      let today = new Date()
      let yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(12, 0, 0, 0);
      //convierto la fecha del pedido capturado para que sea legible en la validación if
      let fechaPed = await dataPedido.data().fechaPedido.toDate();
      if (valor3 != null && fechaPed >= yesterday) {
        const path2 = 'usuarios/' + valor3.uidComercio + '/pedido/';
        this.getPedidoSuscriber = this.firestore.getDoc<Pedido>(path2, valor3.id).subscribe(async res => {
          if (await res) {
            //convertimos la fecha para que sea legible en la impresion html
            res.fechaPedido = res.fechaPedido.toDate();
            res.fechaEntrega = res.fechaEntrega.toDate();
            this.cargaPedido[0] = await res;
            //creo un contador para ver los detalles del status del pedido
            let contador = await this.calculaTiempopedido(this.cargaPedido[0].fechaEntrega);
            //clearInterval(this.timerReset1); (se comento este celarinterval en observacion, se presume que estaba deteniendo el contador)
            if (contador !== 'fin' && contador && (this.cargaPedido[0].estado == 'ENVIADO' || this.cargaPedido[0].estado == 'VISTOPORCOMERCIO' || this.cargaPedido[0].estado == 'ENCAMINO' || this.cargaPedido[0].estado == 'PAGADO' || this.cargaPedido[0].estado == 'ENVIADOREPARTIDOR' || this.cargaPedido[0].estado == 'LISTOPARARETIRAR' || this.cargaPedido[0].estado == 'ACEPTADOREPARTIDOR')) {
              clearInterval(this.timerReset1);
              this.timerReset1 = setInterval(async () => {
                this.timerPedido1 = await this.calculaTiempopedido(this.cargaPedido[0].fechaEntrega);
                //Desactivo setTimeout si timer pedido devuelve la palabra fin 
                if (this.timerPedido1 == 'fin') {
                  clearInterval(this.timerReset1);
                }
              }, 1000);
            } else if (contador == 'fin') {
              this.timerPedido1 = 'fin';
            }
            //se capturan datos del repartidor
            if (this.cargaPedido[0].uidRepartidor != this.uidReparcontrol && this.cargaPedido[0].delivery) {
              this.uidReparcontrol = this.cargaPedido[0].uidRepartidor;
              //se capturan lo datos del repartidor
              const path5 = 'prerepartidor/' + this.cargaPedido[0].uidRepartidor;
              await this.firestore.getCollection4<preRepartidor>(path5).then(async repa => {
                let valor3: preRepartidor;
                valor3 = await repa.data();
                this.getRepartidorasignado = valor3.nombre + ' ' + valor3.apellido + ', ' + valor3.telefono;
                this.telefonoRepar = valor3.telefono;
                this.urlFotorepar = valor3.urlfoto;
              });
            }
            //se captura la ubicacion del comercio
            if (this.latComer == 0 && this.cargaPedido[0].delivery) {
              const path6 = 'precomercio/' + this.cargaPedido[0].uidComercio;
              await this.firestore.getCollection4<preComercio>(path6).then(async comer => {
                let valor4: preComercio;
                valor4 = await comer.data();
                //guargo los valores de ubicacion del comercio
                this.latComer = valor4.ubicacionpri.lat;
                this.lngComer = valor4.ubicacionpri.lng;
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover = await this.loadingController.getTop();
                if (popover) {
                  await popover.dismiss(null);
                }
              });
            } else {
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading() (en pruebas)
              const popover1 = await this.loadingController.getTop();
              if (popover1) {
                await popover1.dismiss(null);
              }
            }

          }
        })
      } else if (valor3.estado == 'ENTREGADO' && valor3.fechaPedido < yesterday) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
        //muestro mensaje para orden vencida para visualización
        return await this.presentToast('La orden ya no se encuentra disponible para visualización...');
      } else if (valor3 == null || valor3 == undefined) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
        const popover3 = await this.loadingController.getTop();
        if (popover3) {
          await popover3.dismiss(null);
        }
        //muestro mensaje cuando el id de pedido no esxiste en la consulta
        return await this.presentToast('El pedido no existe');
      }
    }).catch(async () => {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
      const popover6 = await this.loadingController.getTop();
      if (popover6) {
        await popover6.dismiss(null);
      }
      //muestro mensaje de problemas en el guardado
      return await this.presentToast('El pedido no existe');
    });
  }


  async calculaTiempopedido(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds 
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);

    if (minutes >= 0) {
      if (minutes == 0 && hours == 0) {
        let res: string;
        res = seconds + ' seg.';
        return res;
      }
      if (minutes > 0 && minutes < 20 && hours == 0) {
        let res: string;
        res = minutes + ' min y ' + seconds + ' seg.';
        return res;
      }
      if (minutes >= 20 || hours > 0) {
        let res: string;
        res = 'fin';
        return res;
      }
    }
  }

  //inicar llamada telefonica
  startCallphone(phone: string) {
    window.open("tel:" + phone);
  }

  //inicar conversación por whatssap
  startWhass(phone2: string, nombrecli: string, idPed: string) {
    let link1 = 'https://wa.me/' + phone2 + '?text=Ref:%20' + idPed + ', Hola%20mi%20nombre%20es%20' + nombrecli.trim() + ',' + ' deseo%20saber%20información%20de%20mi%20Pedido'
    window.open(link1, '_blank');
  }

  async renotificarPedido(pedidoNoti: Pedido) {
    const alert2 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Notificar pedido vía WhatsApp',
      backdropDismiss: false,
      message: "" + pedidoNoti.cliente.nombre.trim() + ", utiliza esta opción si aún no haz notificado el pedido al comercio via WhatsApp",
      buttons: [
        {
          text: 'Volver',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'Notificar',
          id: 'confirm-button',
          handler: () => {
            if (pedidoNoti.delivery && pedidoNoti.zonadelivery.idzona != 'id123') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidoNoti.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidoNoti.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidoNoti.id + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidoNoti.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20' + '*USD' + pedidoNoti.zonadelivery.precio.toFixed(2) + '*' + '%20por%20Servicio%20Delivery%20¿me%20indica%20por%20favor%20las%20formas%20de%20pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
            } else if (pedidoNoti.delivery && pedidoNoti.zonadelivery.idzona == 'id123') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidoNoti.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidoNoti.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidoNoti.id + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidoNoti.precioTotal.toFixed(2) + '*' + '%20de%20Pedido%20y%20deseo%20cotizar%20disponibilidad%20de%20Servicio%20Delivery,%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
            } else if (!pedidoNoti.delivery && (pedidoNoti.detallePedido.trim()).substr(-8) != 'COMERCIO') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidoNoti.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidoNoti.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidoNoti.id + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidoNoti.precioTotal.toFixed(2) + '*' + '%20¿me%20indica%20por%20favor%20las%20formas%20de%20pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
            } else if (!pedidoNoti.delivery && (pedidoNoti.detallePedido.trim()).substr(-8) == 'COMERCIO') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidoNoti.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidoNoti.cliente.nombre.trim() + ',%20*me%20encuentro%20en%20el%20comercio*%20y%20requiero%20concretar%20la%20orden%20https://entregamovil.com/orden/' + pedidoNoti.id + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidoNoti.precioTotal.toFixed(2) + '*' + '%20¿me%20indica%20por%20favor%20el%20paso%20a%20seguir?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
            }
          }
        }
      ]
    });
    await alert2.present();
  }


  /*
  cierraModal(){
    this.urlFotorepar2='';
  }
  
  iniciamodal(urlfoto:string){
    this.urlFotorepar2=urlfoto;
   }
  */

  // función para calificar repartidor
  async calificaRepartidor(pedidoCal: Pedido) {
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Calificación de Repartidor',
      backdropDismiss: false,
      message: "¿" + this.dataUser.nombre + ", Como Califica al Servicio prestado por el <b>Repartidor (Delivery)</b> de su Pedido?",
      inputs: [
        {
          name: 'radio5',
          type: 'radio',
          label: '★★★★★',
          value: 100,
          checked: false
        },
        {
          name: 'radio4',
          type: 'radio',
          label: '★★★★',
          value: 98,
          checked: false
        },
        {
          name: 'radio3',
          type: 'radio',
          label: '★★★',
          value: 96,
          checked: false
        },
        {
          name: 'radio2',
          type: 'radio',
          label: '★★',
          value: 94,
          checked: false
        },
        {
          name: 'radio1',
          type: 'radio',
          label: '★',
          value: 92,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {
            //console.log('Confirm Cancel:'+blah);
          }
        }, {
          text: 'CALIFICAR',
          id: 'confirm-button',
          handler: async (data: number) => {
            if (data != null) {
              //muestro mensaje de calificación en pantalla
              await this.presentLoading1();
              //creo la ruta para actualizar la calificacion
              const path1 = 'usuarios/' + pedidoCal.uidComercio + '/pedido/';
              //Actualizo la opcion del delivery a false 
              await this.firestore.updateDoc<Pedido>({ valoracionRepar: data }, path1, pedidoCal.id).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover = await this.loadingController.getTop();
                if (popover) {
                  await popover.dismiss(null);
                }
                return await this.presentToast2('¡Gracias por Calificar al Repartidor!')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover1 = await this.loadingController.getTop();
                if (popover1) {
                  await popover1.dismiss(null);
                }
                //si hay un error al actualizar la calificación del delivery
                return await this.presentToast('Ocurrio un problema de conexión durante la calificación, Intente Nuevamente ó comuniquese con soporte de App')
              });
            } else if (data == null) {
              return await this.presentToast('Es necesario seleccionar una opción para calificar')
            }
          }
        }
      ]
    });
    await alert.present();
  }

  // función para calificar el Comercio
  async calificaComercio(pedidoCal2: Pedido) {
    const alert1 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Calificación de Comercio',
      backdropDismiss: false,
      message: "¿" + this.dataUser.nombre + ", Como Califica al Servicio prestado por el <b>Comercio</b> para este Pedido?",
      inputs: [
        {
          name: 'radio55',
          type: 'radio',
          label: '★★★★★',
          value: 100,
          checked: false
        },
        {
          name: 'radio44',
          type: 'radio',
          label: '★★★★',
          value: 98,
          checked: false
        },
        {
          name: 'radio33',
          type: 'radio',
          label: '★★★',
          value: 96,
          checked: false
        },
        {
          name: 'radio22',
          type: 'radio',
          label: '★★',
          value: 94,
          checked: false
        },
        {
          name: 'radio11',
          type: 'radio',
          label: '★',
          value: 92,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'CALIFICAR',
          id: 'confirm-button',
          handler: async (data1: number) => {
            if (data1 != null) {
              //muestro mensaje de calificación en pantalla
              await this.presentLoading1();
              //creo la ruta para actualizar la calificacion al comercio
              const path1 = 'usuarios/' + pedidoCal2.uidComercio + '/pedido/';
              //Actualizo la opcion del delivery a false 
              await this.firestore.updateDoc<Pedido>({ valoracionComer: data1 }, path1, pedidoCal2.id).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover2 = await this.loadingController.getTop();
                if (popover2) {
                  await popover2.dismiss(null);
                }
                return await this.presentToast2('¡Gracias por Calificar al Comercio!')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover3 = await this.loadingController.getTop();
                if (popover3) {
                  await popover3.dismiss(null);
                }
                //si hay un error al actualizar la calificación del delivery
                return await this.presentToast('Ocurrio un problema de conexión durante la calificación, Intente Nuevamente ó comuniquese con soporte de App')
              });
            } else if (data1 == null) {
              return await this.presentToast('Es necesario seleccionar una opción para calificar')
            }

          }
        }
      ]
    });
    await alert1.present();
  }


  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Visualizando Orden, por favor espere...',
      duration: 10000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading1() {
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando Calificación...',
      duration: 10000
    });
    //presenta mensaje
    await loading.present();
  }



  //configuracion de la funcion que muestra cuando la orden ya no esta disponible
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 2500,
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast2(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 3000,
      color: 'success',
      position: 'middle'
    });
    toast2.present();
  }

}



