import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FormaPago } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-formapago',
  templateUrl: './formapago.component.html',
  styleUrls: ['./formapago.component.scss'],
})
export class FormapagoComponent implements OnInit, OnDestroy {

  //Declaro el Objeto de la interfaz de la categoria de Menus
  formaPago: FormaPago = {
    disponible: false,
    formapago: "",
    idformapago: "",
  }

  //creo un array para cargar las formas de pago
  formaspago: FormaPago[] = [];
  formaspago2: FormaPago[] = [];

  //creo objeto de susucripcion observables a categorias
  private getformaspagoSuscriber: Subscription = undefined;
  private getdataSuscriber: Subscription = undefined;

  //creo una variable que me va a almacenar el uid del usuario logeado
  private uidUserLog = "";

  constructor(private firestore: FirestoreService,
    private modalController: ModalController,
    private traeUid: UserlogedService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private toastController: ToastController) {

    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.getdataSuscriber = this.traeUid.getDatauser().subscribe(async res => {
      if (res) {
        this.uidUserLog = res.uid;
        //se cargan las categorias registradas
        await this.getFormaspago(this.uidUserLog);
      }
    })
  }



  ngOnInit(): void {
  }

  //agrego desuscripción a observables
  ngOnDestroy() {
    if (this.getformaspagoSuscriber != undefined) {
      this.getformaspagoSuscriber.unsubscribe();
    }
    if (this.getdataSuscriber != undefined) {
      this.getdataSuscriber.unsubscribe();
    }

  }

  async registrarFormapago() {

    //si se consigue laguna coincidencia con la categoria que se esta registrando, se dara una alerta de registro
    if (this.formaspago2.length && this.uidUserLog != '') {
      const alert = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: "Se encontrarón coincidencias de formas de pago registradas con la que intenta registrar: <b>" + this.formaPago.formapago.toUpperCase()
          + "</b> ¿Confirma que la forma de pago que desea <b>REGISTRAR</b> es Correcta? <b>Nota: Trate de registrar formas de pagos independientes, una vez registradas, usted no las podrá eliminar</b> ",
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              //configuracion de la funcion que muestra mensaje de espera
              await this.presentLoading()
              this.formaPago.idformapago = this.firestore.getId();
              this.formaPago.formapago = (this.formaPago.formapago.toUpperCase()).trim();
              //realizo una ultima consulta de información para ver si el nombr de forma de pago es exactamente igual
              for await (let formaspago of this.formaspago) {
                if (this.formaspago.length && formaspago.formapago == this.formaPago.formapago.trim()) {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover7 = await this.loadingController.getTop();
                  if (popover7) {
                    await popover7.dismiss(null);
                  }
                  //si se consigue alguna coincidencia se termina el ciclo
                  return await this.presentToast2('¡No se pudo registrar, el Nombre de Forma de Pago ya se encuentra Registrado!')
                }
              }
              const data = this.formaPago;
              const path = 'usuarios/' + this.uidUserLog + '/formapago/';
              await this.firestore.createDoc2(data, path, data.idformapago).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover = await this.loadingController.getTop();
                if (popover) {
                  await popover.dismiss(null);
                }
                //muestro mensaje de actuslización con exito
                return await this.presentToast1('¡Registro de Forma de Pago Exitoso!')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover2 = await this.loadingController.getTop();
                if (popover2) {
                  await popover2.dismiss(null);
                }
                //si hay un error al registrar la categoria
                return await this.presentToast2('No fue posible Registrar la Forma de Pago, Intente Nuevamente ó comuniquese con soporte de App')
              });;
              //se cierra el popup
              await this.modalController.dismiss();
            }
          }
        ]
      });
      await alert.present();
    } else if (!this.formaspago2.length && this.uidUserLog != '') {
      //configuracion de la funcion que muestra mensaje de espera
      await this.presentLoading()
      this.formaPago.idformapago = this.firestore.getId();
      this.formaPago.formapago = (this.formaPago.formapago.toUpperCase()).trim();
      //realizo una ultima consulta de información para ver si el nombr de Categoria es exactamente igual
      for await (let formaspago1 of this.formaspago) {
        if (this.formaspago.length && formaspago1.formapago == this.formaPago.formapago.trim()) {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover6 = await this.loadingController.getTop();
          if (popover6) {
            await popover6.dismiss(null);
          }
          //si se consigue alguna coincidencia se termina el ciclo
          return await this.presentToast2('¡No se pudo registrar, el Nombre de Forma de Pago ya se encuentra Registrado!')
        }
      }
      const data = this.formaPago;
      const path = 'usuarios/' + this.uidUserLog + '/formapago/';
      await this.firestore.createDoc2(data, path, data.idformapago).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
        //muestro mensaje de actuslización con exito
        return await this.presentToast1('¡Registro de Forma de Pago Exitoso!')
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover3 = await this.loadingController.getTop();
        if (popover3) {
          await popover3.dismiss(null);
        }
        //si hay un error al registrar la categoria
        return await this.presentToast2('No fue posible Registrar la Forma de Pago, Intente Nuevamente ó comuniquese con soporte de App')
      });;
      //se cierra el popup
      await this.modalController.dismiss();
    }
  }


  async getFormaspago(uidComer: string) {
    const pathc = 'usuarios/' + uidComer + '/formapago';
    this.getformaspagoSuscriber = this.firestore.getCollection<FormaPago>(pathc).subscribe(async formaspagoo => {
      this.formaspago = [];
      if (await formaspagoo.length) {
        formaspagoo.forEach((doc: FormaPago) => {
          this.formaspago.push(doc)
        })
        //le aplico reduccion de duplicados
        this.formaspago = Array.from(this.formaspago.reduce((map, obj) => map.set(obj.formapago, obj), new Map()).values());
        this.formaspago2 = this.formaspago;
        //Utilizo funcion predefinida de (a,b) para Ordenar el array de formas de pago por nombre
        this.formaspago2.sort(function (a, b) {
          if (a.formapago < b.formapago) {
            return -1;
          } else if (a.formapago > b.formapago)
            return 1;
          else
            return 0;
        });

        //Utilizo funcion predefinida de (a,b) para Ordenar el array de formas de pago por disponibilidad
        this.formaspago2.sort(function (a, b) {
          if (b.disponible < a.disponible) {
            return -1;
          } else if (b.disponible > a.disponible)
            return 1;
          else
            return 0;
        });

      } else if (!formaspagoo.length) {
        //Declaro el Objeto de la interfaz de la categoria de Menus para crear la primera forma de pago
        let formaPago: FormaPago = {
          disponible: false,
          formapago: 'EFECTIVO',
          idformapago: this.firestore.getId(),
        }
        const path = 'usuarios/' + uidComer + '/formapago/';
        return await this.firestore.createDoc2(formaPago, path, formaPago.idformapago);
      }
    })
  }

  //creo funcion para filtrar la categroia segun lo que voy escribiendo
  async searchCategoria(event) {
    if (event.target.value != '' && event.target.value.trim() != "") {
      this.formaspago2 = this.formaspago.filter((formas: FormaPago) => {
        return ((formas.formapago.toLowerCase().indexOf(event.target.value.toLowerCase().trim()) > -1));
      });
    } else {
      this.formaspago2 = this.formaspago;
    }
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    let loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Registrando Forma de Pago...',
      duration: 10000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading1() {
    //crea mensaje
    let loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      duration: 10000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast1(mensaje: any) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 3000,
      color: 'success',
      position: 'middle'
    });
    toast2.present();
  }

  //configuracion de la funcion que muestra erroe ne pantalla
  async presentToast2(mensaje: any) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'danger',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast1.present();
  }

  //Función para Activar o desactivar la forma de pago
  async activaFormapago(formaactivar: FormaPago) {
    //si el producto no esta disponible  
    if (formaactivar.disponible == false) {
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>HABILITAR</strong> la forma de pago: <b>' + formaactivar.formapago + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              //configuracion de la funcion que muestra mensaje de espera
              await this.presentLoading1()
              const path2 = 'usuarios/' + this.uidUserLog + '/formapago/';
              //Actualizo habilitar a true en formas de pago
              await this.firestore.updateDoc<FormaPago>({ disponible: true }, path2, formaactivar.idformapago).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover8 = await this.loadingController.getTop();
                if (popover8) {
                  await popover8.dismiss(null);
                }
                //muestro mensaje de habilitacion con exito
                return await this.presentToast1('<b>Habilitación</b> de Forma de Pago Exitosa...')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover9 = await this.loadingController.getTop();
                if (popover9) {
                  await popover9.dismiss(null);
                }
                formaactivar.disponible = false;
                return await this.presentToast1('Ocurrio un problema de conexion al Habilitar la forma de Pago');
              });
            }
          }
        ]
      });
      await alert1.present();
    } else if (formaactivar.disponible == true) {
      const alert2 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>DESHABILITAR</strong> la forma de pago: <b>' + formaactivar.formapago + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              //configuracion de la funcion que muestra mensaje de espera
              await this.presentLoading1()
              const path2 = 'usuarios/' + this.uidUserLog + '/formapago/';
              //Actualizo deshabilitar a true en formas de pago
              await this.firestore.updateDoc<FormaPago>({ disponible: false }, path2, formaactivar.idformapago).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover10 = await this.loadingController.getTop();
                if (popover10) {
                  await popover10.dismiss(null);
                }
                //muestro mensaje de habilitacion con exito
                return await this.presentToast1('<b>Deshabilitación</b> de Forma de Pago Exitosa...')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover11 = await this.loadingController.getTop();
                if (popover11) {
                  await popover11.dismiss(null);
                }
                formaactivar.disponible = true;
                return await this.presentToast1('Ocurrio un problema de conexion al Deshabilitar la forma de Pago');
              });
            }
          }
        ]
      });
      await alert2.present();
    }
  }




}

