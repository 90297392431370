import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import { User } from '../models/users.model';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class UserlogedService {
 
  usuarioLog$ = new BehaviorSubject<any>(null);
  usuariodataLog$ = new BehaviorSubject<User>(null);
  usuarioLog:any;

  constructor(private authService:AuthService,
              private firestore:FirestoreService) { 
                this.authService.getUserLogged().subscribe(async res=>{
                  this.usuarioLog=res;
                  this.usuarioLog$.next(this.usuarioLog);
                  //Obtengo data del Usuario Logueado
                  const pathUser='usuarios/';
                  if(res){
                    this.firestore.getDoc<User>(pathUser,res.uid).subscribe(res2=>{
                      this.usuariodataLog$.next(res2)
                    })
                  }else{
                    this.usuariodataLog$.next(null)
                  }
                })
              }

 getDatauser(): Observable<User>{
  return this.usuariodataLog$.asObservable();
 }

 
 getUserlog(): Observable<any>{
  return this.usuarioLog$.asObservable();
 }


}
