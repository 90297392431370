import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { User } from '../models/users.model';
import { AuthService } from '../services/auth.service';
import { FirestoreService } from '../services/firestore.service';
import { GooglemapsService } from '../services/googlemaps.service';
import { EnviaubicacionService } from '../services/enviaubicacion.service';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';



@Component({
  selector: 'app-googlemaps',
  templateUrl: './googlemaps.component.html',
  styleUrls: ['./googlemaps.component.scss'],
})


export class GooglemapsComponent implements OnInit {

  position = {
    lat: 5.90,
    lng: -65.28,
  };

  //position que toma al cancelar mapa
  position2 = {
    lat: 5.90,
    lng: -65.28,
  };

  position3 = {
    lat: 5.90,
    lng: -65.28,
  };

  //Inicio con un tamaño zoom de 6 para la vista pais
  zoomMap = 6;

  label = {
    titulo: 'Esta es su ubicación de perfil como usuario',
    subtitulo: 'Presione <b>"Recalcular mi Ubicacion"</b> si desea recalcular su Posición, <b>ó</b> toque su ubicación en el mapa, cuando esté conforme presione <b>"Fijar Ubicación"</b>'
  }

  label1 = {
    titulo: 'Mapa Interactivo',
    subtitulo: 'presione <b>"Obtener mi Ubicacion"</b> para capturar su Posición Actual <b>ó</b> toque su ubicación en el mapa, cuando esté conforme presione <b>"Fijar Ubicación"</b>'
  }

  label2 = {
    titulo: 'En esta área se encuentra su localidad',
    subtitulo: 'Presione <b>"Obtener mi Ubicacion"</b> para capturar su Posición Actual, <b>ó</b> toque la ubicación para despacho en el mapa, cuando esté conforme presione <b>"Fijar Ubicación"</b>'
  }


  map: any;
  marker: any;
  infowindow: any;
  positionSet: any

  //@ViewChild hace referencia a donde se va a cargar el mapa en el documento html, cuyo identificador se llame map
  @ViewChild('map') divMap!: ElementRef;

  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLog = "";

  //Declaro una variable para el mensaje de espera en pantalla, relacionada a la funcion presentLoading()
  loading: any;

  //activa y desactiva boton obtener ubicación del medio
  activadorUbica = false;

  //variable que guarda informacion relacionada a la ubicacion capturada
  private infoDirec = '';

  //variable que habilita la vista del boton fijar
  verFijar = true;

  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Inject,
    private googlemapsService: GooglemapsService,
    private authService: AuthService,
    private firestore: FirestoreService,
    private enviaUbicacion: EnviaubicacionService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public toastController: ToastController,
  ) { }


  ngOnInit() {
    this.init();
  }


  async init() {
    this.googlemapsService.init(this.renderer, this.document).then(() => {
      /*llamo a la funcion tipo observable del usuario logeado(si lo hay) y me suscribo para obtener el uid y
      guardo el uid solo si existe un usuario logeado*/
      this.authService.getUserLogged().subscribe(async res => {
        if (res !== null) {
          this.uidUserLog = res.uid;
          //obtengo el usuario logueado
          const path = 'usuarios';
          const id = this.uidUserLog;
          await this.firestore.getDoc2<User>(path, id).then(async res => {
            this.position = await res.data().ubicacionpri;
            this.initMap();
          });
        } else {
          this.enviaUbicacion.pasaUbicacion2.subscribe(data => {
            if (data) {
              //paso a false para ocutar el boton fijar para que sea el usuario quien seleccione un nuevo lugar
              this.verFijar = false;
              if (data.dat.lat == 5.90) {
                this.position = data.dat;
                this.initMap();
              } else {
                this.position = data.dat;
                this.initMap1();
              }
            } else if (!data) {
              this.initMap();
            }
          })
        }
      });
    }).catch(() => {

    });
  }


  initMap(): void {

    const position = this.position;

    let latLng = new google.maps.LatLng(position.lat, position.lng);

    let mapOptions = {
      center: latLng,
      zoom: this.zoomMap,
      disableDefaultUI: true,
      clickableIcons: false,
    }

    this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);

    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.BOUNCE,
      draggable: true,
    });

    this.clickHandleEvent();
    this.infowindow = new google.maps.InfoWindow();

    this.addMarker(position);

    //cuando carga el mapa inicial muestra mensajes diferente

    if (position.lat == 5.90) {
      this.setInfoWindow(this.marker, this.label1.titulo, this.label1.subtitulo);
    } else if (position.lat != 5.90) {
      this.setInfoWindow(this.marker, this.label.titulo, this.label.subtitulo);
    }

  }


  initMap1(): void {
    const position = this.position;
    let latLng = new google.maps.LatLng(position.lat, position.lng);

    let mapOptions = {
      center: latLng,
      zoom: 14,
      disableDefaultUI: true,
      clickableIcons: false,
    }

    this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);

    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      visible: false
    });

    this.clickHandleEvent();
    this.infowindow = new google.maps.InfoWindow();

    this.addMarker(position);

    //cuando carga el mapa inicial muestra mensajes diferente
    if (position.lat == 5.90) {
      this.setInfoWindow(this.marker, this.label1.titulo, this.label1.subtitulo);
    } else if (position.lat != 5.90) {
      //this.setInfoWindow(this.marker, this.label2.titulo, this.label2.subtitulo);
    }
  }



  async clickHandleEvent() {
    await this.map.addListener('click', async (event: any) => {
      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      //hace el marker visible
      if (!this.marker.visible) {
        this.marker = new google.maps.Marker({
          map: this.map,
          draggable: true,
          animation: google.maps.Animation.BOUNCE,
          visible: true
        });
      }
      //cambio a true verfijar en cualquier momento que se toca la pantalla
      if (!this.verFijar) {
        this.verFijar = true;
      }
      //esta variable desactiva el boton de obtener ubicacion del centro del mapa
      this.activadorUbica = true;
      //se cierra el 2do mensaje ya que resulta una mejor experiencia a la vista del mapa
      this.infowindow.close();
      await this.addMarker(position);
    });
  }

  async addMarker(position: any) {
    let latLng = new google.maps.LatLng(position.lat, position.lng);

    //Solo cuando hay un cambio de ubicacion  predeterminada se actualiza el zoom
    if (position.lat !== 5.90) {
      this.zoomMap = 16;
    }
    await this.marker.setPosition(latLng);
    //aqui se actualiza el zoom solo cuando el zoom prdeterminado sea 6
    if (this.map.zoom === 6) {
      this.map.zoom = this.zoomMap;
    }
    await this.map.panTo(position);
    this.positionSet = await position;

  }


  //esta parte esta relacionada a la evaluacion de la api que devuelve información del lugar 03-10
  private async getDatamap(positionget: any) {
    const geocoder = new google.maps.Geocoder();
    return await geocoder
      .geocode({ location: positionget })
      .then((response) => {
        if (response.results[0]) {
          //evaluo si esta informacion me trae un signo +
          if (String((response.results[0].address_components[0].long_name)).includes('+')) {
            if (String((response.results[0].address_components[1].long_name)).includes('+')) {
              this.infoDirec = response.results[0].address_components[2].long_name + ', ' + response.results[0].address_components[3].long_name;
            } else if (!String((response.results[0].address_components[1].long_name)).includes('+')) {
              this.infoDirec = response.results[0].address_components[1].long_name + ', ' + response.results[0].address_components[2].long_name + ', ' + response.results[0].address_components[3].long_name;
            }
          } else if (!String((response.results[0].address_components[0].long_name)).includes('+')) {
            this.infoDirec = response.results[0].address_components[0].long_name + ', ' + response.results[0].address_components[1].long_name + ', ' + response.results[0].address_components[2].long_name + ', ' + response.results[0].address_components[3].long_name;
          }
        } else {
          this.infoDirec = ''
        }
      })
  }


  //funcion que pinta el mensaje del mapa en pantalla 
  setInfoWindow(marker: any, titulo: string, subtitulo: string) {
    const contentString = '<div id="contentInsideMap">' +
      '<div>' +
      '</div>' +
      '<p style="font-weight: bold; margin-bottom: 5px;">' +
      '<a style="color:red">LEA CUIDADOSAMENTE: </a>' + titulo +
      '<div id="bodyContent">' +
      '<p class"normal m-0">'
      + subtitulo + '</p>' +
      '</div>' +
      '</div>';
    this.infowindow.setContent(contentString);
    this.infowindow.open(this.map, marker);
  }


  async mylocation() {
    //esta variable desactiva el boton de obtener ubicacion del centro del mapa
    this.activadorUbica = true;
    //verifico los permisos de acceso al gps
    await Geolocation.getCurrentPosition().then(async res => {
      if (res) {
        //activo el boton de verfijar si esta dsactivado
        if (!this.verFijar) {
          this.verFijar = true;
        }
        //hace el marker visible
        if (!this.marker.visible) {
          this.marker = new google.maps.Marker({
            map: this.map,
            draggable: true,
            animation: google.maps.Animation.BOUNCE,
            visible: true
          });
        }
        //llamo la funcion de mensaje en pantalla
        await this.presentLoading();
        this.position.lat = res.coords.latitude,
          this.position.lng = res.coords.longitude,
          await this.addMarker(this.position);
        //cuando se obtiene ubicacion actual, se cambia el mensaje del mapa
        //this.setInfoWindow(this.marker, this.label.titulo, this.label.subtitulo);
        //se cierra el 2do mensaje ya que resulta una mejor experiencia a la vista del mapa
        this.infowindow.close();
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover = await this.loadingController.getTop();
        if (popover) {
          await popover.dismiss(null);
        }
      } else if (!res) {
        //se cierra el 2do mensaje ya que resulta una mejor experiencia a la vista del mapa
        await this.infowindow.close();
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }
        //muestro mensaje de problemas en el guardado
        await this.presentToast('¡Active el GPS de su dispositivo ó toque su ubicación en el Mapa!')
        await Geolocation.requestPermissions();
      }
    }).catch(async () => {
      if (this.infowindow != undefined) {
        //se cierra el 2do mensaje ya que resulta una mejor experiencia a la vista del mapa
        await this.infowindow.close();
      }
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover2 = await this.loadingController.getTop();
      if (popover2) {
        await popover2.dismiss(null);
      }
      //muestro mensaje de problemas en el guardado
      await this.presentToast('¡Active el GPS de su dispositivo ó toque su ubicación en el Mapa!')
      await Geolocation.requestPermissions();
    })
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 3000,
      color: 'danger',
      position: 'middle'
    });
    await toast.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    let loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Obteniendo Ubicación...',
      duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading1() {
    //crea mensaje
    let loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Enviando Ubicación...',
      //duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }


  async aceptar() {
    await this.presentLoading1();
    //aqui se llama la funcion(desactivada actualmente para ahorrar costos) para mostrar direccion de la ubicacion capturada (costo actual por cada 1000vistas 5usd)
    //await this.getDatamap(this.positionSet);
    this.enviaUbicacion.pasaUbicacion.emit({
      data: this.positionSet,
      data11: this.infoDirec
    })
    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
    const popover12 = await this.loadingController.getTop();
    if (popover12) {
      await popover12.dismiss(null);
    }
    //se cierra el popup
    this.modalController.dismiss();
  }

  cancelar() {
    //console.log("clic aceptar=>", this.positionSet);

    this.enviaUbicacion.pasaUbicacion.emit({
      data: this.position2
    })
    //se cierra el popup
    this.modalController.dismiss();
  }

}