import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Importaciones relacionadas a Firebase
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';


//importacion automatica desplegada cuando se inició el proyecto con ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

//componentes
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//importaciones creadas al inicio del proyecto despelago con ionic
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

//importaciones de agrupacion de componentes por carpetas
import { PagesModule } from './pages/pages.module';
import { ComercioModule } from './comercio/comercio.module';

//se importa la libreria firebase para subir fotos al storage
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

//Agregamos el modulo de GoogleMaps para cargar los mapas
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglemapsComponent } from './googlemaps/googlemaps.component';
//Date Import (para covertir la informacion de fechas a español)
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-VE';
registerLocaleData(localeEs, 'es');

//GooglePlus
import { GooglePlus } from '@ionic-native/google-plus/ngx';

/* deshabilitado en 01-08-24
//recpachat version 9 compatible con angular version 13
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha"


//estas 2 importaciones estan relacionadas con AppCheck Recapcha
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
*/

//Web Intent
import { WebIntent } from '@ionic-native/web-intent/ngx';


@NgModule({
  declarations: [AppComponent, GooglemapsComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    PagesModule,
    ComercioModule,
    AppRoutingModule,
    GoogleMapsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:1000'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig), // se habilita esta inicializacion 01-08-24, ya que se deshabilito la asociada a recapchatv3

    /*se inhabilita la verificacion recapchaV3 al 01-08 por problemas con app android
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAppCheck(() => initializeAppCheck(getApp(),
      {
        provider: new ReCaptchaV3Provider(environment.sitekeyv3),
        isTokenAutoRefreshEnabled: true
      })),
  */
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    //RecaptchaModule,  deshabilitado en 01-08-24
    //RecaptchaV3Module,  deshabilitado en 01-08-24
  ],
  providers: [
    GooglePlus,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    /*
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.sitekeyv3,
    },
    */
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    WebIntent
  ],
  bootstrap: [AppComponent],
  exports: [GooglemapsComponent]
})
export class AppModule { }