import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { preCliente, preComercio, preRepartidor, User } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import { FirestorageService } from 'src/app/services/firestorage.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { debounceTime } from 'rxjs/operators'; //importo para agregar un tiempo de espera en las busquedas asincronas relacionado a FormControl (en pruebas)
import { EnviaubicacionService } from 'src/app/services/enviaubicacion.service';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { GooglemapsComponent } from 'src/app/googlemaps/googlemaps.component';
import { UserlogedService } from 'src/app/services/userloged.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit, OnDestroy {

  //creo un array de perfiles de usuarios y locales para el caso de Restaurant
  perfilesUser: string[] = ["CLIENTE", "PROVEEDOR", "REPARTIDOR"];
  tipolocalesUser: any[] = [];
  //se crea la variable que guardará el evento de la captura de foto de perfil
  newFile = "";
  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLog = "";
  //inizializo el objeto de usuarios con datos iniciales en el registro
  datos: User = {
    nombre: "",
    apellido: "",
    tipolocal: "",
    token: "",
    nombrelocal: "",
    uid: "",
    email: "",
    password: "",
    telefono: "",
    direccionlocal: "",
    plan: "",
    ubicacionpri: { lat: 5.90, lng: -65.28 },
    ubicacionsec: { lat: 1, lng: 1 },
    perfil: "",
    urlfoto: "",
    fechaRegistro: new Date,
    fechaActRegistro: new Date,
    estado: false,
    estatus: 'ACTIVO',
    ocupado: false,
    ubicacion: "",
    municipio: "",
    valoracion: 100,
    deliveryOn: false
  }

  //declaracion relacionado al FormBuilder 
  declare form: FormGroup;

  //declaro la expresin regular para numero de telefono venezuela
  telefonoPattern: any = /\+584([0-9]){9}$/;

  //declaro variable que cuenta bucles de error
  cuentaEvent = 0;

  //variable relacionada al captcha
  captcha2 = '';

  //declaro arrays que guardaran los estados y municipios
  colecEstados: any[] = [];
  colecMunic: any[] = [];
  private selectEstado = '';

  //suscripción a observables de datos
  private dataSuscri: Subscription = undefined;
  private dataPerfiles: Subscription = undefined;

  //esta varibale se crea para verificar durante el registro que el telefono no se encuentre registrado
  private txtTelefono = '';

  //activador de vista de mapa
  verMapa = false;

  //variable para contar las llamadas al mapa, si se llama mas de 3 veces se reinicia el registro
  private cuentaMapa = 0;


  constructor(private auth: AuthService,
    private firestore: FirestoreService,
    private firestorageService: FirestorageService,
    private router: Router,
    private enviaUbicacion: EnviaubicacionService,
    private formBuilder: FormBuilder,
    private modalControler: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private traeUid: UserlogedService,
    private _renderer: Renderer2) {

    //se recibe en el constructor el uid por medio de Subject
    this.dataSuscri = this.traeUid.getUserlog().subscribe(res => {
      if (res) {
        this.uidUserLog = res.uid;
      }
    })


    this.buildForm();
  }



  ngOnInit() {
    console.log("valor ress ", this.uidUserLog )
    this.arrancar();
  }

  ngOnDestroy() {
    if (this.dataSuscri != undefined) {
      this.dataSuscri.unsubscribe()
    }
    if (this.dataPerfiles != undefined) {
      this.dataPerfiles.unsubscribe()
    }
  }

  //metodo relacionado al FormGroup 
  private buildForm() {
    this.form = this.formBuilder.group({
      emailControl: [this.datos.email, [Validators.required, Validators.email]],
      referenciaControl: [this.datos.direccionlocal, [Validators.required]],
      perfilControl: [this.datos.perfil, [Validators.required]],
      tipolocalControl: [this.datos.tipolocal, []],
      nombrelocalControl: [this.datos.nombrelocal, []],
      nombreControl: [this.datos.nombre, [Validators.required]],
      apellidoControl: [this.datos.apellido, [Validators.required]],
      passwordControl: [this.datos.password, [Validators.required]],
      passwordControl1: ['', [Validators.required]],
      telefonoControl: [this.datos.telefono, [Validators.required, Validators.pattern(this.telefonoPattern)]],
      estadoControl: [this.datos.ubicacion, [Validators.required]],
      municipioControl: [this.datos.municipio, [Validators.required]],
      privacidadControl: ['', [Validators.required]],
    });
    this.form.valueChanges.pipe(
      //uso del debounceTime para los ciclos de espera
      debounceTime(500)
    ).subscribe((): void => {
      this.datos.nombre = this.form.controls['nombreControl'].value;
      this.datos.apellido = this.form.controls['apellidoControl'].value;
      this.datos.tipolocal = this.form.controls['tipolocalControl'].value;
      this.datos.nombrelocal = this.form.controls['nombrelocalControl'].value;
      this.datos.email = this.form.controls['emailControl'].value;
      this.datos.password = this.form.controls['passwordControl'].value;
      this.datos.telefono = this.form.controls['telefonoControl'].value;
      this.datos.direccionlocal = this.form.controls['referenciaControl'].value;
      this.datos.perfil = this.form.controls['perfilControl'].value;
      this.datos.ubicacion = this.form.controls['estadoControl'].value;
      this.datos.municipio = this.form.controls['municipioControl'].value;

      //capturo el tipo de perfil, cuando sea proveedor, consulto base de datos
      if (this.datos.perfil == 'PROVEEDOR' && !this.tipolocalesUser.length) {
        const path1 = 'tipocomercio'
        this.dataPerfiles = this.firestore.getCollection<any>(path1).subscribe(res => {
          if (res) {
            this.tipolocalesUser = res;
          }
        })
      }
    });
  }

  //metodo relacionado al FormGroup, para salvar los datos del formulario
  save(event: Event) {
    event.preventDefault(); //este metodo cancela el comportamiento por defecto de las pag html y captura lo que tiene antes de recargar
    //reverifico que realmente el formulario es valido (paso todas las validaciones) y así salvo los cambios
    if (this.form.valid) {
      this.cuentaEvent = this.cuentaEvent + 1;
      if (this.cuentaEvent == 1) {
        //aplico settimmer para anular avisos repetidos
        let timerOut4: NodeJS.Timeout;
        clearTimeout(timerOut4);
        timerOut4 = setTimeout(async () => {
          //si el formato es valido procedo a activar el proceso de registro
          this.RegistrarUsuario();
        }, 1000)
      }
    } else if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
  }


  //funcion relacionada al captcha
  async resolved(captchaResponse: string) {
    this.captcha2 = captchaResponse;

    //envio la respuesta a google (en proceso de evaluacion)
    let script = this._renderer.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js?captchaResponse";
    this._renderer.appendChild(document.body, script);
  }


  //se recibe ubicacion desde el servicio que pasa ubicacion desde el componente googlemaps  
  arrancar() {
    this.enviaUbicacion.pasaUbicacion.subscribe(res => {
      this.verMapa = true;
      this.datos.ubicacionpri.lat = res.data.lat;
      this.datos.ubicacionpri.lng = res.data.lng;
    });
  }

  async RegistrarUsuario() {
    //lanzo el loading de espera de registro de usuario
    await this.presentLoading();
    //registro primero credenciales de usuario en firebase si es exitoso registro usuario en base de datos
    await this.auth.register(this.datos).then(async res => {
      if (res) {
        const path = 'usuarios';
        const id = res.user?.uid;
        this.datos.password = "";
        this.datos.uid = res.user?.uid;
        //si el perfil del usuario es diferente a PROVEEDOR,  se resetea el tipo de local
        if (this.datos.perfil !== 'PROVEEDOR') {
          this.datos.tipolocal = "";
        }
        //si el perfil del usuario es diferente de usuario, se cambia a inactivo el usuario para esperar activacion por equipo app
        if (this.datos.perfil !== 'CLIENTE') {
          this.datos.estatus = "INACTIVO";
        }
        //el plan por defecto en elregistro de proveedores es el basico de 50 productos sin carga de fotos instantanteas
        if (this.datos.perfil == 'PROVEEDOR') {
          this.datos.plan = "AA";
        }
        //creo un path para la ruta de la imagen de perfil
        const path2 = 'fotoperfilusuario/' + id + '/';
        /*se llama el servicio para guardar la imagen solo cuando this.NewFile sea diferente de vacio, solo en este caso 
        tendrá valor, porque lo tomó desde el metodo newImageUpload()*/
        if (this.newFile != "") {
          const resImag = await this.firestorageService.uploadImages(this.newFile, path2, this.datos.email);
          //le asigno el valor de la url al campo urlfoto
          this.datos.urlfoto = resImag;
        }
        await this.firestore.createUser(this.datos, path, id).then(async () => {
          if (this.datos.perfil == 'CLIENTE') {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover = await this.loadingController.getTop();
            if (popover) {
              await popover.dismiss(null);
            }
            await this.presentToast('Registro de usuario Exitoso');
            //Se limpia el valor de la varibale this.newfile, si se decide crear otro usuario, el evento no toma la ultima foto cargada
            this.newFile = "";
            //Despues de este Proceso Manda el Usuario al perfil de Usuario
            return await this.router.navigate(['/perfilusuario']);
          } else if (this.datos.perfil != 'CLIENTE') {
            //Se limpia el valor de la varibale this.newfile, si se decide crear otro usuario, el evento no toma la ultima foto cargada
            this.newFile = "";
            await this.verificaCorreo();
          }
        }).catch(async (res) => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover1 = await this.loadingController.getTop();
          if (popover1) {
            await popover1.dismiss(null);
          }
          return await this.presentToast1('Ocurrio un error al completar el registro del usuario ' + res);
        })
      }
    }).catch(async (err) => {
      let codeRes = err.code;
      //Se limpia el valor de la varibale this.newfile, si se decide crear otro usuario, el evento no toma la ultima foto cargada
      this.newFile = "";
      this.cuentaEvent = 0;
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover2 = await this.loadingController.getTop();
      if (popover2) {
        await popover2.dismiss(null);
      }
      //reinicio el registro a los 3 seg
      setTimeout(async () => {
        window.location.reload();
      }, 3000)
      //capturo el mensaje de erro segun el caso
      switch (codeRes) {
        case 'auth/email-already-in-use':
          return await this.presentToast1('El email ya se encuentra registrado');
        case 'auth/weak-password':
          return await this.presentToast1('Contraseña es muy debil, debe utilizar 6 caracteres como mínimo');
        case 'auth/invalid-email':
          return await this.presentToast1('Ha introducido un email invalido');
        default:
          return await this.presentToast1('Error de registro desconocido');
      }
    })
  }

  //verificar correo electronico si esta validado
  async verificaCorreo() {
    await this.auth.getUserCurrent().then(async user => {
      await user?.sendEmailVerification().then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover22 = await this.loadingController.getTop();
        if (popover22) {
          await popover22.dismiss(null);
        }
        await this.auth.logout();
        return await this.presentToast('Se envió a su correo electrónico, un link para validar su registro').then(() => {
          // Despues de este Proceso Manda el Usuario al perfil de Usuario
          setTimeout(async () => {
            return await this.router.navigate(['/ingresar']).then(() => {
              //me desuscribo de captador de usuario logueado
              if (this.dataSuscri != undefined) {
                this.dataSuscri.unsubscribe();
              }
              //una vez que se cierra sesion mando a recargar pagina
              window.location.reload();
            })
          }, 2000)
        });
      });
    })
  }


  //Agregando Funcion para subir Imagen de Perfil del creador de la cuenta
  async newImageUpload(event: any) {
    if (event.target.files && event.target.files[0]) {
      console.log('event', event)
      //el tamaño de la imagen debe ser menor o igual a 400Kb
      if (event.target.files[0].size <= 400000) {
        //Guardo en variable newFile la foto que se va a subir
        this.newFile = event.target.files[0];
        const reader = new FileReader();
        reader.onload = ((image) => {
          this.datos.urlfoto = image.target?.result as string;
        });
        reader.readAsDataURL(event.target.files[0]);
      } else if (event.target.files[0].size > 400000) {
        this.newFile = '';
        this.datos.urlfoto = '';
        return await this.presentToast1('El tamaño de la imagen debe ser menor o igual a 400Kb')
      }
    }
  }


  //funcion para llamar google maps 
  async getGooglemaps() {

    //contador de veces que se ha llamada el mapa en el registro, maximo 3
    this.cuentaMapa = this.cuentaMapa + 1;
    if (this.cuentaMapa > 3) {
      await this.presentLoading44();
      return setTimeout(() => {
        window.location.reload();
      }, 5000);
    }

    this.verMapa = false;
    //envio al eventemmiter ifnromacion 2 segundos despues para que ejecute el mapa
    setTimeout(async () => {
      this.enviaUbicacion.pasaUbicacion2.emit({
        dat: this.datos.ubicacionpri
      })
    }, 2000);


    const modal = await this.modalControler.create({
      component: GooglemapsComponent,
      //cssClass: 'ion-modal'
      cssClass: 'fullscreen',
    });
    await modal.present();
  }


  //metodo para obtener informción de estados
  async getEstad() {
    if (this.selectEstado != this.datos.ubicacion) {
      this.colecMunic = [];
      this.datos.municipio = '';
    }
    if (!this.colecEstados.length && !this.colecMunic.length) {
      const path = 'estados';
      await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('estado', 'asc')).then(async estads => {
        await estads.forEach(async (doc: any) => {
          let valor: any[] = [];
          valor[0] = doc;
          this.colecEstados.push(valor[0]);
        });
        //le aplico reduccion de duplicados (si los hay)
        this.colecEstados = Array.from(this.colecEstados.reduce((map, obj) => map.set(obj.id, obj), new Map()).values());
      })
    }
    //obtengo los municipios
    if (this.datos.ubicacion != '' && !this.colecMunic.length) {
      let idEs = '';
      for await (let estadose of this.colecEstados) {
        if (this.datos.ubicacion == estadose.data().estado) {
          idEs = estadose.id;
        }
      }
      this.colecMunic = [];
      const path = 'estados/' + idEs + '/municipio/';
      await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('municipio', 'asc')).then(async munic => {
        await munic.forEach(async (doc: any) => {
          let valor1: any[] = [];
          valor1[0] = doc.data();
          this.colecMunic.push(valor1[0]);
        });
        //le aplico reduccion de duplicados (si los hay)
        this.colecMunic = Array.from(this.colecMunic.reduce((map, obj) => map.set(obj.municipio, obj), new Map()).values());
      })
    }

    this.selectEstado = this.datos.ubicacion;
  }

  //creo funcion para validar que el elefono no exista en el registro de base de datos
  /*
  async validaTelefono(event){
    this.txtTelefono=event.target.value;
          //agrego los path para guardado
          const path='usuarios/';
          if(this.txtTelefono.length==13){
            //llamo la funcion de mensaje en pantalla
            await this.presentLoading2();
            await this.firestore.getCollection5<User>(path,ref=>(ref.where('telefono','==',this.txtTelefono)).limit(1)).then(async telefono=>{
              if(!telefono.empty){
              await telefono.forEach(async (doc:any) => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popoverr = await this.loadingController.getTop();
                  if (popoverr){
                    await popoverr.dismiss(null); 
                  }
                  event.target.value='';
                  //muestro mensaje exitoso en el registro de solicitud a repartidor
                  return await this.presentToast3('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
              });
            }else if (telefono.empty){
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popoverr1 = await this.loadingController.getTop();
              if (popoverr1){
                await popoverr1.dismiss(null); 
              }
            }
          })
      }     
  }
  */

  async validaTelefono(event) {
    this.txtTelefono = event.target.value;
    //agrego los path para guardado
    const path = 'precomercio/';
    if (this.txtTelefono.length == 13) {
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading2();
      await this.firestore.getCollection5<preComercio>(path, ref => (ref.where('telefono', '==', this.txtTelefono)).limit(1)).then(async telefono => {
        if (!telefono.empty) {
          await telefono.forEach(async (doc: any) => {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popoverr = await this.loadingController.getTop();
            if (popoverr) {
              await popoverr.dismiss(null);
            }
            event.target.value = '';
            //muestro mensaje exitoso en el registro de solicitud a repartidor
            return await this.presentToast3('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
          });
        } else if (telefono.empty) {
          //agrego los path para guardado
          const path1 = 'prerepartidor/';
          await this.firestore.getCollection5<preRepartidor>(path1, ref => (ref.where('telefono', '==', this.txtTelefono)).limit(1)).then(async telefono1 => {
            if (!telefono1.empty) {
              await telefono1.forEach(async (doc: any) => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popoverr = await this.loadingController.getTop();
                if (popoverr) {
                  await popoverr.dismiss(null);
                }
                event.target.value = '';
                //muestro mensaje exitoso en el registro de solicitud a repartidor
                return await this.presentToast3('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
              });
            } else if (telefono1.empty) {
              //agrego los path para guardado
              const path2 = 'precliente/';
              await this.firestore.getCollection5<preCliente>(path2, ref => (ref.where('telefono', '==', this.txtTelefono)).limit(1)).then(async telefono2 => {
                if (!telefono2.empty) {
                  await telefono2.forEach(async (doc: any) => {
                    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                    const popoverr = await this.loadingController.getTop();
                    if (popoverr) {
                      await popoverr.dismiss(null);
                    }
                    event.target.value = '';
                    //muestro mensaje exitoso en el registro de solicitud a repartidor
                    return await this.presentToast3('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
                  });
                } else if (telefono2.empty) {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popoverr1 = await this.loadingController.getTop();
                  if (popoverr1) {
                    await popoverr1.dismiss(null);
                  }
                }
              })
            }
          })
        }
      })
    }
  }


  //configuracion de la funcion que muestra un mensaje exitoso en pantalla
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 7000,
      color: 'success',
      position: 'middle'
    });
    await toast.present();
  }

  //configuracion de la funcion que muestra un mensaje exitoso en pantalla
  async presentToast2(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 3000,
      color: 'success',
      position: 'middle'
    });
    await toast.present();
  }

  //configuracion de la funcion que muestra mensaje de advertencia
  async presentToast1(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 7000,
      position: 'middle'
    });
    await toast2.present();
  }

  //configuracion de la funcion que muestra mensaje de advertencia
  async presentToast3(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 4000,
      position: 'middle'
    });
    await toast2.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Registrando Usuario...',
      //duration: 4000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera en validacion de telefono
  async presentLoading2() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Verificando Teléfono...',
      //duration: 4000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la para recargar pedido en pantalla
  async presentLoading44() {
    let loading4 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Alcanzo el limite de llamadas al mapa, iniciando de nuevo el registro...',
      duration: 5000
    });
    //presenta mensaje
    await loading4.present();
  }

}
