import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform, ToastController } from '@ionic/angular';

// Importaciones relacionadas a publicidad Admob
 /* se inhabilita publicidad al 03-05-24
import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition
} from '@capacitor-community/admob';
*/

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  nombreComer = ''
  nombreLocali = ''

  showAnime = false;

  constructor(private location: LocationStrategy,
    private toastController: ToastController,
    private router: Router,
    private alertController: AlertController,
    public platformh: Platform) {
    //inhabilita que se pueda retroceder el menu
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  async ngOnInit() {
   // await this.initializeAdmob();

    //establezco una animación de 1 segundo cada 15 segundos en pag principal
    if (!this.platformh.is('capacitor')) {
      setInterval(() => {
        this.showAnime = !this.showAnime;
        setTimeout(() => {
          this.showAnime = !this.showAnime;
        }, 1000);
      }, 15000);
    }
  }

  //inicializo la publicidad Admob
   /* se inhabilita publicidad al 03-05-24
  async initializeAdmob() {


    if (this.platformh.is('cordova')) {
      const options: BannerAdOptions = {
        adId: 'ca-app-pub-4505046024910255/8420417432',
        adSize: BannerAdSize.BANNER,
        position: BannerAdPosition.BOTTOM_CENTER,
        margin: 0,
        //isTesting: false,
        isTesting: true,
        npa: false
      };

      await AdMob.initialize({
        requestTrackingAuthorization: false,
        //initializeForTesting: false,
        initializeForTesting: true,
      }).catch(err => console.error('Could not init Admob', err));

      await AdMob.showBanner(options).catch(err => console.error('Could not show Admob banner', err));;
    }
  }


  ionViewDidLeave() {
    if (AdMob && this.platformh.is('cordova')) {
      AdMob.removeBanner();
    }
  }
  */


  async busquedaComer() {
    if (this.nombreComer.trim() != '' && this.nombreLocali.trim() != '') {
      await this.presentToast('Sin resultados, próximamente invitaremos a unirse a más comercios')
    } else if (this.nombreComer.trim() == '' && this.nombreLocali.trim() != '') {
      await this.presentToast('Sin resultados, próximamente invitaremos a unirse a más comercios por área de localización')
    } else if (this.nombreComer.trim() != '' && this.nombreLocali.trim() == '') {
      await this.presentToast('Sin resultados, próximamente invitaremos a unirse a más comercios')
    }
  }


  //configuracion de la funcion que muestra mensaje 
  async presentToast(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 4000,
      position: 'middle'
    });
    toast2.present();
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast1(mensaje: string) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle',
    });
    await toast1.present();
  }

  //funcion para copiar Pedido
  async copiaTexto() {
    let inputFalso = document.createElement('input');
    inputFalso.setAttribute('value', 'soporte.entregamovil@gmail.com');
    document.body.appendChild(inputFalso);
    inputFalso.select();
    document.execCommand("copy");
    document.body.removeChild(inputFalso);
    return await this.presentToast1('Email de contacto copiado ' + inputFalso.value)
  }

  //inicar conversación por whatssap
  startWhass() {
    let link = 'https://wa.me/+584243065560?text=Hola%20le%20escribo%20para%20solicitar%20información%20sobre%20suscripciones%20al%20servicio%20Entregamovil'
    window.open(link, '_blank');
  }

  //inicar conversación por whatssap
  startWhass1() {
    let link = 'https://wa.me/+584243065560?text=Hola%20le%20escribo%20para%20solicitar%20información%20sobre%20suscripcion%20de%20Comercios'
    window.open(link, '_blank');
  }

  //inicar conversación por whatssap
  startWhass2() {
    let link = 'https://wa.me/+584243065560?text=Hola%20le%20escribo%20para%20solicitar%20información%20sobre%20suscripcion%20de%20Repartidores'
    window.open(link, '_blank');
  }

  //inicar instagram
  startInsta() {
    let link = 'https://www.instagram.com/entregamovil'
    window.open(link, '_blank');
  }
  //inicar instagram
  startYoutu() {
    let link = 'https://youtu.be/e6FnXu4_VYs'
    window.open(link, '_blank');
  }
  //inicar instagram
  startAndro() {
    let link = 'https://play.google.com/store/apps/details?id=com.entregamovil.entregamovil'
    window.open(link, '_blank');
  }

  //muestra Informacion
  async verInformacion() {
    //envio un mensaje diferente cuando se trata de una zona a cotizar
    const alert1 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Información',
      backdropDismiss: true,
      message: "<b>Entregamovil</b> es una Aplicación de Gestión de Pedidos en línea para automatizar los procesos de pedidos y entregas entre clientes, comercios y repartidores. " +
        'Funciona como una herramienta de rápida interacción entre las partes mencionadas sin manipular el dinero... contáctanos para obtener más detalles',
      buttons: [
        {
          text: 'INFORMACIÓN VIA WHATSAPP',
          id: 'confirm-button',
          handler: () => {
            this.startWhass();
          }
        }, {
          text: 'EMAIL CONTACTO',
          id: 'confirm-button2',
          handler: async () => {
            await this.copiaTexto();
          }
        }, {
          text: 'VOLVER',
          id: 'cancel-button',
          handler: async () => {

          }
        }
      ]
    });
    await alert1.present();
  }

  //funcion prueba para abrir menú diseño
  async abremenu() {
    try {
      return await this.router.navigate(['/menumovil/+584243065560']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  //funcion prueba para abrir catálogo diseño
  async abrecatalogo() {
    try {
      return await this.router.navigate(['/productomovil/+584243065560']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  //funcion prueba para abrir menú modelo
  async abremenu1() {
    try {
      return await this.router.navigate(['/menumovil/+584128749246']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  //funcion prueba para abrir catálogo modelo
  async abrecatalogo1() {
    try {
      return await this.router.navigate(['/productomovil/+584128749246']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }


}
