import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CategoriaMenu } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';



@Component({
  selector: 'app-popupcategoria',
  templateUrl: './popupcategoria.component.html',
  styleUrls: ['./popupcategoria.component.scss'],
})
export class PopupcategoriaComponent implements OnInit {

  //Declaro el Objeto de la interfaz de la categoria de Menus
  categoriaMenu2: CategoriaMenu = {
    idCategoriaMenu: "",
    nombreCategoria: ""
  }

  //creo un array para cargar las actegorias creadas
  categorias: CategoriaMenu[] = [];
  categorias2: CategoriaMenu[] = [];

  //creo objeto de susucripcion observables a categorias
  private getcategoriasSuscriber: Subscription = undefined;
  private getdataSuscriber: Subscription = undefined;

  //creo una variable que me va a almacenar el uid del usuario logeado
  private uidUserLog = "";

  constructor(private firestore: FirestoreService,
    private modalController: ModalController,
    private traeUid: UserlogedService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private toastController: ToastController) {

    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.getdataSuscriber = this.traeUid.getDatauser().subscribe(async res => {
      if (res) {
        this.uidUserLog = res.uid;
        //se cargan las categorias registradas
        await this.getCategorias(this.uidUserLog);
      }
    })
  }



  ngOnInit(): void {
  }

  async registrarCategoria2() {
    //si se consigue alguna coincidencia con la categoria que se esta registrando, se dará un alerta de registro
    if (this.categorias2.length && this.uidUserLog != '') {
      const alert = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: "Se encontrarón coincidencias de categorias registradas con la que intenta registrar: <b>" + this.categoriaMenu2.nombreCategoria.toUpperCase()
          + "</b> ¿Confirma que la categoria que desea <b>REGISTRAR</b> es Correcta? <b>Nota: Trate de registrar categorias globales, una vez registradas, usted no las podrá eliminar</b> ",
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              //configuracion de la funcion que muestra mensaje de espera
              await this.presentLoading()
              this.categoriaMenu2.idCategoriaMenu = this.firestore.getId();
              this.categoriaMenu2.nombreCategoria = (this.categoriaMenu2.nombreCategoria.toUpperCase()).trim();
              //realizo una ultima consulta de información para ver si el nombr de Categoria es exactamente igual
              for await (let catego2 of this.categorias) {
                if (this.categorias.length && catego2.nombreCategoria == this.categoriaMenu2.nombreCategoria.trim()) {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover7 = await this.loadingController.getTop();
                  if (popover7) {
                    await popover7.dismiss(null);
                  }
                  //si se consigue alguna coincidencia se termina el ciclo
                  return await this.presentToast2('¡No se pudo registrar, el Nombre de Categoria ya se encuentra Registrado!')
                }
              }
              const data = this.categoriaMenu2;
              const path = 'usuarios/' + this.uidUserLog + '/categoriaMenu/';
              await this.firestore.createDoc2(data, path, data.idCategoriaMenu).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover = await this.loadingController.getTop();
                if (popover) {
                  await popover.dismiss(null);
                }
                //muestro mensaje de actuslización con exito
                return await this.presentToast1('¡Registro de Categoria Exitoso!')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover2 = await this.loadingController.getTop();
                if (popover2) {
                  await popover2.dismiss(null);
                }
                //si hay un error al registrar la categoria
                return await this.presentToast2('No fue posible Registrar la categoria, Intente Nuevamente ó comuniquese con soporte de App')
              });;
              //se cierra el popup
              await this.modalController.dismiss();
            }
          }
        ]
      });
      await alert.present();
    } else if (!this.categorias2.length && this.uidUserLog != '') {
      //configuracion de la funcion que muestra mensaje de espera
      await this.presentLoading()
      this.categoriaMenu2.idCategoriaMenu = this.firestore.getId();
      this.categoriaMenu2.nombreCategoria = (this.categoriaMenu2.nombreCategoria.toUpperCase()).trim();
      //realizo una ultima consulta de información para ver si el nombr de Categoria es exactamente igual
      for await (let catego1 of this.categorias) {
        if (this.categorias.length && catego1.nombreCategoria == this.categoriaMenu2.nombreCategoria.trim()) {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover6 = await this.loadingController.getTop();
          if (popover6) {
            await popover6.dismiss(null);
          }
          //si se consigue alguna coincidencia se termina el ciclo
          return await this.presentToast2('¡No se pudo registrar, el Nombre de Categoria ya se encuentra Registrado!')
        }
      }
      const data = this.categoriaMenu2;
      const path = 'usuarios/' + this.uidUserLog + '/categoriaMenu/';
      await this.firestore.createDoc2(data, path, data.idCategoriaMenu).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
        //muestro mensaje de actuslización con exito
        return await this.presentToast1('¡Registro de Categoria Exitoso!')
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover3 = await this.loadingController.getTop();
        if (popover3) {
          await popover3.dismiss(null);
        }
        //si hay un error al registrar la categoria
        return await this.presentToast2('No fue posible Registrar la categoria, Intente Nuevamente ó comuniquese con soporte de App')
      });;
      //se cierra el popup
      await this.modalController.dismiss();
    }
  }


  async getCategorias(uidComer: string) {
    const pathc = 'usuarios/' + uidComer + '/categoriaMenu';
    this.getcategoriasSuscriber = this.firestore.getCollection<CategoriaMenu>(pathc).subscribe(async categoria => {
      this.categorias = [];
      if (await categoria) {
        await categoria.forEach((doc: CategoriaMenu) => {
          this.categorias.push(doc)
        })
        //le aplico reduccion de duplicados
        this.categorias = Array.from(this.categorias.reduce((map, obj) => map.set(obj.nombreCategoria, obj), new Map()).values());
        this.categorias2 = this.categorias;
        //Utilizo funcion predefinida de (a,b) para Ordenar el array de categorias
        this.categorias2.sort(function (a, b) {
          if (a.nombreCategoria < b.nombreCategoria) {
            return -1;
          } else if (a.nombreCategoria > b.nombreCategoria)
            return 1;
          else
            return 0;
        });
      }
    })
  }

  //creo funcion para filtrar la categroia segun lo que voy escribiendo
  async searchCategoria(event) {
    if (event.target.value != '' && event.target.value.trim() != "") {
      this.categorias2 = this.categorias.filter((categor: CategoriaMenu) => {
        return ((categor.nombreCategoria.toLowerCase().indexOf(event.target.value.toLowerCase().trim()) > -1));
      });
    } else {
      this.categorias2 = this.categorias;
    }
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    let loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Registrando Categoria...',
      duration: 10000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast1(mensaje: any) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 3000,
      color: 'success',
      position: 'middle'
    });
    toast2.present();
  }

  //configuracion de la funcion que muestra erroe ne pantalla
  async presentToast2(mensaje: any) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'danger',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast1.present();
  }


}
