import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MimenuComponent } from './comercio/mimenu/mimenu.component';
import { OrdenComponent } from './comercio/orden/orden.component';
import { PedidoComponent } from './comercio/pedido/pedido.component';
import { RepartidoresComponent } from './comercio/repartidores/repartidores.component';
import { GooglemapsComponent } from './googlemaps/googlemaps.component';
import { ComerciosafiComponent } from './pages/comerciosafi/comerciosafi.component';
import { HomeComponent } from './pages/home/home.component';
import { IngresarComponent } from './pages/ingresar/ingresar.component';
import { MenumovilComponent } from './pages/menumovil/menumovil.component';
import { MipedidoComponent } from './pages/mipedido/mipedido.component';
import { PerfilusuarioComponent } from './pages/perfilusuario/perfilusuario.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { RepartidorComponent } from './pages/repartidor/repartidor.component';
import { RepartosComponent } from './pages/repartos/repartos.component';

//importacion para proteccion de rutas para que abran solo con usuarios logueados
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { ZonaentregaComponent } from './comercio/zonaentrega/zonaentrega.component';
import { PedidosComponent } from './comercio/pedidos/pedidos.component';
import { FormapagoComponent } from './comercio/formapago/formapago.component';
import { ProductomovilComponent } from './pages/productomovil/productomovil.component';
import { PoliprivacidadComponent } from './pages/poliprivacidad/poliprivacidad.component';
import { AfiliacionComponent } from './pages/afiliacion/afiliacion.component';

//importacion para nuevo componenete de pedido 11/03/24
import { PedidoiPage } from './pages/tab/pedidoi/pedidoi.page';

//creación de nuevo diseño de registro de usuario 18/03/2024
import { RegComponent} from './pages/reg/reg.component';


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },

  {
    path: "menumovil/:id",
    component: MenumovilComponent
  },

  {
    path: "productomovil/:id",
    component: ProductomovilComponent
  },

  {
    path: "orden/:id",
    component: OrdenComponent
  },

  //se agregaron nuevas rutas de forma automatica para la navegacion de la etiqueta ions_tabs de ionic 11/03/24
  {
    path: 'pedidoi',
    loadChildren: () => import('./pages/tab/pedidoi/pedidoi.module').then(m => m.PedidoiPageModule)
  },
  {
    path: 'startpedido',
    loadChildren: () => import('./pages/tab/startpedido/startpedido.module').then( m => m.StartpedidoPageModule)
  },
  {
    path: 'detalle',
    loadChildren: () => import('./pages/tab/detalle/detalle.module').then( m => m.DetallePageModule)
  },

  {
    path: "mipedido",
    component: MipedidoComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: "pedido",
    component: PedidoComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: "formapago",
    component: FormapagoComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: "pedidos",
    component: PedidosComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'mimenu',
    component: MimenuComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'zonas',
    component: ZonaentregaComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'repartidor',
    component: RepartidorComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'repartidores',
    component: RepartidoresComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'comercioafiliado',
    component: ComerciosafiComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'repartos',
    component: RepartosComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'ingresar',
    component: IngresarComponent
  },

  // { 
  //   path: 'registrar',
  //   component: RegistroComponent
  // },

  //creación de nuevo diseño de registro de usuario 18/03/2024
  {
    path: 'registrar',
    component: RegComponent
  },

  {
    path: 'perfilusuario',
    component: PerfilusuarioComponent,
    canActivate: [AngularFireAuthGuard]
  },

  {
    path: 'googlemaps',
    component: GooglemapsComponent
  },

  {
    path: 'politicaprivacidad',
    component: PoliprivacidadComponent
  },

  {
    path: 'afiliacion',
    component: AfiliacionComponent
  },

  //se llama al home si se coloca una ruta vacia
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  //se llama al home si se coloca cualquier ruta
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
