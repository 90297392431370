import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ComercioAfiliado, RepartidorAfiliado, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-repartidores',
  templateUrl: './repartidores.component.html',
  styleUrls: ['./repartidores.component.scss'],
})
export class RepartidoresComponent implements OnInit, OnDestroy {

  //creo una variable que me va a almacenar la data del usuario logeado
  dataUserlog: User;
  //variable que guarda dinamicamente el texto que se introduce en la busqueda del repartidor
  textRepartidor = '';
  //variable que almacena dinamicamente el repartidor encontrado
  searchedRepartidor: User;
  //Declaro una variable para el mensaje de espera en pantalla, relacionada a la funcion presentLoading()
  loading2: any;
  //declaro variable para habilitar la vista de datos de repartidor encontrado
  verdataRepartidor = false;
  //declaro variable a utilizar sobre solicitud de afiliaciones entre las partes
  repartidorafiliado: RepartidorAfiliado = {
    uid: '',
    nombre: '',
    email: '',
    habilitarcomercio: false,
    habilitarrepartidor: false,
    afiliacion: 'PENDIENTE'
  }

  //declaro variable a utilizar sobre solicitud de afiliaciones entre las partes
  comercioafiliado: ComercioAfiliado = {
    uid: '',
    nombre: '',
    email: '',
    habilitarcomercio: false,
    habilitarrepartidor: false,
    afiliacion: 'PENDIENTE'
  }

  //variable para activar boton de invitación repartidor
  activaInvita = false;

  //array para capturar repartidores
  public repartidorAfiget: RepartidorAfiliado[] = [];
  public repartidorAfiget2: RepartidorAfiliado[] = [];

  //declaracion de Observables para aplicar la desuscripcion
  private getdataSuscriber: Subscription;
  private getrepartidorSuscriber1: Subscription;
  private getrepartidoresSuscriber: Subscription = undefined;
  private getrepartidoresSuscriber1: Subscription = undefined;

  constructor(private firestore: FirestoreService,
    private loadingController2: LoadingController,
    private toastController2: ToastController,
    private traeUid: UserlogedService,
    private alertController: AlertController,) {
    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.getdataSuscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.dataUserlog = res;
        //llamo la lista de repartidores
        this.getRepartidores();
      }
    })
  }


  ngOnInit() { }

  ngOnDestroy() {
    if (this.getdataSuscriber != undefined) {
      this.getdataSuscriber.unsubscribe();
    }
    if (this.getrepartidoresSuscriber != undefined) {
      this.getrepartidoresSuscriber.unsubscribe();
    }
    if (this.getrepartidorSuscriber1 != undefined) {
      this.getrepartidorSuscriber1.unsubscribe();
    }
    if (this.getrepartidoresSuscriber1 != undefined) {
      this.getrepartidoresSuscriber1.unsubscribe();
    }
  }

  //creo funcion para filtrar los pedidos segun el nombre que voy colocando
  async searchRepartidor(event) {
    this.textRepartidor = event.target.value;
    //agrego los path para guardado
    const path = 'usuarios/';
    this.searchedRepartidor = null;
    this.verdataRepartidor = false;
    if (this.textRepartidor.length == 11) {
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading1();
      this.getrepartidoresSuscriber = this.firestore.getCollection3<User>(path, ref => (ref.where('telefono', '==', '+58' + this.textRepartidor.substr(1))).limit(1)).subscribe(async repartidor => {
        if (repartidor.length && repartidor[0].perfil == 'REPARTIDOR') {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popoverr = await this.loadingController2.getTop();
          if (popoverr) {
            await popoverr.dismiss(null);
          }
          this.searchedRepartidor = repartidor[0];
        } else {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popoverr1 = await this.loadingController2.getTop();
          if (popoverr1) {
            await popoverr1.dismiss(null);
          }
          //muestro mensaje exitoso en el registro de solicitud a repartidor
          await this.presentToastt('No se econtró repartidor con el numero de teléfono suministrado')
        }
      });
    }
  }

  //filtro por correo repartidor
  searchRepartidor2(event) {
    this.repartidorAfiget = this.repartidorAfiget2.filter((repartidor: RepartidorAfiliado) => {
      return ((repartidor.email.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) ||
        (repartidor.nombre.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    });
  }

  //llamo los datos del repartidor encontrado
  openRepartidor(repartidor: User) {
    this.verdataRepartidor = true;
  }

  //llamo los datos del repartidor encontrado
  async enviarSolicitud(repartidor: User) {
    //agrego los path para guardado
    const path = 'usuarios/' + this.dataUserlog.uid + '/repartidorafiliado/';
    const path2 = 'usuarios/' + repartidor.uid + '/comercioafiliado/';
    //actualizo los datos que seran almacenados en el comercio 
    this.repartidorafiliado = {
      uid: repartidor.uid,
      nombre: repartidor.nombre + ' ' + repartidor.apellido,
      email: repartidor.email,
      habilitarcomercio: false,
      habilitarrepartidor: false,
      afiliacion: 'PENDIENTE'
    }
    //actualizo los datos que seran almacenados en el repartidor
    this.comercioafiliado = {
      uid: this.dataUserlog.uid,
      nombre: this.dataUserlog.nombrelocal,
      email: '',
      habilitarcomercio: false,
      habilitarrepartidor: false,
      afiliacion: 'PENDIENTE'
    }

    //si la solicitud ya fue enviada al repartidor se emite alerta de espera
    await this.firestore.getDoc2<ComercioAfiliado>(path2, this.comercioafiliado.uid).then(async repartidor2 => {
      //si no existe el comercio en el repartidor procedo a enviar la solicitud
      if (repartidor2.data()) {
        //cierro los datos del repartidor y limpio el buscador
        this.verdataRepartidor = false;
        this.textRepartidor = '';
        //muestro mensaje exitoso en el registro de solicitud a repartidor
        return await this.presentToastt('Ya Existe una Solicitud con este Repartidor')
      } else if (!repartidor2.data()) {
        //llamo la funcion de mensaje en pantalla
        await this.presentLoading();
        //Creo la solicitud al repartidor
        await this.firestore.createDoc2<ComercioAfiliado>(this.comercioafiliado, path2, this.comercioafiliado.uid).then(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover = await this.loadingController2.getTop();
          if (popover) {
            await popover.dismiss(null);
          }
          //cierro los datos del repartidor y limpio el buscador
          this.verdataRepartidor = false;
          this.textRepartidor = '';
          //muestro mensaje exitoso en el registro de solicitud a repartidor
          await this.presentToast('Solicitud Enviada al Repartidor Exitosamente...')
          //Creo la solicitud al Comercio
          await this.firestore.createDoc2<RepartidorAfiliado>(this.repartidorafiliado, path, this.repartidorafiliado.uid).then(async () => {
            //muestro mensaje exitoso en el registro de solicitud a repartidor
            await this.presentToast('Solicitud Guardada en el Comercio Exitosamente...')
          }).catch(async () => {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover1 = await this.loadingController2.getTop();
            if (popover1) {
              await popover1.dismiss(null);
            }
            //muestro mensaje de problemas durante la solicitud al repartidor
            await this.presentToastt2('Ocurrio un problema al guardar la Solicitud al comercio, por favor Intente Nuevamente...', '')
          });
        }).catch(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover2 = await this.loadingController2.getTop();
          if (popover2) {
            await popover2.dismiss(null);
          }
          //muestro mensaje de problemas durante la solicitud al repartidor
          await this.presentToastt2('Ocurrio un problema de conexion al Enviar la Solicitud al Repartidor, por favor Intente Nuevamente...', '')
        });
      }
    });
  }


  //obtengo las solicitudes de comercios afiliados
  getRepartidores() {
    //creo la ruta para obtener los productos del usuario logeado
    const path = 'usuarios/' + this.dataUserlog.uid + '/repartidorafiliado/';
    this.getrepartidorSuscriber1 = this.firestore.getCollection<RepartidorAfiliado>(path).subscribe(res => {
      if (res) {
        this.repartidorAfiget = res;
        this.repartidorAfiget2 = res;
        //Utilizo funcion predefinida de (a,b) para Ordenar el array de repartidores por habilitacion
        this.repartidorAfiget.sort(function (a, b) {
          if (b.habilitarcomercio < a.habilitarcomercio) {
            return -1;
          } else if (b.habilitarcomercio > a.habilitarcomercio)
            return 1;
          else
            return 0;
        });
      }
    })
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    this.loading2 = await this.loadingController2.create({
      cssClass: 'normal',
      message: 'Enviando Solicitud al Repartidor...',
      duration: 2500
    });
    //presenta mensaje
    await this.loading2.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading1() {
    //crea mensaje
    const loading2 = await this.loadingController2.create({
      cssClass: 'normal',
      message: 'Buscando Repartidor...',
      duration: 8000
    });
    //presenta mensaje
    await loading2.present();
  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast(mensaje: string) {
    const toast = await this.toastController2.create({
      message: mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    toast.present();
  }


  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToastt(mensaje: string) {
    const toast = await this.toastController2.create({
      message: mensaje,
      cssClass: 'toast-background',
      duration: 2500,
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra mensaje en Linea con Comercio
  async presentToastt1(mensaje: string, repartidornombre: string) {
    const toast1 = await this.toastController2.create({
      message: mensaje + ' ' + repartidornombre,
      color: 'success',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    toast1.present();
  }

  //configuracion de la funcion que muestra mensaje fuera de Linea con Comercio
  async presentToastt2(mensaje: string, nombrerepartidor: string) {
    const toast2 = await this.toastController2.create({
      message: mensaje + ' ' + nombrerepartidor,
      color: 'danger',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    toast2.present();
  }

  //Función para poner en linea el repartidor con el comercio
  async activaComercio(repartidor: RepartidorAfiliado) {
    //si el comercio trae el valor habilitar en true
    if (repartidor.habilitarcomercio == false) {
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>HABILITAR</strong> entregas con el Repartidor : <b>' + repartidor.nombre + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.dataUserlog.uid + '/repartidorafiliado/';
              const path3 = 'usuarios/' + repartidor.uid + '/comercioafiliado/';
              await this.firestore.updateDoc<RepartidorAfiliado>({ habilitarcomercio: true }, path2, repartidor.uid).then(async () => {
                //cambio el valor en la coleccion del repartidor
                await this.firestore.updateDoc<ComercioAfiliado>({ habilitarcomercio: true }, path3, this.dataUserlog.uid).then(async () => {
                  //muestro mensaje de Estado en Linea al Repartidor
                  return await this.presentToastt1('EN LINEA con ', repartidor.nombre)
                }).catch(async () => {
                  //muestro mensaje de problemas durante la solicitud al repartidor
                  await this.presentToastt('Ocurrio un problema de conexion al Habilitar Delivery con el Repartidor, Intente Nuevamente')
                  repartidor.habilitarcomercio = false;
                });
              }).catch(async () => {
                //muestro mensaje de problemas en el guardado
                await this.presentToastt('No se pudo HABILITAR el Repartidor, Intente nuevamente')
                repartidor.habilitarcomercio = false;
              });
            }
          }
        ]
      });
      await alert1.present();
    } else if (repartidor.habilitarcomercio == true) {

      const alert2 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>DESHABILITAR</strong> entregas con el Repartidor: <b>' + repartidor.nombre + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.dataUserlog.uid + '/repartidorafiliado/';
              const path3 = 'usuarios/' + repartidor.uid + '/comercioafiliado/';
              await this.firestore.updateDoc<RepartidorAfiliado>({ habilitarcomercio: false }, path2, repartidor.uid).then(async () => {
                //cambio el status en la colecccion del raprtidor
                await this.firestore.updateDoc<ComercioAfiliado>({ habilitarcomercio: false }, path3, this.dataUserlog.uid).then(async () => {
                  //muestro mensaje de Estado en Linea al Repartidor
                  return await this.presentToastt2('DESHABILITÓ EXITOSAMENTE A: ', repartidor.nombre)
                }).catch(async () => {
                  //muestro mensaje de problemas durante la solicitud al repartidor
                  await this.presentToastt('Ocurrio un problema de conexion al Deshabilitar Delivery con el Repartidor, Intente Nuevamente')
                  repartidor.habilitarcomercio = true;
                });
              }).catch(async () => {
                //muestro mensaje de problemas en el guardado
                await this.presentToastt('No se pudo DESHABILITAR Repartos con el Comercio, Intente nuevamente')
                repartidor.habilitarcomercio = true;
              });
            }
          }
        ]
      });
      await alert2.present();

    }

  }



}
