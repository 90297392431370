import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MimenuComponent } from './mimenu/mimenu.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopupcategoriaComponent } from './popupcategoria/popupcategoria.component';
import { OrdenComponent } from './orden/orden.component';
import { PedidoComponent } from './pedido/pedido.component';
import { RepartidoresComponent } from './repartidores/repartidores.component';
import { PopuprepartidorComponent } from './popuprepartidor/popuprepartidor.component';
import { ZonaentregaComponent } from './zonaentrega/zonaentrega.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { FormapagoComponent } from './formapago/formapago.component';



@NgModule({
  declarations: [
    MimenuComponent, //declaracion de modulo incluido dentro de comercio
    PopupcategoriaComponent,
    OrdenComponent,
    PedidoComponent,
    RepartidoresComponent,
    PopuprepartidorComponent,
    ZonaentregaComponent,
    PedidosComponent,
    FormapagoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PopupcategoriaComponent,
    PopuprepartidorComponent
  ]
})
export class ComercioModule { }
