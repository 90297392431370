import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GooglemapsService {

  private apikey1 = environment.mapsConfig.apikey1
  private apikey2 = environment.mapsConfig.apikey2
  mapsLoaded = false;

  constructor(private platform: Platform) { }

  init(renderer: any, document: any): Promise<any> {
    return new Promise(async (resolve) => {

      if (this.mapsLoaded) {
        resolve(true);
        return;
      }


      const script = renderer.createElement('script');
      script.id = 'googleMaps';
      script.async = true;

      /*
        window['mapInit'] = () => {
         // console.log('Mapa cargado 2')
          this.mapsLoaded = true;
          resolve(true);
          return;
        }
      */

      window['initMap'] = () => {
        this.mapsLoaded = true;
        resolve(true);
        return;
      }

      if (this.platform.is('cordova')) {
        //script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.apikey2+'&callback=mapInit';
        //script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apikey2 + '&callback=initMap';
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apikey2 + '&callback=initMap&v=weekly&libraries=marker'
        /*
        window['initMap'] = () => {
          console.log('Mapa cargado con android')
          this.mapsLoaded = true;
          resolve(true);
          return;
        }
        */
      } else {
        //script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.apikey1+'&callback=mapInit';
        //script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.apikey1+'&callback=initMap';

        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apikey1 + '&callback=initMap&v=weekly&libraries=marker'



        /*
        window['initMap'] = () => {
          console.log('Mapa cargado sin android')
          this.mapsLoaded = true;
          resolve(true);
          return;
        }
        */
      }

      renderer.appendChild(document.body, script);
    });
  }
}
