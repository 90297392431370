import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Pedido, PedidoAsignado, RepartidorAfiliado, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss'],
})
export class PedidosComponent implements OnInit, OnDestroy {

  showPicker = false;
  showPicker1 = false;
  dateValue = new Date();
  dateValue1 = new Date();
  hoy = new Date(this.dateValue.setHours(0, 0, 0, 0)).toISOString();
  minimaBusqueda: any;
  dateValue2: any;

  //declaracion de Observables para aplicar la desuscripcion
  private getdatSuscriber: Subscription = undefined;
  private getrepartidoresSuscriber: Subscription = undefined;
  private getPedidosSuscriber: Subscription = undefined;
  private getPedidosSuscribertotal: Subscription = undefined;
  private getPedidosSuscriberrepar: Subscription = undefined;
  private getPedidosSuscribersinrepar: Subscription = undefined;

  //creo una variable que me va a almacenar la data del comercio logueado
  public dataUserLog: User = undefined;
  public repartidores: RepartidorAfiliado[] = [];
  public repartidores2: RepartidorAfiliado[] = [];
  public getPedidosrepar: Pedido[] = [];
  public getPedidosrepar2: any[] = [];
  public verResumenpedido: PedidoAsignado = undefined;
  public cargaPedido1: Pedido = undefined;

  //activa buscadorepartidor
  public busquedaRepartidor = false;
  public totalRepartidorusd: number = 0;
  public nombreReapartidor = '';
  public uidReapartidor = '';


  //variable que activa ver resumen del pedido
  public verResumen = false;
  public verRegistrapago = false;
  public verDetallepedido = false;
  public verVentas = false;
  public verTitulo = 0;

  //guarda valor de settimer para cerrar el spiner cuando termina el ciclo for
  private terminaSpin: any = undefined;


  constructor(private traeUid: UserlogedService,
    private firestore: FirestoreService,
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController) {

    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.getdatSuscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.dataUserLog = res;
      }
    })

  }


  ngOnInit() {
    //establezco la fecha minima de 3 meses atras para busquedas de historial de pedidos
    let vvv = new Date();
    let vv1 = vvv.setMonth(vvv.getMonth() - 3);
    this.minimaBusqueda = (new Date(vv1)).toISOString();
  }

  //se llama esta funcion para desuscribir observables
  async ngOnDestroy() {
    await this.desuscribeObser();
  }

  //función para desuscribir de observables
  async desuscribeObser() {
    if (this.getdatSuscriber != undefined) {
      this.getdatSuscriber.unsubscribe();
    }
    if (this.getrepartidoresSuscriber != undefined) {
      this.getrepartidoresSuscriber.unsubscribe();
    }
    if (this.getPedidosSuscriber != undefined) {
      this.getPedidosSuscriber.unsubscribe();
    }
    if (this.getPedidosSuscribertotal != undefined) {
      this.getPedidosSuscribertotal.unsubscribe();
    }
    if (this.getPedidosSuscriberrepar != undefined) {
      this.getPedidosSuscriberrepar.unsubscribe();
    }
    if (this.getPedidosSuscribersinrepar != undefined) {
      this.getPedidosSuscribersinrepar.unsubscribe();
    }
  }

  //función para desuscribir de observables
  async desuscribeObser1() {
    if (this.getPedidosSuscriber != undefined) {
      this.getPedidosSuscriber.unsubscribe();
    }
    if (this.getPedidosSuscribertotal != undefined) {
      this.getPedidosSuscribertotal.unsubscribe();
    }
    if (this.getPedidosSuscriberrepar != undefined) {
      this.getPedidosSuscriberrepar.unsubscribe();
    }
    if (this.getPedidosSuscribersinrepar != undefined) {
      this.getPedidosSuscribersinrepar.unsubscribe();
    }
  }

  /*Este metodo se activa cuando me cambio de componenente, si el usuario decide navegar a otro compoenente antes
del cambio automatico se llama ngOnDestroy*/
  async ionViewDidLeave() {
    await this.desuscribeObser();
  }

  //esta funcion cierra la vista previa de un pedido
  cerrarVista() {
    this.verResumen = false;
  }

  //n esta funcion se ve un detalle de pedido
  verDetalles(pedido1: PedidoAsignado) {
    let dateConver: any;
    dateConver = pedido1.fechapagocomer;
    this.verResumen = true;
    this.verResumenpedido = pedido1;
    this.verResumenpedido.fechapagocomer = dateConver.toDate();

  }

  //en esta funcion se registra un pago por lotes, minimo 1 dia
  async registrarPago(nombrerepartidor: string) {

    //envio un mensaje diferente cuando se trata de una zona a cotizar
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Registro de Pago',
      backdropDismiss: false,
      message: "Por favor escriba información sobre la forma de pago que utilizó para el repartidor: <b>" + nombrerepartidor + "</b>, " +
        "se recomienda colocar información detallada para un mejor control, <b>Ejemplo: Forma de Pago, una referencia de pago, dias pagados y Total pagado </b>",
      inputs: [
        {
          name: 'referencia',
          type: 'text',
          id: 'referencia-id',
          placeholder: 'Escriba aqui la información detallada del pago'
        },
        {
          name: 'fechapago',
          type: 'datetime-local',
          id: 'fecha-id',
          placeholder: 'Fecha de Pago'
        }
      ],
      buttons: [
        {
          text: 'REGISTRAR',
          id: 'confirm-button',
          handler: async (data1) => {
            if (data1.fechapago != '' && data1.referencia != '') {
              //llamo la funcion de mensaje en pantalla
              await this.presentLoading();
              //creo la ruta para actualizar pago conciliados en pedido asignado
              const path1 = 'usuarios/' + this.uidReapartidor + '/pedidoasignado/';
              for await (let pedidoasing of this.getPedidosrepar2) {
                await this.firestore.updateDoc<PedidoAsignado>({ pagadocomercio: true, referenciapagocomer: String(data1.referencia), fechapagocomer: (new Date(data1.fechapago)) }, path1, pedidoasing.idPedido).then(async () => {
                  this.nombreReapartidor = '';
                  this.totalRepartidorusd = 0;
                  this.getPedidosrepar2 = [];
                }).catch(async () => {
                  //si hay un error al actualizar la zona del delivery
                  return await this.presentToast1('No fue posible registrar el pago al repartidor, Intente Nuevamente ó comuniquese con soporte de App')
                });
              }
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover1 = await this.loadingController.getTop();
              if (popover1) {
                await popover1.dismiss(null);
                if (!this.getPedidosrepar2.length) {
                  await this.presentToast('Registro de pago exitoso...');
                }
              } else {
                if (!this.getPedidosrepar2.length) {
                  await this.presentToast('Registro de pago exitoso...');
                }
              }
            } else if (data1.fechapago == '' || data1.referencia == '') {
              await this.presentToast1('Debe escribir detalles del pago y fecha de pago (ambos)')
              return false;
            }
          }
        }, {
          text: 'CANCELAR',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();

  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 2000,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra mensaje cuando se envia un mensaje de advertencia
  async presentToast1(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast2.present();
  }


  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      //duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }

  //Funcion que maneja cambios relacionados a la fecha inicial de busqueda
  async dateChanged(value: any) {
    await this.desuscribeObser1();
    this.dateValue = value;
    this.dateValue1 = value;
    this.showPicker = false;
    let aaa = new Date(value);
    aaa.setHours(0, 0, 0, 0);
    let bbb = aaa.setMonth(aaa.getMonth() + 1);
    this.dateValue2 = (new Date(bbb)).toISOString();
    this.verResumenpedido = undefined;
    this.totalRepartidorusd = 0;
    this.nombreReapartidor = '';
    this.verResumen = false;
    this.getPedidosrepar2 = [];
    this.verTitulo = 0;
    this.verRegistrapago = false;
  }

  //Funcion que maneja cambios relacionados a la fecha final de busqueda
  async dateChanged1(valuee: any) {
    await this.desuscribeObser1();
    this.dateValue1 = valuee;
    this.showPicker1 = false;
    this.verResumenpedido = undefined;
    this.totalRepartidorusd = 0;
    this.nombreReapartidor = '';
    this.verResumen = false;
    this.getPedidosrepar2 = [];
    this.verTitulo = 0;
    this.verRegistrapago = false;
  }

  //funcion que me trae los repartidores
  getRepartidores() {
    this.getPedidosrepar2 = [];
    this.totalRepartidorusd = 0;
    this.nombreReapartidor = '';
    this.busquedaRepartidor = true;
    if (!this.repartidores.length) {
      //creo la ruta para obtener los datos del comercio
      const path = 'usuarios/' + this.dataUserLog.uid + '/repartidorafiliado';
      //consulto repartidores afiliados al comercio
      this.getrepartidoresSuscriber = this.firestore.getCollection<RepartidorAfiliado>(path).subscribe(res => {
        if (res) {
          this.repartidores = res;
        }
      });
    }
  }

  //funcion que busca el repartidor de un listado por nombre o email
  searchRepartidor(event) {
    //me desuscribo del observable de repartidor
    if (this.getrepartidoresSuscriber != undefined) {
      this.getrepartidoresSuscriber.unsubscribe();
    }
    this.repartidores2 = this.repartidores.filter((repartidor: RepartidorAfiliado) => {
      return ((repartidor.nombre.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) ||
        (repartidor.email.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    });
  }

  //consultar pedidos por repartidor
  async getPedidosrepartidor(repartidor: RepartidorAfiliado) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.getPedidosrepar2 = [];
    this.totalRepartidorusd = 0;
    this.busquedaRepartidor = false;
    this.verVentas = false;
    this.verTitulo = 1;
    const pathh = 'usuarios/' + this.dataUserLog.uid + '/pedido/';
    let fechaInicial = new Date(this.dateValue);
    let fechaFinal = new Date(this.dateValue1);
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.getPedidosSuscriber = this.firestore.getCollection3<Pedido>(pathh, ref => (ref.where('fechaPedido', '>=', fechaInicial).where('fechaPedido', '<=', fechaFinal).where('estado', '==', 'ENTREGADO').where('uidRepartidor', '==', repartidor.uid).where('cobrodelivComercio', '==', true))).subscribe(async pedidos => {
      if (pedidos.length) {
        this.getPedidosrepar = pedidos;
        this.nombreReapartidor = repartidor.nombre + ', ' + repartidor.email;
        this.uidReapartidor = repartidor.uid;
        //me desuscribo del observable de pedidos por repartidor
        ///this.getPedidosSuscriber.unsubscribe();
        //limpio array definitivo de repartidores con pagos no conciliados por ellos
        this.getPedidosrepar2 = [];
        for await (let totales of this.getPedidosrepar) {
          let patth = 'usuarios/' + totales.uidRepartidor + '/pedidoasignado/';
          await this.firestore.getDoc2<PedidoAsignado>(patth, totales.id).then(async pedidoAsing => {
            let valor2: PedidoAsignado[] = [];
            valor2.push(await pedidoAsing.data());
            if (valor2[0].estatus == 'ENTREGADO' && valor2[0].pagadocliente == false) {
              //si se detecta algun registro del dia como pagado se inhabilita el boton de registra pago
              if (valor2[0].pagadocomercio) {
                this.verRegistrapago = false;
              }
              //convertimos la fecha para que sea legible en la impresion html
              let fechaleg = pedidoAsing.data().fechaEntrega;
              valor2[0].fechaEntrega = fechaleg.toDate();
              let concate: any[] = [];
              concate = (valor2.map(obj => (Object.assign(obj, { cobrodelivComercio: totales.cobrodelivComercio }))));
              this.getPedidosrepar2.push(concate[0])
            }
          });
          if (this.terminaSpin != undefined) {
            clearTimeout(this.terminaSpin);
          }
        }
        //al culminar el ciclo for await procedo a reducir duplicados(si los hay) por el id de pedido y enviarlo para la siguienter evaluacion
        this.getPedidosrepar2 = Array.from(this.getPedidosrepar2.reduce((map, obj) => map.set(obj.idPedido, obj), new Map()).values());
        //ordenamos el array por fecha de entrega
        this.getPedidosrepar2.sort(function compare(a, b) {
          return new Date(a.fechaEntrega).getTime() - new Date(b.fechaEntrega).getTime()
        });
        //en este for totalizo los pedidos totales no pagados
        this.totalRepartidorusd = 0;
        for await (let totales2 of this.getPedidosrepar2) {
          this.totalRepartidorusd = this.totalRepartidorusd + totales2.precio;
          if (this.terminaSpin != undefined) {
            clearTimeout(this.terminaSpin);
          }
        }
        this.terminaSpin = undefined;
        this.terminaSpin = setTimeout(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover8 = await this.loadingController.getTop();
          if (popover8 && this.terminaSpin != undefined) {
            await popover8.dismiss(null);
          }
        }, 2000);
      } else if (!pedidos.length && this.getPedidosSuscriber != undefined) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover9 = await this.loadingController.getTop();
        if (popover9) {
          await popover9.dismiss(null);
        }
        return await this.presentToast1('No se econtraron pedidos para el repartidor seleccionado')
      }
    })
  }


  //consultar pedidos pendiente por pagar
  async getPedidosrepartidor2() {
    this.verRegistrapago = true;
    let filtrado: PedidoAsignado[] = [];
    for await (let filtros of this.getPedidosrepar2) {
      if (filtros.pagadocomercio == false) {
        //convertimos la fecha para que sea legible en la impresion html
        filtrado.push(filtros)
      }
    }
    if (!filtrado.length) {
      this.verRegistrapago = false;
      return await this.presentToast1('No se encontraron pagos pendientes para este repartidor en el periodo consultado')
    }
    //en este for totalizo los pedidos totales no pagados
    this.totalRepartidorusd = 0;
    for await (let totales3 of filtrado) {
      this.totalRepartidorusd = this.totalRepartidorusd + totales3.precio;
    }
    //le paso el nuevo valor con el filtro al array final
    this.getPedidosrepar2 = filtrado;
  }


  //en esta función se guardan ñas ventas totales del periodo establecido
  async getVentastotales() {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.getPedidosrepar2 = [];
    this.totalRepartidorusd = 0;
    this.busquedaRepartidor = false;
    this.verVentas = true;
    this.verTitulo = 4;
    const pathh = 'usuarios/' + this.dataUserLog.uid + '/pedido/';
    let fechaInicial = new Date(this.dateValue);
    let fechaFinal = new Date(this.dateValue1);
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.getPedidosSuscribertotal = this.firestore.getCollection3<Pedido>(pathh, ref => (ref.where('fechaPedido', '>=', fechaInicial).where('fechaPedido', '<=', fechaFinal).where('estado', '==', 'ENTREGADO'))).subscribe(async pedidos => {
      if (pedidos.length) {
        this.getPedidosrepar = pedidos;
        //me desuscribo del observable
        //this.getPedidosSuscribertotal.unsubscribe(); 
        //limpio array definitivo de repartidores con pagos no conciliados por ellos
        this.getPedidosrepar2 = [];
        //al culminar el ciclo for await procedo a reducir duplicados(si los hay) por el id de pedido y enviarlo para la siguienter evaluacion
        this.getPedidosrepar2 = Array.from(this.getPedidosrepar.reduce((map, obj) => map.set(obj.id, obj), new Map()).values());
        //en este for totalizo los pedidos totales no pagados
        this.totalRepartidorusd = 0;
        for await (let totales2 of this.getPedidosrepar2) {
          let fecha1 = totales2.fechaEntrega.toDate();
          let fecha2 = totales2.fechaPedido.toDate();
          totales2.fechaEntrega = fecha1;
          totales2.fechaPedido = fecha2;
          this.totalRepartidorusd = this.totalRepartidorusd + totales2.precioTotal;
          if (this.terminaSpin != undefined) {
            clearTimeout(this.terminaSpin);
          }
        }
        //ordenamos el array por fecha de entrega
        this.getPedidosrepar2.sort(function compare(a, b) {
          return new Date(a.fechaPedido).getTime() - new Date(b.fechaPedido).getTime()
        });
        this.terminaSpin = undefined;
        this.terminaSpin = setTimeout(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover = await this.loadingController.getTop();
          if (popover && this.terminaSpin != undefined) {
            await popover.dismiss(null);
          }
        }, 2000);
      } else if (!pedidos.length) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }
        return await this.presentToast1('No se econtraron pedidos para el periodo seleccionado')
      }
    })
  }

  //en esta función se guardan las ventas totales del periodo establecido con delivery
  async getVentastotalesrepar() {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.getPedidosrepar2 = [];
    this.totalRepartidorusd = 0;
    this.busquedaRepartidor = false;
    this.verVentas = true;
    this.verTitulo = 2;
    const pathh = 'usuarios/' + this.dataUserLog.uid + '/pedido/';
    let fechaInicial = new Date(this.dateValue);
    let fechaFinal = new Date(this.dateValue1);
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.getPedidosSuscriberrepar = this.firestore.getCollection3<Pedido>(pathh, ref => (ref.where('fechaPedido', '>=', fechaInicial).where('fechaPedido', '<=', fechaFinal).where('estado', '==', 'ENTREGADO').where('delivery', '==', true))).subscribe(async pedidos => {
      if (pedidos.length) {
        this.getPedidosrepar = pedidos;
        //me desuscribo del observable
        ///this.getPedidosSuscriberrepar.unsubscribe();
        //limpio array definitivo de repartidores con pagos no conciliados por ellos
        this.getPedidosrepar2 = [];
        //al culminar el ciclo for await procedo a reducir duplicados(si los hay) por el id de pedido y enviarlo para la siguienter evaluacion
        this.getPedidosrepar2 = Array.from(this.getPedidosrepar.reduce((map, obj) => map.set(obj.id, obj), new Map()).values());
        //en este for totalizo los pedidos totales no pagados
        this.totalRepartidorusd = 0;
        for await (let totales2 of this.getPedidosrepar2) {
          let fecha1 = totales2.fechaEntrega.toDate();
          let fecha2 = totales2.fechaPedido.toDate();
          totales2.fechaEntrega = fecha1;
          totales2.fechaPedido = fecha2;
          this.totalRepartidorusd = this.totalRepartidorusd + totales2.precioTotal;
          if (this.terminaSpin != undefined) {
            clearTimeout(this.terminaSpin);
          }
        }
        //ordenamos el array por fecha de entrega
        this.getPedidosrepar2.sort(function compare(a, b) {
          return new Date(a.fechaPedido).getTime() - new Date(b.fechaPedido).getTime()
        });
        this.terminaSpin = undefined;
        this.terminaSpin = setTimeout(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover3 = await this.loadingController.getTop();
          if (popover3 && this.terminaSpin != undefined) {
            await popover3.dismiss(null);
          }
        }, 2000);
      } else if (!pedidos.length) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover4 = await this.loadingController.getTop();
        if (popover4) {
          await popover4.dismiss(null);
        }
        return await this.presentToast1('No se econtraron pedidos para el periodo seleccionado')
      }
    })
  }

  //en esta función se guardan las ventas totales del periodo establecido sin delivery
  async getVentastotalessinrepar() {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.getPedidosrepar2 = [];
    this.totalRepartidorusd = 0;
    this.busquedaRepartidor = false;
    this.verVentas = true;
    this.verTitulo = 3;
    const pathh = 'usuarios/' + this.dataUserLog.uid + '/pedido/';
    let fechaInicial = new Date(this.dateValue);
    let fechaFinal = new Date(this.dateValue1);
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.getPedidosSuscribersinrepar = this.firestore.getCollection3<Pedido>(pathh, ref => (ref.where('fechaPedido', '>=', fechaInicial).where('fechaPedido', '<=', fechaFinal).where('estado', '==', 'ENTREGADO').where('delivery', '==', false))).subscribe(async pedidos => {
      if (pedidos.length) {
        this.getPedidosrepar = pedidos;
        //me desuscribo de observable
        ///this.getPedidosSuscribersinrepar.unsubscribe();
        //limpio array definitivo de repartidores con pagos no conciliados por ellos
        this.getPedidosrepar2 = [];
        //al culminar el ciclo for await procedo a reducir duplicados(si los hay) por el id de pedido y enviarlo para la siguienter evaluacion
        this.getPedidosrepar2 = Array.from(this.getPedidosrepar.reduce((map, obj) => map.set(obj.id, obj), new Map()).values());
        //en este for totalizo los pedidos totales no pagados
        this.totalRepartidorusd = 0;
        for await (let totales2 of this.getPedidosrepar2) {
          let fecha1 = totales2.fechaEntrega.toDate();
          let fecha2 = totales2.fechaPedido.toDate();
          totales2.fechaEntrega = fecha1;
          totales2.fechaPedido = fecha2;
          this.totalRepartidorusd = this.totalRepartidorusd + totales2.precioTotal;
          if (this.terminaSpin != undefined) {
            clearTimeout(this.terminaSpin);
          }
        }
        //ordenamos el array por fecha de entrega
        this.getPedidosrepar2.sort(function compare(a, b) {
          return new Date(a.fechaPedido).getTime() - new Date(b.fechaPedido).getTime()
        });
        this.terminaSpin = undefined;
        this.terminaSpin = setTimeout(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover5 = await this.loadingController.getTop();
          if (popover5 && this.terminaSpin != undefined) {
            await popover5.dismiss(null);
          }
        }, 2000);
      } else if (!pedidos.length) {
        await this.presentToast1('No se econtraron pedidos para el periodo seleccionado')
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover6 = await this.loadingController.getTop();
        if (popover6) {
          await popover6.dismiss(null);
        }
      }
    })
  }


  //esta funcion se mira con detalle el pedido
  async verPedido(idPedido: string) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.verDetallepedido = true;
    const path2 = 'usuarios/' + this.dataUserLog.uid + '/pedido/';
    await this.firestore.getDoc2<Pedido>(path2, idPedido).then(async pedidoGet => {
      let fecha1 = pedidoGet.data().fechaEntrega.toDate();
      let fecha2 = pedidoGet.data().fechaPedido.toDate();
      this.cargaPedido1 = pedidoGet.data();
      this.cargaPedido1.fechaEntrega = fecha1;
      this.cargaPedido1.fechaPedido = fecha2;
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover1 = await this.loadingController.getTop();
      if (popover1) {
        await popover1.dismiss(null);
      }
    })
  }


  //cierra la vista de resumen de pedido
  cerrarVista2() {
    this.verDetallepedido = false;
  }

}
