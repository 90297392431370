import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Pedido, PedidoEntregado, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-mipedido',
  templateUrl: './mipedido.component.html',
  styleUrls: ['./mipedido.component.scss'],
})
export class MipedidoComponent implements OnInit, OnDestroy {
  showPicker = false;
  datValue = new Date();
  hoy = new Date(this.datValue.setHours(0, 0, 0, 0)).toISOString();
  minimaBusqueda: any;
  dateValue2: any;

  //declaracion de Observables para aplicar la desuscripcion
  private getdat2Suscriber: Subscription = undefined;
  private mipedidoSuscriber: Subscription = undefined;

  //variable que activa ver en pantalla completa los detalles del pedido
  public verDetallepedido = false;

  //creo una variable que me va a almacenar la data del usuario logueado
  public dataUserLog1: User = undefined;
  private getpedCliente: PedidoEntregado[] = [];
  public getpedCliente2: PedidoEntregado[] = [];
  public cargaPed: Pedido = undefined;


  constructor(private traeUid: UserlogedService,
    private firestore: FirestoreService,
    private loadingController: LoadingController,
    private toastController: ToastController) {
    //se recibe en el constructor la data del user por medio de Subject
    this.getdat2Suscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.dataUserLog1 = res;
      }
    })
  }

  ngOnInit() {
    //establezco la fecha minima de 1 mes atras para busquedas de historial de pedidos
    let vvv = new Date();
    let vv1 = vvv.setMonth(vvv.getMonth() - 1);
    this.minimaBusqueda = (new Date(vv1)).toISOString();
  }

  /*Este metodo se activa cuando me cambio de componenente, si el usuario decide navegar a otro compoenente antes
  del cambio automatico se llma ngOnDestroy*/
  async ionViewDidLeave() {
    await this.ngOnDestroy();
  }

  async ngOnDestroy() {
    if (this.getdat2Suscriber != undefined) {
      this.getdat2Suscriber.unsubscribe();
    }
    if (this.mipedidoSuscriber != undefined) {
      this.mipedidoSuscriber.unsubscribe();
    }
  }


  //Funcion que maneja cambios relacionados a la fecha inicial de busqueda
  async dateChanged(value: any) {
    this.datValue = value;
    this.showPicker = false;
    let aaa = new Date(value);
    aaa.setHours(0, 0, 0, 0);
    this.getpedCliente2 = [];
  }

  //funcion que llama los pedidos del usuario logueado
  async getPedidos() {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    const pathh = 'usuarios/' + this.dataUserLog1.uid + '/mipedido/';
    let fechaInicial = new Date(this.datValue);
    let fechaFinal = new Date(this.datValue);
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.getpedCliente = [];
    this.mipedidoSuscriber = this.firestore.getCollection3<PedidoEntregado>(pathh, ref => (ref.where('fechaPedido', '>=', fechaInicial).where('fechaPedido', '<=', fechaFinal).where('estado', '==', 'ENTREGADO'))).subscribe(async pedidos => {
      if (pedidos.length && pedidos) {
        this.getpedCliente = pedidos;
        if (this.getpedCliente.length) {
          //me desuscribo de la coelccion de pedidos
          //this.mipedidoSuscriber.unsubscribe();
          this.getpedCliente2 = [];
          for await (let ped of this.getpedCliente) {
            //convertimos la fecha para que sea legible en la impresion html
            let valor = [];
            valor[0] = ped;
            let fechaleg: any;
            fechaleg = ped.fechaPedido;
            valor[0].fechaPedido = fechaleg.toDate();
            this.getpedCliente2.push(valor[0]);
          }
          //al culminar el ciclo for await procedo a reducir duplicados(si los hay) por el id de pedido y enviarlo para la siguienter evaluacion
          this.getpedCliente2 = Array.from(this.getpedCliente2.reduce((map, obj) => map.set(obj.id, obj), new Map()).values());
          //ordenamos el array por fecha de Pedido
          this.getpedCliente2.sort(function compare(a, b) {
            return new Date(a.fechaPedido).getTime() - new Date(b.fechaPedido).getTime()
          });
          const popover = await this.loadingController.getTop();
          if (popover) {
            await popover.dismiss(null);
          }
        }
        /*
        else if(!this.getpedCliente.length){
          this.getpedCliente2=[]
            const popover1 = await this.loadingController.getTop();
            if (popover1){
              await popover1.dismiss(null); 
            }  
          return await this.presentToast1('No se econtraron pedidos concretados en el dia seleccionado')
        }
        */
      } else if (!pedidos.length && this.mipedidoSuscriber != undefined) {
        this.getpedCliente2 = []
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }
        return await this.presentToast1('No se econtraron pedidos concretados en el dia seleccionado')
      }

    })

  }

  //esta funcion se mira con detalle el pedido
  async verPedido(Ped1: PedidoEntregado) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.verDetallepedido = true;
    const path2 = 'usuarios/' + Ped1.uidCom + '/pedido/';
    await this.firestore.getDoc2<Pedido>(path2, Ped1.id).then(async pedidoGet => {
      let fecha1 = pedidoGet.data().fechaEntrega.toDate();
      let fecha2 = pedidoGet.data().fechaPedido.toDate();
      this.cargaPed = pedidoGet.data();
      this.cargaPed.fechaEntrega = fecha1;
      this.cargaPed.fechaPedido = fecha2;
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover1 = await this.loadingController.getTop();
      if (popover1) {
        await popover1.dismiss(null);
      }
    })
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      //duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje cuando se envia un mensaje de advertencia
  async presentToast1(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast2.present();
  }

  //esta funcion cierra la vista previa de un pedido
  cerrarVista() {
    this.verDetallepedido = false;
  }
}
