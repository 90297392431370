// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyChiMs7oWIBQm6j-_QH9IjBx4Vih49SsvM',
    authDomain: 'menumovilapp.firebaseapp.com',
    projectId: 'menumovilapp',
    storageBucket: 'menumovilapp.appspot.com',
    messagingSenderId: '533034778667',
    appId: '1:533034778667:web:f4d54f6298abbab75d2a6e',
    measurementId: 'G-142HJ09EPR'
  },
  //Apis de google maps, la 2 es para andrid, la 1 es para la web
  mapsConfig: {
    apikey1: 'AIzaSyBhmhPymjpb_Pupjv7u07mkI9HT32j18UU',
    apikey2: 'AIzaSyCyBMXlgTwUUa4RbfAZIxmTtmyaBbyVR1g',
    apikey3: 'AIzaSyDLd2z-EDBC0KYy2HpoSGifCNr_EoXp_Zg',
  },
  //clave de sitio para recachat 3
  sitekeyv3: '6LdTrZQiAAAAAEdZjHlVTQAl1QyeDVKMG_YUg_jn'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
