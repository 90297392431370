import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Pedido, preComercio, ProductoPedido, Productos, User } from '../models/users.model';
import { FirestoreService } from './firestore.service';
import { UserlogedService } from './userloged.service';

@Injectable({
  providedIn: 'root'
})
export class CarritoService implements OnDestroy {

  //este decorador se inyecta para enviar los datos desde este servicio a donde se necesite
  pasaData: EventEmitter<any> = new EventEmitter();
  //este decorador se inyecta  para enviar los datos desde el servicio repartidores, el false no lo hace asyncrono
  pasaData2: EventEmitter<any> = new EventEmitter();
  //se crea este servicio pasadata solo para ser usado en el pedido final
  pasaDatapedidofinal: EventEmitter<any> = new EventEmitter();
  //se crea este servicio pasadata solo para ser usado en Pedido al asiganr delivery
  pasaDatapedidofinal2: EventEmitter<any> = new EventEmitter();

  //declaro la variable pedido como privada
  pedido!: Pedido;

  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLog:any;
  //creo un path para guardar la coleccion de carrito
  path='carrito/';
  //creo una variable para usuario logueado
  clienteLog!:User;
  //declaracion de Observables para aplicar la desuscripcion
  private getClienteLogSuscriber:Subscription=undefined;
  private dataSuscriber:Subscription=undefined;
 
 
 
   constructor(private traeUid:UserlogedService,
               private firestore:FirestoreService) { 
 
                  //se recibe en el constructor el uid capturado en el componente principal
                  this.dataSuscriber=this.traeUid.getUserlog().subscribe(res=>{
                   if(res){
                     this.uidUserLog=res.uid;
                    //cargo datos del usuario logeado
                    this.loadCliente(this.uidUserLog);
                    //me desuscribo del observable de usuario
                    if(this.dataSuscriber!=undefined){
                      this.dataSuscriber.unsubscribe();
                    }
                   }else{
                     this.uidUserLog=0;
                     //inicializo los valores de cliente a por defecto
                         this.clienteLog={
                           nombre: "",
                           apellido: "",
                           tipolocal: "",
                           token:"",
                           nombrelocal: "",
                           uid: "",
                           email: "",
                           password: "",
                           telefono: "",
                           direccionlocal: "",
                           plan:"",
                           ubicacionpri:{
                             lat:5.90,
                             lng:-65.28
                           },
                           ubicacionsec:{
                             lat:0,
                             lng:0
                           },
                           perfil: "",
                           urlfoto: "",
                           fechaRegistro: new Date,
                           fechaActRegistro: new Date,
                           estado:false,
                           estatus:'ACTIVO',
                           ocupado:false,
                           ubicacion:"",
                           municipio:"",
                           valoracion:100,
                           deliveryOn:false
                       }
 
                     //inicio el carrito con valores por defecto de usurio no logueado
                     this.initCarrito(this.uidUserLog)
                   }

                 });
 
      
   }

   ngOnDestroy(): void {
    if( this.getClienteLogSuscriber!=undefined){
      this.getClienteLogSuscriber.unsubscribe()
    }
    if( this.dataSuscriber!=undefined){
      this.dataSuscriber.unsubscribe()
    }
     
   }
 
 
    loadCarrito(uidUserLog:any){
      //se inicia carrito
      this.initCarrito(uidUserLog);
    }
 
 
   initCarrito(uidUserLog:any){
     //si hay usuario logueado el carrito se inicia con los siguientes datos
       this.pedido={
         id:"",
         cliente: this.clienteLog,
         productos: [],
         nombreComercio: "",
         uidComercio: "",
         uidRepartidor:"",
         telefonoComer:"",
         detallePedido:"",
         precioTotal: 0,
         estado: "ENVIADO",
         fechaPedido: new Date,
         fechaEntrega: new Date,
         valoracionRepar: 0,
         valoracionComer: 0,
         ubicacionDespacho:{
          lat:this.clienteLog.ubicacionpri.lat,
          lng:this.clienteLog.ubicacionpri.lng
         },
         telefono:this.clienteLog.telefono,
         formapago:'',
         referenciapago:'',
         delivery:false,
         zonadelivery:{
          idzona: "",
          precio: null,
          zona: "",
          habilitar:null,
          fechaPrecio:null,
          ubicacion:null
         },
         cobrodelivComercio:false,
         cobrodelivRepartidor:false
       }
   }
 
   //metodo para Obtener Datos de usuario Logeado
   loadCliente(uidUserLog:any){
           //obtengo el usuario logeado
           if(uidUserLog!=0){
               //creo la ruta para obtener los datos del usuario logeado
               const path='usuarios/';
               const id=uidUserLog;
               //En la respuesta de esta coleccion, obtengo usuario
               this.getClienteLogSuscriber=this.firestore.getDoc<User>(path,id).subscribe(res=>{
                if(res){
                 this.clienteLog=res;
                 this.clienteLog.telefono='0'+this.clienteLog.telefono.substr(3);
                }
                 //me desuscribo del observable
                 this.getClienteLogSuscriber.unsubscribe();

                 /***inicializo la funcion LoadCarrito que cargará los valores por defecto ya que al 27-05-2022
                 se eliminó la opcion de cargar carrito previo para disminuir el uso de firebase***/
                 this.loadCarrito(uidUserLog)
               });
           }else{
             //console.log("loadcliente: NO SE ENCONTRÓ UN USUARIO LOGUEADO");
           }
   }
 
 
   getCarrito(){
     return this.pedido;
   }
 
 
   addProducto(productoInput:Productos){
       const item = this.pedido.productos.find(productoPedido=>{
         return(productoPedido.producto.idProducto===productoInput.idProducto)
       });
       if(item!==undefined){
         item.cantidad++;
       }else{
         const add:ProductoPedido={
           producto: productoInput,
           cantidad: 1
         }
         this.pedido.productos.push(add);
       }
     
     //le asigno al pedido los datos del comercio
    // this.pedido.nombreComercio=comercio.nombrelocal;
    // this.pedido.uidComercio=comercio.uid;
    // this.pedido.telefonoComer=comercio.telefono;
 
      //se emite la data del calculo 
      this.pasaData.emit({
        data:this.pedido
      })
   
   }
 
   removeProducto(productoInput:Productos){ 
       //creo esta variable que me va a servir para limpiar el array cuando rebajemos articulos
       let position=0; 
       const item = this.pedido.productos.find((productoPedido,index)=>{
         position=index;
         return(productoPedido.producto.idProducto===productoInput.idProducto)
       });
       if(item!==undefined){
         item.cantidad--;
         //creo este if con la funcion splice para eliminar el producto del arreglo cuando ea igual a 0
         if(item?.cantidad===0){
           this.pedido.productos.splice(position,1)
         }
       }
        //se emite la data del calculo 
        this.pasaData.emit({
        data:this.pedido
      })
   }
 

    //codigo para realizar el pedido y agregarlo inicialmente solo al pedido del comercio
    async realizarPedido(pedidoConfirmado:Pedido){
      const data=pedidoConfirmado;
      const rutapedido='usuarios/'+pedidoConfirmado.uidComercio+'/pedido/';
      //creo una ruta donde se almacenara una ko*ia del pedido
      const rutapedido2='orden/';
      /* se aplica recalculo de seguridad: antes de guardar el pedido, verifico que para el momento de enviar el pedido el producto esté disponible, que el precio del producto del pedido sea 
      mismo al momento de enviarlo y que el total del pedido coincida con el calculo interno*/
      const pathcheck1='usuarios/'+data.uidComercio+'/productos/';
      const prodPedido=data.productos;
      let productosAsing:Productos[]=[];
      for await (let add1 of prodPedido) {
        await this.firestore.getCollection5<Productos>(pathcheck1,ref=>(ref).where('precio','==',add1.producto.precio).where('disponible','==',true).where('idProducto','==',add1.producto.idProducto)).then(async prodAsing=>{
          await prodAsing.forEach(async (doc:any) => {
          let valor2:any[]=[];
          valor2[0] = doc.data();
          productosAsing.push(valor2[0]);
          //le aplico reduccion de duplicados
          productosAsing=Array.from(productosAsing.reduce((map,obj)=>map.set(obj.idProducto,obj), new Map()).values());
          });
        }); 
      }
      //recalculo totales (en primer lugar ambos array deben tener los mismos registros, en caso contrario algun producto ya no esta disponible o cambio de precio)
      if (prodPedido.length==productosAsing.length) {
        //recorro el array del pedido y multiplico la cantidad por el precio de producto
          let totalPed=0;
          for await (let add2 of productosAsing) {
            for await(let add3 of prodPedido) {
              if(add2.idProducto==add3.producto.idProducto){
                totalPed=totalPed+(add3.cantidad*add2.precio)
              }
            }
          }
          //solo si ambos valores coinciden todo va bien en los totales
          if (data.precioTotal.toFixed(2)==totalPed.toFixed(2)) {
                //con este id asigno un id para un nuevo pedido 
                if(data.id==""){
                  data.id=this.firestore.getId();
                }        
              //creo el pedido en el comercio
               await this.firestore.createDoc2(data,rutapedido,data.id).then(async ()=>{
                ///aqui se almacenara una ko*ia del pedido (se eliminio al 20-10 para hacer el respaldo desde functions)
                ///await this.firestore.createDoc2(data,rutapedido2,data.id).then(()=>{
                       //se pasa la data que fue guardada
                       return this.pasaDatapedidofinal.emit({
                       data:data
                       })
                      ///}).catch(()=>{
                      ///   return this.pasaDatapedidofinal.emit({
                       ///    data:null
                        /// });
                      ///});
                }).catch(()=>{
                  return this.pasaDatapedidofinal.emit({
                    data:null
                  });
                });
            } else if (data.precioTotal!=totalPed) {
              //retrono valor de 'dif' diferido ya que se encontró inconsistencia en la información
              return this.pasaDatapedidofinal.emit({
                data:'dif'
              });
            }
      } else if (prodPedido.length!=productosAsing.length) {
       //retrono valor de 'dif' diferido ya que se encontró inconsistencia en la información
        return this.pasaDatapedidofinal.emit({
          data:'dif'
        });
      }
             
    }
 

  //función para limpiar carrito
   clearCarrito(){
      this.pedido={
       id:"",
       cliente:{
               nombre: "",
               apellido: "",
               tipolocal: "",
               token:"",
               nombrelocal: "",
               uid: "",
               email: "",
               password: "",
               telefono: "",
               direccionlocal: "",
               plan: "",
               ubicacionpri:{
                 lat:5.90,
                 lng:-65.28
               },
               ubicacionsec:{
                 lat:0,
                 lng:0
               },
               perfil: "",
               urlfoto: "",
               fechaRegistro: new Date,
               fechaActRegistro: new Date,
               estado:false,
               estatus:'ACTIVO',
               ocupado:false,
               ubicacion:"",
               municipio:"",
               valoracion:100,
               deliveryOn:false
           },
       productos: [],
       nombreComercio: "",
       uidComercio: "",
       uidRepartidor:"",
       telefonoComer:"",
       detallePedido:"",
       precioTotal: 0,
       estado: "ENVIADO",
       fechaPedido: new Date,
       fechaEntrega: new Date,
       valoracionRepar: 0,
       valoracionComer: 0,
       ubicacionDespacho:{
         lat:5.90,
         lng:-65.28
       },
       telefono:"",
       formapago:"",
       referenciapago:"",
       delivery:false,
       zonadelivery:{
        idzona: "",
        precio: null,
        zona: "",
        habilitar:null,
        fechaPrecio:null,
        ubicacion:null
       },
       cobrodelivComercio:false,
       cobrodelivRepartidor:false
     }
     
     //se manda el valor de this.pedido limpio
     return this.pedido;
 
   }

   
}
