import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ComercioAfiliado, RepartidorAfiliado, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-comerciosafi',
  templateUrl: './comerciosafi.component.html',
  styleUrls: ['./comerciosafi.component.scss'],
})
export class ComerciosafiComponent implements OnInit, OnDestroy {

  //declaracion de Observables para aplicar la desuscripcion
  private getcomerciosSuscriber: Subscription;
  private dataSuscriber: Subscription;

  //creo una variable que me va a almacenar el uid del usuario logeado
  public dataUserLog: User;

  public comerciosAfiget: ComercioAfiliado[] = [];
  public comerciosAfiget2: ComercioAfiliado[] = [];

  constructor(private traeUid: UserlogedService,
    private firestore: FirestoreService,
    private alertController: AlertController,
    private toastController: ToastController) {
    //se recibe en el constructor la data del usuario registrado por medio de Subject
    this.dataSuscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.dataUserLog = res;
        //cargo datos de categorias y productos asociados al usuario
        this.getComercios();
      } else if (!res || res == null) {
        this.dataUserLog = null;
      }
    })
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.getcomerciosSuscriber != undefined) {
      this.getcomerciosSuscriber.unsubscribe();
    }
    if (this.dataSuscriber != undefined) {
      this.dataSuscriber.unsubscribe();
    }
  }

  //obtengo las solicitudes de comercios afiliados
  getComercios() {
    //creo la ruta para obtener los productos del usuario logeado
    const path = 'usuarios/' + this.dataUserLog.uid + '/comercioafiliado/';
    this.getcomerciosSuscriber = this.firestore.getCollection<ComercioAfiliado>(path).subscribe(res => {
      if (res) {
        this.comerciosAfiget = res;
        this.comerciosAfiget2 = res;
      }
    })
  }

  //filtro por nombre de Comercio
  searchComercio(event) {
    this.comerciosAfiget = this.comerciosAfiget2.filter((comercio: ComercioAfiliado) => {
      return ((comercio.nombre.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1))
    });
  }


  async cambiarEstatus(comercioget: ComercioAfiliado) {
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: '¿Confirma que desea <strong>APROBAR</strong> la Afiliación al Comercio:' + ' ' + comercioget.nombre + '?',
      buttons: [
        {
          text: 'MAS TARDE',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            if (comercioget.afiliacion == 'PENDIENTE') {
              const path2 = 'usuarios/' + this.dataUserLog.uid + '/comercioafiliado/';
              const path3 = 'usuarios/' + comercioget.uid + '/repartidorafiliado/';
              await this.firestore.updateDoc<ComercioAfiliado>({ afiliacion: 'APROBADA' }, path2, comercioget.uid).then(async () => {
                //actualizo la aprobacion al repartidor
                await this.firestore.updateDoc<RepartidorAfiliado>({ afiliacion: 'APROBADA' }, path3, this.dataUserLog.uid).then(async () => {
                  //muestro mensaje de guardado con exito
                  return await this.presentToast('Afiliación CONFIRMADA, el comercio ha sido notificado')
                }).catch(async () => {
                  //muestro mensaje de problemas durante la solicitud al repartidor
                  await this.presentToast('Ocurrio un problema de conexion al enviar la APROBACIÓN al comercio, intente nuevamente')
                });
              }).catch(async () => {
                //muestro mensaje de problemas en el guardado
                await this.presentToast('No se pudo APROBAR el comercio, intente nuevamente')
              });
            } else {
              //muestro mensaje de problemas en el guardado
              this.presentToast('La afiliación ya se encuentra APROBADA')
            }
          }
        }
      ]
    });
    await alert.present();
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'toast-background',
      duration: 2500,
      position: 'middle'
    });
    await toast.present();
  }

  //configuracion de la funcion que muestra mensaje en Linea con Comercio
  async presentToast1(mensaje: string, comercionombre: string) {
    const toast1 = await this.toastController.create({
      message: mensaje + ' con ' + comercionombre,
      color: 'success',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    await toast1.present();
  }

  //configuracion de la funcion que muestra mensaje fuera de Linea con Comercio
  async presentToast2(mensaje: string, nombrecomer: string) {
    const toast2 = await this.toastController.create({
      message: mensaje + ' para ' + nombrecomer,
      color: 'danger',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    await toast2.present();

  }
  //Función para poner en linea el repartidor con el comercio
  async activaRepartidor(comercio: ComercioAfiliado) {
    //si el comercio trae el valor habilitar en true
    if (comercio.habilitarrepartidor == false) {
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea estar <b>EN LINEA</b> para solicitudes de repartos del Comercio: <b>' + comercio.nombre + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.dataUserLog.uid + '/comercioafiliado/';
              const path3 = 'usuarios/' + comercio.uid + '/repartidorafiliado/';
              await this.firestore.updateDoc<ComercioAfiliado>({ habilitarrepartidor: true }, path2, comercio.uid).then(async () => {
                //cambio el valor en la coleccion del repartidor
                await this.firestore.updateDoc<RepartidorAfiliado>({ habilitarrepartidor: true }, path3, this.dataUserLog.uid).then(async () => {
                  //muestro mensaje de Estado en Linea al Repartidor
                  await this.presentToast1('EN LINEA ', comercio.nombre)
                }).catch(async () => {
                  //muestro mensaje de problemas durante la solicitud al repartidor
                  await this.presentToast('Ocurrio un problema de conexion al habilitar repartos con el comercio, intente nuevamente')
                  comercio.habilitarrepartidor = false;
                });
              }).catch(async () => {
                //muestro mensaje de problemas en el guardado
                await this.presentToast('No se pudo HABILITAR repartos con el comercio, intente nuevamente')
                comercio.habilitarrepartidor = false;
              });
            }
          }
        ]
      });
      await alert1.present();
    } else if (comercio.habilitarrepartidor == true) {

      const alert2 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea estar <b>FUERA DE LINEA</b> para solicitudes de repartos del comercio: <b>' + comercio.nombre + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.dataUserLog.uid + '/comercioafiliado/';
              const path3 = 'usuarios/' + comercio.uid + '/repartidorafiliado/';
              await this.firestore.updateDoc<ComercioAfiliado>({ habilitarrepartidor: false }, path2, comercio.uid).then(async () => {
                //cambio el status en la colecccion del raprtidor
                await this.firestore.updateDoc<RepartidorAfiliado>({ habilitarrepartidor: false }, path3, this.dataUserLog.uid).then(async () => {
                  //muestro mensaje de Estado en Linea al Repartidor
                  await this.presentToast2('FUERA DE LINEA ', comercio.nombre)
                }).catch(async () => {
                  //muestro mensaje de problemas durante la solicitud al repartidor
                  await this.presentToast('Ocurrio un problema de conexion al deshabilitar repartos con el comercio, intente nuevamente')
                  comercio.habilitarrepartidor = true;
                });
              }).catch(async () => {
                //muestro mensaje de problemas en el guardado
                await this.presentToast('No se pudo DESHABILITAR repartos con el comercio, intente nuevamente')
                comercio.habilitarrepartidor = true;
              });
            }
          }
        ]
      });
      await alert2.present();
    }
  }

}
