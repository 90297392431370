import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { Productos } from '../models/users.model';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class BuscarproductoService {

    //declaro variable tipo objeto que contendrá el valor Subject
    fotoproductos:any;
    fotoproductos$:any;
    productos:Productos[]=[];
    productos$:any;
  
    constructor(private firestore:FirestoreService,
                private firestore2: AngularFirestore) { 
      this.fotoproductos$ = new BehaviorSubject<any>(null);
      this.productos$= new BehaviorSubject<Productos>(null);
    }
  
    fotoProductos(path:string){
      this.firestore.getCollection<any>(path).subscribe(res => {
        if(res){
        this.fotoproductos=res;
        this.fotoproductos$.next(this.fotoproductos)
        }
      })
    }
    
    getFotoProductos(): Observable<any>{
     return this.fotoproductos$.asObservable()
    }
  
    productosCap(path:string){
      this.firestore.getCollection<Productos>(path).subscribe(res => {
        if(res){
        this.productos=res;
        this.productos$.next(this.productos)
        }
      })
    }
    
    getProductos(): Observable<any>{
     return this.productos$.asObservable()
    }

    //trae solo una foto del registro
    getFoto<tipo>(path:string,id:string): Promise<any>{
      const collection=this.firestore2.collection(path).doc<tipo>(id);
      return collection.get().toPromise();
    }
}
