import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from './models/users.model';
import { AuthService } from './services/auth.service';
import { NotificationsService2 } from './services/notifications2.service';
//import { NotificationsService } from './services/notifications.service';
import { UserlogedService } from './services/userloged.service';
//Web Intent
//import { IntentOptions, WebIntent } from '@ionic-native/web-intent/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  //declaro la variable que almacenará globalmente la data del usuario logueado y otra guardara el UID
  UserLog: any;

  //declaracion de Observables para aplicar la desuscripcion
  userRegistrado: User;
  private getdatauserSuscriber: Subscription = null;

  admin = false;

  //variable que carga el mensaje iniciando mientras se carga usuario
  loading: any;


  /* se llama desde el constructor principal el servico de notificaciones => private notificationsService: NotificationsService
  aunque pareciera que no se llama, si se esta ejecutando el servicio al cargar la app*/
  constructor(private notificationsService: NotificationsService2,
    private authService: AuthService,
    private router: Router,
    private traeUid: UserlogedService,
    private platform: Platform,
    private loadingController33: LoadingController,
    private alertController: AlertController, // agregado al 27-03-24 guarda relacion con alerta eliminacion de cuenta politi priv google
    //private webIntent: WebIntent
  ) {
    //Inicilización webIntent
    //this.getIntentData();

    //evaluando funcion de inicializacion para aplicaciones moviles Android
    this.initializeApp();
    /*llamo a la funcion tipo observable del usuario logeado(si lo hay) y me suscribo para obtener la data y
    la guardo para ser compartida entre componentes*/
    this.traeUid.getUserlog().subscribe(async res => {
      if (res) {
        await this.presentLoading2();
        this.UserLog = res;
        //En la respuesta de esta coleccion, obtengo usuario
        this.getdatauserSuscriber = this.traeUid.getDatauser().subscribe(async res => {
          if (res) {
            this.userRegistrado = res;
            if (this.loading != undefined) {
              await this.loading.dismiss();
            }
            //si el usuario es Repartidor se manda directo a la pestaña de repartos o pedido en proveedores
            if (res.perfil == 'REPARTIDOR' && res.estado == true) {
              await this.router.navigate(['/repartidor']);
            } else if (res.perfil == 'PROVEEDOR' && res.estado == true) {
              await this.router.navigate(['/pedido']);
            }
          }
        });
        if (this.loading != undefined) {
          await this.loading.dismiss();
        }
      } else {
        this.UserLog = null;
        this.userRegistrado = null;
        if (this.getdatauserSuscriber != null) {
          this.getdatauserSuscriber.unsubscribe();
        }
      }
      if (this.loading != undefined) {
        await this.loading.dismiss();
      }
    });


  }



  //funcion relacionada a las notificaciones android
  private async initializeApp() {
    await this.platform.ready();

    // arranco las notificaciones
    this.notificationsService.initPush();
  }



  /*
  //Funcion Web Intent
  getIntentData(){
    if (this.platform.is('android') && this.platform.is('capacitor')){
      const options: IntentOptions = {
        action: this.webIntent.ACTION_VIEW,
        url: 'http://www.entregamovil.com',
        type: 'application/vnd.android.package-archive'
      };
      
      this.webIntent.startActivity(options);
    }
  }
  */

  //configuracion de la funcion que muestra mensaje de espera mientras se descarga el Menú
  async presentLoading2() {
    //crea mensaje
    this.loading = await this.loadingController33.create({
      cssClass: 'normal',
      message: 'Iniciando...',
      duration: 8000
    });
    //presenta mensaje
    await this.loading.present();
  }

  async cerrarSesion() {
    if (this.platform.is('cordova')) {
      //GooglePlus.logout();
      await this.authService.logout().then(async () => {
        //navego al home
        await this.router.navigate(['/home']);
        //una vez que se cierra sesion mando a recargar pagina
        window.location.reload();
      });
    } else {
      await this.authService.logout().then(async () => {
        //navego al home
        await this.router.navigate(['/home']);
        //una vez que se cierra sesion mando a recargar pagina
        window.location.reload();
      });
    }
  }


  async perfilUsuario() {
    try {
      return await this.router.navigate(['/perfilusuario']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async miMenu() {
    try {
      return await this.router.navigate(['/mimenu']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Pedido() {
    try {
      return await this.router.navigate(['/pedido']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async historialPed() {
    try {
      return await this.router.navigate(['/pedidos']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Repartidores() {
    try {
      return await this.router.navigate(['/repartidores']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async formasPago() {
    try {
      return await this.router.navigate(['/formapago']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Repartidor() {
    try {
      await this.router.navigate(['/repartidor']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Comercioafiliado() {
    try {
      return await this.router.navigate(['/comercioafiliado']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Repartos() {
    try {
      return await this.router.navigate(['/repartos']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Mipedido() {
    try {
      return await this.router.navigate(['/mipedido']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async Registrar() {
    try {
      return await this.router.navigate(['/registrar']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async zonaEntrega() {
    try {
      return await this.router.navigate(['/zonas']);
    } catch (err) {
      return await this.router.navigate(['/home']);
    }
  }

  async irHome() {
    try {
      await this.router.navigate(['/home']);
      //una vez que se cierra sesion mando a recargar pagina
      ///window.location.reload();
    } catch (err) {
      await this.router.navigate(['/home']);
      //una vez que se cierra sesion mando a recargar pagina
      ///window.location.reload();
    }
  }

  async irPolitica() {
    try {
      await this.router.navigate(['/politicaprivacidad']);
    } catch (err) {
      await this.router.navigate(['/home']);
    }
  }

  async irPlan() {
    try {
      await this.router.navigate(['/afiliacion']);
    } catch (err) {
      await this.router.navigate(['/home']);
    }
  }

  //se agrego una opcion directa a usuario para eliminacion de su cuenta por politicas seguridad google 27-03-24
  async eliminarCuenta() {
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Eliminar cuenta y datos asociados',
      backdropDismiss: false,
      message: "¡Lamentamos que te vayas!, para eliminar definitivamente tu cuenta y datos asociados, por favor inicia sesión por última vez, luego navega a tu perfil de usuario y presiona el enlace con el texto <strong>Eliminar mi cuenta y datos asociados</strong>",
      buttons: [
        {
          text: 'CANCELAR',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => { }
        },
        {
          text: 'INICIAR SESIÓN',
          cssClass: 'normal',
          id: 'confirm-button',
          handler: async () => {
            try {
              return await this.router.navigate(['/ingresar']);
            } catch (err) {
              return await this.router.navigate(['/home']);
            }
          }
        },
      ]
    });
    await alert.present();
  }
}