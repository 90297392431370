import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { IngresarComponent } from './ingresar/ingresar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistroComponent } from './registro/registro.component';
import { PerfilusuarioComponent } from './perfilusuario/perfilusuario.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MenumovilComponent } from './menumovil/menumovil.component';
import { MipedidoComponent } from './mipedido/mipedido.component';
import { RepartidorComponent } from './repartidor/repartidor.component';
import { ComerciosafiComponent } from './comerciosafi/comerciosafi.component';
import { RepartosComponent } from './repartos/repartos.component';
import { RecaptchaModule } from "ng-recaptcha"
import { ProductomovilComponent } from './productomovil/productomovil.component';
import { PoliprivacidadComponent } from './poliprivacidad/poliprivacidad.component';
import { AfiliacionComponent } from './afiliacion/afiliacion.component';
import { RegComponent } from './reg/reg.component';




@NgModule({
  declarations: [
    //declaracion de modulos incluidos dentro de pages
    HomeComponent,
    NavbarComponent,
    IngresarComponent,
    RegistroComponent,
    PerfilusuarioComponent,
    MenumovilComponent,
    MipedidoComponent,
    ComerciosafiComponent,
    RepartidorComponent,
    RepartosComponent,
    ProductomovilComponent,
    PoliprivacidadComponent,
    AfiliacionComponent,
    RegComponent //impoprtacion de nuevo componenete de registro en pruebas 18/03/24

  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    GoogleMapsModule,
    RecaptchaModule
  ],
  exports: [
    NavbarComponent
  ]
})
export class PagesModule { }
