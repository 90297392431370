import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetpositionService {

  public repartidorLocation:{
    lat:number;
    lng:number;
    fecha:Date
  }

  //Creo la suscripcion al observable para capturar la ubicación del usuario
  public getLocate$ = new BehaviorSubject<any>(null);

  constructor( ) {}

  //convertimos esta funcion en una promesa para que reaccione solo cuando se obtengan datos de la posicion
  public async getRepartidorlocation(){
      await Geolocation.getCurrentPosition().then((res)=>{
        this.repartidorLocation={lat:res.coords.latitude,lng:res.coords.longitude,fecha:new Date()};
        //Agrego al Observavble el Valor Obtenido
        if(this.repartidorLocation.lat!=null || this.repartidorLocation.lat!=undefined){
          this.getLocate$.next(this.repartidorLocation)
        }
      }).catch(()=>{
        this.repartidorLocation=null;
          //Agrego al Observavble el Valor Obtenido
          this.getLocate$.next(this.repartidorLocation)
      });
  }


 public getLocaterepartidor(): Observable<any>{
    return this.getLocate$.asObservable();
   }

//manda ubicacion directa
 public getLocaterepartidor2(){
  return this.getLocate$.value;
 }

  
}
