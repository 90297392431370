import { Injectable } from '@angular/core';
import {
  Capacitor
} from '@capacitor/core';
//se importan todas estas dependencias para las notificaciones
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { UserlogedService } from './userloged.service';
import { FirestoreService } from './firestore.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService2 {

  //declaro la variable que almacenará globalmente la data del usuario logueado y otra guardara el UID
  private uidUserlog:string='';

  constructor(private router: Router,
              private traeUid:UserlogedService,
              private firestore:FirestoreService,) {
                    //llamo a la funcion tipo observable del usuario logeado(si lo hay) y me suscribo para obtener el id            
                    this.traeUid.getUserlog().subscribe(async res=>{
                    if(await res){
                      this.uidUserlog=res.uid;
                      this.initPush();
                    }
                  })
              }


  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }else{
      //return console.log('PushNotifications.requestPermission() -> no es movil');
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive==='granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
       // console.log('PushNotifications.requestPermission() -> Notificaciones Desactivdas');
      }
    });

    PushNotifications.addListener(
      'registration',
      async (token: Token) => {
          ///console.log('My token: ' + JSON.stringify(token));
          await this.guadarToken(token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
       /// console.log('Error: ' + JSON.stringify(error));
        console.log('error en el registro del token',error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        //console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        this.router.navigate([notification.notification.data.enlace]);
      }
    );
  }

  //guarda el token del usuario logueado
  async guadarToken(token: any) {
    const Uid =  this.uidUserlog
    if (Uid!='') {
      // console.log('guardar Token Firebase ->', Uid);
        const path = '/usuarios/';
        const userUpdate = {
          token: token,
        };
        await this.firestore.updateDoc(userUpdate, path, Uid).then(()=>
          console.log('Se guardo el token')
        );
        //console.log('guardar TokenFirebase()->', userUpdate, path, Uid);
    }
  }
}