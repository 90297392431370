import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { getStorage, ref, deleteObject } from "firebase/storage";

@Injectable({
  providedIn: 'root'
})
export class FirestorageService {

  //Inyecto al constructor el modulo firestore para poder utilizarlo
  constructor(public _fireStorage: AngularFireStorage) { }


  //se crea el servicio uploadImages para esperar la url del archivo y guardarla en la base de datos de productos 
  uploadImages(file: any, path: string, nombre: string): Promise<string> {

    return new Promise(resolve => {
      const dateUpdate = new Date();
      const filePath = path + '/' + nombre + dateUpdate;
      const ref = this._fireStorage.ref(filePath);
      const task = ref.put(file);

      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe(res => {
            const downloadURL = res;
            resolve(downloadURL);
            return;
          });
        })
      ).subscribe();
    });

  }

  //servicio para eliminar la foto del producto
  deleteFotoPro(path: string, nombre: string) {
    const filePath = path + '/' + nombre;
    const collection = this._fireStorage.ref(filePath);
    return collection.delete();
  }

  //servicio para eliminar la foto del usuario 08/04/2024
  deleteFotoUser(id: string) {
      const storage = getStorage();
      // Create a reference to the file to delete
      const desertRef = ref(storage, id);
      return deleteObject(desertRef);
  }

}
