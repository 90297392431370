import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ZonaDelivery } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-zonaentrega',
  templateUrl: './zonaentrega.component.html',
  styleUrls: ['./zonaentrega.component.scss'],
})
export class ZonaentregaComponent implements OnInit, OnDestroy {

  //declaracion de Observables para aplicar la desuscripcion
  private getZonasSuscriber: Subscription;
  private dataSuscriber: Subscription = undefined;
  private getlocalidadesSuscriber: Subscription = undefined;

  //creo una variable que me va a almacenar el uid del comercio logeado
  public uidUserLog: any;

  //Se declara el array zonasGet que almacenará las zonas del comercio
  public zonasGet: ZonaDelivery[] = [];
  public zonasGet2: ZonaDelivery[] = [];

  //declaro variable newZOna para ser usada en la interfaz, habilita zona del Registro
  public newZona = false;
  public actualizaPrecio = false;
  public estado: string = '';
  public estado1: string = '';
  public estados: any[] = [];
  public estados2: any[] = [];
  public municipio: string = '';
  public municipio1: string = '';
  public municipios: any[] = [];
  public parroquia: string = '';
  public parroquia1: string = '';
  public parroquias: any[] = [];
  public localidad: string = '';
  public localidades: any[] = [];

  public zonaAgregada: ZonaDelivery;

  //declaro variables que guardaran las rutas
  private idEstado: string = '';
  private idMunicipio: string = '';
  private idParroquia: string = '';

  //declaro una variable que me cargará el nombre del lugar para hacer delivery
  public traeLocalidad: string = '';

  //controla si existen localidades cargadas
  public traeLocalidad2: string = '';

  //aqui se van agregando las localidades para ser enviadas a la base de datos
  public traeLocalidad3: string[] = [];
  private add1: string = '';
  private add2: string = '';

  //declaracion relacionado al FormBuilder 
  declare form: FormGroup;

  //variable que almacena el valor de la ultima fecha de actualizacion de precio
  public fechaPrecio: any;
  public precioAnterior: any;

  //guarda la ubicacion de la parroquia seleccionada
  parroquiaUbica: any;

  //carga la direccion url del mapa en pantalla
  public fotoMapa: string;

  constructor(private traeUid: UserlogedService,
    private firestore: FirestoreService,
    private alertController: AlertController,
    private toastController: ToastController,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private storage: AngularFireStorage) {
    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.dataSuscriber = this.traeUid.getUserlog().subscribe(async res => {
      if (await res) {
        this.uidUserLog = await res.uid;
        //cargo datos de categorias y productos asociados al usuario
        this.getZonas(this.uidUserLog);
        //disparador del metodo relacionado al FormGroup 
        this.buildForm();
      } else {
        this.uidUserLog = 0;
      }
    })
  }


  ngOnInit() { }

  //se obtiene la foto del mapa guardada en el storage 23-02-23
  getMapa(parro: string) {
    this.fotoMapa = '';
    const filePath = 'mapas/' + parro + '.png';
    const ref = this.storage.ref(filePath);
    ref.getDownloadURL().toPromise().then(res => {
      this.fotoMapa = res;
    }).catch(() => {
      this.fotoMapa = '';
    })
  }

  //activo esta función para aplicar desuscripcion de observables
  ngOnDestroy() {
    if (this.dataSuscriber != undefined) {
      this.dataSuscriber.unsubscribe();
    }
    if (this.getZonasSuscriber != undefined) {
      this.getZonasSuscriber.unsubscribe();
    }
    if (this.getlocalidadesSuscriber != undefined) {
      this.getlocalidadesSuscriber.unsubscribe();
    }
  }

  //función para filtrar localidades
  searchZona(event) {
    this.zonasGet = this.zonasGet2.filter((zonas: ZonaDelivery) => {
      return ((zonas.zonaResumen.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    });
  }

  //función para eliminar localidades
  async deleteZona(zona: ZonaDelivery) {
    const alertt = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: '¿Confirma que desea <strong>Eliminar</strong> la Localidad:' + ' <b>' + zona.parroquia + '</b> y sus lugares asociados?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //llamo la funcion de mensaje en pantalla
            await this.presentLoading();
            if (this.uidUserLog !== 0) {
              const path = 'usuarios/' + this.uidUserLog + '/zonadelivery/';
              this.firestore.deletePro(path, zona.idzona).then(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover = await this.loadingController.getTop();
                if (popover) {
                  await popover.dismiss(null);
                }
                //muestro mensaje de guardado con exito
                return this.presentToastt('Localidad eliminada exitosamente')
              }).catch(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popover1 = await this.loadingController.getTop();
                if (popover1) {
                  await popover1.dismiss(null);
                }
                //muestro mensaje de problemas en el guardado
                return await this.presentToast('Ocurrio un problema de Conexion, No se pudo eliminar la localidad: ', zona.parroquia)
              })
            }
          }
        }
      ]
    });
    await alertt.present();

  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading2 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      //duration: 2000
    });
    //presenta mensaje
    await loading2.present();
  }

  async addLocalidad(data: any) {
    //Reseteamos el Objeto Zona Agregada
    let zonaAdd: ZonaDelivery = {
      habilitar: false,
      idzona: this.idParroquia,
      precio: 0,
      zonaResumen: this.traeLocalidad,
      zona: data,
      parroquia: this.parroquia,
      fechaPrecio: new Date(),
      ubicacion: this.parroquiaUbica
    }
    //reverifico si la parroquia asociada a estas localidades ya existe
    const path2 = 'usuarios/' + this.uidUserLog + '/zonadelivery/' + zonaAdd.idzona;
    const path3 = 'usuarios/' + this.uidUserLog + '/zonadelivery/';
    await this.firestore.getCollection4<ZonaDelivery>(path2).then(async zona => {
      let zona1: any;
      zona1 = await zona.data();
      if (zona1 == undefined) {
        //Creo la Localidad para despachos
        await this.firestore.createDoc2<ZonaDelivery>(zonaAdd, path3, zonaAdd.idzona).then(async () => {
          //reseteo variables relacionadas al proceso
          this.limpiaZonas();
          this.reiniciaValores();
          //muestro mensaje de guardado con exito
          return await this.presentToastt('Localidad registrada exitosamente...')
        }).catch(async () => {
          //muestro mensaje de problemas en el guardado
          return await this.presentToast('Ocurrio un problema al registrar la localidad: ', zonaAdd.parroquia);
        });
      } else if (zona1 !== undefined) {
        //reseteo variables relacionadas al proceso
        this.limpiaZonas();
        this.reiniciaValores();
        //muestro mensaje de problemas en el guardado
        return await this.presentToast1('La localidad: <b>' + zonaAdd.parroquia + '</b>', 'ya Existe, eliminela primero y actualizela a su gusto...');
      }
    })

  }

  //cambio de precio
  cambiaPrecio(zona: ZonaDelivery) {
    this.actualizaPrecio = true;
    let bbb: any = zona.fechaPrecio;
    this.fechaPrecio = bbb.toDate();
    this.precioAnterior = zona.precio;
    this.zonaAgregada = {
      habilitar: zona.habilitar,
      idzona: zona.idzona,
      precio: zona.precio,
      zonaResumen: zona.zonaResumen,
      zona: zona.zona,
      parroquia: zona.parroquia,
      fechaPrecio: zona.fechaPrecio,
      ubicacion: zona.ubicacion
    }
  }

  //cambio de precio
  async confirmaPrecio(zona: ZonaDelivery) {
    const path22 = 'usuarios/' + this.uidUserLog + '/zonadelivery/';
    //Actualizo habilitar a true en zonadelivery
    await this.firestore.updateDoc<ZonaDelivery>({ precio: zona.precio, fechaPrecio: new Date() }, path22, zona.idzona).then(async () => {
      //muestro mensaje de guardado con exito
      return await this.presentToastt('Precio de Entrega actualizado Exitosamente...')
    }).catch(async () => {
      this.actualizaPrecio = true;
      zona.precio = 0;
      //muestro mensaje de problemas durante la solicitud al repartidor
      return await this.presentToast('Ocurrio un problema al Actualizar el Precio de entrega en la localidad: ', zona.parroquia);
    });

  }

  //creo esta funcion para precargar las zonas y localidades que tengo configuradas
  async getZonas(uidUser: any) {
    if (uidUser != 0) {
      if (!this.zonasGet.length) {
        //creo la ruta para obtener las zonas registaradas del usuario logeado
        const path = 'usuarios/' + uidUser + '/zonadelivery/';
        this.getZonasSuscriber = this.firestore.getCollection(path).subscribe(res => {
          if (res) {
            this.zonasGet = res;
            this.zonasGet2 = res;
            //Utilizo funcion predefinida de (a,b) para Ordenar el array por nombre de localidad
            this.zonasGet.sort(function (a, b) {
              if ((a.parroquia.substr(1) + a.parroquia.slice(0, 1)) < b.parroquia.substr(1) + b.parroquia.slice(0, 1)) {
                return -1;
              } else if ((a.parroquia.substr(1) + a.parroquia.slice(0, 1)) > (b.parroquia.substr(1) + b.parroquia.slice(0, 1)))
                return 1;
              else
                return 0;
            });
            //Utilizo funcion predefinida de (a,b) para Ordenar el array como segunda opcion por habilitacion
            this.zonasGet.sort(function (a, b) {
              if (a.habilitar > b.habilitar) {
                return -1;
              } else if (a.habilitar < b.habilitar)
                return 1;
              else
                return 0;
            });
          }
        })
      }
    }
  }


  //Función para Activar o desactivar la Localidad
  async activaZona(zonas: ZonaDelivery) {
    //si la zona tiene el valor habilitar en false  
    if (zonas.habilitar == false) {
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>HABILITAR</strong> entregas en: <b>' + zonas.zonaResumen + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.uidUserLog + '/zonadelivery/';
              //Actualizo habilitar a true en zonadelivery
              await this.firestore.updateDoc<ZonaDelivery>({ habilitar: true }, path2, zonas.idzona).then(async () => {
                //muestro mensaje de guardado con exito
                return await this.presentToastt('<b>Habilitación</b> de entregas Exitosa...')
              }).catch(async () => {
                zonas.habilitar = false;
                //muestro mensaje de problemas durante la solicitud al repartidor
                await this.presentToast('Ocurrio un problema de conexion al Habilitar despachos en: ', zonas.zonaResumen);
              });
            }
          }
        ]
      });
      await alert1.present();
    } else if (zonas.habilitar == true) {
      const alert2 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: '¿Confirma que desea <strong>DESHABILITAR</strong> entregas en: <b>' + zonas.zonaResumen + '</b>?',
        buttons: [
          {
            text: 'NO ',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              const path2 = 'usuarios/' + this.uidUserLog + '/zonadelivery/';
              //Actualizo habilitar a true en zonadelivery
              await this.firestore.updateDoc<ZonaDelivery>({ habilitar: false }, path2, zonas.idzona).then(async () => {
                //muestro mensaje de guardado con exito
                return await this.presentToastt('<b>Deshabilitación</b> de entregas de forma Exitosa...')
              }).catch(async () => {
                zonas.habilitar = true;
                //muestro mensaje de problemas durante la solicitud al repartidor
                await this.presentToast('Ocurrio un problema de conexion al Deshabilitar entregas en: ', zonas.zonaResumen);
              });
            }
          }
        ]
      });
      await alert2.present();
    }
  }

  //configuracion de la funcion que muestra mensaje normal
  async presentToastt(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 1500,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra errores en pantalla
  async presentToast(mensaje: string, nombrezona: string) {
    const toast2 = await this.toastController.create({
      message: mensaje + ' ' + nombrezona,
      color: 'danger',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    toast2.present();
  }

  //configuracion funcion que muestra mensaje tipo advertencia
  async presentToast1(mensaje: string, nombrezona: string) {
    const toast3 = await this.toastController.create({
      message: mensaje + ' ' + nombrezona,
      color: 'warning',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast3.present();
  }

  //limpia array de zonas
  limpiaZonas() {
    this.traeLocalidad = '';
    this.traeLocalidad2 = '';
    this.localidades = [];
    this.traeLocalidad3 = [];
  }


  //Funcion para agregar una nueva zona
  async nuevaZona() {
    ///se agrego limpiar localidades aqui para evaluar comportmiento 21/10
    this.localidades = [];
    this.newZona = true;
    this.actualizaPrecio = false;
    if (this.estados.length == 0) {
      //cargo los estados con sus id
      const path = 'estados/';
      await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('estado', 'asc')).then(async res => {
        const ccc = [];
        await res.forEach(async (doc: any) => {
          let aa1 = [];
          aa1[0] = doc.data();
          let aaa = [];
          aaa = (aa1.map(obj => (Object.assign(obj, { id: doc.id }))));
          ccc.push(aaa[0]);
        })
        this.estados = ccc;
      });
    }
  }

  //metodo relacionado al FormGroup
  private buildForm() {
    this.form = this.formBuilder.group({
      estadoControl: [this.estado, [Validators.required]],
      municipioControl: [this.municipio, [Validators.required]],
      parroquiaControl: [this.parroquia, [Validators.required]],
    })
    this.form.valueChanges.pipe(
    ).subscribe((): void => {
      this.estado = this.form.controls['estadoControl'].value;
      this.municipio = this.form.controls['municipioControl'].value;
      this.parroquia = this.form.controls['parroquiaControl'].value;
    });
  }


  //función para obtener los municipios
  async getMunicipios() {
    this.localidades = [];
    this.municipio = '';
    if (this.municipios.length == 0 || this.estado != this.estado1) {
      this.estado1 = this.estado
      this.idEstado = ''
      for await (let est of this.estados) {
        if (est.estado == this.estado) {
          this.idEstado = est.id;
          break
        }
      }
      if (this.idEstado != '') {
        const path = 'estados/' + this.idEstado + '/municipio/';
        await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('municipio', 'asc')).then(async res => {
          const ccc = [];
          await res.forEach(async (doc: any) => {
            let aa1 = [];
            aa1[0] = doc.data();
            let aaa = [];
            aaa = (aa1.map(obj => (Object.assign(obj, { id: doc.id }))));
            ccc.push(aaa[0]);
          })
          this.municipios = ccc;
        });
      }
    }
  }

  //función para obtener las parroquias
  async getParroquias() {
    if (this.parroquias.length == 0 || this.municipio != this.municipio1) {
      this.localidades = [];
      this.traeLocalidad = this.estado;
      this.municipio1 = this.municipio
      this.idMunicipio = ''
      for await (let mun of this.municipios) {
        if (mun.municipio == this.municipio) {
          this.idMunicipio = mun.id;
          break
        }
      }
      const path2 = 'estados/' + this.idEstado + '/municipio/' + this.idMunicipio + '/parroquia/';
      await this.firestore.getCollection5<any>(path2, ref => (ref).orderBy('parroquia', 'asc')).then(async res => {
        const ccc = [];
        await res.forEach(async (doc: any) => {
          let aa1 = [];
          aa1[0] = doc.data();
          let aaa = [];
          aaa = (aa1.map(obj => (Object.assign(obj, { id: doc.id }))));
          ccc.push(aaa[0]);
        })
        this.parroquias = ccc;
        //Utilizo funcion predefinida de (a,b) para Ordenar el array por nombre de parroquia
        this.parroquias.sort(function (a, b) {
          if ((a.parroquia.substr(1) + a.parroquia.slice(0, 1)) < b.parroquia.substr(1) + b.parroquia.slice(0, 1)) {
            return -1;
          } else if ((a.parroquia.substr(1) + a.parroquia.slice(0, 1)) > (b.parroquia.substr(1) + b.parroquia.slice(0, 1)))
            return 1;
          else
            return 0;
        });
      });
    }
  }

  //función para obtener las localidades
  async getlocalidades() {
    this.localidades = [];
    this.idParroquia = ''

    if (this.parroquia != this.parroquia1) {
      let replace = this.traeLocalidad.replace(' - ' + this.parroquia1 + ',', '');
      this.traeLocalidad = replace;
      this.parroquia1 = this.parroquia;
    }
    if (this.parroquia != "Seleccione...") {
      for await (let par of this.parroquias) {
        if (par.parroquia == this.parroquia) {
          this.idParroquia = par.id;
          this.parroquiaUbica = par.ubicacion;
          break
        }
      }
      const path3 = 'estados/' + this.idEstado + '/municipio/' + this.idMunicipio + '/parroquia/' + this.idParroquia + '/localidad/';
      this.getlocalidadesSuscriber = this.firestore.getCollection(path3).subscribe(res => {
        if (res) {
          this.localidades = res;
        }
      })
    }
  }

  //transforma el valor capturado en la seleccion
  pasaValor(data: string, act: string) {
    if (this.traeLocalidad == '') {
      this.traeLocalidad = data;
    } else {
      this.traeLocalidad = data + ', ' + this.traeLocalidad;
      if (act == '1') {
        this.add1 = this.traeLocalidad;
      } else if (act != '' && act != '1') {
        this.add2 = act + this.add1;
        this.traeLocalidad3.push(this.add2);
        //elimino registros duplicados
        let result = this.traeLocalidad3.filter((item, index) => {
          return this.traeLocalidad3.indexOf(item) === index;
        })
        this.traeLocalidad3 = result;
      }
    }
  }


  //pasa las localidades con el checked
  pasalocalidad($event) {
    let valor = $event.target.value;
    let checked = $event.target.checked;
    if (checked) {
      let add = valor;
      this.pasaValor(valor, add);
      this.traeLocalidad2 = this.traeLocalidad2 + valor;
    } else if (!checked) {
      let replace = this.traeLocalidad.replace(valor + ',', '');
      let replace2 = this.traeLocalidad2.replace(valor, '');
      this.traeLocalidad = replace;
      this.traeLocalidad2 = replace2
      if (this.traeLocalidad2 == '') {
        this.localidades = [];
        this.traeLocalidad = '';
        this.traeLocalidad3 = [];
      }
      //elimino valor del array si ya existe
      let valorUnchecked = $event.target.value;
      let position = 0;
      let traeLocalidad33 = this.traeLocalidad3;
      traeLocalidad33.filter((item, index) => {
        if (item === (valorUnchecked + this.add1)) {
          return position = index;
        }
      })
      //elimino con valor relacionado al index
      this.traeLocalidad3.splice(position, 1)
    }
  }

  //creo esta funcion para ser utilizada al crear una localidad, limpia las variables principales en uso
  reiniciaValores() {
    this.localidades = [];
    this.traeLocalidad = '';
    this.traeLocalidad3 = [];
  }


}

