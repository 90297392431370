import { Injectable,EventEmitter, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnviaubicacionService {
  //este decorador de inyecta  para enviar la ubicacion dese el componente googlecomponet al que se necesite
  pasaUbicacion: EventEmitter<any> = new EventEmitter();
  //este decorador se inyecta para enviar el uid desde donde se necesite
  pasaUid: EventEmitter<any> = new EventEmitter();
  //este decorador de inyecta  para enviar la ubicacion desde la localidad principal (para hacer vista previa de zonas de localidad)
  @Output() pasaUbicacion2 = new EventEmitter();

 constructor() { }
}
