import { Component, DoCheck, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GooglemapsComponent } from 'src/app/googlemaps/googlemaps.component';
import { Pedido, preComercio, Productos, User, ZonaDelivery2 } from 'src/app/models/users.model';
import { BuscarproductoService } from 'src/app/services/buscarproducto.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { EnviaubicacionService } from 'src/app/services/enviaubicacion.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LocationStrategy } from '@angular/common';
import { UserlogedService } from 'src/app/services/userloged.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-productomovil',
  templateUrl: './productomovil.component.html',
  styleUrls: ['./productomovil.component.scss'],
})
export class ProductomovilComponent implements OnInit, OnDestroy, DoCheck {


  //Variable que guarda el id del pedido Recibido por URL
  uidMenu = "";
  //Se declara el array productosGet para armacenar los productos
  productosGet2: Productos[] = [];
  //Se declara el array productosGet3 para filtrar productos
  productosGet3: Productos[] = [];
  //Se declara el array productosGet4 para filtrar productos con fotos capturadas
  productosGet4: Productos[] = [];
  //creo un array para guardar las fotos de los productos
  fotoproductosGet2 = [];
  //variable para guardar el uid del comercio
  private uidComer: string;
  ///ifComercio:User[]=[];
  ifComercio: preComercio = undefined;
  //variable para guardar las categorias de los productos obtenidos
  categoProduct2: any;
  //variable para guardar las categorias de los productos obtenidos durante el filtrado
  categoProduct3: any;
  //declaro la variable pedido para almacenar el pedido desde el servicio carrito
  pedido!: Pedido;

  //declaro estas variables para totalizar el total pedido tanto en usd como cantidad de productos
  totalPedidousd!: number;
  totalProductos!: number;

  //declaro variables en selectCatego y selectCategotodo que funcionará para visualizar los productos segun la categoria seleccionada
  selectCatego = "";
  selectCategotodo = "TODO";

  //declaracion relacionado al FormBuilder para tomar datos adicionales en pedido mdiante formulario
  declare form: FormGroup;

  //declaracion de Observables para aplicar la desuscripcion
  private getComerciosSuscriber: Subscription = undefined;
  private getProductosSuscriber: Subscription = undefined;
  private getfotoproductosSuscriber: Subscription = undefined;
  private getuidUser: Subscription = undefined;
  private getConectionon: Subscription = undefined;
  private getConectionoff: Subscription = undefined;
  private getConectionslow: Subscription = undefined;


  //variable que guarda url de foto para renderizar
  fotovista = '';
  fotovista2 = '';

  //contadores de tiempo para cerrar el pedidosi no hay actividad
  contador1 = false;

  //contadores de tiempo para reenvio de pedido por whatssap
  contador3 = false;

  //defino valor de temporizador para ngDoCheck
  timer: any;

  //variable que define si se cambia de ruta al home en el ngOnDestroy
  navigateHome = true;

  //variable que controla el efecto de text de preciototal
  efectPreciototal = false;

  //declaro variable para guardar forma pago totales
  formaPagototal = '';

  //declaro variable para capturar el evento de la zona para delivery
  textZona: any;
  zona: ZonaDelivery2[] = [];
  zonaGet: ZonaDelivery2[] = [];
  zonaCotizar: ZonaDelivery2 = {
    idzona: "id123",
    precio: 0,
    zona: "¿SIN RESULTADO? COTIZA TU LOCALIDAD AQUÍ",
    habilitar: true,
    fechaPrecio: new Date(),
    ubicacion: { lat: 5.9, lng: -65.28 }
  };
  busquedaZona = true;

  //varibale que define si el cliente logueado quiere ver su ubicacion
  verUbicacion = false;

  //captura ubicacion principal del usuario logueado
  ubicacionUserprinlat: any = 5.9;
  ubicacionUserprinlng: any = -65.28;

  //guarda direccion del perfil principal
  direccionPerfil: string = '';

  //declaro la expresin regular para numero de telefono venezuela
  //private telefonoPattern:any=/\+584([0-9]){9}$/;
  private telefonoPattern: any = /04([0-9]){9}$/;

  //variables relacionada a la vista previa del mapa
  myUrl: any;
  myTrustedURL: any;

  //variable loading del menu
  loading: any = null;

  //en este array se guardan las formas de pago
  formap2: any[] = [];

  //función que activa un setinterval para agregar un efecto al boton enviar
  efectoEnviar = false;
  setintervalEnviar: any = undefined;

  //variable relacionada al captcha
  captcha = '';
  nombreCatcha = '';
  private uidUserlog: string;

  //guarda estatus de conexión a internet
  private estatusInternet: boolean;
  private setTimer: NodeJS.Timeout;

  //estas variables llaman mis claves api de mapas
  apikey1 = environment.mapsConfig.apikey1
  apikey2 = environment.mapsConfig.apikey2

  //variable que guarda referencia de la ubicacion capturada
  direcUbica = '';

  //variable para contar las llamadas al mapa, si se llama mas de 3 veces se reinicia pedido
  private cuentaMapa = 0;

  //variable que lleva al fnal de pedido para continuar pago
  public continuarPago: boolean;

  //variable que activa la vista de la barra de busqueda
  public verBusqueda = false;
  private valorbusqueda = '';

  //variable que carga el valor de la cantidad de producto por item
  public cuentaProd: number;

  //oculta el elemento capcha
  public capVisible = true;

  constructor(private activateRoute: ActivatedRoute,
    private firestore: FirestoreService,
    private carritoService: CarritoService,
    private modalControler: ModalController,
    private formBuilder: FormBuilder,
    private enviaUbicacion: EnviaubicacionService,
    private alertController: AlertController,
    private buscarproducto: BuscarproductoService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private location: LocationStrategy,
    private traeUid: UserlogedService,
    private _renderer: Renderer2,
    private platform: Platform
  ) {
    //se recibe en el constructor la data del usuario si hay usuario logueado
    this.getuidUser = this.traeUid.getDatauser().subscribe(async res => {
      if (res) {
        this.nombreCatcha = res.nombre + ' ' + res.apellido;
        this.uidUserlog = res.uid;
      }
    })
    //aca obtengo el uid del comercio dese la ruta URL
    this.activateRoute.params.subscribe(async params => {
      if (params !== null) {
        this.uidMenu = await params['id'];
        await this.getComercio(this.uidMenu)
      }
    });

    //inhabilita que se pueda retroceder el menu
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    //activa los observabables para el estatus de conexión a internet
    this.verStatusconec();
  }



  ngOnInit(): void {
    //se llama el metodo trae pedido desde aca
    this.traePedidotemp();
    //se llama el metodo que trae ubicacion desde aca
    this.traeUbicacion();
  }


  //en este punto recargo el pedido para que reinice valores por defecto solo en caso que this.pedido
  //tenga informacion caragada, lo que indica que ya se habia gerado o inicado un pedido antes 
  //(en observacion comportamiento del ionViewWillEnter())
  async ionViewWillEnter() {
    if (this.pedido && this.pedido.productos.length > 0) {
      await this.presentLoading4()
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      setTimeout(async () => {
        const popover22 = await this.loadingController.getTop();
        window.location.reload();
        if (popover22) {
          await popover22.dismiss(null);
        }
      }, 2000);
    }
  }


  //funcion relacionada al captcha
  async resolved(captchaResponse: string) {
    this.captcha = captchaResponse;

    //envio la respuesta a google (en proceso de evaluacion)
    let script = this._renderer.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js?captchaResponse";
    this._renderer.appendChild(document.body, script);


    if (this.uidMenu != '') {
      await this.getComercio(this.uidMenu)
    }

    //se oculta grecapchat-badge para que no interrumpa en el proceso de pedido
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }

  }

  //verficar si recpacha dejo de estar oculto
  async verificarRecap() {
    clearTimeout(this.setTimer);
    this.setTimer = setTimeout(async () => {
      let objRecap = document.getElementById('reCap');
      if (objRecap && objRecap.hidden && this.nombreCatcha != '') {
        this.estatusInternet = false;
        return await this.presentToastiii('Se detecta lentitud de conexión a internet, por favor Espere...')
      }
    }, 3000);
  }


  //verificar status de conexion a internet
  verStatusconec() {
    //evento que captura cuando no hay conexion a internet
    this.getConectionoff = fromEvent(window, 'offline').subscribe(async () => {
      this.estatusInternet = false;
      return await this.presentToasti('Se detecta problemas de conexión a internet...')
    });
    //evento que captura cuando vuelve la conexion a internet
    this.getConectionon = fromEvent(window, 'online').subscribe(async (resp: any) => {
      if (!this.estatusInternet) {
        const popove1 = await this.toastController.getTop();
        if (popove1) {
          await popove1.dismiss(null);
        }
        this.estatusInternet = true;
        return await this.presentToastii('de nuevo en LINEA...')
      }
    });
    //evento que captura cuando no hay lenta conexion a internet
    this.getConectionslow = fromEvent(window, 'slowline').subscribe(async () => {
      return await this.presentToastiii('Se detecta lentitud de conexión a internet...')
    });
  }


  //funcion para autorizar URL de carga de mapa sin costo
  procesaUrl() {
    this.myTrustedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.myUrl);
  }

  //funcion que activa un setInterval para animar el boton enviarpedido
  animaEnviar() {

    this.setintervalEnviar = setInterval(() => {

      this.efectoEnviar = !this.efectoEnviar

    }, 400);

  }


  //esta funcion lleva al final del pedido
  scrollAnima() {
    setTimeout(() => {
      const destino = document.querySelector('.banner')
      destino.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300);

  }

  //esta funcion lo lleva solo el toolbar de busqueda localidad para que no pierda el enfoque
  scrollAnima1() {
    setTimeout(() => {
      const destino = document.querySelector('.banner1')
      destino.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300);
  }

  //esta funcion lo lleva solo input de telefono para que no pierda el enfoque
  scrollAnima2() {
    setTimeout(() => {
      const destino = document.querySelector('.banner2')
      destino.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300);
  }

  //esta funcion lo lleva al input de detalles de pedido, permite ver lo que se va agregando a la orden
  scrollAnima3() {
    setTimeout(() => {
      const destino = document.querySelector('.banner3')
      destino.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300);
  }



  async ngOnDestroy() {
    //aplico desuscripcion de observables de este componenete al fianalizarlo
    if (this.getComerciosSuscriber != undefined) {
      this.getComerciosSuscriber.unsubscribe();
    }
    if (this.getProductosSuscriber != undefined) {
      this.getProductosSuscriber.unsubscribe();
    }
    if (this.getfotoproductosSuscriber != undefined) {
      this.getfotoproductosSuscriber.unsubscribe();
    }
    if (this.getConectionon != undefined) {
      this.getConectionon.unsubscribe();
    }
    if (this.getConectionoff != undefined) {
      this.getConectionoff.unsubscribe();
    }
    if (this.getConectionslow != undefined) {
      this.getConectionslow.unsubscribe();
    }
    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
    const popover = await this.loadingController.getTop();
    if (popover) {
      await popover.dismiss(null);
    }
    //luego de realizar las desuscripciones mando al home, solo si se cambia por inactividad
    if (this.navigateHome) {
      await this.router.navigate(['/home']);
    }


    //se emite ubicación por defecto para resetear la ultima ubicacion capturada por el eventEmitter 26/03/24
    this.enviaUbicacion.pasaUbicacion.emit({
      data: {
        lat: 5.90,
        lng: -65.28,
      }
    });
  }

  //esta función la llamo cuando se finaliza el pedido de forma exitosa y llamo a ver la orden en tiempo real
  async verOrden() {
    //aplico desuscripcion de observables de este componenete al fianalizarlo
    if (this.getComerciosSuscriber != undefined) {
      this.getComerciosSuscriber.unsubscribe();
    }
    if (this.getProductosSuscriber != undefined) {
      this.getProductosSuscriber.unsubscribe();
    }
    if (this.getfotoproductosSuscriber != undefined) {
      this.getfotoproductosSuscriber.unsubscribe();
    }
    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
    const popover1 = await this.loadingController.getTop();
    if (popover1) {
      await popover1.dismiss(null);
    }
  }


  //metodo relacionado al FormGroup para validar nombre requerido y telefono en formato venezuela
  private buildForm() {
    //agrego al nombre del pedido el nombre capturado en el inicio si uid es vacio
    if (this.pedido.cliente.uid == '') {
      //this.pedido.cliente.nombre = this.nombreCatcha; 06/03/24 se elimina porque ya no se pide nombre antes de cargar menu
    }

    this.form = this.formBuilder.group({
      //creo un primero controlador para validacion de formulario, '' inicia en vacio, []con validacion solo sincrona
      //se agrego validacion para que no tome el espacio en blanco cuando no trae usuario registrado 06/03/24
      nombreControl: [this.pedido.cliente.nombre == "" ? this.pedido.cliente.nombre : this.pedido.cliente.nombre + ' ' + this.pedido.cliente.apellido, [Validators.required]],
      telefonoControl: [this.pedido.telefono, [Validators.required, Validators.pattern(this.telefonoPattern)]],
    });

    this.form.valueChanges.pipe(
      //uso del debounceTime para los ciclos de espera
      debounceTime(1000)
    ).subscribe((): void => {
      //console.log(value);
      this.pedido.cliente.nombre = this.form.controls['nombreControl'].value;
      this.pedido.telefono = this.form.controls['telefonoControl'].value;
    });

  }


  //metodo relacionado al FormGroup, para salvar los datos del formulario solo si el nombre esta completado
  async save(event: Event, pedidofinal: Pedido, nombrecomercio: string) {


    this.scrollAnima3() //en observacion comportamiento al 09/03/24


    event.preventDefault(); //este metodo cancela el comportamiento por defecto de las pag html y captura lo que tiene antes de recargar
    //reverifico que realmente el formulario es valido (paso todas las validaciones) y así salvo los cambios

    //detengo el setinterval que ayuda a la animacion del boton enviar
    clearInterval(this.setintervalEnviar)

    if (this.form.valid) {
      //esta validación la hago para capturar informacion de los clientes que hacen el pedido en el comercio
      if (!pedidofinal.delivery) {
        const alert19 = await this.alertController.create({
          cssClass: 'normal',
          header: 'Confimación',
          backdropDismiss: false,
          message: "¿Se encuentra actualmente presente en el comercio?",
          buttons: [
            {
              text: 'NO',
              cssClass: 'normal',
              id: 'cancel-button1',
              handler: () => {
                //si el formato es valido procedo a activar el proceso de registro
                this.openDialogpopup(pedidofinal, nombrecomercio)
              }
            }, {
              text: 'SI',
              id: 'confirm-button1',
              handler: () => {
                pedidofinal.detallePedido = pedidofinal.detallePedido + ' => CLIENTE PRESENTE EN COMERCIO'
                //si el formato es valido procedo a activar el proceso de registro
                this.openDialogpopup(pedidofinal, nombrecomercio)
              }
            }
          ]
        });
        await alert19.present();
      } else if (pedidofinal.delivery) {
        //si el formato es valido procedo a activar el proceso de registro
        this.openDialogpopup(pedidofinal, nombrecomercio)
      }
    } else {
      this.form.markAllAsTouched();
      return;
    }
  }


  //utilizo esta funcion para llamar a la funcion ngOnDestroy para aplicarse cuando no se detecta actividad
  iniciaSettime(time: number) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      //Habilito la navegacion al Home automatica
      this.navigateHome = true;
      //Ejecuto el ngOnDestroy
      this.ngOnDestroy();
    }, time);
  }

  //utilizo ngDoCheck para llamar a la funcion iniciaSettime para iniciar un contador de inactividad en 3 minutos
  ngDoCheck() {
    this.iniciaSettime(180000);
  }

  /*Este metodo se activa cuando me cambio de componenente, si el usuario decide navegar a otro compoenente antes
  del cambio automatico se resetea el setTimeOut activo, y se llama al ngOnDestroy pero sin cambio de ruta*/
  ionViewDidLeave() {
    if (this.timer != undefined) {
      //Finalizo ClearTimeou Activo
      clearTimeout(this.timer);
      //Inhabilito la navegacion al Home automatica
      this.navigateHome = false;
      //llamo al ngOnDestroy cada vez que me salga de este componente
      this.ngOnDestroy();
      //llamo evento de entrada para reseteo de valores 26-03-24
      this.ionViewWillEnter()
    }
  }

  //configuracion de la funcion que muestra mensaje de espera mientras se descarga el Menú
  async presentLoading() {
    //crea mensaje
    this.loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Abriendo catálogo, por favor espere...',
      duration: 5000
    });
    //presenta mensaje
    await this.loading.present();
  }


  //metodo que recibe ubicacion desde el servicio que pasa ubicacion desde el componente googlemaps  
  traeUbicacion() {
    this.enviaUbicacion.pasaUbicacion.subscribe(async res2 => {
      if (res2 && this.pedido != undefined && this.pedido.id === "" && this.pedido.delivery) { // 26-03-24 se busca no llamar este observable desde otro componente
        if (res2 && (res2.data.lat != 5.90 && res2.data.lng != -65.28)) {
          this.pedido.ubicacionDespacho.lat = res2.data.lat;
          this.pedido.ubicacionDespacho.lng = res2.data.lng;

          //se le pasa el valor a la variable para mostrar en el menu el area de entrega asociada a la ubicación   
          if (res2.data11 == '') {
            this.direcUbica = res2.data11;
          } else if (res2.data11 != '') {
            this.direcUbica = '(' + res2.data11 + ')';
          }

          //proceso la url por api segun si es dispositivo android o web
          if (this.platform.is('cordova')) {
            //guardo la direccion que va a cargar el mapa embed
            this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + this.pedido.ubicacionDespacho.lat + ',' + this.pedido.ubicacionDespacho.lng + "&zoom=16"
          } else {
            //guardo la direccion que va a cargar el mapa embed
            this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + this.pedido.ubicacionDespacho.lat + ',' + this.pedido.ubicacionDespacho.lng + "&zoom=16"
          }


          //proceso la url de forma segura
          this.procesaUrl();

          if (this.pedido.zonadelivery.idzona != 'id123') {
            const alert6 = await this.alertController.create({
              cssClass: 'normal',
              header: 'Confirmación',
              backdropDismiss: false,
              message: "<b>" + this.pedido.cliente.nombre.trim() + "</b>, por favor escriba una dirección o punto de referencia de la ubicación fijada <b>"
                + this.direcUbica + "</b> en: <b>" + this.pedido.zonadelivery.zona + "</b>",
              inputs: [
                {
                  name: '',
                  type: 'text',
                  //id: 'lugar-id',
                  placeholder: 'escribala aquí, Ejemplo: calle A número 1, frente a la plaza'
                },
              ],
              buttons: [
                {
                  text: 'CAMBIAR LOCALIDAD',
                  // role: 'cancel',
                  cssClass: 'normal',
                  id: 'cancel-button',
                  handler: () => {
                    this.busquedaZona = true;
                    this.pedido.ubicacionDespacho.lat = this.ubicacionUserprinlat;
                    this.pedido.ubicacionDespacho.lng = this.ubicacionUserprinlng;
                    this.pedido.zonadelivery = {
                      idzona: "",
                      precio: null,
                      zona: "",
                      habilitar: null,
                      fechaPrecio: null,
                      ubicacion: null
                    };
                  }
                }, {
                  text: 'Enviar',
                  id: 'confirm-button',
                  handler: (data) => {
                    if (data[0].length) {
                      this.busquedaZona = false;
                      this.verUbicacion = true;
                      this.pedido.cliente.direccionlocal = data[0];
                      //llamo al interval que me anima el boton enviar
                      this.animaEnviar();
                      //llevo al final de la pagina automaticamente
                      this.scrollAnima()
                    } else if (!data[0].length) {
                      return false;
                    }
                  }
                }
              ]
            });
            await alert6.present();
          } else if (this.pedido.zonadelivery.idzona == 'id123') {
            //envio un mensaje diferente cuando se trata de una zona a cotizar
            const alert8 = await this.alertController.create({
              cssClass: 'normal',
              header: 'Confirmación',
              backdropDismiss: false,
              message: "<b>" + this.pedido.cliente.nombre.trim() + "</b>, escriba una dirección o punto de referencia asociado a la ubicación fijada, <b>"
                + this.direcUbica + "</b> para que podamos evaluar la disponibilidad de entrega  ",
              inputs: [
                {
                  name: '',
                  type: 'text',
                  //id: 'lugar-id1',
                  placeholder: 'escribala aquí, Ejemplo: calle A número 1, frente a la plaza'
                },
              ],
              buttons: [
                {
                  text: 'Cancelar',
                  // role: 'cancel',
                  cssClass: 'normal',
                  id: 'cancel-button',
                  handler: () => {
                    this.busquedaZona = true;
                    this.pedido.ubicacionDespacho.lat = this.ubicacionUserprinlat;
                    this.pedido.ubicacionDespacho.lng = this.ubicacionUserprinlng;
                    this.pedido.zonadelivery = {
                      idzona: "",
                      precio: null,
                      zona: "",
                      habilitar: null,
                      fechaPrecio: null,
                      ubicacion: null
                    };
                  }
                }, {
                  text: 'Enviar',
                  id: 'confirm-button',
                  handler: (data1) => {
                    if (data1[0].length) {
                      this.busquedaZona = false;
                      this.verUbicacion = true;
                      this.pedido.cliente.direccionlocal = data1[0];
                      //llamo al interval que me anima el boton enviar
                      this.animaEnviar();
                      //llevo al final de la pagina automaticamente
                      this.scrollAnima()
                    } else if (!data1[0].length) {
                      return false;
                    }
                  }
                }
              ]
            });
            await alert8.present();
          }
        } else {
          this.noDelivery();
          this.pedido.cliente.direccionlocal = '';
          return await this.presentToast1('No proporcionó ubicación para completar el pedido con delivery, ', 'puede continuar con su pedido sin servicio de delivery')
        }
      }
    });

  }

  async deliveryOn(deliverAct: boolean) {
    if (deliverAct == false) {
      const alert20 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Información',
        backdropDismiss: false,
        message: "Actualmente no disponemos delivery, solo PickUp",
        buttons: [
          {
            text: 'OK',
            cssClass: 'normal',
            id: 'cancel-button1',
            handler: () => {
            }
          }
        ]
      });
      await alert20.present();
    }
  }

  traePedidotemp() {
    this.carritoService.pasaData.subscribe(res => {
      this.pedido = res.data;
      //le asigno al pedido los datos del comercio
      this.pedido.nombreComercio = this.ifComercio.nombrelocal;
      this.pedido.uidComercio = this.uidComer;
      this.pedido.telefonoComer = this.ifComercio.telefono;
      this.buildForm();
      this.getTotalusd();
      this.getTotalproductos();

      //cargo estos valores solo cuando exista usuario logueado
      if (this.ubicacionUserprinlat == 5.9 && this.ubicacionUserprinlng == -65.28 && this.pedido.cliente.uid != '') {
        this.ubicacionUserprinlat = this.pedido.cliente.ubicacionpri.lat
        this.ubicacionUserprinlng = this.pedido.cliente.ubicacionpri.lng
        this.direccionPerfil = this.pedido.cliente.direccionlocal

        //proceso la url por api segun si esd dispositivo android o web
        if (this.platform.is('cordova')) {
          //guardo la direccion que va a cargar el mapa embed
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + this.pedido.ubicacionDespacho.lat + ',' + this.pedido.ubicacionDespacho.lng + "&zoom=16"
        } else {
          //guardo la direccion que va a cargar el mapa embed
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + this.pedido.ubicacionDespacho.lat + ',' + this.pedido.ubicacionDespacho.lng + "&zoom=16"
        }
        //proceso la url de forma segura
        this.procesaUrl();
      }

      //le asigno el valor al campo this.pedido.precioTotal y le aplico redonde a 2 decimales con toFixe(2)
      this.pedido.precioTotal = parseFloat(this.totalPedidousd.toFixed(2))
    });
  }


  //Funcion para llamar al popup de confirmacion de envio de pedido
  async openDialogpopup(pedido: Pedido, local: string) {
    //levanto el spinner de procesando pedido
    this.presentLoading3();
    const pedidoTemp = pedido;
    let productoPedtotal = "";
    //aca guardo las formas de pagos disponbles del comercio para mostrarlas al cliente
    const path2 = 'usuarios/' + pedido.uidComercio + '/formapago';
    this.formap2 = [];
    await this.firestore.getCollection5<any>(path2, ref => (ref).where('disponible', '==', true)).then(async formaPago => {
      if (formaPago) {
        await formaPago.forEach(async (doc: any) => {
          let formap: any[] = [];
          formap[0] = doc.data();
          let formas: any = {
            name: '',
            type: 'checkbox',
            label: '',
            value: '',
            checked: false
          }
          formas = {
            name: formap[0].formapago,
            type: 'checkbox',
            label: formap[0].formapago,
            value: formap[0].formapago,
            checked: false
          }
          this.formap2.push(formas);
        })
      } else if (!formaPago) {
        this.formap2 = [];
      }
      //le aplico reduccion de duplicados
      this.formap2 = Array.from(this.formap2.reduce((map, obj) => map.set(obj, obj), new Map()).values());
    })

    //si la opcion de delivery esta en true y no hay una zona seleccionada no se puede enviar pedido
    if (pedidoTemp.delivery && pedidoTemp.zonadelivery.idzona == '') {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading() (en pruebas)
      const popover = await this.loadingController.getTop();
      if (popover) {
        await popover.dismiss(null);
      }

      const alert9 = await this.alertController.create({
        cssClass: 'normal',
        header: '¡ALERTA!',
        backdropDismiss: false,
        message: "¡<b>" + pedidoTemp.cliente.nombre.trim() + "</b> Es necesario completar los pasos para pedido con delivery!",
        buttons: [
          {
            text: 'SIN DELIVERY',
            //role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {
              this.noDelivery();
            }
          }, {
            text: 'OK',
            id: 'confirm-button',
            handler: () => {
              return;
            }
          }
        ]
      });
      await alert9.present();
    } else if (!pedidoTemp.delivery || pedidoTemp.zonadelivery.idzona == 'id123') {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover1 = await this.loadingController.getTop();
      if (popover1) {
        await popover1.dismiss(null);
      }

      //declaro esta variable para almacenar todos los productos pedidos y mandarla en el mensaje de confirmacion
      pedidoTemp.productos.forEach(productoPed => {
        productoPedtotal = productoPedtotal + " " + (productoPed.cantidad + " " + productoPed.producto.nombreProducto + "<br>");
      })
      const alert = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        backdropDismiss: false,
        message: "<b>" + this.pedido.cliente.nombre.trim() + "</b>, para finalizar seleccione forma de pago y confirme el pedido:"
          + " <b><br>" + productoPedtotal + "</b> por un total de:" + " " + "  <b>$" + pedido.precioTotal.toFixed(2) + "</b>, presione <b>SI</b> cuando este listo.",
        inputs: this.formap2,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {
              //esto limpia this.pedido.detallePedido los casos de cancelacion del envio en el punto de seleccion de forma de pago
              if ((this.pedido.detallePedido.trim()).substr(-8) == 'COMERCIO') {
                let replace = this.pedido.detallePedido.replace(' => CLIENTE PRESENTE EN COMERCIO', '');
                this.pedido.detallePedido = replace;
              }
            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async (data2) => {
              //limpio valores de productos que no son necesario agregarlo al pedido
              pedidoTemp.productos.forEach(urlpro => {
                urlpro.producto.urlfoto = '';
                urlpro.producto.fechaActualizacion = null;
                urlpro.producto.fechaCreacion = null;
              })
              await this.enviarPedido(pedidoTemp, data2)
            }
          }
        ]
      });
      await alert.present();
    } else if (pedidoTemp.delivery && pedidoTemp.zonadelivery.idzona != '' && pedidoTemp.zonadelivery.idzona != 'id123') {

      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover2 = await this.loadingController.getTop();
      if (popover2) {
        await popover2.dismiss(null);
      }

      //declaro esta variable para almacenar todos los productos pedidos y mandarla en el mensaje de confirmacion
      pedidoTemp.productos.forEach(productoPed => {
        productoPedtotal = productoPedtotal + " " + (productoPed.cantidad + " " + productoPed.producto.nombreProducto + "<br>");
      })
      const alert = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        backdropDismiss: false,
        message: "<b>" + this.pedido.cliente.nombre.trim() + "</b>, para finalizar seleccione forma de pago y confirme el pedido:"
          + " <b><br>" + productoPedtotal + "</b> por un Total de:" + " " + "  <b>$" + (pedido.precioTotal + pedido.zonadelivery.precio).toFixed(2) + "</b> correspondiente a $" + pedido.precioTotal.toFixed(2) + " del pedido y $" + pedido.zonadelivery.precio.toFixed(2) + " del servicio delivery, presione <b>SI</b> cuando este listo.",
        inputs: this.formap2,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {

            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async (data3) => {
              //limpio valores de productos que no son necesario agregarlo al pedido
              pedidoTemp.productos.forEach(urlpro => {
                urlpro.producto.urlfoto = '';
                urlpro.producto.fechaActualizacion = null;
                urlpro.producto.fechaCreacion = null;
              })
              await this.enviarPedido(pedidoTemp, data3)
            }
          }
        ]
      });
      await alert.present();
    }
  }

  //Obtengo en un array todas las Zonas para delivery
  async getZonas(uidComercio: string) {
    this.busquedaZona = true;
    this.zona = [];
    //creo la ruta para obtener las zonas asociadas al comercio
    const path = 'usuarios/' + uidComercio + '/zonadelivery/';
    //obtengo todas las zonas de delivery
    let concatenado: ZonaDelivery2[] = [];
    await this.firestore.getCollection5<ZonaDelivery2>(path, ref => (ref).orderBy('idzona', 'asc')).then(async zonasget => {
      await zonasget.forEach(async (doc: any) => {
        let valor: any[] = [];
        let valor2: any[] = [];
        valor[0] = doc.data();
        valor2 = doc.data().zona;
        let valor3 = valor2;
        if (valor[0].habilitar == true) {
          //concateno en 1 solo array
          for await (let add3 of valor2) {
            let concatenado1 = (valor3.map(obj => (Object.assign({ fechaPrecio: valor[0].fechaPrecio, habilitar: valor[0].habilitar, idzona: valor[0].idzona, precio: valor[0].precio, zona: add3, ubicacion: valor[0].ubicacion }))));
            concatenado.push(concatenado1[0]);
          }
        }
      })
      //aplico reduccion de duplicados (si la hay) por nombre de zona
      Array.from(concatenado.reduce((map, obj) => map.set(obj.zona, obj), new Map()).values());
    })
    //agrego la zona de cotizacion por defecto
    concatenado.push(this.zonaCotizar);
    //le paso el valor de concatenado
    this.zona = concatenado;
  }


  //Funcion para buscar Zonas para delivery
  async searchZona(event: any, delivery: boolean) {
    if (delivery) {
      //aplico regla normalize para sustituir acentos
      this.textZona = (event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).trim();
      if (this.textZona && this.textZona.trim() != "") {
        this.zonaGet = this.zona.filter((zonafilter: any) => {
          return ((zonafilter.zona.toLowerCase().indexOf(this.textZona.toLowerCase()) > -1) || zonafilter.idzona == "id123" ||
            (zonafilter.zona.toLowerCase().indexOf((this.textZona.split(' ').pop().split(' ').shift()).trim()) > -1));
        })
        this.scrollAnima1();
      }
    }

  }

  //Funcion que toma la zona seleccionada con un alert para confirmar que la ubicacion por defecto del perfil esta dentro de la zona seleccionada
  async selectZona(zonaSelect: ZonaDelivery2) {
    //en esta constante se guarda la ubicacion de la localidad
    const dataLocalidad = zonaSelect.ubicacion;

    //cada vez que se selecciona na nueva zona le asigo al perfil de cliente su direccion por defecto
    this.pedido.cliente.direccionlocal = this.direccionPerfil;

    if (this.pedido.cliente.uid != '' && zonaSelect.idzona != 'id123') {
      const alert5 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        message: "<b>" + this.pedido.cliente.nombre.trim() + "</b>, ¿confirma que su ubicación registrada: <b>" + this.pedido.cliente.direccionlocal + "</b>" +
          " pertenece a la localidad que acaba de seleccionar: <b>" + zonaSelect.zona + "</b>?, en caso contrario, presione: <b>ENTREGAR EN OTRA UBICACIÓN</b>",
        buttons: [
          {
            text: 'ENTREGAR EN OTRA UBICACIÓN',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {
              this.busquedaZona = false;
              this.pedido.zonadelivery = zonaSelect;
              this.getGooglemaps();
            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: () => {
              this.busquedaZona = false;
              this.pedido.zonadelivery = zonaSelect;
              this.pedido.ubicacionDespacho.lat = this.ubicacionUserprinlat,
                this.pedido.ubicacionDespacho.lng = this.ubicacionUserprinlng,
                //llamo al interval que me anima el boton enviar
                this.animaEnviar();
              //llevo al final de la pagina automaticamente
              this.scrollAnima()
            }
          }
        ]
      });
      await alert5.present();
    } else if (zonaSelect.idzona == 'id123' && this.pedido.cliente.uid != '') {

      const alert7 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        backdropDismiss: false,
        message: "<b>" + this.pedido.cliente.nombre.trim() + ' ' + this.pedido.cliente.apellido.trim() + "</b>, su ubicación registrada se toma como la primera opción de entrega, ¿desea cotizar el costo y disponibilidad de despacho a: <b>" + this.pedido.cliente.direccionlocal + "?</b>",
        buttons: [
          {
            text: 'SELECCIONAR OTRA UBICACION PARA ENTREGA',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {
              this.busquedaZona = false;
              this.pedido.zonadelivery = zonaSelect;
              this.getGooglemaps();
            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: () => {
              this.busquedaZona = false;
              this.pedido.zonadelivery = zonaSelect;
              this.pedido.ubicacionDespacho.lat = this.ubicacionUserprinlat,
                this.pedido.ubicacionDespacho.lng = this.ubicacionUserprinlng,
                //llamo al interval que me anima el boton enviar
                this.animaEnviar();
              //llevo al final de la pagina automaticamente
              this.scrollAnima()
            }
          }
        ]
      });
      await alert7.present();
      // si no hay usuario logueado
    } else if (this.pedido.cliente.uid == '') {
      //si no es pedido por cotizacion
      if (zonaSelect.ubicacion.lat != 5.90) {
        const alert8 = await this.alertController.create({
          cssClass: 'normal',
          header: 'LEA CUIDADOSAMENTE',
          backdropDismiss: false,
          message: "<b>" + this.pedido.cliente.nombre.trim() + "</b>, ¿la ubicación para entregar el pedido esta dentro de <b>" + zonaSelect.zona + "</b>?",
          buttons: [
            {
              text: 'NO',
              cssClass: 'normal',
              id: 'cancel-button',
              handler: () => {
                this.busquedaZona = true;
                this.pedido.zonadelivery.idzona = '';
                this.pedido.zonadelivery.zona = '';
                //this.pedido.zonadelivery=zonaSelect;
              }
            }, {
              text: 'SI',
              id: 'confirm-button',
              handler: async () => {
                await this.presentLoading5(zonaSelect.zona);
                this.busquedaZona = false;
                this.pedido.zonadelivery = zonaSelect;
                this.enviaUbicacion.pasaUbicacion2.emit({
                  dat: dataLocalidad
                })
                this.getGooglemaps();
                setTimeout(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover12 = await this.loadingController.getTop();
                  if (popover12) {
                    await popover12.dismiss(null);
                  }
                  this.enviaUbicacion.pasaUbicacion2.emit({
                    dat: dataLocalidad
                  })
                }, 4000);
              }
            }
          ]
        });
        await alert8.present();
      } else {
        this.busquedaZona = false;
        this.pedido.zonadelivery = zonaSelect;
        this.enviaUbicacion.pasaUbicacion2.emit({
          dat: this.pedido.ubicacionDespacho
        })
        this.getGooglemaps();
        setTimeout(async () => {
          this.enviaUbicacion.pasaUbicacion2.emit({
            dat: this.pedido.ubicacionDespacho
          })
        }, 2000);
      }
    }
  }

  //funcion para corregis informacion del mapa mandandole la ultima ubicacion caragada en el pedido
  corrigeUbicacion() {
    this.getGooglemaps();
    setTimeout(async () => {
      this.enviaUbicacion.pasaUbicacion2.emit({
        dat: this.pedido.ubicacionDespacho
      })
    }, 2000);
  }


  //limpia valores de this.pedido de la zona si se cambia a no delivery
  noDelivery() {
    this.pedido.delivery = false;
    if (this.pedido.cliente.uid != '') {
      this.pedido.ubicacionDespacho.lat = this.ubicacionUserprinlat;
      this.pedido.ubicacionDespacho.lng = this.ubicacionUserprinlng;
    }
    this.pedido.zonadelivery = {
      idzona: "",
      precio: null,
      zona: "",
      habilitar: null,
      fechaPrecio: null,
      ubicacion: null
    };
  }


  //funcion que obtine el total del pedido en usd
  getTotalusd() {
    this.totalPedidousd = 0;
    this.pedido.productos.forEach(producto => {
      this.totalPedidousd = (producto.producto.precio * producto.cantidad) + this.totalPedidousd;
    });
  }

  //funcion que obtiene el total de productos
  getTotalproductos() {
    this.totalProductos = 0;
    this.pedido.productos.forEach(producto => {
      this.totalProductos = producto.cantidad + this.totalProductos;
    });
  }

  //configuracion de la funcion que muestra mensaje de espera mientras se envia el pedido
  async presentLoading2() {
    let loading1 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Enviado Pedido, por favor espere...',
      //duration: 500
    });
    //presenta mensaje
    await loading1.present();
  }
  //configuracion de la funcion que muestra mensaje de espera mientras se envia el pedido
  async presentLoading3() {
    let loading3 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando Pedido...',
      duration: 10000
    });
    //presenta mensaje
    await loading3.present();
  }

  //configuracion de la para recargar pedido en pantalla
  async presentLoading4() {
    let loading4 = await this.loadingController.create({
      cssClass: 'normal',
      //message: 'Iniciando un nuevo pedido...', //26/03/24
      message: 'Actualizando vista...',
      duration: 5000
    });
    //presenta mensaje
    await loading4.present();
  }

  //configuracion de la para recargar pedido en pantalla
  async presentLoading44() {
    let loading4 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Alcanzo el limite de llamadas al mapa, iniciando de nuevo el pedido...',
      duration: 5000
    });
    //presenta mensaje
    await loading4.present();
  }

  //configuracion de la para recargar pedido en pantalla
  async presentLoading5(localidad: string) {
    let loading5 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Abriendo mapa, para que seleccione la ubicación de entrega dentro de: <b>' + localidad + '</b>',
      duration: 10000
    });
    //presenta mensaje
    await loading5.present();
  }



  async enviarPedido(pedidoConfirmado: Pedido, formapago: any[]) {

    let formapago11: any[] = [];
    formapago11 = formapago;
    this.formaPagototal = '';
    //en este for se agregan las formas de pago al mensaje whatssap
    if (formapago11 != undefined) {
      for await (let formaspag of formapago11) {
        if (this.formaPagototal == '') {
          this.formaPagototal = this.formaPagototal + ' ' + '*' + formaspag;
        } else if (this.formaPagototal != '') {
          this.formaPagototal = this.formaPagototal + ' ' + formaspag;
        }
      }
    }
    //mando el menaje de envio de pedido
    await this.presentLoading2();
    //igualo pedido desde el popup a pedido confirmado
    this.pedido = pedidoConfirmado;
    //le agrego el +58 al telefono para una mejor experiencia de usuario
    let numTelefono = this.pedido.telefono.substr(1)
    numTelefono = '+58' + numTelefono;
    this.pedido.telefono = numTelefono;
    //se actualiza la fecha del pedido justo al enviarlo
    this.pedido.fechaPedido = new Date();
    this.pedido.fechaEntrega = this.pedido.fechaPedido;
    //Se llama el metodo para guardar en el pedido la ubicacion seleccionada desde el mapa para envio
    this.traeUbicacion();
    //con esta funcion se guarda el pedido en el comercio y en el cliente
    this.carritoService.realizarPedido(this.pedido)
    //se recibe data guardada del pedido  
    this.carritoService.pasaDatapedidofinal.subscribe(async res => {
      //if res es diferente de null quiere decir que no hubo error al guardar el pedido tanto al comercio como el cliente
      if (await res.data !== null && await res.data !== 'dif') {
        this.pedido = await res.data;
        //se realiza petición de reenvio de pedido x whatssap
        setTimeout(() => {
          this.reenvioWhatsapp(res.data, formapago)
        }, 3000);
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover33 = await this.loadingController.getTop();
        if (popover33) {
          await popover33.dismiss(null);
        }
        if (formapago11 != undefined && this.pedido.delivery && this.pedido.zonadelivery.idzona != 'id123') {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20' + '*USD' + this.pedido.zonadelivery.precio.toFixed(2) + '*' + '%20por%20Servicio%20Delivery,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        } else if (formapago11 == undefined && this.pedido.delivery && this.pedido.zonadelivery.idzona != 'id123') {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20' + '*USD' + this.pedido.zonadelivery.precio.toFixed(2) + '*' + '%20por%20Servicio%20Delivery,%20¿Me%20indica%20por%20favor%20las%20formas%20de%20Pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        } else if (formapago11 != undefined && this.pedido.delivery && this.pedido.zonadelivery.idzona == 'id123') {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20de%20Pedido%20y%20deseo%20cotizar%20disponibilidad%20de%20Servicio%20Delivery,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        } else if (formapago11 == undefined && this.pedido.delivery && this.pedido.zonadelivery.idzona == 'id123') {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20de%20Pedido%20y%20deseo%20cotizar%20disponibilidad%20de%20Servicio%20Delivery,%20¿Me%20indica%20por%20favor%20las%20formas%20de%20Pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        } else if (formapago11 == undefined && !this.pedido.delivery) {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20¿Me%20indica%20por%20favor%20las%20formas%20de%20Pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        } else if (formapago11 != undefined && !this.pedido.delivery && (this.pedido.detallePedido.trim()).substr(-8) != 'COMERCIO') {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + '%20y%20requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        } else if (formapago11 != undefined && !this.pedido.delivery && (this.pedido.detallePedido.trim()).substr(-8) == 'COMERCIO') {
          //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
          let link = 'https://wa.me/' + this.pedido.telefonoComer.trim() + '?text=Hola%20soy%20' + this.pedido.cliente.nombre.trim() + ',%20*me%20encuentro%20en%20el%20comercio*%20y%20requiero%20concretar%20la%20orden%20https://entregamovil.com/orden/' + this.pedido.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
            + '*' + this.pedido.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
          window.open(link, '_blank');
        }
      } else if (await res.data == null) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover3 = await this.loadingController.getTop();
        if (popover3) {
          await popover3.dismiss(null);
        }
        //si la respuesta al realizar el pedido viene con error, es decir si res=null
        const alert2 = await this.alertController.create({
          cssClass: 'normal',
          header: 'Información',
          backdropDismiss: false,
          message: "Ocurrió un problema de conexión al procesar su pedido,<b>¿Desea reintentar enviarlo?</b>",
          buttons: [
            {
              text: 'NO',
              role: 'cancel',
              cssClass: 'normal',
              id: 'cancel-button',
              handler: async () => {
                window.location.reload();
                await this.ngOnDestroy();
              }
            }, {
              text: 'SI',
              id: 'confirm-button',
              handler: async () => {
                await this.enviarPedido(pedidoConfirmado, formapago11)
              }
            }
          ]
        });
        await alert2.present();
      } else if (await res.data == 'dif') {
        //esto limpia this.pedido.detallePedido los casos de cancelacion del envio en el punto de seleccion de forma de pago
        if ((this.pedido.detallePedido.trim()).substr(-8) == 'COMERCIO') {
          let replace = this.pedido.detallePedido.replace(' => CLIENTE PRESENTE EN COMERCIO', '');
          this.pedido.detallePedido = replace;
        }
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover33 = await this.loadingController.getTop();
        if (popover33) {
          await popover33.dismiss(null);
        }
        this.pedido.productos = [];
        this.pedido.precioTotal = 0;
        return await this.presentToast4('Durante el procesamiento de su pedido, algún producto dejó de estar disponible ó cambió de precio, por favor realice el pedido nuevamente');
      }
    });
  }

  //opcion para reenviar pedido al comercio via WhatSap si así se desea, en caso contrario manda al home
  async reenvioWhatsapp(pedidowhat: Pedido, formapago: any[]) {
    let formapago11: any[] = [];
    formapago11 = formapago;
    this.formaPagototal = '';
    //en este for se agregan las formas de pago al mensaje whatssap
    if (formapago11 != undefined) {
      for await (let formaspag of formapago11) {
        if (this.formaPagototal == '') {
          this.formaPagototal = this.formaPagototal + ' ' + '*' + formaspag;
        } else if (this.formaPagototal != '') {
          this.formaPagototal = this.formaPagototal + ' ' + formaspag;
        }
      }
    }

    const alert3 = await this.alertController.create({
      cssClass: 'normal',
      header: 'LEA Cuidadosamente',
      message: "¿Desea notificar su pedido por un total de <b>" + pedidowhat.precioTotal.toFixed(2) + "$</b>, al comercio <b>" + pedidowhat.nombreComercio + "</b> mediante WhatsApp?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          //cssClass: 'normal',
          id: 'cancel-button',
          handler: async () => {
            await this.verOrden();
            //luego navego a ver el estado de la orden en tiempo real
            await this.router.navigate(['/orden/' + pedidowhat.id]);
          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: () => {
            //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
            if (formapago11 != undefined && pedidowhat.delivery && pedidowhat.zonadelivery.idzona != 'id123') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ' y ' + ' requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20' + '*USD' + pedidowhat.zonadelivery.precio.toFixed(2) + '*' + '%20por%20Servicio%20Delivery,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            } else if (formapago11 != undefined && pedidowhat.delivery && pedidowhat.zonadelivery.idzona == 'id123') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ' y ' + ' requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + '%20de%20Pedido%20y%20deseo%20cotizar%20disponibilidad%20de%20Servicio%20Delivery,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            } else if (formapago11 == undefined && !pedidowhat.delivery) {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ' y ' + ' requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + ' %20¿Me%20indica%20por%20favor%20las%20formas%20de%20Pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            } else if (formapago11 == undefined && pedidowhat.delivery && pedidowhat.zonadelivery.idzona == 'id123') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ' y ' + ' requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + '%20de%20Pedido%20y%20deseo%20cotizar%20disponibilidad%20de%20Servicio%20Delivery,%20¿Me%20indica%20por%20favor%20las%20formas%20de%20Pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            } else if (formapago11 == undefined && pedidowhat.delivery && pedidowhat.zonadelivery.idzona != 'id123') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ' y ' + ' requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20' + '*USD' + pedidowhat.zonadelivery.precio.toFixed(2) + '*' + '%20por%20Servicio%20Delivery,%20¿Me%20indica%20por%20favor%20las%20formas%20de%20Pago?%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            } else if (formapago11 != undefined && !pedidowhat.delivery && (pedidowhat.detallePedido.trim()).substr(-8) != 'COMERCIO') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ' y ' + ' requiero%20pagar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            } else if (formapago11 != undefined && !pedidowhat.delivery && (pedidowhat.detallePedido.trim()).substr(-8) == 'COMERCIO') {
              //Se envia un enlace para el Whatssap del comercio con la informacion del pedido
              let link = 'https://wa.me/' + pedidowhat.telefonoComer.trim() + '?text=Hola%20soy%20' + pedidowhat.cliente.nombre.trim() + ',%20*me%20encuentro%20en%20el%20comercio*%20y%20requiero%20concretar%20la%20orden%20https://entregamovil.com/orden/' + pedidowhat.id.trim() + ' ' + 'por%20un%20total%20de%20*USD* '
                + '*' + pedidowhat.precioTotal.toFixed(2) + '*' + '%20de%20Pedido,%20forma%20de%20pago%20' + this.formaPagototal + '*' + ' ,' + '%20*POR*%20*FAVOR*%20*NO*%20*MODIFIQUE*%20*ESTE*%20*MENSAJE*';
              window.open(link, '_blank');
              //vuelvo a llamar esta funcion de reenvio de pedido al whatssap a los 3 seg
              if (link != '') {
                setTimeout(() => {
                  this.reenvioWhatsapp(pedidowhat, formapago11)
                }, 3000);
              }
            }
          }
        },
        {
          text: 'VER ESTADO DE MI PEDIDO',
          id: 'confirm-button',
          handler: async () => {
            await this.copiaTexto(pedidowhat.id);
            await this.verOrden();
            //luego navego a ver el estado de la orden en tiempo real
            await this.router.navigate(['/orden/' + pedidowhat.id]);
          }
        }

      ]
    });

    //si en 30 segundos no hay respuesta se cierra y manda al estado de la orden
    setTimeout(async () => {
      this.contador3 = true;
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
      const popover1 = await this.loadingController.getTop();
      if (popover1) {
        await popover1.dismiss(null);
      }
      //cierro el cuadro de reenvio de pedido por whatssap en pantalla
      if (alert3) {
        await alert3.dismiss(null);
      }
      await this.verOrden();
      //luego navego a ver el estado de la orden en tiempo real
      await this.router.navigate(['/orden/' + pedidowhat.id]);
    }, 30000);

    if (!this.contador3) {
      await alert3.present();
    }

  }

  //funcion para copiar Pedido
  async copiaTexto(idpedido: string) {
    let inputFalso = document.createElement('input');
    inputFalso.setAttribute('value', 'https://entregamovil.com/orden/' + idpedido);
    document.body.appendChild(inputFalso);
    inputFalso.select();
    document.execCommand("copy");
    document.body.removeChild(inputFalso);
    return await this.presentToast1('Ref: ' + idpedido.substr(-5), ', pedido copiado al portapapeles')
  }


  //creo funcion para seleccion de categorias de menu
  selectCategoria(categoria: string) {
    this.selectCatego = categoria;
    this.selectCategotodo = "";
    this.verBusqueda = false;
    this.valorbusqueda = '';
  }

  //creo funcion para seleccion de todas las categorias Menu
  selectCategoriatodo() {
    this.selectCategotodo = "TODO";
    this.verBusqueda = false;
    this.valorbusqueda = '';
    this.productosGet2 = this.productosGet3;
    this.categoProduct2 = this.categoProduct3;
  }

  //este metodo trae el componente googlemaps en modo popup
  async getGooglemaps() {

    //contador de veces que se ha llamada el mapa en un inicio de pedido, maximo 3
    this.cuentaMapa = this.cuentaMapa + 1;
    if (this.cuentaMapa > 3) {
      await this.presentLoading44();
      return setTimeout(() => {
        window.location.reload();
      }, 5000);
    }

    const modal = await this.modalControler.create({
      component: GooglemapsComponent,
      backdropDismiss: false,
      //cssClass: 'ion-modal',
      cssClass: 'fullscreen',
    });
    await modal.present();
  }

  async getComercio(uidUserMenu: string) {
    //creo la ruta para obtener los datos del comercio
    const path = 'precomercio/';
    const id = uidUserMenu;
    //obtengo el comercio asociado al id(telefono registrado de comercio)
    await this.firestore.getCollection5<preComercio>(path, ref => (ref.where('telefono', '==', id)).limit(1)).then(async comercio1 => {
      if (comercio1.empty == false) {
        await comercio1.forEach(async (doc: any) => {
          //guardo id de comercio
          this.uidComer = doc.id;
          //obtengo el comercio asociado al id capturado
          this.getComerciosSuscriber = this.firestore.getDoc<preComercio>(path, this.uidComer).subscribe(async (comercio: preComercio) => {
            //le asigno el comercio a If comercio
            this.ifComercio = comercio;

            //se agrego este if al 26-02-23, si el comercio esta fuera de linea no muestra el cuadro de desafio recpacha
            this.capVisible = true;
            if ((this.ifComercio.estatus != 'ACTIVO' || this.ifComercio.estado == false) && this.captcha == '') {
              this.capVisible = false;
              return this.presentToast(this.ifComercio.nombrelocal, ' se encuentra fuera de linea en este momento, por favor informe al comercio')
            }

            //se desactiva el recapcha solo cuando el mismo comercio requiere ver su menu
            if (this.uidUserlog == this.uidComer) {
              this.captcha = 'ok';
            }

            /*se agrego este if al 26-02-23, guarda relación con el mostrado de datos de comercio y foto al momento de lamar el menu o catalogo
            solo llama los productos cuando el recapcha esta resuelto*/
            //if (this.captcha != '') { 06/03/24 se habilita carga de productos sin validacion recapch
            if (this.captcha != '' || this.captcha === '') { //|| this.captcha === '' agregado al 06/03/24 para evaluacion de no dependencia de recapch
              //se crea el primer condicional para plan sin foto instantanea
              if (this.ifComercio != undefined && this.ifComercio.estatus == 'ACTIVO' && this.ifComercio.estado == true && (this.ifComercio.plan == 'AA' || this.ifComercio.plan == 'BB' || this.ifComercio.plan == 'CC' || this.ifComercio.plan == 'DD' || this.ifComercio.plan == 'EE' || this.ifComercio.plan == 'GG')) {
                //lanzo mensaje de procesando Menu en pantalla solo cuando no existan productos cargados
                if (!this.productosGet2.length) {
                  await this.presentLoading();
                }
                //obtengo rutas para productos  segun el comercio encontrado
                const path2 = 'usuarios/' + this.uidComer + '/productos/';
                //llamo la funcion para recargar la vista a la base de datos, solo this.productosGet esta vacio,
                //así me aseguro que solo se hará la consulta cuando yo decida limpiarlo 
                if (this.productosGet2.length == 0) {
                  //cargo los productos
                  this.buscarproducto.productosCap(path2);
                }

                //En la respuesta de esta coleccion, obtengo datos de comerio dede el servicio Subject
                this.getProductosSuscriber = this.buscarproducto.getProductos().subscribe(async resproducto => {
                  if (await resproducto) {
                    //igualo el array de productos a la respuesta del observador
                    this.productosGet2 = resproducto;
                    //await this.getfotoProductos();

                    //se llena el 2do array para filtrar productos
                    this.productosGet3 = this.productosGet2

                    //Utilizo funcion predefinida de (a,b) para iniciar el Orden del Array por nombreproducto
                    this.productosGet2.sort(function (a, b) {
                      if (a.nombreProducto < b.nombreProducto) {
                        return -1;
                      } else if (a.nombreProducto > b.nombreProducto)
                        return 1;
                      else
                        return 0;
                    });

                    //declaro array para tomar las categorias de los productos
                    let categoProduct = [];
                    for (let catego of this.productosGet2) {
                      if (catego.disponible) {
                        categoProduct.push(catego.categoria)
                      }
                    }

                    //creo un filtro para eliminar categorias duplicadas
                    this.categoProduct2 = categoProduct.filter((element, index, array) => {
                      return index === array.indexOf(element);
                    })
                    //Utilizo funcion predefinida de (a,b) para Ordenar el array de categorias
                    this.categoProduct2.sort(function (a: string, b: string) {
                      if (a < b) {
                        return -1;
                      } else if (a > b)
                        return 1;
                      else
                        return 0;
                    });

                    //guardo el array de categroias original para ser utilizado en el filtrado
                    this.categoProduct3 = this.categoProduct2;
                  }
                  //cierro mensaje de descarga de menú
                  const popover3 = await this.loadingController.getTop();
                  if (popover3) {
                    await popover3.dismiss(null);
                  }
                });

                //Se crea el segundo condicional para plan con foto instantanea
              } else if (this.ifComercio != undefined && this.ifComercio.estatus == 'ACTIVO' && this.ifComercio.estado == true && (this.ifComercio.plan == 'AF' || this.ifComercio.plan == 'BF' || this.ifComercio.plan == 'CF' || this.ifComercio.plan == 'DF' || this.ifComercio.plan == 'EF' || this.ifComercio.plan == 'GF')) {
                //lanzo mensaje de procesando Menu en pantalla solo cuando no existan productos cargados
                if (!this.productosGet2.length) {
                  await this.presentLoading();
                }
                //obtengo rutas para productos  segun el comercio encontrado
                const path2 = 'usuarios/' + this.uidComer + '/productos/';
                //llamo la funcion para recargar la vista a la base de datos, solo this.productosGet esta vacio,
                //así me aseguro que solo se hará la consulta cuando yo decida limpiarlo 
                if (this.productosGet2.length == 0) {
                  //cargo los productos
                  this.buscarproducto.productosCap(path2);
                }
                //En la respuesta de esta coleccion, obtengo datos de comerio dede el servicio Subject
                this.getProductosSuscriber = this.buscarproducto.getProductos().subscribe(async resproducto => {
                  if (await resproducto) {
                    //igualo el array de productos a la respuesta del observador
                    this.productosGet2 = resproducto;

                    //se llena el 2do array para filtrar productos
                    this.productosGet3 = this.productosGet2

                    //Utilizo funcion predefinida de (a,b) para iniciar el Orden del Array por nombreproducto
                    this.productosGet2.sort(function (a, b) {
                      if (a.nombreProducto < b.nombreProducto) {
                        return -1;
                      } else if (a.nombreProducto > b.nombreProducto)
                        return 1;
                      else
                        return 0;
                    });
                    //declaro array para tomar las categorias de los productos
                    let categoProduct = [];
                    for (let catego of this.productosGet2) {
                      if (catego.disponible) {
                        categoProduct.push(catego.categoria)
                      }
                    }
                    //creo un filtro para eliminar categorias duplicadas
                    this.categoProduct2 = categoProduct.filter((element, index, array) => {
                      return index === array.indexOf(element);
                    })
                    //Utilizo funcion predefinida de (a,b) para Ordenar el array de categorias
                    this.categoProduct2.sort(function (a: string, b: string) {
                      if (a < b) {
                        return -1;
                      } else if (a > b)
                        return 1;
                      else
                        return 0;
                    });

                    //guardo el array de categroias original para ser utilizado en el filtrado
                    this.categoProduct3 = this.categoProduct2;
                    //cierro mensaje de descarga de menú
                    const popover3 = await this.loadingController.getTop();
                    if (popover3) {
                      await popover3.dismiss(null);
                    }
                    //cargo las fotos de los productos
                    await this.getfotoProductos()
                  }
                });
              } else if (this.ifComercio.estatus != 'ACTIVO' || this.ifComercio.estado == false) {
                return this.presentToast(this.ifComercio.nombrelocal, ' se encuentra fuera de linea en este momento, por favor informe al comercio')
              }
            }
          })
        })
      } else if (comercio1.empty) {
        return this.presentToast('¡Ups! parece que no se encontró ningún comercio afiliado con el número telefónico: ', uidUserMenu + ', intentelo nuevamente')
      }
    })
  }


  //filtro de productos
  searchProducto(event) {
    this.valorbusqueda = event.target.value;
    this.categoProduct2 = [];
    this.productosGet2 = this.productosGet3.filter((producto: Productos) => {
      return ((producto.nombreProducto.toLowerCase().indexOf((this.valorbusqueda.toLowerCase().split(' ').pop().split(' ').shift()).trim()) > -1));
    });

    //declaro array para tomar las categorias de los productos durante el filtrado
    for (let catego of this.productosGet2) {
      this.categoProduct2.push(catego.categoria)
    }

    //creo un filtro para eliminar categorias duplicadas durante el filtrado
    this.categoProduct2 = this.categoProduct2.filter((element, index, array) => {
      return index === array.indexOf(element);
    })


  }


  //metodo para Obtener Datos del Comercio
  /*
  async getComercio(uidUserMenu:string){
     //creo la ruta para obtener los datos del comercio
     const path='usuarios/';
     const id=uidUserMenu;
    //obtengo el comercio asociado al id(telefono registrado de comercio)
    this.getComerciosSuscriber=this.firestore.getCollection3<User>(path,ref=>(ref.where('telefono','==',id)).limit(1)).subscribe(async comercio=>{
      //le asigno el comercio a If comercio
      this.ifComercio=comercio;
      //Me desuscribo de observable de usuarios para liberar recursos
     this.getComerciosSuscriber.unsubscribe;
      if(comercio.length && comercio[0].estatus=='ACTIVO' && comercio[0].estado==true){
        //lanzo mensaje de procesando Menu en pantalla solo cuando no existan productos cargados
        if(!this.productosGet2.length){
         await this.presentLoading(); 
        }
        //obtengo rutas para productos  segun el comercio encontrado
        const path2='usuarios/'+this.ifComercio[0].uid+'/productos/';
   
  
        //llamo la funcion para recargar la vista a la base de datos, solo this.productosGet esta vacio,
        //así me aseguro que solo se hará la consulta cuando yo decida limpiarlo 
        if(this.productosGet2.length==0){
          //cargo los productos
          this.buscarproducto.productosCap(path2);
        }
  
          //En la respuesta de esta coleccion, obtengo datos de comerio dede el servicio Subject
          this.getProductosSuscriber=this.buscarproducto.getProductos().subscribe(async resproducto=>{
            if (await resproducto) {
                //igualo el array de productos a la respuesta del observador
                this.productosGet2=resproducto; 
        
                //await this.getfotoProductos();
  
                //declaro array para tomar las categorias de los productos
                let categoProduct=[];
                for (let catego of this.productosGet2){
                  if(catego.disponible){
                    categoProduct.push(catego.categoria)
                  }
                }
  
              //creo un filtro para eliminar categorias duplicadas
              this.categoProduct2=categoProduct.filter((element,index,array)=>{
                return index===array.indexOf(element);   
              })
              //Utilizo funcion predefinida de (a,b) para Ordenar el array de categorias
              this.categoProduct2.sort(function (a: string,b: string){
                if(a<b){
                  return -1;
                }else if(a>b)
                  return 1;
                else
                  return 0;
              }); 
            }
            //cierro mensaje de descarga de menú
              const popover3 = await this.loadingController.getTop();
              if (popover3){
                await popover3.dismiss(null); 
              }
          });
      }else if(this.ifComercio.length && (this.ifComercio[0].estatus!='ACTIVO' || this.ifComercio[0].estado==false)){
        return this.presentToast(this.ifComercio[0].nombrelocal,' se encuentra fuera de linea en este momento, por favor informe al comercio')
      }else if(!comercio.length && !this.ifComercio.length) {
        return  this.presentToast('¡Ups! parece que no se encontró ningún comercio afiliado con el número telefónico: ',uidUserMenu+', intentelo nuevamente')
       }
    })
  }
  */

  //creo funcion para agregar foto de productos al array que carga los productos del menu
  async getfotoProductos() {
    //Utilizo funcion predefinida de (a,b) para iniciar el Orden del Array por categoria y el llenado de foto sea en orden
    this.productosGet2.sort(function (a, b) {
      if (a.categoria < b.categoria) {
        return -1;
      } else if (a.categoria > b.categoria)
        return 1;
      else
        return 0;
    });
    //en la primera consulta this.productosGet4 esta vacio y se llena, para reservar las fotos de productos para que en caso
    //que ocurra una actualizacion de productos por parte del comercio no se vuelva a consultar la base de datos
    if (!this.productosGet4.length) {
      for (let producto2 of this.productosGet2) {
        let pathfoto = 'usuarios/' + this.uidComer + '/fotoproductos/';
        await this.buscarproducto.getFoto(pathfoto, producto2.idProducto).then(async fotoproduct => {
          let valor: any;
          valor = fotoproduct.data();
          //le asigno al campo urlfoto la foto encontrada
          producto2.urlfoto = valor.datafoto;
          //se carga array con productos para respladar fotos cargadas
          this.productosGet4.push(producto2)
        }).catch(() => {
          producto2.urlfoto = "";
          this.productosGet4.push(producto2)
        })
      }
      //entrará a este else solo si durante la consulta de catalogo o menu el comercio ha actualizado algún producto
    } else if (this.productosGet4.length) {
      for (let producto2 of this.productosGet2) {
        for (let producto3 of this.productosGet4) {
          if (producto2.idProducto === producto3.idProducto) {
            producto2.urlfoto = producto3.urlfoto
          }
        }
      }
    }
  }

  //se crea función para ver foto del producto solicitada
  async verfotoProducto(producto: Productos) {
    //solo consulto la foto si no ha sido previamente cargadas
    if (producto.urlfoto == '') {
      //creo la ruta para obtener las fotos de productos del usuario logeado
      ///const pathfoto='usuarios/'+this.ifComercio[0].uid+'/fotoproductos/';
      const pathfoto = 'usuarios/' + this.uidComer + '/fotoproductos/';
      //consulto la foto
      await this.buscarproducto.getFoto(pathfoto, producto.idProducto).then(async fotoproduct => {
        let valor: any;
        valor = fotoproduct.data();
        //le asigno al campo urlfoto la foto encontrada
        for (let producto2 of this.productosGet2) {
          if (producto2.idProducto === valor.idproducto && producto2.urlfoto == '') {
            producto2.urlfoto = valor.datafoto;
          }
        }
        this.modalpruebas(producto.nombreProducto, valor.datafoto);
      })
    } else if (producto.urlfoto != '') {
      this.modalpruebas(producto.nombreProducto, producto.urlfoto);
    }
  }


  modalpruebas(nombrepro: string, urlfoto: string) {
    this.fotovista = nombrepro;
    this.fotovista2 = urlfoto;
  }

  //mediante esta funcion voy agregango pedidos al carrito
  addCarrito(producto: Productos) {
    this.carritoService.addProducto(producto);
    //aqui se manejan variables para efecto de precio y cantidad
    this.efectPreciototal = true;
    //habilita el boton para continuar con el pedido
    this.continuarPago = true;
    //se resetean los 3 valores del filtro de producto
    //this.verBusqueda=false;
    this.valorbusqueda = '';
    //le asigno la cantidad a la variable cuentaProd para mostrarla en pantalla
    for (let listaPro of this.pedido.productos) {
      if (listaPro.producto.idProducto == producto.idProducto) {
        this.cuentaProd = listaPro.cantidad;
      }
    }

    setTimeout(() => {
      this.efectPreciototal = false;
    }, 500);
  }

  //mediante esta funcion voy rebajando articulos al pedido
  removeProducto(producto: Productos) {
    this.carritoService.removeProducto(producto);


    if (this.pedido.productos.length == 0) {
      //deshabilita el boton para continuar con el pedido
      this.continuarPago = false;
    }

  }

  cierraModal() {
    this.fotovista2 = '';
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast(comercio: string, mensaje: string) {
    const toast = await this.toastController.create({
      message: comercio + mensaje,
      cssClass: 'toast-background',
      duration: 10000,
      position: 'middle',
    });
    await toast.present();
  }


  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast1(comercio: string, mensaje: string) {
    const toast1 = await this.toastController.create({
      message: comercio + mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 6000,
      position: 'middle',
    });
    await toast1.present();
  }

  //configuracion de la funcion que muestra mensaje de internet sin conexion
  async presentToasti(comercio: string) {
    const toast11 = await this.toastController.create({
      message: comercio,
      cssClass: 'toast-background',
      //duration: 2000,
      position: 'top',
    });
    await toast11.present();
  }

  //configuracion de la funcion que muestra mensaje de internet sin conexion
  async presentToastiii(comercio: string) {
    const toast13 = await this.toastController.create({
      message: comercio,
      cssClass: 'toast-background',
      duration: 4000,
      position: 'top',
    });
    await toast13.present();
  }

  //configuracion de la funcion que muestra mensaje de internet con conexion
  async presentToastii(comercio: string) {
    const toast12 = await this.toastController.create({
      message: comercio,
      color: 'success',
      cssClass: 'toast-background',
      duration: 2000,
      position: 'top',
    });
    await toast12.present();
  }

  //configuracion de la funcion que muestra mensaje de alerta
  async presentToast4(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 5000,
      position: 'middle'
    });
    await toast2.present();
  }

}
