import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Pedido, PedidoAsignado, RepartidorAfiliado, User, ZonaDelivery2 } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EstadorepartidorService } from 'src/app/services/estadorepartidor.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { PopuprepartidorComponent } from '../popuprepartidor/popuprepartidor.component';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss'],
})
export class PedidoComponent implements OnInit {

  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLog: any = 0;
  //array que guarda los pedidos
  pedidos: Pedido[] = [];
  //variable que guarda dinamicamente el texto que se introduce en la busqueda del pedido
  textPedido = '';
  //variable que almacena dinamicamente el pedcamposlatcomerido 
  searchedPedido: Pedido[] = [];
  //en esta variable guardo el pedido asociado al numero de orden
  cargaPedido: Pedido[] = [];
  //Declaro una variable para el mensaje de espera en pantalla, relacionada a la funcion presentLoading()
  private loading: any;
  private loading2: any = undefined;
  //creo un array para definir las formas de pago
  formasPago: string[] = [];
  //creo variable que oculta boton de registrar pago y habilita campos de registro de pago
  activaregistraPago = false;
  //declaracion de Observables para aplicar la desuscripcion
  public getdataSuscriber: Subscription;
  public getPedidosSuscriber: Subscription = undefined;
  public getPedidoSuscriber: Subscription = undefined;
  public getRepartidoresActivosSuscriber: Subscription = undefined;
  public getRepartidoresActivosSuscriber2: Subscription = undefined;
  private getformasPagoSuscriber: Subscription = undefined;

  //coordenadas del Comercio para pintarla en el mapa
  latComer: number = 0;
  lngComer: number = 0;

  //variable para ver la ubicacion del cliente de mejor manera
  verUbicauser: boolean = false;

  //variables relacionadas a la vist previa del mapa
  myUrl: any;
  myTrustedURL: any;

  traeRepartidores: RepartidorAfiliado[] = [];

  //trae ubicaciones de repartidores
  getUbicaciones: any[] = [];
  Pedidosasignados: any[] = [];
  getRepartidorasignado = '';
  getTelefrepar = '';
  getNombrerepar = '';

  /*aplico un suiche en la creacion del pedido que me asegura que el repartidor asignado realizo todo el algoritmo
  de seleccion*/
  suicheAsignacion: boolean;

  //esta variable activa o desactiva el array de pedidos recibidos
  public verPendientes = false;

  //defino valor de temporizador para enviar informacion con Eventemmiter cuando el observavble deje de transmitir
  public contador: any = undefined;

  //estas variables cuentan las ordenes segun su estado para la notificacion automatica
  cuentaPagado = 0;
  cuentaRechazado = 0;
  cuentaCancelado = 0;
  cuentaVisto = 0;
  cuentaDevuelto = 0;
  cuentaTotal = 0;

  //creo una variable para un contador
  timerPedido: string;
  timerReset: any;

  //desactiva boton de delivery automatico
  verDeliveryauto = true;

  //cuando se seleccione efectivo el sistema preguntara estas opciones
  detalleEfectivo: string[] = ["COBRADO", "lo cobrará repartidor", "lo cobrará comercio"];
  detalleEfectivo2 = '';
  detalleEfectivo3: string[] = ["COBRADO", "lo cobrará comercio"];

  //estas variables llaman mis claves api de mapas
  private apikey1 = environment.mapsConfig.apikey1
  private apikey2 = environment.mapsConfig.apikey2
  //esta variable carga la apihey definitiva para mostrar en la orden
  apik: string;

  //variable que guarda correo del comercio
  private emailComercio: string;

  constructor(private firestore: FirestoreService,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private modalControler: ModalController,
    public traeUid: UserlogedService,
    private sanitizer: DomSanitizer,
    private estadorepartidor: EstadorepartidorService,
    private carritoService: CarritoService,
    private alertController: AlertController,
    private platform: Platform) {

    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.getdataSuscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        //console.log('recibiendo UID del usuario logueado desde el com principal...',res.uid);
        this.uidUserLog = res.uid;
        //guargo los valores de ubicacion del comercio
        this.latComer = res.ubicacionpri.lat;
        this.lngComer = res.ubicacionpri.lng;
        //se captura el correo del comercio
        this.emailComercio = res.email;
        //se llaman todas las ordenes del comercio
        this.getOrdenes(this.uidUserLog);
        //cargo las formas de pago del comercio
        this.getFormaspago(this.uidUserLog);

        //proceso la url por api segun si es dispositivo android o web
        if (this.platform.is('cordova')) {
          this.apik = this.apikey2
        } else {
          this.apik = this.apikey1
        }
      }
    })


  }


  async ngOnInit() {
    //recibe data cuando se cierra popup
    this.recibeDatapopup();
  }


  //cargo las formas de pago del comercio
  async getFormaspago(uidComer: string) {
    const pathf = 'usuarios/' + uidComer + '/formapago';
    this.getformasPagoSuscriber = this.firestore.getCollection3<any>(pathf, ref => (ref).where('disponible', '==', true)).subscribe(async formasPag => {
      this.formasPago = [];
      if (formasPag) {
        formasPag.forEach((doc: any) => {
          this.formasPago.push(doc.formapago)
        })
        //le aplico reduccion de duplicados
        this.formasPago = Array.from(this.formasPago.reduce((map, obj) => map.set(obj, obj), new Map()).values());
      }
    })
  }


  //Obtengo en un array todos los comercios
  getOrdenes(userUid: string) {
    //aplico desuscripcion si la hay
    if (this.getPedidosSuscriber != undefined) {
      this.getPedidosSuscriber.unsubscribe();
    }
    //creo la ruta para obtener los datos del comercio
    const path = 'usuarios/' + userUid + '/pedido/';
    //la fecha de busqueda de pedidos incluye,ayer a partir de las 3pm y el dia actual 
    let today = new Date()
    let yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setHours(15, 0, 0, 0);
    let end = new Date(today.getTime());
    end.setHours(23, 59, 59, 999);
    this.getPedidosSuscriber = this.firestore.getCollection3<Pedido>(path, ref => (ref.where('fechaPedido', '>=', yesterday).where('fechaPedido', '<=', end)).orderBy('fechaPedido', 'desc')).subscribe(async pedido => {
      this.pedidos = [];
      //convertimos la fecha para que sea legible en la impresion html
      pedido.forEach((element: any) => {
        element.fechaPedido = element.fechaPedido.toDate(),
          this.pedidos.push(element)
      })
      //en esta zona procedo a contabilizar estado de ordenes para notificacion
      this.filtraPedidos();
    });
  }

  //filtra solo pedidos para ver en el ion-fab-button como pendientes
  async filtraPedidos() {
    this.cuentaPagado = 0;
    this.cuentaCancelado = 0;
    this.cuentaRechazado = 0;
    this.cuentaVisto = 0;
    this.cuentaDevuelto = 0;
    this.cuentaTotal = 0;
    this.searchedPedido = [];
    for await (let pedidoestatus of this.pedidos) {
      if (pedidoestatus.estado == 'PAGADO') {
        this.cuentaPagado = this.cuentaPagado + 1;
        this.cuentaTotal = this.cuentaTotal + 1;
        this.searchedPedido.push(pedidoestatus);
        this.verDeliveryauto = true;
      }
      if (pedidoestatus.estado == 'RECHAZADAREPARTIDOR') {
        this.cuentaRechazado = this.cuentaRechazado + 1;
        this.cuentaTotal = this.cuentaTotal + 1;
        this.searchedPedido.push(pedidoestatus);
        this.verDeliveryauto = true;
      }
      if (pedidoestatus.estado == 'CANCELADOREPARTIDOR') {
        this.cuentaCancelado = this.cuentaCancelado + 1;
        this.cuentaTotal = this.cuentaTotal + 1;
        this.searchedPedido.push(pedidoestatus);
        this.verDeliveryauto = true;
      }
      if (pedidoestatus.estado == 'VISTOPORCOMERCIO') {
        this.cuentaVisto = this.cuentaVisto + 1;
        this.cuentaTotal = this.cuentaTotal + 1;
        this.searchedPedido.push(pedidoestatus);
        this.verDeliveryauto = true;
      }
      if (pedidoestatus.estado == 'DEVUELTO') {
        this.cuentaDevuelto = this.cuentaDevuelto + 1;
        this.cuentaTotal = this.cuentaTotal + 1;
        this.searchedPedido.push(pedidoestatus);
        this.verDeliveryauto = true;
      }
    }
    //le aplico reduccion de duplicados
    this.searchedPedido = Array.from(this.searchedPedido.reduce((map, obj) => map.set(obj.id, obj), new Map()).values());
  }

  //funcion para autorizar URL de carga de mapa de area de entrega
  procesaUrl() {
    this.myTrustedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.myUrl);
  }

  async ngOnDestroy() {
    //aplico desuscripcion de observables de este componenete al finalizarlo
    this.getdataSuscriber.unsubscribe();
    this.getPedidosSuscriber.unsubscribe();
    if (this.getRepartidoresActivosSuscriber != undefined) {
      this.getRepartidoresActivosSuscriber.unsubscribe();
    }
    if (this.getRepartidoresActivosSuscriber2 != undefined) {
      this.getRepartidoresActivosSuscriber2.unsubscribe();
    }
    if (this.getPedidoSuscriber != undefined) {
      this.getPedidoSuscriber.unsubscribe();
    }
    this.estadorepartidor.desuscribeObservables();
  }


  //creo funcion para filtrar los pedidos segun el nombre que voy colocando
  async searchPedido(event) {
    this.verPendientes = true;
    this.verDeliveryauto = true;
    this.searchedPedido = []
    this.textPedido = event.target.value;
    if (this.textPedido != '' && this.textPedido.trim() != "" && event.target.value.length > 2) {
      this.searchedPedido = this.pedidos.filter((pedido: Pedido) => {
        return ((pedido.id.toLowerCase().indexOf(this.textPedido.toLowerCase()) > -1) ||
          (pedido.cliente.nombre.toLowerCase().indexOf(this.textPedido.toLowerCase()) > -1));
      });
    }
  }


  //Funcion que abre la orden desde la barra de busqueda 
  async openOrder(orden: Pedido) {
    //variable desactiva la vista del registro de pago
    this.activaregistraPago = false;
    //la vista con mas zoom de ubicacion de usuario se cambia
    this.verUbicauser = false;
    //this.verpendientes lo paso a false
    this.verPendientes = !this.verPendientes;
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    //agrego los path para guardado
    const path = 'usuarios/' + this.uidUserLog + '/pedido/';
    //actualizo el pedido a visto por el comercio solo cuando la orden sea igual a ENVIADO
    if (orden.estado == 'ENVIADO') {
      //orden.estado="VISTOPORCOMERCIO";
      await this.firestore.updateDoc<Pedido>({ estado: "VISTOPORCOMERCIO", fechaEntrega: new Date() }, path, orden.id).then(() => {

      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover = await this.loadingController.getTop();
        if (popover) {
          await popover.dismiss(null);
        }
        //muestro mensaje de problemas en la actualizacion del pedido
        await this.presentToast('No se pudo actualizar el ESTADO del pedido, por favor busque el pedido nuevamente ')
      });
    }
    //si existe suscripcion se elimina, para que solo tenga en memoria la que esta cargada y replique solo los cambios de la cargada en pantalla
    if (this.getPedidoSuscriber != undefined) {
      this.getPedidoSuscriber.unsubscribe();
    }
    //me suscribo a un observable para replicar los datos del pedido seleccionado 
    this.getPedidoSuscriber = this.firestore.getDoc<Pedido>(path, orden.id).subscribe(async pedidoGet => {
      if (pedidoGet) {
        //trae el pedido del usuario seleccionado
        this.cargaPedido[0] = await pedidoGet;
        //se conviertembede la fecha a formato legible por html
        this.cargaPedido[0].fechaPedido = await pedidoGet.fechaPedido.toDate();
        this.cargaPedido[0].fechaEntrega = await pedidoGet.fechaEntrega.toDate();

        //proceso la url por api segun si esd dispositivo android o web
        if (this.platform.is('cordova')) {
          //guardo la direccion que va a cargar el mapa embed
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + this.cargaPedido[0].ubicacionDespacho.lat + ',' + this.cargaPedido[0].ubicacionDespacho.lng + "&zoom=16"
        } else {
          //guardo la direccion que va a cargar el mapa embed
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + this.cargaPedido[0].ubicacionDespacho.lat + ',' + this.cargaPedido[0].ubicacionDespacho.lng + "&zoom=16"
        }

        //proceso la url de forma segura
        this.procesaUrl();
        //limpio this.textpedido
        this.textPedido = "";
        //cosulto el nombre y correo del repartidor asignado
        if (this.cargaPedido[0].uidRepartidor != '') {
          const path5 = 'usuarios/' + this.cargaPedido[0].uidRepartidor;
          await this.firestore.getCollection4<User>(path5).then(async repa => {
            let valor3: User;
            valor3 = repa.data();
            this.getRepartidorasignado = valor3.nombre + ' ' + valor3.apellido + ', ' + valor3.email + ', ' + valor3.telefono;
            this.getTelefrepar = valor3.telefono;
            this.getNombrerepar = valor3.nombre
          });
        }
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }

        //creo un contador de 10 minutos de limite para que el comercio tome control total del cambio de repartidor si es necesario
        let contador = await this.calculaTiempopedido(this.cargaPedido[0].fechaEntrega);
        //clearInterval(this.timerReset); (se comento este celarinterval en observacion, se presume que estaba deteniendo el contador)
        if (contador !== 'fin' && contador && (this.cargaPedido[0].estado == 'ENVIADOREPARTIDOR' || this.cargaPedido[0].estado == 'ACEPTADOREPARTIDOR' || this.cargaPedido[0].estado == 'ENCAMINO')) {
          this.verDeliveryauto = false;
          clearInterval(this.timerReset);
          this.timerReset = setInterval(async () => {
            this.timerPedido = await this.calculaTiempopedido(this.cargaPedido[0].fechaEntrega);
            //Desactivo setTimeout si timer pedido devuelve la palabra fin 
            if (this.timerPedido == 'fin') {
              clearInterval(this.timerReset);
            }
          }, 1000);
        } else if (contador == 'fin') {
          this.timerPedido = 'fin';
        }

        //se limpia la vista de pedidos que cambian a entregado en los proximos 10 min
        if (this.cargaPedido[0].estado == 'ENTREGADO') {
          let calculaTiempo2 = await this.calculaTiempopedido2(this.cargaPedido[0].fechaEntrega);
          if (calculaTiempo2 == 1) {
            //en 5 segundos limpio el array que me tiene el pedido en pantalla
            setTimeout(() => {
              this.cargaPedido = [];
              //se elimina suscripción-observable al pedido actual en pantalla
              if (this.getPedidoSuscriber != undefined) {
                this.getPedidoSuscriber.unsubscribe();
              }
            }, 5000);
          }
        }

      }
    })

  }

  //funcion para copiar ubicación de despacho
  async copiaUbicacion() {
    let inputFalso = document.createElement('input');
    inputFalso.setAttribute('value', 'http://maps.google.com/maps?daddr=' + this.cargaPedido[0].ubicacionDespacho.lat + ',' + this.cargaPedido[0].ubicacionDespacho.lng);
    document.body.appendChild(inputFalso);
    inputFalso.select();
    document.execCommand("copy");
    document.body.removeChild(inputFalso);
    return await this.presentToast('¡Ubicación de entrega copiada!')
    //return await this.presentToast('Ubicación de entrega copiada '+inputFalso.value)
  }



  async calculaTiempopedido(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds 
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);


    if (minutes >= 0) {
      if (minutes == 0 && hours == 0) {
        let res: string;
        res = seconds + ' seg.';
        return res;
      }
      if (minutes > 0 && minutes < 10 && hours == 0) {
        let res: string;
        res = minutes + ' min y ' + seconds + ' seg.';
        return res;
      }
      if (minutes >= 10 || hours > 0) {
        let res: string;
        res = 'fin';
        return res;
      }
    }

  }

  // se crea esta función para calcula el tiempo de la orden entregada pasados 5 segundos
  async calculaTiempopedido2(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds 
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);

    if (seconds <= 10 && minutes == 0 && hours == 0) {
      let res: number;
      res = 1;
      return res;
    } else {
      let res: number;
      res = 2;
      return res;
    }
  }

  //inicar llamada telefonica
  startCallphone(phone: string) {
    window.open("tel:" + phone);
  }


  //inicar conversación por whatssap
  startWhass(pedidowhat: Pedido) {
    let link = 'https://wa.me/' + pedidowhat.telefono.trim() + '?text=Ref:%20' + (pedidowhat.id.trim()).substr(-5) + ',%20Hola%20' + pedidowhat.cliente.nombre.trim() + ',%20le%20escribimos%20desde%20el%20Comercio:%20' + pedidowhat.nombreComercio.trim() + ',%20en%20relación%20a%20su%20pedido%20https://entregamovil.com/orden/' + pedidowhat.id.trim();
    window.open(link, '_blank');
  }


  //inicar conversación por whatssap
  startWhassrepar(pedidowhat: Pedido) {
    let link1 = 'https://wa.me/' + this.getTelefrepar.trim() + '?text=Ref:%20' + (pedidowhat.id.trim()).substr(-5) + ',%20Hola%20' + this.getNombrerepar.trim() + ',%20te%20escribimos%20desde%20el%20Comercio:%20' + pedidowhat.nombreComercio.trim();
    window.open(link1, '_blank');
  }


  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    this.loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      duration: 4000
    });
    //presenta mensaje
    await this.loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading2() {
    //crea mensaje
    this.loading2 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Localizando Repartidor...',
      duration: 8500
    });
    //presenta mensaje
    await this.loading2.present();
  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 2000,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast2(mensaje: string, nombreRepar: string) {
    const toast2 = await this.toastController.create({
      message: mensaje + ' ' + nombreRepar,
      cssClass: 'normal',
      duration: 3000,
      color: 'success',
      position: 'middle'
    });
    toast2.present();
  }

  //configuracion de la funcion que muestra mensaje de envio NO exitoso al repartidor
  async presentToast3(mensaje: string, nombreRepar: string) {
    const toast1 = await this.toastController.create({
      message: mensaje + ' ' + nombreRepar,
      color: 'danger',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast1.present();
  }

  //configuracion de la funcion que muestra mensaje cuando no hay repartidores disponibles
  async presentToast4(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 2500,
      position: 'middle'
    });
    toast2.present();
  }


  //confirmacion de pago recibido
  async registrarPago(pedido1: Pedido) {
    //si el delivery esta por cotizar procedo a verificar si se concretó 
    if (pedido1.zonadelivery.idzona == 'id123' && pedido1.delivery) {
      //envio un mensaje diferente cuando se trata de una zona a cotizar
      const alert = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        backdropDismiss: false,
        message: "¿Concretó la cotización para el Delivery de este pedido?, sí es afirmativo por favor escriba el costo en USD <b>(sí el delivery es Gratis escriba 0)</b>, la localidad, " +
          "y luego presione <b>SI</b>, en caso contrario presione <b>NO</b>",
        inputs: [
          {
            name: 'costo',
            type: 'number',
            id: 'costo-id',
            placeholder: 'Escriba aqui el costo en USD'
          },
          {
            name: 'localidad',
            type: 'text',
            id: 'lugar-id',
            placeholder: 'Escriba aquí la localidad'
          }
        ],
        buttons: [
          {
            text: 'SI',
            id: 'confirm-button',
            handler: async (data1) => {
              if (data1.costo != null && data1.localidad != '') {
                //valido que data 1 sea un numero
                if (isNaN(await data1.costo) && typeof data1.costo === 'number') {
                  await this.presentToast4('El costo debe ser un valor numerico')
                  return false;
                } else {
                  //creo la informacion para la nueva zona de delivery, se le asigna el id id1233 para delivery cotizado concretado
                  let zonadelivery1: ZonaDelivery2 = {
                    idzona: 'id1233',
                    precio: Number(data1.costo),
                    zona: data1.localidad,
                    habilitar: true,
                    fechaPrecio: new Date(),
                    ubicacion: pedido1.ubicacionDespacho
                  };
                  //creo la ruta para actualizar el delivery a false
                  const path1 = 'usuarios/' + pedido1.uidComercio + '/pedido/';
                  //Actualizo la opcion del delivery a false 
                  await this.firestore.updateDoc<Pedido>({ zonadelivery: zonadelivery1 }, path1, pedido1.id).then(async () => {
                    //aplico una espera de 3 segundos despues que se ha confirmado el pago, ya que cuando hay conexion lenta tarda en reflejar la actualización
                    setTimeout(async () => {
                      this.activaregistraPago = true;
                    }, 3000);
                  }).catch(async () => {
                    //si hay un error al actualizar la zona del delivery
                    return await this.presentToast('No fue posible actualizar la información para Delivery, Intente Nuevamente o comuniquese con soporte de App')
                  });
                }
              } else if (data1.costo == null || data1.localidad == '') {
                await this.presentToast4('Debe escribir el costo del servicio delivery y localidad')
                return false;
              }
            }
          }, {
            text: 'NO',
            // role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: async () => {
              //creo la ruta para actualizar el delivery a false
              const path1 = 'usuarios/' + pedido1.uidComercio + '/pedido/';
              //Actualizo la opcion del delivery a false 
              await this.firestore.updateDoc<Pedido>({ delivery: false }, path1, pedido1.id).then(async () => {
                //aplico una espera de 3 segundos despues que se ha confirmado el pago, ya que cuando hay conexion lenta tarda en reflejar la actualización
                setTimeout(async () => {
                  this.activaregistraPago = true;
                }, 3000);
              }).catch(async () => {
                //si hay un error al actualizar la opcion del delivery
                return await this.presentToast4('No fue posible eliminar la opción de Delivery, intente nuevamente o comuniquese con soporte de App')
              });
            }
          }, {
            text: 'CANCELAR',
            // role: 'cancel',
            cssClass: 'normal',
            id: 'cancel-button',
            handler: () => {
            }
          }
        ]
      });
      await alert.present();
    } else if (pedido1.zonadelivery.idzona != 'id123' || !pedido1.delivery) {
      //aplico una espera de 3 segundos despues que se ha confirmado el pago, ya que cuando hay conexion lenta tarda en reflejar la actualización
      this.activaregistraPago = true;
    }


  }

  //confirmacion de pago recibido
  async confirmarPago(pedidoPagado: Pedido) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    //oculto registro de pago
    this.activaregistraPago = false;
    //agrego los path para guardado
    const path = 'usuarios/' + this.uidUserLog + '/pedido/';
    //actualizo el pedido a PAGADO solo cuando la orden sea igual a VISTOPORCOMERCIO
    if (pedidoPagado.estado === 'VISTOPORCOMERCIO') {
      //si el pedido es marcado como efectivo se le agrega a la referencia  el estatus del efectivo
      if (pedidoPagado.formapago == 'EFECTIVO') {
        pedidoPagado.referenciapago = this.detalleEfectivo2 + ', total pedido USD: ' + pedidoPagado.precioTotal.toFixed(2) + ' ' + pedidoPagado.referenciapago;
      }
      //si el pedido es con delivery y el costo de delivery es igual a 0, se actualiza automaticamente
      //el campo cobrodeliverycomercio como si fuera responsable del delivery y no se llama el cuadro de
      //confirmacion de recibo de delivery
      if (pedidoPagado.zonadelivery.precio == 0 && pedidoPagado.delivery) {
        await this.firestore.updateDoc<Pedido>({ cobrodelivComercio: true, estado: 'PAGADO', formapago: pedidoPagado.formapago, referenciapago: pedidoPagado.referenciapago, fechaEntrega: new Date() }, path, pedidoPagado.id).then(async () => {
          //limpio la variable que guarda detalle del efectivo
          this.detalleEfectivo2 = '';
        }).catch(async () => {
          //aplico una espera de 3 segundos despues que se ha confirmado el pago, ya que cuando hay conexion lenta tarda en reflejar la actualización
          setTimeout(async () => {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover = await this.loadingController.getTop();
            if (popover) {
              await popover.dismiss(null);
            }
            //limpio la variable que guarda detalle del efectivo
            this.detalleEfectivo2 = '';
            //variable activa nuevamente el registro de pago
            this.activaregistraPago = true;
            //muestro mensaje de problemas en la actualizacion del pedido
            await this.presentToast('No se pudo actualizar el ESTADO del pedido, por favor registre el pago nuevamente ')
          }, 3000);
        });
      } else {
        await this.firestore.updateDoc<Pedido>({ estado: 'PAGADO', formapago: pedidoPagado.formapago, referenciapago: pedidoPagado.referenciapago, fechaEntrega: new Date() }, path, pedidoPagado.id).then(async () => {
          //limpio la variable que guarda detalle del efectivo
          this.detalleEfectivo2 = '';
          //llamo la función que verifica si el pago del delivery fue recibido por el comercio
          return await this.costodelivComercio(pedidoPagado);
        }).catch(async () => {
          //aplico una espera de 3 segundos despues que se ha confirmado el pago, ya que cuando hay conexion lenta tarda en reflejar la actualización
          setTimeout(async () => {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover = await this.loadingController.getTop();
            if (popover) {
              await popover.dismiss(null);
            }
            //limpio la variable que guarda detalle del efectivo
            this.detalleEfectivo2 = '';
            //variable activa nuevamente el registro de pago
            this.activaregistraPago = true;
            //muestro mensaje de problemas en la actualizacion del pedido
            await this.presentToast('No se pudo actualizar el ESTADO del pedido, por favor registre el pago nuevamente ')
          }, 3000);
        });
      }


    }
  }


  //confirmacion de pago de delivery recibido por comercio
  async costodelivComercio(pedido2: Pedido) {
    //si el comercio no ha confirmado la recepción del pago del delivery, procedo a preguntar
    if (!pedido2.cobrodelivComercio && pedido2.delivery) {
      //envio un mensaje diferente cuando se trata de una zona a cotizar
      const alert1 = await this.alertController.create({
        cssClass: 'normal',
        header: 'Confirmación',
        backdropDismiss: false,
        message: "¿El pago del servicio delivery por <b>" + pedido2.zonadelivery.precio.toFixed(2) + "USD</b> o su equivalente, fue recibido por el comercio?",
        buttons: [
          {
            text: 'NO',
            id: 'cancel-button',
            handler: async () => {
              return await this.presentToast4('Sí recibe el pago durante el proceso de entrega, notifiquelo en el boton: <b>servicio delivery pagado</b>')
            }
          }, {
            text: 'SI',
            id: 'confirm-button',
            handler: async () => {
              //creo la ruta para actualizar el pago recibido por el comercio a true
              const path1 = 'usuarios/' + pedido2.uidComercio + '/pedido/';
              //Actualizo la opcion del delivery a false 
              await this.firestore.updateDoc<Pedido>({ cobrodelivComercio: true }, path1, pedido2.id).then(async () => {
                //muestro mensaje de actuslización con exito
                return await this.presentToast('¡Repartidor será notificado!')
              }).catch(async () => {
                //si hay un error al actualizar la recepcion de pago por el comercio
                return await this.presentToast4('No fue posible confirmar la recepción de pago del servicio delivery por el comercio, intente nuevamente o comuniquese con soporte de App')
              });
            }
          }
        ]
      });
      await alert1.present();
    }

  }


  /*se crea esta función cuando un pedido inicialmente se ha eleccionado con delivery, pero l cliente por alguna razon
  decide pasar por el producto*/
  async entregaCliente(pedido3: Pedido) {
    //envio un mensaje diferente cuando se trata de una zona a cotizar
    const alert1 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      backdropDismiss: false,
      message: "¿Confirma que desea marcar como <b>ENTREGADO</b> este pedido? <b>Advertencia</b>: Esta opción esta disponible para casos de repartos <b>NO CONCRETADOS</b> por sus repartidores afiliados o si el cliente decide por voluntad propia retirar su pedido por su cuenta.",
      buttons: [
        {
          text: 'CANCELAR',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //creo la ruta para actualizar el pago recibido por el comercio a true
            const path11 = 'usuarios/' + pedido3.uidComercio + '/pedido/';
            const path12 = 'usuarios/' + pedido3.uidComercio + '/pedido/' + pedido3.id;
            //realizo una ultima consulta de información  para ver si el pedido esta en los estatus que permiten entrega forzada
            await this.firestore.getCollection4<Pedido>(path12).then(async pedidoAsing52 => {
              let valor62: any = null;
              valor62 = await pedidoAsing52.data();
              if (await valor62 != null && await valor62.delivery && pedido3.delivery && (await valor62.estado == 'PAGADO' || await valor62.estado == 'RECHAZADAREPARTIDOR' || await valor62.estado == 'CANCELADOREPARTIDOR' || await valor62.estado == 'DEVUELTO') && (pedido3.estado == 'PAGADO' || pedido3.estado == 'RECHAZADAREPARTIDOR' || pedido3.estado == 'CANCELADOREPARTIDOR' || pedido3.estado == 'DEVUELTO')) {
                //Actualizo la opcion del delivery a false 
                await this.firestore.updateDoc<Pedido>({ estado: 'ENTREGADO', delivery: false, uidRepartidor: '', fechaEntrega: new Date() }, path11, pedido3.id).then(async () => {
                  //muestro mensaje de actuslización con exito
                  return await this.presentToast('¡Pedido marcado como entregado exitosamente!')
                }).catch(async () => {
                  //si hay un error al actualizar la recepcion de pago por el comercio
                  return await this.presentToast4('No fue posible marcar el Pedido como ENTREGADO, intente nuevamente o contacte con soporte de App')
                });
              } else {
                //muestro mensaje de problemas en la actualizacion del pedido
                return await this.presentToast4('¡El pedido presenta un estatus que no permite procesar la ENTREGA!, intente nuevamente o contacte con soporte App')
              }
            });
          }
        }
      ]
    });
    await alert1.present();

  }

  async pedidoListo(pedidoListo: Pedido) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    //agrego los path para guardado
    const path = 'usuarios/' + this.uidUserLog + '/pedido/';
    //actualizo el pedido a LISTOPARARETIRAR solo cuando la orden sea igual a PAGADO
    if (pedidoListo.estado == 'PAGADO') {
      //pedidoListo.estado="LISTOPARARETIRAR";
      await this.firestore.updateDoc<Pedido>({ estado: "LISTOPARARETIRAR", fechaEntrega: new Date() }, path, pedidoListo.id).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }
        //muestro mensaje de problemas en la actualizacion del pedido
        await this.presentToast4('No se pudo actualizar el ESTADO del pedido, por favor intente Nuevamente')
      });
    } else {
      //muestro mensaje de problemas en la actualizacion del pedido
      await this.presentToast4('No se pudo actualizar el ESTADO del pedido, intente nuevamente o contacte a soporte App con ref de pedido')
    }

  }

  // esta funcion se utiliza para cancelar el envio al repartidor despues de 10 minutos transcurridos
  async rechazarRepartidor(pedidoRechazado: Pedido) {
    const alert6 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      message: "¿Confirma que desea <b>CANCELAR</b> la asignación en proceso del servicio de reparto?<b> esta acción no se puede revertir, como recomendación primero trate de contactar al repartidor",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //agrego los path para guardado
            const path = 'usuarios/' + this.uidUserLog + '/pedido/' + pedidoRechazado.id;
            const path2 = 'usuarios/' + this.uidUserLog + '/pedido/';
            //realizo una ultima consulta de información  para ver si el pedido no ha sido tomado
            await this.firestore.getCollection4<Pedido>(path).then(async pedidoAsing42 => {
              let valor42: any = null;
              valor42 = await pedidoAsing42.data();
              if (await valor42 != null && (await valor42.estado == 'ENVIADOREPARTIDOR' || await valor42.estado == 'ACEPTADOREPARTIDOR' || await valor42.estado == 'ENCAMINO' || await valor42.estado == 'ENTREGADO') && (this.cargaPedido[0].estado == 'ENVIADOREPARTIDOR' || this.cargaPedido[0].estado == 'ACEPTADOREPARTIDOR' || this.cargaPedido[0].estado == 'ENCAMINO' || this.cargaPedido[0].estado == 'ENTREGADO')) {
                //llamo la funcion de mensaje en pantalla
                await this.presentLoading();
                //pedidoRechazado.estado="RECHAZADAREPARTIDOR";
                await this.firestore.updateDoc<Pedido>({ estado: 'RECHAZADAREPARTIDOR', fechaEntrega: new Date(), uidRepartidor: '' }, path2, pedidoRechazado.id).then(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading() (en pruebas)
                  const popover3 = await this.loadingController.getTop();
                  if (popover3) {
                    await popover3.dismiss(null);
                  }
                  //muestro mensaje de actuslización con exito
                  return await this.presentToast('¡Asignación a repartidor cancelada!, proceda a reasignar entrega...')
                }).catch(async () => {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popover3 = await this.loadingController.getTop();
                  if (popover3) {
                    await popover3.dismiss(null);
                  }
                  //muestro mensaje de problemas en la actualizacion del pedido
                  await this.presentToast4('No se pudo cancelar la asiganción del reparto, por favor intente nuevamente')
                });
              } else if (await valor42.estado == 'CANCELADOREPARTIDOR') {
                //muestro mensaje de problemas en la actualizacion del pedido
                await this.presentToast4('¡El reparto ya fue cancelado por el repartidor!, proceda a reasignar entrega... ')
              } else if (await valor42.estado == 'RECHAZADAREPARTIDOR') {
                //muestro mensaje de problemas en la actualizacion del pedido
                await this.presentToast4('¡la asignación de reparto ya fue <b>CANCELADA</b>!, proceda a reasignar entrega... ')
              } else if (await valor42.estado == 'ENTREGADO' && await valor42.uidRepartidor != '') {
                //muestro mensaje de problemas en la actualizacion del pedido
                await this.presentToast4('¡El reparto ya fue <b>ENTREGADO</b>!, verifique entregas o contacte a repartidor... ')
              } else if (await valor42.estado == 'DEVUELTO') {
                //muestro mensaje de problemas en la actualizacion del pedido
                await this.presentToast4('¡El reparto fue <b>DEVUELTO</b>!, contacte a repartidor... ')
              }
            })
          }
        }
      ]
    });
    await alert6.present();

  }

  async pedidoRetirado(pedidoListo1: Pedido) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    //agrego los path para guardado
    const path = 'usuarios/' + this.uidUserLog + '/pedido/';
    //actualizo el pedido a LISTOPARARETIRAR solo cuando la orden sea igual a PAGADO
    if (pedidoListo1.estado == 'LISTOPARARETIRAR') {
      // pedidoListo1.estado="ENTREGADO";
      await this.firestore.updateDoc<Pedido>({ estado: "ENTREGADO", fechaEntrega: new Date() }, path, pedidoListo1.id).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover2 = await this.loadingController.getTop();
        if (popover2) {
          await popover2.dismiss(null);
        }
        //muestro mensaje de problemas en la actualizacion del pedido
        await this.presentToast('No se pudo actualizar el ESTADO del pedido, por favor intente nuevamente')
      });
    } else {
      //muestro mensaje de problemas en la actualizacion del pedido
      await this.presentToast('No se pudo actualizar el ESTADO del pedido, intente nuevamente o contacte a soporte App con ref de pedido')
    }

  }

  //si el pedido esta en estatus vistopocomercio y no se concreta, el comercio puede anularlo y no le sigue
  //apareciendo en el contador de pedidos pendientes 
  async anularPedido(pedidoA: Pedido) {
    //envio un mensaje diferente cuando se trata de una zona a cotizar
    const alert1 = await this.alertController.create({
      cssClass: 'normal',
      header: 'Confirmación',
      backdropDismiss: false,
      message: "¿Confirma que desea <b>ANULAR</b> este pedido? <b>Advertencia</b>: Esta acción es irreversible y se aconseja utilizarla para casos de pedidos <b>NO CONCRETADOS</b>",
      buttons: [
        {
          text: 'CANCELAR',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //creo la ruta para actualizar el pedido a Anulado por el comercio
            const path15 = 'usuarios/' + pedidoA.uidComercio + '/pedido/';
            const path16 = 'usuarios/' + pedidoA.uidComercio + '/pedido/' + pedidoA.id;
            //realizo una ultima consulta de información  para ver si el pedido esta en los estatus que permite Anularlo
            await this.firestore.getCollection4<Pedido>(path16).then(async pedidoAsing53 => {
              let valor63: any = null;
              valor63 = await pedidoAsing53.data();
              if (await valor63 != null && await valor63.estado == 'VISTOPORCOMERCIO') {
                //Actualizo la opcion del delivery a false 
                await this.firestore.updateDoc<Pedido>({ estado: 'ANULADO', fechaEntrega: new Date() }, path15, pedidoA.id).then(async () => {
                  //se limpia la pantalla 3 segundos despues de la anulación
                  setTimeout(async () => {
                    this.cargaPedido = [];
                  }, 3000);
                  //muestro mensaje de actualización con exito
                  return await this.presentToast('¡Pedido marcado como ANULADO!')
                }).catch(async () => {
                  //si hay un error al actualizar la recepcion de pago por el comercio
                  return await this.presentToast4('No fue posible marcar el pedido como ANULADO, intente nuevamente o contacte con soporte de App')
                });
              } else {
                //muestro mensaje de problemas en la actualizacion del pedido
                return await this.presentToast4('¡El pedido presenta un estatus que no permite ANULARLO!, intente nuevamente o contacte con soporte App')
              }
            });
          }
        }
      ]
    });
    await alert1.present();
  }



  async asignarDelivery() {
    if (this.getRepartidoresActivosSuscriber != undefined) {
      this.getRepartidoresActivosSuscriber.unsubscribe();
    }
    await this.estadorepartidor.estadoRepartidor(this.uidUserLog).then(() => {
      //Activo los estados del Repartidor y me suscribo a observables
      this.getRepartidoresActivosSuscriber = this.estadorepartidor.getEstadorepartidor().subscribe(async res => {
        if (res && await this.modalControler.getTop()) {
          //Si el observable deja de amndar informacion en 2 segundos, es cuando emito los valores (ahorro de recursos de lectura con firebase)
          if (this.contador != undefined) {
            clearTimeout(this.contador);
          }
          this.contador = undefined;
          this.contador = setTimeout(async () => {
            //envio la data de los repartidores
            this.carritoService.pasaData2.emit({
              data: res,
            });
            //envio el pedido seleccionado
            this.carritoService.pasaDatapedidofinal.emit({
              data1: this.cargaPedido[0],
            });
          }, 2000);
        }
      });
      this.modalRepartidores();
    })
  }

  recibeDatapopup() {
    //envio el pedido seleccionado
    this.carritoService.pasaDatapedidofinal2.subscribe(res3 => {
      if (res3) {
        this.cargaPedido[0] = res3.data3;
      }
    })
  }


  //modal de repartidores
  async modalRepartidores() {
    const modal = await this.modalControler.create({
      component: PopuprepartidorComponent,
      backdropDismiss: true,
      cssClass: 'ion-modal'
    });
    await modal.present();
  }

  //se activan funciones asociadas al fabbutton donde se ven los estados de los pedidos
  verFunfanbutton() {
    this.verPendientes = !this.verPendientes;
    if (!this.verDeliveryauto) {
      this.verDeliveryauto = true;
    }
  }

  //implementaion de aignacion de delivery automticamente
  async asignarDeliveryauto() {
    //llamo la funcion de mensaje en pantalla de la busqueda autmatica de repartidor
    await this.presentLoading2();
    //Inicializo el suiche de asignacion de delivery a true el cual será validado en el ultimo paso de asigancion automatica
    this.suicheAsignacion = true;
    // esta opcion desactiva la visa previa de la asigancion de delivery automatico 
    this.verDeliveryauto = false;
    if (this.getRepartidoresActivosSuscriber2 != undefined) {
      this.getRepartidoresActivosSuscriber2.unsubscribe();
    }
    await this.estadorepartidor.estadoRepartidor2(this.uidUserLog).then(() => {
      //Activo los estados del Repartidor y me suscribo a observables
      this.getRepartidoresActivosSuscriber2 = this.estadorepartidor.getEstadorepartidor2().subscribe(async res => {
        if (await res) {
          this.traeRepartidores = [];
          this.traeRepartidores = await res;
          await this.traeRepartidorcerca(this.traeRepartidores);
        } else if (!res) {
          //espero 2,5 segundos y reevaluo si el observable ha mandado alguna iformacion de repartidores disponibles
          setTimeout(async () => {
            if (!this.traeRepartidores.length) {
              //paso el suiche a false para reasegurar
              this.suicheAsignacion = false;
              this.getRepartidoresActivosSuscriber2.unsubscribe();
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading() (en pruebas)
              const popover3 = await this.loadingController.getTop();
              if (popover3) {
                await popover3.dismiss(null);
              }
              this.verDeliveryauto = true;
              return await this.presentToast4('No se localizaron repartidores disponibles, intente nuevamente...')
            }
          }, 2500);
        }

      });
    })
  }

  //Funcion que verifica los repartidores que estan mas cerca (guarda relacion con asigancion automtica delivery)
  async traeRepartidorcerca(repartidorafiliado: any[]) {
    //le aplico reduccion de duplicados
    repartidorafiliado = Array.from(repartidorafiliado.reduce((map, obj) => map.set(obj.uid, obj), new Map()).values());
    this.getUbicaciones = [];
    for await (let add of repartidorafiliado) {
      // Create a reference to the cities collection
      let path = 'usuarios/' + add.uid + '/registraubicacion/';
      await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('fecha', 'asc').limitToLast(1)).then(async ubicacion => {
        await ubicacion.forEach(async (doc: any) => {
          let valor: any[] = [];
          valor[0] = doc.data();
          //Se calcula la distancia del comercio a la ultima del repartidor
          let distkm = await this.getDistanceFromLatLonInKm(this.latComer, this.lngComer, await valor[0].lat, await valor[0].lng);
          //se convierte la fecha a formato legible por html
          valor[0].fecha = await valor[0].fecha.toDate();
          //funcion que calcula el tiempo transcurrido
          let tiempopas = this.calculaTiempo(valor[0].fecha);
          // en esta condicion filtro solo los repartidores a 6km de radio y que muestren ultima conexion maximo 1 hora
          if (distkm <= 6 && tiempopas < 3600) {
            //se concatena las ubicaciones al repartidor
            let aaa: any[] = [];
            aaa = (valor.map(obj => (Object.assign(obj, { nombre: add.nombre, email: add.email, uidRepartidor: add.uid, tiempo: tiempopas, distancia: parseFloat(distkm.toFixed(2)) }))));
            this.getUbicaciones.push(aaa[0]);
            //le aplico reduccion de duplicados
            this.getUbicaciones = Array.from(this.getUbicaciones.reduce((map, obj) => map.set(obj.uidRepartidor, obj), new Map()).values());
          }
        });
      })
    }
    //al culminar el ciclo for await procedo a ordenar el array  por fecha y enviarlo para la siguienter evaluacion
    this.getUbicaciones.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
    return await this.asignacionRepartoauto1(this.getUbicaciones);
  }

  /*una vez que se filtra repartidores por distancia y ultmo tiempo de conexion se proede a aplicar 
  funcion para detectar cuales de estos repartidores tienen menos deliverys en su dia y darle prioridad*/
  async asignacionRepartoauto1(repartidoresfilter: any[]) {
    //si no hay repartidores que cumplan con las condiciones automaticas se envia mensaje y se finaliza
    if (repartidoresfilter.length) {
      this.Pedidosasignados = [];
      //evaluo el pedido asignado segun la fecha de entrega mas reciente
      for await (let add1 of repartidoresfilter) {
        let path = 'usuarios/' + add1.uidRepartidor + '/pedidoasignado/';
        await this.firestore.getCollection5<any>(path, ref => (ref).orderBy('fechaEntrega', 'asc').limitToLast(1)).then(async pedidoAsing => {
          await pedidoAsing.forEach(async (doc: any) => {
            let valor2: any[] = [];
            valor2[0] = doc.data();
            //se concatena el Uid del repartidor a esta consulta para ser usado en la asignacion final
            let concate: any[] = [];
            concate = (valor2.map(obj => (Object.assign(obj, { uidRepartidor: add1.uidRepartidor, emailrepar: add1.email }))));
            this.Pedidosasignados.push(concate[0])
          });
        });
      }
      //al culminar el ciclo for await procedo a reducir duplicados por el uidRepartidor y enviarlo para la siguienter evaluacion
      this.Pedidosasignados = Array.from(this.Pedidosasignados.reduce((map, obj) => map.set(obj.uidRepartidor, obj), new Map()).values());
      return await this.asignacionRepartoauto2(this.Pedidosasignados);
    } else {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
      const popover4 = await this.loadingController.getTop();
      if (popover4) {
        await popover4.dismiss(null);
      }
      //muestro mensaje de Repartidores no disponibles para requerimiento automatico
      this.verDeliveryauto = true;
      await this.presentToast4('Repartidores fuera de rango, intente de forma manual...')
      return this.getRepartidoresActivosSuscriber2.unsubscribe();
    }
  }

  async asignacionRepartoauto2(repartidoresfilter1: any[]) {
    /*revalidacion, si el suiche es falso limpio el array de esta funcion porque ya no lo necesito y esto
    desencadena el fin del proceso*/
    if (this.suicheAsignacion == false) {
      repartidoresfilter1 = [];
      return;
    }
    //reelimino duplicados (si los hay) por uidRepartidor
    repartidoresfilter1 = Array.from(repartidoresfilter1.reduce((map, obj) => map.set(obj.uidRepartidor, obj), new Map()).values());
    //limpio el array 
    let repartidoresfilter2 = [];
    //Selecciono el repartidor con fecha de entrega mas antigua para darle prioridad en la asignacion
    repartidoresfilter2[0] = repartidoresfilter1.reduce((c, n) =>
      Date.parse((n.fechaEntrega).toDate()) < Date.parse((c.fechaEntrega).toDate()) ? n : c
    );

    //reverifico en cada vuelta de bucle si el pedido ya fue asignado
    const pathh = 'usuarios/' + this.cargaPedido[0].uidComercio + '/pedido/' + this.cargaPedido[0].id;
    await this.firestore.getCollection4<Pedido>(pathh).then(async pedidoAsing1 => {
      let valor3: any;
      valor3 = await pedidoAsing1.data();
      //si el estado del pedido e igual al RECHAZADAREPARTIDOR,PAGADO,CANCELADOREPARTIDOR o CAMBIOREPARTIDOR diferente de EnviadoRepartidor se procede
      if (this.suicheAsignacion && (await valor3.estado == 'PAGADO' || await valor3.estado == 'RECHAZADAREPARTIDOR' || await valor3.estado == 'CANCELADOREPARTIDOR' || await valor3.estado == 'DEVUELTO') && (this.cargaPedido[0].estado == 'PAGADO' || this.cargaPedido[0].estado == 'RECHAZADAREPARTIDOR' || this.cargaPedido[0].estado == 'CANCELADOREPARTIDOR' || this.cargaPedido[0].estado == 'DEVUELTO')) {
        const path5 = 'usuarios/' + repartidoresfilter2[0].uidRepartidor + '/pedidoasignado/';
        const path6 = 'usuarios/' + repartidoresfilter2[0].uidRepartidor;
        let pedidoAsignado: PedidoAsignado = {
          estatus: 'ENVIADOREPARTIDOR',
          fecha: new Date(),
          fechaEntrega: new Date(),
          idComercio: this.cargaPedido[0].uidComercio,
          idPedido: this.cargaPedido[0].id,
          nombreComercio: this.cargaPedido[0].nombreComercio,
          precio: this.cargaPedido[0].zonadelivery.precio,
          pagadocliente: false,
          pagadocomercio: false,
          conciliado: false,
          fechapagocomer: null,
          referenciapagocomer: '',
          emailrepar: repartidoresfilter2[0].emailrepar,
          emailcomer: this.emailComercio
        }
        //reverifico si el repartidor no se ha ocupado
        await this.firestore.getCollection4<User>(path6).then(async ocupadoRep1 => {
          let valor4: any = null;
          valor4 = await ocupadoRep1.data();
          // reevaluo si en este punto el repartidor se ha ocupado
          if (this.suicheAsignacion && await valor4 != null && await valor4.estado == true && await valor4.ocupado == false && await valor4.estatus == 'ACTIVO') {
            /*paso a false el suiche de asigancion para que solo entre a la asigancion automatica si la
            seleccion viene desde el principio*/
            this.suicheAsignacion = false;
            //Creo la Solicitud al Repartidor (guarda relacion con Funcion en servidor: newAsignacion para atualizado de pedido)
            return await this.firestore.createDoc2<PedidoAsignado>(pedidoAsignado, path5, pedidoAsignado.idPedido).then(async () => {
              //aplico una espera de 3 segundos despues de creado el pedido, ya que cuando hay conexion lenta tarda en reflejar la actualización
              setTimeout(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
                const popover5 = await this.loadingController.getTop();
                if (popover5) {
                  await popover5.dismiss(null);
                }
                //muestro mensaje de Estado en Linea al Repartidor
                return await this.presentToast2('Solicitud enviada automáticamente al repartidor', valor4.nombre.trim() + ' ' + valor4.apellido.trim());
              }, 3000);

            }).catch(async (res) => {
              console.log('errrro', res)
              //aplico una espera de 3 segundos despues de creado el pedido, ya que cuando hay conexion lenta tarda en reflejar la actualización
              setTimeout(async () => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading() 
                const popover6 = await this.loadingController.getTop();
                if (popover6) {
                  await popover6.dismiss(null);
                }
                //muestro mensaje de problemas en el guardado
                return await this.presentToast3('Ocurrio un problema al enviar la solicitud automatica al repartidor', valor4.nombre.trim() + ' ' + valor4.apellido.trim());
              }, 3000);
            });
          } else {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover6 = await this.loadingController.getTop();
            if (popover6) {
              await popover6.dismiss(null);
            }
            this.verDeliveryauto = true;
            await this.presentToast4('No se localizaron repartidores disponibles, intente nuevamente en unos minutos...')
            return this.getRepartidoresActivosSuscriber2.unsubscribe();
          }
        });
      } else {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover7 = await this.loadingController.getTop();
        if (popover7) {
          await popover7.dismiss(null);
        }
        await this.presentToast4('Ocurrio un problema de conexion durante la asignacion automática, intente nuevamente...')
        return this.getRepartidoresActivosSuscriber2.unsubscribe();
      }
    });

  }


  //fucion que calcula distancia del repartidor al comercio (guarda relacion con asigancion automatica delivery)
  async getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371;
    // calculo del radio de la tierra pra mejorar el calculo lat 
    let dLat = this.deg2rad(lat2 - lat1);
    // calculo del radio de la tierra pra mejorar el calculo lon 
    let dLon = this.deg2rad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    // Distancia en Km
    return d;
  }
  //funcion asociada al calculo de distancia
  deg2rad(deg: number) {
    return deg * (Math.PI / 180)
  }



  //funcion para caluclar tiempo basado en contador de tiempo pasado (guarda relacion con asigancion automatica delivery)     
  calculaTiempo(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds
    let seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = Math.round(timeDiff % 60);
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = Math.round(timeDiff % 24);
    // remove hours from the date
    timeDiff = Math.floor(timeDiff / 24);
    // the rest of timeDiff is number of days
    let days = timeDiff;

    /*Establezco tiempo para filtrar repartidores en asignacion automatica, seleccion
    entre 5 segundos y 1 hora*/
    if ((seconds > 5 || (minutes >= 0 && minutes <= 59)) && hours == 0 && days == 0) {
      if (seconds > 5 && minutes == 0) {
        let res: number;
        return res = seconds;
      }
      if (minutes >= 1 && minutes <= 59) {
        let res: number;
        return res = minutes * 60;
      }
    } else {
      //valor en segundos equivalente a 31 minutos que sera tomado fuera de rango por tiempor ultima vez
      let res: number;
      return res = 3600
    }
  }


  verUbicacioncliente() {
    this.verUbicauser = true;
  }

}
