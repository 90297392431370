import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Subscription } from 'rxjs';
import { GooglemapsComponent } from 'src/app/googlemaps/googlemaps.component';
import { preCliente, preComercio, preRepartidor, User } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import { EnviaubicacionService } from 'src/app/services/enviaubicacion.service';
import { FirestorageService } from 'src/app/services/firestorage.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-perfilusuario',
  templateUrl: './perfilusuario.component.html',
  styleUrls: ['./perfilusuario.component.scss'],
})
export class PerfilusuarioComponent implements OnInit, OnDestroy {


  datos: User = {
    nombre: "",
    apellido: "",
    tipolocal: "",
    token: "",
    nombrelocal: "",
    uid: "",
    email: "",
    password: "",
    telefono: "",
    direccionlocal: "",
    plan: "",
    ubicacionpri: { lat: 0, lng: 0 },
    ubicacionsec: { lat: 0, lng: 0 },
    perfil: "",
    urlfoto: "",
    fechaRegistro: new Date,
    fechaActRegistro: new Date,
    estado: false,
    estatus: 'ACTIVO',
    ocupado: false,
    ubicacion: "",
    municipio: "",
    valoracion: 100,
    deliveryOn: false,
  }

  //Se crea una variable tipo arreglo para almacenar el usuario logeado
  userRegis: User[] = [];

  //se crea la variable que guardará el evento de la captura de foto de perfil
  newFile = "";

  //creo una variable que me va a almacenar el uid del usuario logeado
  uidUserLog: any;

  //creo un array de perfiles de usuarios y locales para el caso de Restaurant
  perfilesUser: string[] = ["CLIENTE", "PROVEEDOR", "REPARTIDOR"];
  tipolocalesUser: any[] = [];

  //declaro variable para activar la opcion de ver o no la ubicacion
  verUbica = false;

  //declaro variable para activar la opcion de ver la foto del perfil
  verFotoper = false;

  //declaracion de Observables para aplicar la desuscripcion
  private getdatauserSuscriber: Subscription = undefined;
  private dataSuscriber: Subscription = undefined;
  private gettipocomerSuscriber: Subscription = undefined;

  //variables relacionada a la vista previa del mapa
  myUrl: any;
  myTrustedURL: any;

  //creo variable que activará campos modificables
  modificable = true;

  //estas variables llaman mis claves api de mapas
  private apikey1 = environment.mapsConfig.apikey1
  private apikey2 = environment.mapsConfig.apikey2

  //esta varibale se crea para verificar durante la modificacion que el telefono no se encuentre registrado
  private txtTelefono = '';

  //variable para contar las llamadas al mapa, si se llama mas de 3 veces se reinicia el registro
  private cuentaMapa = 0;

  constructor(private firestore: FirestoreService,
    private firestorageService: FirestorageService,
    private modalControler: ModalController,
    private enviaUbicacion: EnviaubicacionService,
    private traeUid: UserlogedService,
    private sanitizer: DomSanitizer,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private authService: AuthService,
    private platform: Platform,
    private router: Router,
    private alertController: AlertController // se agrego al 27/03/24 guarda relacion con el,inicacion de cuenta politi priv google
  ) { }

  ngOnInit() {
    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.dataSuscriber = this.traeUid.getUserlog().subscribe(res => {
      if (res) {
        this.uidUserLog = res.uid;
        this.getUsuario();
      } else { //en caso contrario limpio todos los valores de variable por defecto
        this.uidUserLog = 0;
        this.datos = {
          nombre: "",
          apellido: "",
          tipolocal: "",
          token: "",
          nombrelocal: "",
          uid: "",
          email: "",
          password: "",
          telefono: "",
          direccionlocal: "",
          plan: "",
          ubicacionpri: { lat: 0, lng: 0 },
          ubicacionsec: { lat: 0, lng: 0 },
          perfil: "",
          urlfoto: "",
          fechaRegistro: new Date,
          fechaActRegistro: new Date,
          estado: false,
          estatus: 'ACTIVO',
          ocupado: false,
          ubicacion: "",
          municipio: "",
          valoracion: 100,
          deliveryOn: false,
        }
        this.userRegis = [];
        this.newFile = "";
      }
    })

    //funcion para recibir la actualizacion de ubicacacion
    this.actualizaUbicacion();
  }

  ngOnDestroy() {
    if (this.getdatauserSuscriber != undefined) {
      this.getdatauserSuscriber.unsubscribe()
    }
    if (this.dataSuscriber != undefined) {
      this.dataSuscriber.unsubscribe()
    }
    if (this.gettipocomerSuscriber != undefined) {
      this.gettipocomerSuscriber.unsubscribe()
    }
  }



  //metodo para Obtener Datos de usuario Logeado
  getUsuario() {
    this.getdatauserSuscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.userRegis[0] = res;
        //si el estatus es inactivo acepta modifcación de datos clave
        if (this.userRegis[0].estatus == 'INACTIVO') {
          this.modificable = false;
        } else if (this.userRegis[0].estatus == 'ACTIVO') {
          this.modificable = true;
        }
        //se cargan los tipos de comercio
        if (this.userRegis[0].perfil == 'PROVEEDOR') {
          const path1 = 'tipocomercio'
          this.gettipocomerSuscriber = this.firestore.getCollection<any>(path1).subscribe(res1 => {
            if (res1) {
              this.tipolocalesUser = res1;
            }
          })
        }
      }
    });
  }

  //cambiar correo electronico (esta opcion todavia no esta implementada para ser utilizada por los usuarios)
  /*
  async verificaCorreo(){
    await this.authService.getUserCurrent().then(async user=>{
      await user?.updateEmail('').then((res)=>{
        console.log("respuesta de la actualizacion",res)
      });
    })
  }
  */


  async modificarUsuario() {
    if (this.uidUserLog != 0) {
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading();
      const path = 'usuarios';
      const id = this.uidUserLog;
      this.userRegis[0].password = "";
      this.userRegis[0].fechaActRegistro = new Date;
      //creo un path para la ruta de la imagen de perfil
      // const path2 = this.userRegis[0].nombre + '-' + this.userRegis[0].apellido + '/' + id + '/';
      // const path2 = 'fotoperfilusuario' + id + '/';
      const path2 = 'fotoperfilusuario/' + id;
      /*se llama el servicio para guardar la imagen solo cuando this.NewFile sea diferente de vacio, solo en este caso 
      tendrá valor, porque lo tomó desde el metodo newImageUpload()*/
      if (this.newFile != "") {
        const resImag = await this.firestorageService.uploadImages(this.newFile, path2, this.userRegis[0].email);
        //le asigno el valor de la url al campo urlfoto
        this.userRegis[0].urlfoto = resImag;
      }
      //si el perfil del usuario es diferente a PROVEEDOR, se resetea el tipo de local
      if (this.userRegis[0].perfil !== 'PROVEEDOR') {
        this.userRegis[0].tipolocal = "";
      }
      await this.firestore.createUser(this.userRegis[0], path, id).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover = await this.loadingController.getTop();
        if (popover) {
          await popover.dismiss(null);
        }
        return await this.presentToast('Modificación de Usuario Exitosa')
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popove = await this.loadingController.getTop();
        if (popove) {
          await popove.dismiss(null);
        }
        return await this.presentToast1('Error en la actualización de perfil, intente nuevamente')
      });
    }
    //Se limpia el valor de la varibale this.newfile, si se decide crear otro usuario, el evento no toma la ultima foto cargada
    this.newFile = "";
  }



  //Agregando Funcion para subir Imagen de Perfil del creador de la cuenta
  async newImageUpload(event: any) {
    if (event.target.files && event.target.files[0]) {
      //el tamaño de la imagen debe ser menor o igual a 400Kb
      if (event.target.files[0].size <= 400000) {
        //Guardo en variable newFile la foto que se va a subir
        this.newFile = event.target.files[0];
        const reader = new FileReader();
        reader.onload = ((image) => {
          this.userRegis[0].urlfoto = image.target?.result as string;
        });
        reader.readAsDataURL(event.target.files[0]);
      } else if (event.target.files[0].size > 400000) {
        this.newFile = '';
        this.verFotoper = false;
        return await this.presentToast1('El tamaño de la imagen debe ser menor o igual a 400Kb')
      }
    }
  }

  //agregando @viewChild que se va a encargar de monitorear el Html
  @ViewChild("placesRef") placesRef: GooglePlaceDirective | undefined;


  //añadiendo variables dianmicas relacionadas al mapa para el html
  public latitude: any;
  public longitude: any;
  public optionsMaps: any;
  public zoom: any;

  async getGooglemaps() {

    //contador de veces que se ha llamada el mapa en el registro, maximo 3
    this.cuentaMapa = this.cuentaMapa + 1;
    if (this.cuentaMapa > 3) {
      await this.presentLoading44();
      return setTimeout(() => {
        window.location.reload();
      }, 5000);
    }

    const modal = await this.modalControler.create({
      component: GooglemapsComponent,
      //cssClass: 'ion-modal'
      cssClass: 'fullscreen',
    });
    await modal.present();
    this.verUbica = false;
  }

  actualizaUbicacion() {
    //se recibe ubicacion desde el servicio que pasa ubicacion desde el componente googlemaps        
    this.enviaUbicacion.pasaUbicacion.subscribe(res => {
      if (res) {
        this.userRegis[0].ubicacionpri.lat = res.data.lat;
        this.userRegis[0].ubicacionpri.lng = res.data.lng;

        //proceso la url por api segun si esd dispositivo android o web
        if (this.platform.is('cordova')) {
          //guardo la direccion que va a cargar el mapa embed
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + res.data.lat + ',' + res.data.lng + "&zoom=16"
        } else {
          //guardo la direccion que va a cargar el mapa embed
          this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + res.data.lat + ',' + res.data.lng + "&zoom=16"
        }

        //proceso la url de forma segura
        this.procesaUrl();
        this.verUbica = true;
      }
    });
  }


  verUbicacion() {
    this.verUbica = true;

    //proceso la url por api segun si esd dispositivo android o web
    if (this.platform.is('cordova')) {
      //guardo la direccion que va a cargar el mapa embed
      this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey2 + "&center=" + this.userRegis[0].ubicacionpri.lat + ',' + this.userRegis[0].ubicacionpri.lng + "&zoom=16"
    } else {
      //guardo la direccion que va a cargar el mapa embed
      this.myUrl = "https://www.google.com/maps/embed/v1/view?key=" + this.apikey1 + "&center=" + this.userRegis[0].ubicacionpri.lat + ',' + this.userRegis[0].ubicacionpri.lng + "&zoom=16"
    }
    //proceso la url de forma segura
    this.procesaUrl();
  }

  //funcion para autorizar URL de carga de mapa sin costo
  procesaUrl() {
    this.myTrustedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.myUrl);
  }

  verFotoperfil() {
    this.verFotoper = true;
  }

  //creo funcion para validar que el elefono no exista en el registro de base de datos
  /*
  async validaTelefono(event){
   this.txtTelefono=event.target.value;

   //verfiico en este if que la validacion sea correcta
   if((event.target.value.substring(0,3)!='+58' && this.txtTelefono.length>=3) || event.target.value.substring(3,4)=='0' || event.target.value.length>13){
     //console.log('entro a validar telefono', aaa)
     event.target.value='';
     return await this.presentToast1('Formato Incorrecto de teléfono, observe el Ejemplo')
   }
     //agrego los path para guardado
     const path='usuarios/';
     if(this.txtTelefono.length==13 && this.userRegis[0].estatus=='INACTIVO'){
       //llamo la funcion de mensaje en pantalla
       await this.presentLoading2();
       await this.firestore.getCollection5<User>(path,ref=>(ref.where('telefono','==',this.txtTelefono)).limit(1)).then(async telefono=>{
         if(!telefono.empty){
         await telefono.forEach(async (doc:any) => {
             //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
             const popoverr = await this.loadingController.getTop();
             if (popoverr){
               await popoverr.dismiss(null); 
             }
             event.target.value='';
             //muestro mensaje exitoso en el registro de solicitud a repartidor
             return await this.presentToast1('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
         });
       }else if (telefono.empty){
         //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
         const popoverr1 = await this.loadingController.getTop();
         if (popoverr1){
           await popoverr1.dismiss(null); 
         }
       }
     })
     }     
 }
 */


  //creo funcion para validar que el elefono no exista en el registro de base de datos
  async validaTelefono(event) {
    this.txtTelefono = event.target.value;
    //verfiico en este if que la validacion sea correcta
    if ((event.target.value.substring(0, 3) != '+58' && this.txtTelefono.length >= 3) || event.target.value.substring(3, 4) == '0' || event.target.value.length > 13) {
      //console.log('entro a validar telefono', aaa)
      event.target.value = '';
      return await this.presentToast1('Formato Incorrecto de teléfono, observe el ejemplo')
    }
    //agrego los path para guardado
    const path = 'precomercio/';
    const path1 = 'prerepartidor/';
    const path2 = 'precliente/';
    if (this.txtTelefono.length == 13 && this.userRegis[0].estatus == 'INACTIVO') {
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading2();
      await this.firestore.getCollection5<preComercio>(path, ref => (ref.where('telefono', '==', this.txtTelefono)).limit(1)).then(async telefono => {
        if (!telefono.empty) {
          await telefono.forEach(async (doc: any) => {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popoverr = await this.loadingController.getTop();
            if (popoverr) {
              await popoverr.dismiss(null);
            }
            event.target.value = '';
            //muestro mensaje exitoso en el registro de solicitud a repartidor
            return await this.presentToast1('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
          });
        } else if (telefono.empty) {
          await this.firestore.getCollection5<preRepartidor>(path1, ref => (ref.where('telefono', '==', this.txtTelefono)).limit(1)).then(async telefono1 => {
            if (!telefono1.empty) {
              await telefono1.forEach(async (doc: any) => {
                //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                const popoverr = await this.loadingController.getTop();
                if (popoverr) {
                  await popoverr.dismiss(null);
                }
                event.target.value = '';
                //muestro mensaje exitoso en el registro de solicitud a repartidor
                return await this.presentToast1('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
              });
            } else if (telefono1.empty) {
              await this.firestore.getCollection5<preCliente>(path2, ref => (ref.where('telefono', '==', this.txtTelefono)).limit(1)).then(async telefono2 => {
                if (!telefono2.empty) {
                  await telefono2.forEach(async (doc: any) => {
                    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                    const popoverr = await this.loadingController.getTop();
                    if (popoverr) {
                      await popoverr.dismiss(null);
                    }
                    event.target.value = '';
                    //muestro mensaje exitoso en el registro de solicitud a repartidor
                    return await this.presentToast1('El Número de Teléfono ya se encuentra Afiliado, por favor utilice otro')
                  });
                } else if (telefono2.empty) {
                  //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
                  const popoverr1 = await this.loadingController.getTop();
                  if (popoverr1) {
                    await popoverr1.dismiss(null);
                  }
                }
              })
            }
          })
        }
      })
    }
  }

  //configuracion de la funcion que muestra mensaje en Linea con Comercio
  async presentToast(mensaje: string) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 3000,
      position: 'middle'
    });
    await toast1.present();
  }

  //configuracion de la funcion que muestra mensaje cuando se envia un mensaje de advertencia
  async presentToast1(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 6000,
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Modificando Perfil...',
      //duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }

  //configuracion de la funcion que muestra mensaje de espera en validacion de telefono
  async presentLoading2() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Verificando Teléfono...',
      //duration: 4000
    });
    //presenta mensaje
    await loading.present();
  }


  //configuracion de la para recargar pedido en pantalla
  async presentLoading44() {
    let loading4 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Alcanzo el limite de llamadas al mapa, iniciando de nuevo...',
      duration: 5000
    });
    //presenta mensaje
    await loading4.present();
  }

  // navegar a politica de privacidad
  async irPolitica() {
    try {
      await this.router.navigate(['/politicaprivacidad']);
    } catch (err) {
      await this.router.navigate(['/home']);
    }
  }

  //funcion para copiar link a menu
  async linkMenu() {
    let inputFalso = document.createElement('input');
    inputFalso.setAttribute('value', 'https://entregamovil.com/menumovil/' + this.userRegis[0].telefono);
    document.body.appendChild(inputFalso);
    inputFalso.select();
    document.execCommand("copy");
    document.body.removeChild(inputFalso);
    return await this.presentToast('Link de acceso a su menú copiado ' + inputFalso.value)
  }

  //funcion para copiar link a catalogo
  async linkCata() {
    let inputFalso = document.createElement('input');
    inputFalso.setAttribute('value', 'https://entregamovil.com/productomovil/' + this.userRegis[0].telefono);
    document.body.appendChild(inputFalso);
    inputFalso.select();
    document.execCommand("copy");
    document.body.removeChild(inputFalso);
    return await this.presentToast('Link de acceso a su catálogo copiado ' + inputFalso.value)
  }


  //se agrego una opcion directa a usuario para eliminacion de su cuenta por politicas seguridad google 27-03-24
  async eliminarCuenta() {

    //el proceso de eliminacion de cuenta solo es posible si el usuario tienes menos de 1/2 hora de sesion iniciada 
    this.authService.checkTimeAuth().then(async res => {
      let tiempo = ((new Date().getTime() - new Date(res.lastSignInTime).getTime()) / 3_600_000);

      //si el tiempo transcurrido desde el ultimo inicio de sesion es menor a 1/2 hora se procede
      if (tiempo < 0.25) {
        const alert = await this.alertController.create({
          cssClass: 'normal',
          header: 'Eliminar cuenta y datos asociados',
          backdropDismiss: false,
          message: "¡Lamentamos que te vayas!, para eliminar definitivamente tu cuenta y datos asociados, confirma escribiendo <strong>CONFIRMAR</strong>, luego presiona <strong>PROCESAR</strong>, después de esta confirmación tu cuenta no podrá ser recuperada.",
          inputs: [
            {
              name: 'eliminar',
              type: 'text',
              cssClass: 'normal',
              //id: 'confirm-id',
              placeholder: 'confirme escribiendo CONFIRMAR'
            },
          ],
          buttons: [
            {
              text: 'CANCELAR',
              role: 'cancel',
              cssClass: 'normal',
              id: 'cancel-button',
              handler: () => {
              }
            },
            {
              text: 'PROCESAR',
              cssClass: 'normal',
              id: 'confirm-button',
              handler: async (data) => {
                if (data.eliminar.trim() == 'CONFIRMAR' || data.eliminar.trim() == 'Confirmar' || data.eliminar.trim() == 'confirmar') {
                  //Guardo la referencia url de la foto del perfil para ser eliminada del storage 08/04/2024 
                  const urlFotoPerfil = this.userRegis[0].urlfoto;
                  //elimino registros pre segun tipo de usuario
                  if (this.userRegis[0].perfil == "CLIENTE") {
                    //elimino data asociada al cliente en precliente
                    this.firestore.deleteDoc('precliente/', this.userRegis[0].uid).then(() => {
                      //elimino data asociada al cliente en usuarios
                      this.firestore.deleteDoc('usuarios/', this.userRegis[0].uid).then(() => {
                        //Si usuario tiene foto elimina foto y luego usuario autenticado
                        if (urlFotoPerfil != '') {
                          //elimino foto perfil del storage
                          this.firestorageService.deleteFotoUser(urlFotoPerfil).then(() => {
                            //elimina usuario del modulo de autenticacion de usuarios de firebase
                            this.authService.deleteUser().then(async () => {
                              await this.router.navigate(['/home']).then(() => {
                                window.location.reload();
                              });
                            })
                          }).catch(() => {
                            //elimina usuario del modulo de autenticacion de usuarios de firebase
                            this.authService.deleteUser().then(async () => {
                              await this.router.navigate(['/home']).then(() => {
                                window.location.reload();
                              });
                            })
                          })
                        } else {
                          //elimina usuario del modulo de autenticacion de usuarios de firebase
                          this.authService.deleteUser().then(async () => {
                            await this.router.navigate(['/home']).then(() => {
                              window.location.reload();
                            });
                          })
                        }
                      })
                    }).catch(async () => {
                      return await this.presentToast1('Error E4033 al eliminar cuenta de usuario, por favor contacte a soporte App para continuar');
                    })
                  } else if (this.userRegis[0].perfil == "REPARTIDOR") {
                    //elimino data asociada al cliente en prerepartidor
                    this.firestore.deleteDoc('prerepartidor/', this.userRegis[0].uid).then(() => {
                      //elimino data asociada al cliente en usuarios
                      this.firestore.deleteDoc('usuarios/', this.userRegis[0].uid).then(() => {
                        //Si usuario tiene foto elimina foto y luego usuario autenticado
                        if (urlFotoPerfil != '') {
                          //elimino foto perfil del storage
                          this.firestorageService.deleteFotoUser(urlFotoPerfil).then(() => {
                            //elimina usuario del modulo de autenticacion de usuarios de firebase
                            this.authService.deleteUser().then(async () => {
                              await this.router.navigate(['/home']).then(() => {
                                window.location.reload();
                              });
                            })
                          }).catch(() => {
                            //elimina usuario del modulo de autenticacion de usuarios de firebase
                            this.authService.deleteUser().then(async () => {
                              await this.router.navigate(['/home']).then(() => {
                                window.location.reload();
                              });
                            })
                          })
                        } else {
                          //elimina usuario del modulo de autenticacion de usuarios de firebase
                          this.authService.deleteUser().then(async () => {
                            await this.router.navigate(['/home']).then(() => {
                              window.location.reload();
                            });
                          })
                        }
                      })
                    }).catch(async () => {
                      return await this.presentToast1('Error E4033 al eliminar cuenta de usuario, por favor contacte a soporte App para continuar');
                    })
                  } else if (this.userRegis[0].perfil == "PROVEEDOR") {
                    //elimino data asociada al cliente en precomercio
                    this.firestore.deleteDoc('precomercio/', this.userRegis[0].uid).then(() => {
                      //elimino data asociada al cliente en usuarios
                      this.firestore.deleteDoc('usuarios/', this.userRegis[0].uid).then(() => {
                        //Si usuario tiene foto elimina foto y luego usuario autenticado
                        if (urlFotoPerfil != '') {
                          //elimino foto perfil del storage
                          this.firestorageService.deleteFotoUser(urlFotoPerfil).then(() => {
                            //elimina usuario del modulo de autenticacion de usuarios de firebase
                            this.authService.deleteUser().then(async () => {
                              await this.router.navigate(['/home']).then(() => {
                                window.location.reload();
                              });
                            })
                          }).catch(() => {
                            //elimina usuario del modulo de autenticacion de usuarios de firebase
                            this.authService.deleteUser().then(async () => {
                              await this.router.navigate(['/home']).then(() => {
                                window.location.reload();
                              });
                            })
                          })
                        } else {
                          //elimina usuario del modulo de autenticacion de usuarios de firebase
                          this.authService.deleteUser().then(async () => {
                            await this.router.navigate(['/home']).then(() => {
                              window.location.reload();
                            });
                          })
                        }
                      })
                    }).catch(async () => {
                      return await this.presentToast1('Error E4033 al eliminar cuenta de usuario, por favor contacte a soporte App para continuar');
                    })
                  }
                } else {
                  return await this.presentToast1('Eliminación no fue confirmada, debe confirmar escribiendo CONFIRMAR y luego presionar PROCESAR');
                }
              }
            }
          ]
        });
        await alert.present();

      } else {
        return await this.presentToast1('Por seguridad es necesario cerrar la sesion actual e ingresar nuevamente para continuar con la eliminación de cuenta');
      }

    }).catch(async () => {
      return await this.presentToast1('¡Error E4034 al eliminar cuenta!, intente nuevamente si el problema error continua contacte con soporte App');
    })


  }
}
