import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { GetpositionService } from 'src/app/services/getposition.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  //creo una variable que me va a recibir la data del usuario desde componente principal
  @Input() uidUser: any;
  @Input() dataUser: User;

  //array que va guardando las ubicaciones con fecha
  ubicaRepartidor1: any = undefined;

  //suscripcion a observables
  private getdataubicaSuscriber: Subscription;
  private getdataubicaSuscriber1: Subscription;
  //02-02: guarda relacion con el observable de la app en primer plano
  private getResume: Subscription = undefined;
  private getResume1: Subscription = undefined;

  //Declaro una variable para el mensaje de espera en pantalla, relacionada a la funcion presentLoading()
  private loading1: any;
  private loading2: any;

  //variable que cambia a true cuando todo el componenente se ha cargado tottalmente
  cargalista = false;

  //defino valor de temporizador para guardar la ubicación del repartidor
  public timerRepartidor1: any;

  //02-02: esta variable guarda el valor de la ultima fecha de guardado
  private fechaUbica: any = undefined;
  private tiempoUbica: string;


  constructor(private menu: MenuController,
    private firestore: FirestoreService,
    private router: Router,
    public alertController34: AlertController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public getposition: GetpositionService,
    private platform: Platform,
    private getposition2: GetpositionService) {
    /*02-02: me desuscribo del observable de equipo en primer plano en caso que esté activo
    if(this.getResume!=undefined){
      this.getResume.unsubscribe();
      this.getResume=this.getResume1;
      console.log('entró a desuscripción de observable')
    }
    */
  }



  async ngOnInit() {
    //lo creo para visualizar la opcion ingresar cuando la carga del navbar este lista
    if (this.uidUser == null) {
      this.cargalista = true;
    }

    if (this.platform.is('capacitor') || this.platform.is('cordova') && this.dataUser.estado && this.dataUser.perfil == 'REPARTIDOR') {
      //02-02: me suscribo al evento de volver a pantalla cada ves que app viene de reposo para llamar al guardado de ubicacion
      this.getResume = this.platform.resume.subscribe(async () => {
        if (this.dataUser.estado && !this.dataUser.ocupado && this.dataUser.perfil == 'REPARTIDOR') {
          await this.guardaUbicacion(undefined);
        }
      })
    }
    /*comentado al 02-02-23: 10 segundos despues de la carga inicial del componenete guardo informacion de ubicacion
    setTimeout(async()=>{ 
        await this.registraUbicacion();
      }, 10000);
    */
  }

  // funcion que guarda la ubicacion de repartidor si la ultima ubicacion guardada tiene mas de 5minutos
  async guardaUbicacion(fechaUbicaIni: any) {
    //console.log('entro a la funcion guardaUbicacion', fechaUbicaIni);
    if (fechaUbicaIni != undefined && this.fechaUbica == undefined) {
      this.fechaUbica = fechaUbicaIni;
      //console.log('entro al primer if',this.fechaUbica)
    }
    //solo se entra a guardar información si desde la ultima vez que se guardo ubicación con esta funcion al momento
    //de vuelta a la pantalla principaln han pasado mas de 5 minutos
    //console.log('valor de this.fechaUbica en inicio',this.fechaUbica)
    if (this.fechaUbica != undefined) {
      //console.log('valor de this.fechaUbica en if de fechaUbica',this.fechaUbica)
      this.tiempoUbica = await this.calculaTiempopedido3(this.fechaUbica);
    }
    if (this.fechaUbica == undefined || this.tiempoUbica == 'caducado1') {

      //creo la ruta para consultar la ultima ubicacion del repartidor
      const pathu = 'usuarios/' + this.uidUser.uid + '/registraubicacion/';
      await this.firestore.getCollection5<any>(pathu, ref => (ref).orderBy('fecha', 'asc').limitToLast(1)).then(async ubicacion => {
        await ubicacion.forEach(async (doc: any) => {
          let valor: any[] = [];
          valor[0] = doc.data();
          let timeUbica = await this.calculaTiempopedido2(valor[0].fecha.toDate())
          //console.log('ENTRÓ A CONSULTAR BASE DE DATOS', timeUbica, this.fechaUbica)
          if (timeUbica == 'caducado' || this.fechaUbica == undefined) {
            //Activo el servicio para registrar la ubicacion en la entrega
            await this.getposition2.getRepartidorlocation().then(async () => {
              //Solicito Ubicación Actual del repartidor
              let traeUbicacion = await this.getposition2.getLocaterepartidor2();
              if (traeUbicacion != null) {
                const idUbica = this.firestore.getId();
                await this.firestore.createDoc2(traeUbicacion, pathu, idUbica).then(async () => {
                  //guardo en esta variable la ultima fecha de ubbicación guardada
                  this.fechaUbica = await traeUbicacion.fecha;
                  return await this.presentToast3('Ubicación actualizada')
                }).catch(async () => {
                });
              } else if (traeUbicacion == null) {
                //creo la ruta para consultar la ultima ubicacion del repartidor
                //const pathu2='usuarios/';
                //await this.firestore.updateDoc<User>({estado:false},pathu2,this.uidUserLog).then(async ()=>{        
                return await this.presentToast2('¡Repartidor, no se detecta acceso a su ubicación!')
                //})
              }
            })
          } else {
            return await this.presentToast3('Ubicación actualizada hace ' + timeUbica)
          }
        })
      })
    } else {
      if (this.tiempoUbica != undefined) {
        return await this.presentToast3('Ubicación fue actualizada hace: ' + this.tiempoUbica)
      }
    }
  }

  //02-02: esta funcion se utliza para contar el tiempo de las ordenes segun status
  async calculaTiempopedido2(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);

    if (minutes >= 0) {
      if (minutes == 0 && hours == 0) {
        let res: string;
        res = seconds + ' seg.';
        return res;
      }
      if (minutes < 5 && hours == 0) {
        let res: string;
        res = minutes + ' min y ' + seconds + ' seg.';
        return res;
      }
      if (minutes >= 5 || hours > 0) {
        let res: string;
        res = 'caducado';
        return res;
      }
    }
  }

  //02-02: esta funcion se utliza para contar el tiempo desde la ultima ubicacion guardada al momento que la aplicacion entra en primer plano
  async calculaTiempopedido3(startTime: Date) {
    // later record end time
    let endTime = new Date().getTime();
    // time difference in ms
    let timeDiff = endTime - startTime.getTime();
    // strip the ms
    timeDiff /= 1000;
    // get seconds
    let seconds = 0;
    seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    let minutes = 0;
    minutes = Math.round(timeDiff % 60)
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    let hours = 0;
    hours = Math.round(timeDiff % 24);

    if (minutes >= 0) {
      if (minutes == 0 && hours == 0) {
        let res: string;
        res = seconds + ' seg.';
        return res;
      }
      if (minutes < 5 && hours == 0) {
        let res: string;
        res = minutes + ' min y ' + seconds + ' seg.';
        return res;
      }
      if (minutes >= 5 || hours > 0) {
        let res: string;
        res = 'caducado1';
        return res;
      }
    }
  }



  async registraUbicacion() {
    //guardo informacion cuando el setime este en undefine y el reaprtidor este activo
    const path1 = 'usuarios/';
    if (this.dataUser != null && this.dataUser.perfil == 'REPARTIDOR' && this.dataUser.estado == true && this.timerRepartidor1 == undefined) {
      //Activo el servicio para obtener ubicación
      await this.getposition.getRepartidorlocation().then(() => {
        //Solicito Ubicación Actual del repartidor
        this.getdataubicaSuscriber = this.getposition.getLocaterepartidor().subscribe(async res => {
          if (await res) {
            this.ubicaRepartidor1 = res;
            //guardo valor en base de datos
            const path22 = 'usuarios/' + this.dataUser.uid + '/registraubicacion/';
            const idUbica = this.firestore.getId();
            await this.firestore.createDoc2(this.ubicaRepartidor1, path22, idUbica).then(async () => {
              //Inicio el Settime en el servicio para a los 5 minutos registrar ubicacion
              await this.iniciaSettime(300000, this.dataUser.uid);
              //me desuscribo del observable
              this.getdataubicaSuscriber.unsubscribe();
            }).catch(async () => {
              //Si ocurre un problema al guardar la primera ubicacion al activarse igual activo el setTime 
              // para continuar guardando proximos puntos
              await this.iniciaSettime(300000, this.dataUser.uid);
              //me desuscribo del observable
              this.getdataubicaSuscriber.unsubscribe();
            });
          } else {
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
            const popover = await this.loadingController.getTop();
            if (popover) {
              await popover.dismiss(null);
            }
            /*quito la desactivación automatica porque a partir de la api 31 en android se cambiaron
              formas de acceso por seguridad, solo se dejara la advertencia 29-01-23                     
              await this.firestore.updateDoc<User>({estado:false},path1,this.dataUser.uid).then(async ()=>{
              return await this.presentToast('No hay acceso a su ubicación, desconectando...')
            })
              */
            await this.presentToast('No se detecta acceso a la ubicación del dispositivo')
          }
        })
      })
    }
  }

  async iniciarSesion() {
    const alert = await this.alertController34.create({
      cssClass: 'normal',
      header: 'Confirmación',
      backdropDismiss: true,
      message: "¿Desea iniciar sesión para continuar?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'normal',
          id: 'cancel-button',
          handler: () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            await this.router.navigate(['/ingresar']);
          }
        }
      ]
    });
    await alert.present();
  }



  openMenusinlogin() {
    //me abre el menu de sesion
    this.menu.enable(true, 'menusinlog')
    this.menu.toggle('menusinlog')
  }

  openMenu() {
    //me abre el menu de sesion
    this.menu.enable(true, 'menuloged')
    this.menu.toggle('menuloged')
  }

  openMenuend() {
    //me abre el menu de sesion
    this.menu.toggle('menuend')
    this.menu.enable(false, 'menuloged')
  }

  //configuracion de la funcion que muestra mensaje de espera en Conexion
  async presentLoading1() {
    //crea mensaje
    this.loading1 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Activando...',
      //duration: 2000
    });
    //presenta mensaje
    await this.loading1.present();
  }

  //configuracion de la funcion que muestra mensaje de espera en Desconexion
  async presentLoading2() {
    //crea mensaje
    this.loading2 = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Desactivando...',
      //duration: 2000
    });
    //presenta mensaje
    await this.loading2.present();
  }

  //configuracion de la funcion que muestra mensaje de confirmacion
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'toast-background',
      duration: 2500,
    });
    await toast.present();
  }

  //configuracion de la funcion que muestra mensaje en Linea con Comercio
  async presentToast1(mensaje: string) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 2500,
      position: 'top'
    });
    await toast1.present();
  }

  //configuracion de la funcion que muestra mensaje fuera de Linea con Comercio
  async presentToast2(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'danger',
      cssClass: 'toast-background',
      duration: 2500,
      position: 'top'
    });
    toast2.present();
  }

  //configuracion de la funcion que muestra mensaje en Linea con Comercio
  async presentToast3(mensaje: string) {
    const toast1 = await this.toastController.create({
      message: mensaje,
      color: 'success',
      cssClass: 'toast-background',
      duration: 2500,
      position: 'middle'
    });
    await toast1.present();
  }


  async activaUser(User: User) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading1();
    const path = 'usuarios/';
    await this.firestore.updateDoc<User>({ estado: true }, path, User.uid).then(async () => {
      //Solo para repartidores se activar el servicio de guardar ubicacion
      if (User.perfil == 'REPARTIDOR') {
        //Activo el servicio para obtener ubicación
        await this.getposition.getRepartidorlocation().then(() => {
          //Solicito Ubicación Actual del repartidor
          this.getdataubicaSuscriber = this.getposition.getLocaterepartidor().subscribe(async res => {
            if (await res) {
              this.ubicaRepartidor1 = res;
              //guardo valor en base de datos
              const path2 = 'usuarios/' + User.uid + '/registraubicacion/';
              const idUbica = this.firestore.getId();
              await this.firestore.createDoc2(this.ubicaRepartidor1, path2, idUbica).then(async () => {
                //Inicio el Settime en el servicio para a los 5 minutos registrar ubicacion
                //02-02: await this.iniciaSettime(300000,User.uid);

                //console.log('valor de this.ubicaRepartidor1.fecha al gurdar ubicacion', this.ubicaRepartidor1.fecha)
                await this.guardaUbicacion(this.ubicaRepartidor1.fecha).then(() => {
                  if (this.getResume != undefined) {
                    this.getResume.unsubscribe();
                  }
                  this.getResume = this.platform.resume.subscribe(async () => {
                    if (this.dataUser.estado && !this.dataUser.ocupado && this.dataUser.perfil == 'REPARTIDOR') {
                      if (this.ubicaRepartidor1 != undefined) {
                        await this.guardaUbicacion(this.ubicaRepartidor1.fecha).then(() => {
                          this.ubicaRepartidor1 = undefined;
                        });
                      } else {
                        if (this.fechaUbica == undefined) {
                          await this.guardaUbicacion(undefined);
                        } else if (this.fechaUbica != undefined) {
                          await this.guardaUbicacion(this.fechaUbica);
                        }
                      }
                    }
                  })
                });

                //me desuscribo del observable
                this.getdataubicaSuscriber.unsubscribe();
              }).catch(async () => {
                /*Si ocurre un problema al guardar la primera ubicacion al activarse igual activo el setTime 
                para continuar guardando proximos puntos*/
                //02-02: await this.iniciaSettime(300000,User.uid);
                //me desuscribo del observable
                this.getdataubicaSuscriber.unsubscribe();
              });
            } else {
              //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
              const popover1 = await this.loadingController.getTop();
              if (popover1) {
                await popover1.dismiss(null);
              }
              await this.firestore.updateDoc<User>({ estado: false }, path, User.uid).then(async () => {
                return await this.presentToast('No hay acceso a su ubicación, desconectando...')
              })
            }
          })
        })
      }
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover2 = await this.loadingController.getTop();
      if (popover2) {
        await popover2.dismiss(null);
      };
      //muestro mensaje de Estado en Linea al Repartidor
      await this.presentToast1('¡EN LINEA!');
    }).catch(async () => {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover3 = await this.loadingController.getTop();
      if (popover3) {
        await popover3.dismiss(null);
      }
      User.estado = false;
      //muestro mensaje de problemas durante la solicitud al repartidor
      await this.presentToast('Ocurrio un problema durante la activación, intente nuevamente ')
    });
    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
    const popover4 = await this.loadingController.getTop();
    if (popover4) {
      await popover4.dismiss(null);
    }
  }

  async desactivaUser(User: User) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading2();
    const path = 'usuarios/';
    await this.firestore.updateDoc<User>({ estado: false }, path, User.uid).then(async () => {
      //Solo para repartidores desactivo
      if (User.perfil == 'REPARTIDOR') {
        //Desactivo el Contador 
        clearTimeout(this.timerRepartidor1);
        this.timerRepartidor1 = undefined;
        //02-02: me desuscribo del observable de equipo en primer plano en caso que esté activo
        if (this.getResume != undefined) {
          this.getResume.unsubscribe();
          this.getResume = this.getResume1;
        }
      }
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover55 = await this.loadingController.getTop();
      if (popover55) {
        await popover55.dismiss(null);
      }
      //muestro mensaje de Estado en Linea al Repartidor
      return await this.presentToast2('¡DESCONECTADO!');
    }).catch(async () => {
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover5 = await this.loadingController.getTop();
      if (popover5) {
        await popover5.dismiss(null);
      }
      User.estado = true;
      //Solo para repartidores desactivo
      if (User.perfil == 'REPARTIDOR') {
        //Desactivo el Contador 
        clearTimeout(this.timerRepartidor1);
        this.timerRepartidor1 = undefined;
      }
      //muestro mensaje de problemas durante la solicitud al repartidor
      return await this.presentToast('Ocurrio un problema al desactivarse, intente nuevamente ')
    });
    //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
    const popover6 = await this.loadingController.getTop();
    if (popover6) {
      await popover6.dismiss(null);
    }
  }

  //función para activar o desactivar el servicio de delivery en el comercio
  async activaDely(User: User) {
    if (User.deliveryOn) {
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading2();
      const path = 'usuarios/';
      await this.firestore.updateDoc<User>({ deliveryOn: false }, path, User.uid).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover55 = await this.loadingController.getTop();
        if (popover55) {
          await popover55.dismiss(null);
        }
        //muestro mensaje de Estado en Linea al Repartidor
        return await this.presentToast2('¡ENTREGAS CON DELIVERY DESACTIVADO!');
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover56 = await this.loadingController.getTop();
        if (popover56) {
          await popover56.dismiss(null);
        }
        User.deliveryOn = true;
        //muestro mensaje de problemas durante la solicitud al repartidor
        return await this.presentToast('Ocurrio un problema al DESACTIVAR MODO DELIVERY, intente nuevamente ')
      });
    } else if (!User.deliveryOn) {
      //llamo la funcion de mensaje en pantalla
      await this.presentLoading1();
      const path = 'usuarios/';
      await this.firestore.updateDoc<User>({ deliveryOn: true }, path, User.uid).then(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover57 = await this.loadingController.getTop();
        if (popover57) {
          await popover57.dismiss(null);
        }
        //muestro mensaje de Estado en Linea al Repartidor
        return await this.presentToast1('¡ENTREGAS CON DELIVERY ACTIVADO!');
      }).catch(async () => {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover58 = await this.loadingController.getTop();
        if (popover58) {
          await popover58.dismiss(null);
        }
        User.deliveryOn = false;
        //muestro mensaje de problemas durante la solicitud al repartidor
        return await this.presentToast('Ocurrio un problema al ACTIVAR MODO DELIVERY, intente nuevamente ')
      });
    }
  }


  //utilizo esta funcion para 
  async iniciaSettime(time: number, uidRepartidor: string) {
    //Desactivo setTimeout si  Hay activo 
    clearTimeout(this.timerRepartidor1);
    this.timerRepartidor1 = undefined;
    const path = 'usuarios/';
    //Solo inicio el Temporizador si el usuario esta en estado true
    if (this.dataUser.estado === true && this.dataUser.ocupado === false) {
      this.timerRepartidor1 = setTimeout(async () => {
        //Activo el servicio para obtener ubicación
        await this.getposition.getRepartidorlocation().then(() => {
          //Solicito Ubicación Actual del repartidor
          this.getdataubicaSuscriber1 = this.getposition.getLocaterepartidor().subscribe(async res => {
            if (await res) {
              this.ubicaRepartidor1 = res;
              //guardo valor en base de datos
              const path2 = 'usuarios/' + uidRepartidor + '/registraubicacion/';
              const idUbica = this.firestore.getId();
              await this.firestore.createDoc2(this.ubicaRepartidor1, path2, idUbica).then(async () => {
                //Inicio el Setime para a los 5 minutos registrar ubicacion
                await this.iniciaSettime(300000, uidRepartidor);
                //me desuscribo del observable
                this.getdataubicaSuscriber1.unsubscribe();
              }).catch(async () => {
                /*Si ocurre un problema al guardar la primera ubicacion al activarse igual activo el setTime 
                para continuar guardando proximos puntos*/
                await this.iniciaSettime(300000, uidRepartidor);
                //me desuscribo del observable
                this.getdataubicaSuscriber1.unsubscribe();
              });
            } else {
              /*quito la desactivación automatica porque a partir de la api 31 en android se cambiaron
              formas de acceso por seguridad, solo se dejara la advertencia 29-01-23                     
              await this.firestore.updateDoc<User>({estado:false},path,uidRepartidor).then(async ()=>{        
              return await this.presentToast('No hay acceso a su ubicación, desconectando...')
              })
              */
              await this.presentToast('No se detecta acceso a la ubicación del dispositivo')
            }
          })
        })
      }, time);
    }
  }


}
