import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ComercioAfiliado, Pedido, PedidoAsignado, User } from 'src/app/models/users.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UserlogedService } from 'src/app/services/userloged.service';

@Component({
  selector: 'app-repartos',
  templateUrl: './repartos.component.html',
  styleUrls: ['./repartos.component.scss'],
})
export class RepartosComponent implements OnInit, OnDestroy {

  showPicker = false;
  showPicker1 = false;
  dateValue = new Date();
  dateValue1 = new Date();
  hoy = new Date(this.dateValue.setHours(0, 0, 0, 0)).toISOString();
  minimaBusqueda: any;
  dateValue2: any;


  //declaracion de Observables para aplicar la desuscripcion
  private getdat1Suscriber: Subscription = undefined;
  private getcomerciosSuscriber: Subscription = undefined;
  private getRepartosSuscriber: Subscription = undefined;

  //creo una variable que me va a almacenar la data del comercio logueado
  public dataUserLog1: User = undefined;
  public comercios: ComercioAfiliado[] = [];
  public comercios2: ComercioAfiliado[] = [];
  public getPedidoscomer: Pedido[] = [];
  public getPedidoscomer2: any[] = [];
  public verResumenpedido: PedidoAsignado = undefined;
  public cargaPedido1: Pedido = undefined;

  //activa buscador de comercio
  public busquedaComercio = false;
  public totalComerciousd = 0;
  public nombreComercio = '';

  //variable que activa ver en pantalla completa los detalles del pedido
  public verDetallepedido = false;
  public verResumen = false;
  public cobradoComer = false;
  public cobradoRepar = false;
  public verRegistraconcilia = false;

  //guarda valor de settimer para cerrar el spiner cuando termina el ciclo for
  private terminaSpin1: any = undefined;

  constructor(private traeUid: UserlogedService,
    private firestore: FirestoreService,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController) {
    //se recibe en el constructor el uid capturado en el Servcio para capturar uid por medio de Subject
    this.getdat1Suscriber = this.traeUid.getDatauser().subscribe(res => {
      if (res) {
        this.dataUserLog1 = res;
      }
    })

  }

  ngOnInit() {
    //establezco la fecha minima de 3 meses atras para busquedas de historial de pedidos
    let vvv = new Date();
    let vv1 = vvv.setMonth(vvv.getMonth() - 3);
    this.minimaBusqueda = (new Date(vv1)).toISOString();
  }

  //se llama esta funcion para desuscribir observables
  async ngOnDestroy() {
    await this.desuscribeObser();
  }

  //función para desuscribir de observables
  async desuscribeObser() {
    if (this.getdat1Suscriber != undefined) {
      this.getdat1Suscriber.unsubscribe();
    }
    if (this.getcomerciosSuscriber != undefined) {
      this.getcomerciosSuscriber.unsubscribe();
    }
    if (this.getRepartosSuscriber != undefined) {
      this.getRepartosSuscriber.unsubscribe();
    }
  }

  //función para desuscribir de observables
  async desuscribeObser1() {
    if (this.getcomerciosSuscriber != undefined) {
      this.getcomerciosSuscriber.unsubscribe();
    }
    if (this.getRepartosSuscriber != undefined) {
      this.getRepartosSuscriber.unsubscribe();
    }
  }

  /*Este metodo se activa cuando me cambio de componenente, si el usuario decide navegar a otro compoenente antes
del cambio automatico se llama ngOnDestroy*/
  async ionViewDidLeave() {
    await this.desuscribeObser();
  }


  //funcion que me trae los repartidores
  getComercios() {
    this.getPedidoscomer2 = [];
    this.totalComerciousd = 0;
    this.nombreComercio = '';
    this.busquedaComercio = true;
    this.verRegistraconcilia = false;
    if (!this.comercios.length) {
      //creo la ruta para obtener los datos del comercio
      const path = 'usuarios/' + this.dataUserLog1.uid + '/comercioafiliado';
      //consulto repartidores afiliados al comercio
      this.getcomerciosSuscriber = this.firestore.getCollection<ComercioAfiliado>(path).subscribe(res => {
        if (res) {
          this.comercios = res;
        }
      });
    }
  }


  //funcion que busca el comercio de un listado por nombre 
  searchComercio(event) {
    //me desuscribo del observavble
    if (this.getcomerciosSuscriber != undefined) {
      this.getcomerciosSuscriber.unsubscribe();
    }
    this.comercios2 = this.comercios.filter((comercio: ComercioAfiliado) => {
      return ((comercio.nombre.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    });
  }

  //Funcion que maneja cambios relacionados a la fecha inicial de busqueda
  async dateChanged(value: any) {
    await this.desuscribeObser1();
    this.dateValue = value;
    this.dateValue1 = value;
    this.showPicker = false;
    let aaa = new Date(value);
    aaa.setHours(0, 0, 0, 0);
    let bbb = aaa.setMonth(aaa.getMonth() + 1);
    this.dateValue2 = (new Date(bbb)).toISOString();
    this.verResumenpedido = undefined;
    this.totalComerciousd = 0;
    this.nombreComercio = '';
    this.verResumen = false;
    this.getPedidoscomer2 = [];
  }

  //Funcion que maneja cambios relacionados a la fecha final de busqueda
  async dateChanged1(valuee: any) {
    await this.desuscribeObser1();
    this.dateValue1 = valuee;
    this.showPicker1 = false;
    this.verResumenpedido = undefined;
    this.totalComerciousd = 0;
    this.nombreComercio = '';
    this.verResumen = false;
    this.getPedidoscomer2 = [];
  }

  //n esta funcion se ve un detalle de pedido
  verDetalles(pedido1: PedidoAsignado) {
    let dateConver: any;
    dateConver = pedido1.fechapagocomer;
    this.verResumen = true;
    this.verResumenpedido = pedido1;
    this.verResumenpedido.fechapagocomer = dateConver.toDate();

  }

  //esta funcion se mira con detalle el pedido
  async verPedido(pedidoAsignn: PedidoAsignado) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.verDetallepedido = true;
    const path2 = 'usuarios/' + pedidoAsignn.idComercio + '/pedido/';
    await this.firestore.getDoc2<Pedido>(path2, pedidoAsignn.idPedido).then(async pedidoGet => {
      let fecha1 = pedidoGet.data().fechaEntrega.toDate();
      let fecha2 = pedidoGet.data().fechaPedido.toDate();
      this.cargaPedido1 = pedidoGet.data();
      this.cargaPedido1.fechaEntrega = fecha1;
      this.cargaPedido1.fechaPedido = fecha2;
      //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
      const popover1 = await this.loadingController.getTop();
      if (popover1) {
        await popover1.dismiss(null);
      }
    })
  }

  //esta funcion cierra la vista previa de un pedido
  cerrarVista() {
    this.verResumen = false;
  }

  //cierra la vista de resumen de pedido
  cerrarVista2() {
    this.verDetallepedido = false;
  }


  //consultar pedidos por repartidor
  async getPedidoscomercio(comercio: ComercioAfiliado) {
    //llamo la funcion de mensaje en pantalla
    await this.presentLoading();
    this.totalComerciousd = 0;
    this.busquedaComercio = false;
    const pathh = 'usuarios/' + comercio.uid + '/pedido/';
    let fechaInicial = new Date(this.dateValue);
    let fechaFinal = new Date(this.dateValue1);
    fechaInicial.setHours(0, 0, 0, 0);
    fechaFinal.setHours(23, 59, 59, 999);
    this.getRepartosSuscriber = this.firestore.getCollection3<Pedido>(pathh, ref => (ref.where('fechaPedido', '>=', fechaInicial).where('fechaPedido', '<=', fechaFinal).where('estado', '==', 'ENTREGADO').where('uidRepartidor', '==', this.dataUserLog1.uid))).subscribe(async repartos => {
      if (repartos.length) {
        this.getPedidoscomer = repartos;
        this.nombreComercio = comercio.nombre;
        //me desuscribo del observavble
        ///this.getRepartosSuscriber.unsubscribe();
        //filtro cobrados por comercio
        if (this.cobradoComer == true && this.cobradoRepar == false) {
          //limpio array definitivo de repartidores con pagos no conciliados por ellos
          this.getPedidoscomer2 = [];
          for await (let totales of this.getPedidoscomer) {
            let patth = 'usuarios/' + this.dataUserLog1.uid + '/pedidoasignado/';
            await this.firestore.getDoc2<PedidoAsignado>(patth, totales.id).then(async pedidoAsing => {
              let valor2: PedidoAsignado[] = [];
              valor2.push(await pedidoAsing.data());
              if (valor2[0].estatus == 'ENTREGADO' && totales.cobrodelivComercio && !totales.cobrodelivRepartidor) {
                //convertimos la fecha para que sea legible en la impresion html
                let fechaleg = pedidoAsing.data().fechaEntrega;
                valor2[0].fechaEntrega = fechaleg.toDate();
                this.getPedidoscomer2.push(valor2[0]);
              }
            });
          }
        }
        //filtro cobrados por repartidor
        if (this.cobradoComer == false && this.cobradoRepar == true) {
          //limpio array definitivo de repartidores con pagos no conciliados por ellos
          this.getPedidoscomer2 = [];
          for await (let totales of this.getPedidoscomer) {
            let patth = 'usuarios/' + this.dataUserLog1.uid + '/pedidoasignado/';
            await this.firestore.getDoc2<PedidoAsignado>(patth, totales.id).then(async pedidoAsing => {
              let valor2: PedidoAsignado[] = [];
              valor2.push(await pedidoAsing.data());
              if (valor2[0].estatus == 'ENTREGADO' && !totales.cobrodelivComercio && totales.cobrodelivRepartidor) {
                //convertimos la fecha para que sea legible en la impresion html
                let fechaleg = pedidoAsing.data().fechaEntrega;
                valor2[0].fechaEntrega = fechaleg.toDate();
                this.getPedidoscomer2.push(valor2[0]);
              }
            });
          }
        }
        //filtro cobrados por repartidor
        if (this.cobradoComer == false && this.cobradoRepar == false) {
          //limpio array definitivo de repartidores con pagos no conciliados por ellos
          this.getPedidoscomer2 = [];
          for await (let totales of this.getPedidoscomer) {
            let patth = 'usuarios/' + this.dataUserLog1.uid + '/pedidoasignado/';
            await this.firestore.getDoc2<PedidoAsignado>(patth, totales.id).then(async pedidoAsing => {
              let valor2: PedidoAsignado[] = [];
              valor2.push(await pedidoAsing.data());
              if (valor2[0].estatus == 'ENTREGADO') {
                //convertimos la fecha para que sea legible en la impresion html
                let fechaleg = pedidoAsing.data().fechaEntrega;
                valor2[0].fechaEntrega = fechaleg.toDate();
                this.getPedidoscomer2.push(valor2[0]);
              }
            });
          }
        }
        //al culminar el ciclo for await procedo a reducir duplicados(si los hay) por el id de pedido y enviarlo para la siguienter evaluacion
        this.getPedidoscomer2 = Array.from(this.getPedidoscomer2.reduce((map, obj) => map.set(obj.idPedido, obj), new Map()).values());
        //ordenamos el array por fecha de entrega
        this.getPedidoscomer2.sort(function compare(a, b) {
          return new Date(a.fechaEntrega).getTime() - new Date(b.fechaEntrega).getTime()
        });
        //en este for totalizo los pedidos totales no pagados
        this.totalComerciousd = 0;
        for await (let totales2 of this.getPedidoscomer2) {
          this.totalComerciousd = this.totalComerciousd + totales2.precio;
          if (this.terminaSpin1 != undefined) {
            clearTimeout(this.terminaSpin1);
          }
        }
        this.terminaSpin1 = undefined;
        this.terminaSpin1 = setTimeout(async () => {
          //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
          const popover = await this.loadingController.getTop();
          if (popover && this.terminaSpin1 != undefined) {
            await popover.dismiss(null);
          }
        }, 2000);
        //}else if(!repartos.length){
      } else if (!repartos.length && repartos) {
        //cierro el mensaje de pantalla relacionado a la funcion presentLoading()
        const popover1 = await this.loadingController.getTop();
        if (popover1) {
          await popover1.dismiss(null);
        }
        return await this.presentToast1('No se econtraron repartos con el Comercio Seleccionado')
      }
    })
  }


  //consultar pedidos pendiente por conciliar
  async getPedidoscomercio2() {
    let filtrado: PedidoAsignado[] = [];
    for await (let filtros of this.getPedidoscomer2) {
      if (!filtros.conciliado) {
        //convertimos la fecha para que sea legible en la impresion html
        filtrado.push(filtros)
      }
    }
    if (!filtrado.length) {
      return await this.presentToast1('No se encontraron repartos pendientes por conciliar para este Comercio en el periodo consultado')
    } else if (filtrado.length) {
      this.verRegistraconcilia = true;
      //en este for totalizo los pedidos totales no conciliados
      this.totalComerciousd = 0;
      for await (let totales3 of filtrado) {
        this.totalComerciousd = this.totalComerciousd + totales3.precio;
      }
      //le paso el nuevo valor con el filtro al array final
      this.getPedidoscomer2 = filtrado;
    }
  }


  //en esta funcion se registra un pago por lotes, minimo 1 dia
  async conciliarPago(totalConciliado: string) {
    //envio un mensaje diferente cuando se trata de una zona a cotizar
    const alert = await this.alertController.create({
      cssClass: 'normal',
      header: 'Registro de Pago',
      backdropDismiss: false,
      message: "¿Confirma que Verificó y Recibió conforme el pago Total por: <b>USD " + totalConciliado + "</b>, ó su equivalente en otra moneda?" +
        "Nota: Esta confirmación es definitiva",
      buttons: [
        {
          text: 'CANCELAR',
          id: 'cancel-button',
          handler: async () => {

          }
        }, {
          text: 'SI',
          id: 'confirm-button',
          handler: async () => {
            //llamo la funcion de mensaje en pantalla
            this.presentLoading();
            //creo la ruta para actualizar pago conciliados en pedido asignado
            const path1 = 'usuarios/' + this.dataUserLog1.uid + '/pedidoasignado/';
            for await (let pedidoasing of this.getPedidoscomer2) {
              //,referenciapagocomer:String(data1.referencia),fechapagocomer:(new Date(data1.fechapago))
              await this.firestore.updateDoc<PedidoAsignado>({ conciliado: true }, path1, pedidoasing.idPedido).then(async () => {
                this.totalComerciousd = 0;
                this.verResumen = false;
                this.getPedidoscomer2 = [];
              }).catch(async () => {
                //si hay un error al actualizar la zona del delivery
                return await this.presentToast1('No fue posible registrar el pago como conciliado, Intente Nuevamente ó comuniquese con soporte de App')
              });
            }
            //cierro el mensaje de pantalla relacionado a la funcion presentLoading() (en pruebas)
            const popover1 = await this.loadingController.getTop();
            if (popover1) {
              await popover1.dismiss(null);
              if (!this.getPedidoscomer2.length) {
                await this.presentToast('Registro de Conciliación Exitosa...');
              }
            } else {
              if (!this.getPedidoscomer2.length) {
                await this.presentToast('Registro de Conciliación Exitosa...');
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }



  //configuracion de la funcion que muestra un mensaje en pantalla
  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      cssClass: 'normal',
      duration: 2000,
      color: 'success',
      position: 'middle'
    });
    toast.present();
  }

  //configuracion de la funcion que muestra mensaje cuando se envia un mensaje de advertencia
  async presentToast1(mensaje: string) {
    const toast2 = await this.toastController.create({
      message: mensaje,
      color: 'warning',
      cssClass: 'normal',
      duration: 3000,
      position: 'middle'
    });
    toast2.present();
  }


  //configuracion de la funcion que muestra mensaje de espera
  async presentLoading() {
    //crea mensaje
    const loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Procesando...',
      //duration: 8000
    });
    //presenta mensaje
    await loading.present();
  }




}
