import { Component, OnInit } from '@angular/core';

// Importaciones relacionadas a publicidad Admob
 /* se inhabilita publicidad al 03-05-24
import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition
} from '@capacitor-community/admob';
*/
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-poliprivacidad',
  templateUrl: './poliprivacidad.component.html',
  styleUrls: ['./poliprivacidad.component.scss'],
})
export class PoliprivacidadComponent implements OnInit {

  constructor(private platform: Platform) { }

  async ngOnInit() {
    //await this.initializeAdmob();
  }

  //inicializo la publicidad Admob
  /* se inhabilita publicidad al 03-05-24
  async initializeAdmob() {

    //await this.platformh.ready();
    if (this.platform.is('cordova')) {
      const options: BannerAdOptions = {
        adId: 'ca-app-pub-4505046024910255/8420417432',
        adSize: BannerAdSize.BANNER,
        position: BannerAdPosition.BOTTOM_CENTER,
        margin: 0,
        isTesting: true,
        //isTesting: false,
        npa: false
      };

      await AdMob.initialize({
        requestTrackingAuthorization: false,
        //initializeForTesting: false,
        initializeForTesting: true,
      }).catch(err => console.error('Could not init Admob', err));

      await AdMob.showBanner(options).catch(err => console.error('Could not show Admob banner', err));;

    }

  }

  ionViewDidLeave() {
    if (AdMob && this.platform.is('cordova')) {
      AdMob.removeBanner();
    }
  }
*/
}
